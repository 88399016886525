import * as React from 'react';

type ArkiveViews = {
  welcome: {
    view: string;
    info: string;
    testButton: string;
  };
  homeBanner: {
    title: string;
    subTitle: string;
    description: string;
  };
  // Add more view types if needed
  navigation: {
    home: string;
    explore: string;
    aboutUs: string;
    educationResources: string;
    termsAndConditions: string;
    privacyPolicy: string;
    wildscreen: string;
    cookiePreferences: string;
    notPublishedYet: (taxon: string) => string;
  };
  cookieBanner: {
    title: string;
    description: readonly string[];
    privacyPolicy: {
      pre: string;
      linkCta: string;
      post: string;
    };
    cta: string;
    preferences: {
      title: string;
      essential: {
        title: string;
        description: string;
      };
      analytics: {
        title: string;
        description: string;
      };
    };
  };
  error404: {
    errorCode: string;
    errorMessage: string;
    errorDescription: string;
    backButtonCaption: string;
  };
  sentryErrorPage: {
    title: string;
    subtitle: string;
    descriptionDev: string;
    descriptionProd: (eventId: string) => string | JSX.Element;
    resetButtonCaption: string;
  };
  aboutUs: {
    title: string;
    team: {
      title: string;
      description: readonly (string | React.ReactNode)[];
    };
    what: {
      title: string;
      description: readonly (string | React.ReactNode)[];
    };
    why: {
      title: string;
      description: readonly (string | React.ReactNode)[];
      cta: string;
    };
  };
  educationResources: {
    title: string;
    downloadButton: string;
    ageCategory: string;
    subjects: string;
  };
  privacyPolicy: {
    heading: string;
    content: () => JSX.Element;
  };
  termsAndConditions: {
    heading: string;
    content: () => JSX.Element;
  };
};

type AuthenticatedAdminViews = {
  account: {
    heading: string;
    logoutButton: string;
    email: string;
  };
  search: {
    heading: string;
    placeholder: string;
    noResults: (query: string) => string;
    tooManyResults: (count: number, max: number) => string;
    buttonText: string;
  };
  taxonomy: {
    heading: string;
  };
  editTaxonomy: {
    heading: string;
    backToTaxonomy: string;
    backToSearch: string;
    discardChanges: string;
    saveAsDraft: string;
    publish: string;
    adaptations: string;
    habitat: string;
    landRegion: string;
    behaviour: string;
    biologicalThemes: string;
    threats: string;
    actions: string;
    placeholder: string;
    description: string;
    coverImage: string;
    uploadImage: string;
    scientificName: string;
    friendlyName: string;
    imageCreditText: string;
    characteristics: {
      heading: (isSpecies?: boolean) => string;
    };
    details: {
      save: string;
      commonName: string;
      heading: (isSpecies?: boolean) => string;
      redListStatus: string;
      friendlyName: string;
      friendlyBottomLabel: string;
    };
    taxonomy: {
      commonName: string;
      save: string;
      heading: string;
    };
    unpublish: string;
    toasts: {
      asset: {
        published: (e: 'published' | 'unpublished') => string;
        failed: string;
      };
      taxonomy: {
        published: (e: 'published' | 'unpublished') => string;
        failed: string;
      };
      educationResource: {
        published: (e: 'published' | 'unpublished') => string;
        failed: string;
      };
    };
  };
  assetGallery: {
    heading: string;
    upload: string;
    loading: string;
  };
  uploader: (re: boolean, entity: string) => string;
  assets: {
    heading: (edit: boolean) => string;
    back: string;
    actionAdvert: string;
    discardChanges: string;
    removeAsset: string;
    publish: string;
    details: {
      heading: string;
      assetId: string;
      originId: string;
      upload: (re: boolean) => string;
      title: string;
      description: string;
      credit: string;
      homepageGalleryWorthy: string;
    };
    contentTagging: {
      heading: string;
      species: string;
      behaviours: string;
      lifeStages: string;
      adaptations: string;
      habitats: string;
      regions: string;
      generalContentDescriptors: string;
    };
  };
  homepageContent: {
    heading: string;
  };
  libraryCredit: {
    heading: string;
    label: string;
    findOutMore: string;
    action: (e: 'Add' | 'Update' | 'Delete') => string;
    libraryName: string;
    libraryUrl: string;
    loading: string;
    success: (type: 'added' | 'updated' | 'deleted') => string;
    error: (type: 'create' | 'update' | 'delete', e: string) => string;
  };
  educationResources: {
    heading: string;
    upload: string;
    loading: string;
    form: {
      heading: (edit: boolean) => string;
      back: string;
      discardChanges: string;
      removeResource: string;
      publish: string;
      details: {
        heading: string;
        resourceId: string;
        originId: string;
        upload: (re: boolean) => string;
        title: string;
        description: string;
      };
      contentTagging: {
        heading: string;
        subjects: string;
        ageCategory: string;
        type: string;
        biologicalThemes: string;
        habitats: string;
        landRegions: string;
        threats: string;
        actions: string;
      };
    };
  };
};

type UnauthenticatedAdminViews = {
  // Add more view types if needed
  login: {
    login: string;
    email: string;
    password: string;
    loginButton: string;
    forgotPassword: string;
    errorLoggingIn: string;
    emailValidation: string;
    passwordValidation: string;
    confirmation: string;
  };
  forgotPassword: {
    title: string;
    email: string;
    forgotPasswordButton: string;
    successMessage: string;
  };
  resetPassword: {
    title: string;
    email: string;
    newPassword: string;
    confirmNewPassword: string;
    resetPasswordButton: string;
    emailValidation: string;
    passwordValidation: string;
    codeValidation: string;
    successMessage: string;
  };
};

type Components = Record<string, unknown>;
type TViewTypes = UnauthenticatedAdminViews | ArkiveViews | AuthenticatedAdminViews | any;

type TSectionViewAndComponents<T extends TViewTypes> = {
  views: T;
  components: Components;
};

type Section<T extends TViewTypes, K> = {
  authenticated: TSectionViewAndComponents<K>;
  unauthenticated: TSectionViewAndComponents<T>;
};

export type AppSections = {
  general: Record<string, any>;
  admin: Section<UnauthenticatedAdminViews, AuthenticatedAdminViews>;
  arkive: Section<ArkiveViews, any>;
};

const Spacer: React.FC = () => <div style={{ margin: '20px 0px' }} />;

export const dictionary: { en: AppSections } = {
  en: {
    general: {
      noResultsFound: 'No results found',
      back: 'Back',
      maintenance: {
        title: 'Site is currently under maintenance',
        message: "We're sorry for the inconvenience. Please try again later.",
      },
      species: 'Species',
      gallery: 'Gallery',
      searchResultsFor: 'Search results for',
    },
    admin: {
      authenticated: {
        views: {
          account: {
            heading: 'Account',
            email: 'Email',
            logoutButton: 'Log out',
          },
          search: {
            heading: 'Search',
            placeholder: 'Search for species...',
            noResults: query => `No results found for "${query}"`,
            tooManyResults: (count, max) =>
              `Your search returned ${count} results. Showing the first ${max}, please refine your search to see all the results.`,
            buttonText: 'Search',
          },
          taxonomy: {
            heading: 'Taxonomy',
          },
          uploader: (re: boolean, entity: string) => `${re ? 'Reu' : 'U'}pload ${entity}`,
          editTaxonomy: {
            heading: 'Edit Taxonomy',
            backToSearch: 'Back to results',
            backToTaxonomy: 'Back to taxonomy',
            discardChanges: 'Discard',
            saveAsDraft: 'Save as draft',
            publish: 'Publish',
            unpublish: 'Unpublish',
            scientificName: 'Scientific name',
            coverImage: 'Cover image',
            description: 'Description',
            friendlyName: 'Friendly name',
            imageCreditText: 'Image credit text',
            uploadImage: 'Upload image',
            adaptations: 'Adaptations',
            actions: 'Conservation actions',
            biologicalThemes: 'Biological Themes',
            behaviour: 'Behaviour',
            landRegion: 'Global region',
            habitat: 'Habitat',
            threats: 'Threats',
            placeholder: 'Start typing to show options...',
            characteristics: {
              heading: (isSpecies?: boolean) => `${isSpecies ? 'Species' : 'Classification'} characteristics`,
            },
            details: {
              heading: (isSpecies?: boolean) => `${isSpecies ? 'Species' : 'Classification'} detail`,
              friendlyBottomLabel: 'If completed, this will be the default name.',
              redListStatus: 'Red list status',
            },
            taxonomy: {
              commonName: 'Common name',
              save: 'Save',
              heading: 'Taxonomy',
            },
            toasts: {
              taxonomy: {
                published: e => `Taxonomy ${e} successfully. It may take up to 24 hours to reflect on the site.`,
                failed: 'Failed to publish taxonomy.',
              },
              asset: {
                published: e => `Asset ${e} successfully.`,
                failed: 'Failed to publish asset.',
              },
              educationResource: {
                published: e => `Resource ${e} successfully.`,
                failed: 'Failed to publish education Resource.',
              },
            },
          },
          assetGallery: {
            heading: 'Asset Gallery',
            upload: 'Upload asset',
            loading: 'Loading assets...',
          },
          homepageContent: {
            heading: 'Homepage Content',
          },
          libraryCredit: {
            heading: 'Library Management',
            action: (e: 'Add' | 'Update' | 'Delete') => `${e} Library`,
            libraryName: 'Library Name',
            findOutMore: 'Find out more',
            label: 'Associate with Library',
            libraryUrl: 'Library URL',
            loading: 'Loading libraries...',
            success: (type: 'added' | 'updated' | 'deleted') => `Library ${type} successfully`,
            error: (type: 'add' | 'update' | 'delete', e: string) => `Failed to ${type} library: ${e}`,
          },
          educationResources: {
            heading: 'Education Resources',
            upload: 'Upload resource',
            loading: 'Loading resources...',
            form: {
              heading: (edit: boolean) => `${edit ? 'Edit' : 'Upload'} Resource`,
              back: 'Back to education resources',
              publish: 'Publish',
              discardChanges: 'Discard',
              removeResource: 'Remove resource',
              details: {
                heading: 'Resource details',
                upload: (e: boolean) => `${e ? 'Reu' : 'U'}pload resource`,
                title: 'Resource title',
                description: 'Description',
              },
              contentTagging: {
                heading: 'Content tagging',
                subjects: 'Subjects',
                ageCategory: 'Age category',
                type: 'Resource type',
                biologicalThemes: 'Biological themes',
                habitats: 'Habitats',
                landRegions: 'Global regions',
                threats: 'Threats',
                actions: 'Conservation actions',
              },
            },
          },
          assets: {
            heading: (edit: boolean) => `${edit ? 'Edit' : 'Upload'} Asset`,
            back: 'Back to asset gallery',
            actionAdvert: 'Action advert',
            publish: 'Publish',
            discardChanges: 'Discard',
            removeAsset: 'Remove asset',
            details: {
              heading: 'Asset details',
              assetId: 'Wildscreen asset ID',
              originId: 'Origin ID',
              upload: (e: boolean) => `${e ? 'Reu' : 'U'}pload asset`,
              title: 'Asset title',
              description: 'Description',
              credit: 'Credit/licence text',
              homepageGalleryWorthy: 'Home page gallery worthy',
            },
            contentTagging: {
              heading: 'Content tagging',
              species: 'Species',
              behaviours: 'Behaviour',
              lifeStages: 'Life stage',
              adaptations: 'Adaptations',
              habitats: 'Habitat',
              regions: 'Global region',
              generalContentDescriptors: 'General content descriptors',
            },
          },
        },
        components: {},
      },
      unauthenticated: {
        views: {
          login: {
            email: 'Email',
            login: 'Login',
            password: 'Password',
            loginButton: 'Login',
            forgotPassword: 'Forgot password?',
            emailValidation: 'Must be a valid email',
            passwordValidation: 'Must be at least 1 character',
            errorLoggingIn: 'Username or password is incorrect',
            confirmation: 'Set new password',
          },
          forgotPassword: {
            title: 'Forgot password',
            email: 'Email',
            forgotPasswordButton: 'Forgot password',
            successMessage: 'A password reset email has been sent if such an account exists',
          },
          resetPassword: {
            title: 'Reset password',
            email: 'Email',
            confirmNewPassword: 'Confirm new password',
            newPassword: 'New password',
            resetPasswordButton: 'Reset password',
            emailValidation: 'Must be a valid email',
            passwordValidation: 'Must be at least 1 character',
            codeValidation: 'Must be at least 6 numbers',
            successMessage: 'Password reset successfully',
          },
        },
        components: {},
      },
    } as Section<UnauthenticatedAdminViews, AuthenticatedAdminViews>,
    arkive: {
      authenticated: {
        components: {},
        views: {},
      },
      unauthenticated: {
        views: {
          welcome: {
            view: 'welcome',
            info: 'Hello. It is me, the homepage. Here is some data from context:',
            testButton: 'Test button',
          },
          homeBanner: {
            title: 'Exploring Nature’s Stories',
            subTitle: 'This is Wildscreen’s nature education hub!',
            description:
              'Search videos, pictures and information about the awesome variety of life on Earth and its conservation, generously shared by our network of content contributors - because you can’t save what you can’t see.',
          },
          navigation: {
            home: 'Home',
            explore: 'Explore',
            aboutUs: 'About Us',
            educationResources: 'Education resources',
            privacyPolicy: 'Privacy policy',
            termsAndConditions: 'Terms and conditions',
            wildscreen: 'Wildscreen',
            cookiePreferences: 'Cookie preferences',
            notPublishedYet: (taxon: string) => `${taxon} has not been published yet!`,
          },
          cookieBanner: {
            title: 'We value your privacy',
            description: [
              "We use necessary cookies to make our site work. We'd also like to use analytics cookies that help us make improvements by measuring how you use the site. These will be set only if you select 'Save and close'.",
            ],
            privacyPolicy: {
              pre: 'For more detailed information about cookies we use, please see our ',
              linkCta: 'Privacy Policy',
              post: '.',
            },
            cta: 'Save and close',
            preferences: {
              title: 'Cookie preferences',
              essential: {
                title: 'Essential cookies',
                description: 'To enable core functionality such as security, network management, and accessibility',
              },
              analytics: {
                title: 'Analytics cookies',
                description: "We'd like to set Google Analytics cookies to improve our website.",
              },
            },
          },
          error404: {
            errorCode: '404',
            errorMessage: 'Lost in the wild',
            errorDescription: "We couldn't find what you were looking for",
            backButtonCaption: 'Back to home',
          },
          sentryErrorPage: {
            title: 'Oops!',
            subtitle: 'Something went wrong',
            descriptionProd: (eventId: string) => (
              <>
                Click &lsquo;Retry&rsquo; or refresh the page. <br />
                If the problem continues, please email us at{' '}
                <a
                  href="mailto:hello@wildscreen.org.uk"
                  style={{ display: 'inline', color: 'inherit', textDecoration: 'underline' }}
                >
                  hello@wildscreen.org.uk
                </a>{' '}
                and mention ID {eventId}.
              </>
            ),
            descriptionDev: 'Sentry has detected an error. This message will be redacted in production.',
            resetButtonCaption: 'Retry',
          },
          aboutUs: {
            title: 'About Us',
            team: {
              title: 'The team behind ARK',
              description: [
                'Our backgrounds range from filmmaking to foraminifera, from education to environmental research, from management to marine biology - united by a passion that the natural world is for everyone.',
                <>
                  <br />
                  Wildscreen ARK aims to:
                  <ul>
                    <li>Make engaging educational nature content available to teenagers for free</li>
                    <li>
                      Support educators by offering trusted resources so they can teach about natural history and the
                      environment with confidence.
                    </li>
                    <li>
                      Build routes into the filmmaking, media and conservation industries for young people, particularly
                      those with different life experiences
                    </li>
                    <li>
                      Continue the legacy of ARKive, the world’s leading audio-visual encyclopaedia of life on Earth
                    </li>
                  </ul>
                </>,
                'You can read more about the team here.',
                <>
                  <br />
                  If you would like to be involved in user testing or to support us as an educator, please contact{' '}
                  <a data-no-style={true} href="mailto:hello@wildscreen.org">
                    hello@wildscreen.org
                  </a>
                  .
                </>,
              ],
            },
            what: {
              title: 'What is Wildscreen?',
              description: [
                <>
                  <a data-no-style={true} href="https://wildscreen.org/about-us/vision/">
                    Wildscreen
                  </a>{' '}
                  is a conversation charity with a global impact. We connect creatives within the wildlife film,
                  television and photography industry with conservationists to raise awareness about the environmental
                  crisis and inspire positive change. We envision a world where natural world storytelling is inclusive,
                  accessible and impactful and where nature is protected and thriving.
                </>,
                <>
                  We democratise the creation of and access to nature’s stories by:
                  <ul>
                    <li>Supporting and celebrating the natural world storytelling industry</li>
                    <li>Connecting creativity with conservation by convening storytellers and conservationists</li>
                    <li>Nurturing a global community and next generation of storytellers</li>
                    <li>
                      Raising awareness of conservation through educating and connecting local communities with the
                      natural world through stories
                    </li>
                  </ul>
                </>,
                <>
                  <a
                    data-no-style={true}
                    href="https://us1.list-manage.com/subscribe?u=722a3ad7755a24108f2cd402e&id=e747e24e84"
                  >
                    Click here
                  </a>{' '}
                  to sign up for general newsletter updates from Wildscreen
                </>,
              ],
            },
            why: {
              title: 'Why Wildscreen ARK?',
              description: [
                <>
                  Wildscreen ARK is the newest version of ARKive, a project created by Christopher Parsons, a founding
                  member of the BBC Natural History Unit and the Wildscreen charity. He was passionate about projects
                  that brought nature to a wider audience. ARKive was one of these. A huge, free, multimedia project
                  documenting the lives of 16,000+ endangered species with over 100,000 videos, photos and authenticated
                  fact files. It was with regret that ARKive was retired in 2019, so we are excited to announce a new
                  chapter in its history.
                  <Spacer />
                  There has never been a greater need for young people to feel connected to nature. We’d like Wildscreen
                  ARK to inspire curiosity about the natural world and empower people to protect it. We do this by
                  giving you a hub that takes you on a journey from the nature you care about, to the nature you might
                  care about in the future.
                  <Spacer />
                  Wildscreen ARK is generously funded by The Linbury Trust, Garfield Weston Foundation and The Nisbet
                  Trust, with in-kind support from Rocketmakers.
                </>,
                <>
                  <br />
                  Please consider a Donation to Wildscreen to support Wildscreen ARK
                </>,
              ],
              cta: 'Donate',
            },
          },
          educationResources: {
            title: 'Education resources',
            downloadButton: 'Download',
            ageCategory: 'Age category',
            subjects: 'Subjects',
          },
          privacyPolicy: {
            heading: 'Privacy Policy',
            content: () => (
              <>
                <p>
                  At Wildscreen, we take your privacy very seriously and we promise to do our utmost to safeguard your
                  personal information. This privacy policy describes how and why any personal information collected
                  from you will be used, stored and processed by us and also outlines your rights and choices regarding
                  your information. It applies to all activities and websites operated by Wildscreen.
                </p>
                <h2>Who we are</h2>
                <p>
                  Wildscreen is a conservation charity based in Bristol, UK. Wildscreen envisions a world where natural
                  world storytelling is inclusive, accessible and impactful and where nature is protected and thriving.
                  Wildscreen connects people with nature through storytelling.
                  <Spacer />
                  Wildscreen is a charitable company limited by guarantee and registered in England and Wales
                  (registered charity number 299450 and company number 2206559), whose registered office is at Unit
                  1.11, Temple Studios, Temple Gate, Bristol BS1 6QA, United Kingdom.
                  <Spacer />
                  Wildscreen wholly owns the subsidiary companies, Wildscreen Trading Ltd and Wildscreen Network Ltd,
                  which carry out activities which may be advantageous and ancillary to the charitable objects of
                  Wildscreen, and donates all profits to the charitable company. Our subsidiaries may process personal
                  information in accordance with Wildscreen’s instructions and policies.
                  <Spacer />
                  The charity and trading subsidiaries are different and separate entities. Within this policy, “we”,
                  “us”, and “our”, mean both the charity and its subsidiaries.
                  <Spacer />
                  Wildscreen is registered as a data controller with the Information Commissioner’s Office with the
                  registration number Z2258589.
                </p>
                <h2>Privacy Policy</h2>
                <p>
                  <strong>What personal information do we collect and when?</strong>
                  <Spacer />
                  Personal information is data that can be used to identify you that you give us, for example by filling
                  in a form, visiting our website or by corresponding with us by phone or e-mail.
                  <Spacer />
                  Depending on the purpose for which it’s being collected, the personal information that you give us may
                  include your:
                  <ul>
                    <li>name</li>
                    <li>date of birth</li>
                    <li>
                      contact details, including postal address, phone number, email address, social media identity
                    </li>
                    <li>job title and employer name</li>
                    <li>gender</li>
                  </ul>
                  To identify you, contact you and tailor our services to your needs, to assist with networking
                  services, to credit you correctly on content we feature, monitor and report who we receive charitable
                  donations from;
                  <ul>
                    <li>
                      bank or credit card details – to provide you with a service, fulfil a contract or refund you;
                    </li>
                    <li>
                      IP address and geolocation, and device used to access our websites – to monitor our website usage
                      and impact, identify areas to focus charitable goals and direct you to services local to you,
                      related to our charitable goals;
                    </li>
                    <li>
                      activities carried out on our websites – to improve the service we offer through our website
                    </li>
                  </ul>
                  We may collect personal information when you:
                  <ul>
                    <li>contact us by telephone, email or social media</li>
                    <li>use our website(s)</li>
                    <li>purchase products and services from us, such as event tickets</li>
                    <li>make a donation</li>
                    <li>attend one of our events</li>
                    <li>create a user account for our website(s)</li>
                    <li>subscribe to receive email updates (requires your consent)</li>
                    <li>enter a prize draw or competition</li>
                    <li>fill out a survey or questionnaire</li>
                    <li>licence your photographs or videos to us</li>
                    <li>apply for a bursary</li>
                    <li>use our free wi-fi</li>
                    <li>supply personal details for a publicly accessible source, such as LinkedIn</li>
                    <li>agree to participate in an event or competition e.g. as a speaker or judge</li>
                    <li>ask for press materials</li>
                    <li>apply for a job or volunteering opportunity placement with us</li>
                    <li>become a supplier</li>
                    <li>become a sponsor</li>
                    <li>make a grant to us</li>
                    <li>otherwise provide us with your personal information through other means.</li>
                  </ul>
                  We may also, with your explicit consent, collect and store sensitive personal information such as data
                  about health, race, religious beliefs and sexuality for diversity and inclusion purposes and to fulfil
                  our mission of increasing accessibility and inclusion within the industry.
                  <Spacer />
                  We only collect this type of information where there is a clear reason for us to do so, such as if you
                  are applying for a work/volunteering opportunity or in order to provide you with appropriate
                  facilities or support. Wherever possible we will make it clear why we are collecting this type of
                  information and what it will be used for.
                  <Spacer />
                  <strong>What do we do with your personal information?</strong>
                  <Spacer />
                  Depending on what personal information was collected and how, we may use your information to:
                  <ul>
                    <li>further our charitable objectives</li>
                    <li>provide a service to you and/or fulfil a contractual obligation</li>
                    <li>process donations, purchases or other payments and verify financial transactions</li>
                    <li>process Gift Aid claims</li>
                    <li>manage our events</li>
                    <li>provide you with customer support</li>
                    <li>address any enquiries, concerns or complaints you have raised</li>
                    <li>create a profile about you to help us personalise our services to you</li>
                    <li>deliver relevant advertising and marketing</li>
                    <li>measure and understand the effectiveness of advertising</li>
                    <li>provide information, at your request, about ways to support Wildscreen’s activities</li>
                    <li>
                      carry out research and analysis to better understand our users and improve our services and
                      products
                    </li>
                    <li>check on your marketing preferences from time to time to ensure they’re up to date</li>
                    <li>prevent or detect unlawful behaviour</li>
                    <li>conduct due diligence and ethical screening</li>
                    <li>safeguard our staff and volunteers</li>
                    <li>meet accessibility requirements, where specifically required and consent is provided</li>
                    <li>process your application for a job, volunteering opportunity or bursary</li>
                    <li>protect your vital interests</li>
                    <li>fulfil any obligations laid out in legislation</li>
                  </ul>
                </p>
                <p>
                  <strong>Legal basis for processing</strong>
                  <Spacer />
                  Our legal basis for processing and storing personal information will depend on when and why you have
                  provided us with your personal information. This can be found in the table below:
                  <Spacer />
                  <table>
                    <thead>
                      <tr>
                        <th>Purposes</th>
                        <th>Lawful basis</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>We may use your personal information identify you and tailor our services to you.</td>
                        <td>
                          Legitimate interests - it is our legitimate interest to provide the a service that is suited
                          to you and it is your legitimate interest to be provided with a service that it tailored to
                          you.
                        </td>
                      </tr>
                      <tr>
                        <td>
                          We will use your contact information to provide information that you have requested from us.
                        </td>
                        <td>
                          Legitimate interests - it is your legitimate interest to receive information that you have
                          requested from us.
                        </td>
                      </tr>
                      <tr>
                        <td>
                          We will use your personal information to credit you correctly in the content we feature.
                        </td>
                        <td>Legitimate interests - you have a legitimate interest in being credited correctly.</td>
                      </tr>
                      <tr>
                        <td>We may use your contact details to assist in networking.</td>
                        <td>
                          Legitimate Interests - it is a legitimate interest of yours that any networking you would like
                          to do is facilitated appropriately.
                        </td>
                      </tr>
                      <tr>
                        <td>
                          We will use your bank or credit card details to provide you with a service, fulfil a contract
                          or refund you.
                        </td>
                        <td>
                          Contract - we will use this to fulfil our contract with you where we have an agreement in
                          place with you.
                        </td>
                      </tr>
                      <tr>
                        <td>Where you have agreed to this, we will send you updates about the work of the charity.</td>
                        <td>
                          Consent - if we are corresponding with you via email we need your prior consent to do so. By
                          post, we will rely on legitimate interests, as it is a legitimate interest of the charity to
                          promote its aims and ideals.
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Monitoring and reporting on donations, and complying with our legal and regulatory
                          obligations.
                        </td>
                        <td>
                          We may have to hold your personal data to comply with our legal and regulatory obligations,
                          such as our obligation to hold information about the source of donations. Where we have a
                          legal requirement to keep this information, we rely on legal requirement as our lawful basis.
                          Where there is not a specific legal requirements, we rely on public task, because such
                          information is held for a public task carried out in the public interest. This will also apply
                          if we have to disclose your personal data to a regulatory body or agency.
                        </td>
                      </tr>
                      <tr>
                        <td>
                          We will collect your IP address and geolocation to monitor the usage and impact, identify
                          areas to focus charitable goals and direct you to services local to you.
                        </td>
                        <td>Consent</td>
                      </tr>
                      <tr>
                        <td>
                          We may use cookies on our website to help improve the usability and functionality of our
                          website. Please see our cookie policy for more details.
                        </td>
                        <td>Consent</td>
                      </tr>
                      <tr>
                        <td>
                          We may, with your consent, collect data about your health, race, religious beliefs and
                          sexuality to fulfil our mission of increasing accessibility and inclusion within the industry
                        </td>
                        <td>Explicit consent</td>
                      </tr>
                    </tbody>
                  </table>
                </p>
                <p>
                  <strong>Keeping your data safe</strong>
                  <Spacer />
                  We take steps to ensure all your personal information is safe and secure, and that all staff are aware
                  of and comply with their responsibilities in relation to the UK General Data Protection Regulation and
                  the Data Protection Act 2018. For example:
                  <ul>
                    <li>we have a formal data protection policy and procedures in place.</li>
                    <li>all staff undergo training in data protection requirements, with an annual review.</li>
                    <li>
                      access to personal information is based on a ‘need to know’ basis and restricted to people who
                      require it for legitimate purposes only.
                    </li>
                    <li>we shred paper documents.</li>
                    <li>
                      we have a formal retention schedule in place to ensure that we only keep personal information for
                      an appropriate length of time.
                    </li>
                    <li>we have security locks for our computers to prevent unauthorised access when unattended.</li>
                    <li>we enforce regular password changes through our IT systems.</li>
                    <li>
                      we use industry standard security to encrypt your personal information when it is in transit to
                      our servers. This includes firewalls, SSL encryption and PCI security compliance.
                    </li>
                    <li>
                      the servers that store personal information are in a secure environment in a locked facility.
                    </li>
                  </ul>
                </p>
                <p>
                  <strong>Sharing your data</strong>
                  <Spacer />
                  With your consent, we may need to share your data with approved data processors to fulfil a service
                  for you.This can include email delivery, website hosting and analytics, payment processing and
                  audience surveys.
                  <Spacer />
                  These data processors are given access to the personal information needed to perform their functions
                  but are restricted from using it for other purposes. We require them to comply with data protection
                  regulations and we monitor them to ensure their compliance. Some of our partners have servers located
                  in countries where there is not an equivalent level of protection of your data as exists within the
                  UK. This means that when we collect and process your personal information via their service, your data
                  is transmitted between the UK and another country. We will take steps to ensure your privacy continues
                  to be protected in line with the UK General Data Protection Regulation and the Data Protection Act
                  2018.
                  <Spacer />
                  If our business is sold we will transfer your personal data to a third party: in the event that we
                  sell or buy any business or assets, in which case we will disclose your personal data to the
                  prospective seller or buyer of such business or assets (at all times in accordance with all applicable
                  data protection laws); or if Wildscreen or substantially all of its assets are acquired by a third
                  party, in which case personal data held by Wildscreen about its clients (including those individuals
                  who work for and on behalf of our clients) will be one of the assets transferred to the purchaser, in
                  each case, the legal basis on which we process your data in these circumstances is our legitimate
                  interest to ensure our business can be continued by a purchaser. If you object to our use of personal
                  data in this way, the relevant seller or buyer of our business may not be able to provide services to
                  you.
                  <Spacer />
                  In certain circumstances we may also need to share your personal data if we are under a duty to
                  disclose or share personal data in order to comply with any legal obligation.
                </p>
                <p>
                  <strong>Your rights</strong>
                  <Spacer />
                  You have the following rights with regard to your personal information:
                  <Spacer />
                  Withdraw your consent at any time: You have the right to withdraw consent where you have previously
                  given consent to the processing of your personal information, e.g. unsubscribing from marketing
                  communications.
                  <Spacer />
                  Object to the processing of your personal data: You have the right to object to the processing of your
                  personal information if we are doing so on a legal basis other than consent e.g. when we supply a
                  purchase that you’ve made.
                  <Spacer />
                  Access your personal data: You have the right to access data we hold about you. We will need you to
                  prove your identity before we release any personal information to you
                  <Spacer />
                  Rectification or erasure of your personal data: You have the right to verify the accuracy of your
                  data, ask for it to be updated or correct, and to request that we delete it (unless we have the legal
                  right of obligation to retain it).
                  <Spacer />
                  Restrict the processing of your personal data: You have the right to restrict the use of your personal
                  information if it is inaccurate, our use of your information is unlawful or if we no longer need to
                  use the data for the purposes for which we hold it.
                  <Spacer />
                  Receive and transfer your personal data to another controller: you have the right to receive your
                  information in a structured, electronic format, and to transmit that data to another data controller.
                  This right applies where the personal information is processed by automated means and on the basis of
                  your consent (such as marketing) or performance of a contract (such as a purchase).
                  <Spacer />
                  Lodge a complaint: You have the right to make a complaint to the Information Commissioner’s Office,
                  although we ask to first be given the opportunity to resolve the issue.
                </p>
                <p>
                  <strong>Protecting the privacy of children</strong>
                  <Spacer />
                  While some of our website content and events may be of interest to children and young people we do not
                  knowingly collect personal information from children under the age of 13. If you’re aged 13 or under,
                  you must get your parent/guardian’s permission before you use our websites or provide us with any
                  personal information.
                  <Spacer />
                  Analytics data that is collected from young people’s use of Wildscreen ARK is solely used to improve
                  the features and service of the website. No personal information beyond general location and device
                  used to access Wildscreen ARK is collected; all IP addresses are anonymised by service providers to
                  protect the privacy of children.
                  <Spacer />
                  We do not pass on behaviour-related data to any third party advertisers and any advertisements
                  displayed on Wildscreen ARK have to pass a Data Protection Impact Assessment to mitigate any harm this
                  may cause young people.
                  <Spacer />
                  In the few instances where we might knowingly collect personal information from children, we always
                  seek parental consent and will only ever collect such information for the purposes specified when we
                  collect it.
                  <Spacer />
                  If you become aware that you or any child under your care has provided us with information without
                  your consent, please send an email to <a href="mailto:hello@wildscreen.org">hello@wildscreen.org</a>.
                </p>
                <p>
                  <strong>Use of photographs and video</strong>
                  <Spacer />
                  When a photographer commissioned by Wildscreen is present at any of our events, we may use the
                  resulting images on our websites, social media platforms and in our publicity materials. Permission to
                  use the images for promotional purposes is requested where the person is featured prominently in the
                  image but not when they are in the background. If you have a valid reason why an image should not be
                  used, please send an email to hello@wildscreen.org to request its removal and we will endeavour to
                  fulfil your request where possible.
                </p>
                <p>
                  <strong>Why do we use cookies?</strong>
                  <Spacer />
                  When you interact with us through our websites or by reading our emails we and third parties may
                  collect information by using cookies. We do this for three reasons:
                  <Spacer />
                  To provide you with the service you’ve asked for: For example, we set a cookie when you have logged
                  into your web account on our websites so that the site remembers you and you don’t have to repeatedly
                  log back in every time you visit a new page.
                  <Spacer />
                  To analyse and improve your experience: We want to know what web pages and content are popular and
                  what types of people are interested in them so that we can improve our sites.
                  <Spacer />
                  To provide you with adverts that are relevant to your interests: We use Google Adwords to promote our
                  websites with adverts that show alongside Google search results. You may be shown adverts tailored to
                  you based on the pages you’ve viewed during previous visits to our websites.
                  <Spacer />
                  You have the right to decide whether to accept or reject cookies and can control and/or delete them as
                  you wish. Note that if you choose to reject cookies, your access to some website content and
                  functionality may be restricted. For further information, see our Cookie Policy.
                  <Spacer />
                </p>
                <p>
                  <strong>For how long do we store your data?</strong>
                  <Spacer />
                  We will not retain your personal information for any longer than we need to use it in accordance with
                  this policy. Unless we tell you otherwise (or you make a request for erasure), we will keep your
                  personal information for as long as we have an active relationship, or you continue to want us to keep
                  in touch with you.
                  <Spacer />
                  Note that even if you request that we erase your data, we may still be required to keep it, or may
                  keep it in a form that doesn’t identify you, for the performance of a legal obligation such as
                  financial records.
                  <Spacer />
                  Once we no longer need to retain your personal information, it shall be deleted. Therefore, the rights
                  to access, erasure, rectification and data portability cannot be enforced after the expiration of the
                  retention period.
                  <Spacer />
                </p>
                <p>
                  <strong>Third party websites</strong>
                  <Spacer />
                  Our websites might contain links to websites belonging to third parties and we sometime choose to
                  participate in social networking websites such as Twitter and Facebook. This privacy policy does not
                  extend to these third party websites and we do not have any control over their privacy practices. It
                  is your responsibility to make sure that, when leaving our websites, you have read and understood the
                  other website’s privacy notice.
                </p>
                <p>
                  <strong>Changes to this privacy policy</strong>
                  <Spacer />
                  This Privacy Policy was last updated in November 2023
                  <Spacer />
                  We may need to occasionally make changes to this privacy policy which you can view by checking this
                  page from time to time for the latest version. If we make any significant changes in the way we treat
                  your personal information we will make this clear on our websites or by contacting you directly.
                  <Spacer />
                  If you do not agree to the new terms of this policy, you should stop using our websites, request to
                  cancel any user accounts, and may also ask us to delete your information in accordance with your
                  rights.
                  <Spacer />
                  Do let us know if you have any questions or concerns by emailing{' '}
                  <a href="mailto:hello@wildscreen.org">hello@wildscreen.org</a>
                  <Spacer />
                </p>
                <p>
                  <strong>Contacting us</strong>
                  <Spacer />
                  To exercise your rights, make a Subject Access Request, or to send questions or comments, please
                  contact us at <a href="mailto:hello@wildscreen.org">hello@wildscreen.org</a> or write to us at Unit
                  1,11, Temple Studios, Temple Gate, Bristol BS1 6QA, United Kingdom.
                </p>
                <h2>Cookie Policy</h2>
                <p>
                  <strong>What are cookies?</strong>
                  <Spacer />
                  A cookie is a small text file that a website saves on your computer or mobile device when you visit
                  the site. It enables the website to remember your actions and preferences (such as login, language,
                  font size and other display preferences) over a period of time, so you don’t have to keep re-entering
                  them whenever you come back to the site or browse from one page to another. It can also measure how
                  the website is being used and by how many people.
                  <Spacer />
                  Cookies set by the website owner (here, Wildscreen) are called “first party cookies”. Cookies set by
                  other parties are called “third party cookies”. Third party cookies enable third party features or
                  functionality to be provided on or through our site such as social sharing buttons, advertising and
                  analytics. The parties that set these third party cookies can recognise your computer both when it
                  visits our websites and also when it visits certain other websites.
                  <Spacer />
                  In addition, cookies may be either ‘session cookies’ or ‘persistent cookies’. Your device will
                  automatically delete session cookies when you close your browser. Persistent cookies will stay on your
                  device until the expiry date specified in the cookie itself is reached.
                  <Spacer />
                </p>
                <p>
                  <strong>How do we use cookies?</strong>
                  <Spacer />
                  Some cookies are required for technical reasons in order for our site to operate, and we refer to
                  these as “strictly necessary” cookies. Other cookies also enable us to track the interests and
                  behaviour of our visitors for future site improvements. Third parties serve cookies through our site
                  for advertising, analytics and other purposes. We use both session and persistent cookies.
                </p>
                <p>
                  <strong>Note on Google Services</strong>
                  <Spacer />
                  We use Google Analytics, a web analytics service provided by Google, Inc. (‘Google’). The information
                  generated by its cookies about your use of the website (including your IP address) will be transmitted
                  to and stored by Google on servers in the United States. Google will use this information for the
                  purpose of evaluating your use of the website, compiling reports on website activity for Wildscreen
                  and providing other services relating to website activity and Internet usage. Google may also transfer
                  this information to third parties where required to do so by law, or where such third parties process
                  the information on Google’s behalf. Google will not associate your IP address with any other data held
                  by Google.
                  <Spacer />
                  We have also implemented Google Analytics features based on Display Advertising (Google Analytics
                  Demographics and Interest Reporting). This uses Google’s third party audience data, such as age,
                  gender and interests, with Google Analytics which we will use to develop the website and content
                  around your interests.You can opt-out of Google Analytics for Display Advertising using
                  <a href="https://tools.google.com/dlpage/gaoptout">https://tools.google.com/dlpage/gaoptout</a>.
                  <Spacer />
                  We also make use of Google AdWords in order to promote our website. If you visit certain pages on our
                  website soon after clicking one of our ads displayed in Google search results, both Google and
                  Wildscreen will be able to tell that your visit was a result of a click on one of these ads. If you do
                  not wish for your visit to be tracked, please do not click on Google Ads.
                  <Spacer />
                  For further details on the cookies set by Google Analytics, please refer to the Google Code website or
                  refer to Google Analytics privacy overview.
                  <Spacer />
                </p>
                <p>
                  <strong>Note on Google Services</strong>
                  <Spacer />
                  You have the right to decide whether to accept or reject cookies and can control and/or delete them as
                  you wish – for details see <a href="https://www.allaboutcookies.org">www.allaboutcookies.org</a>. You
                  can delete all cookies that are already on your device and you can set most browsers to prevent them
                  from being placed. The third parties also allow you to manage their cookies independently. If you
                  choose to reject cookies, you may still use our website through your access to some functionality and
                  areas may be restricted.
                  <Spacer />
                  Note that you can opt-out of many network advertising programs that track your browsing habits via
                  cookies with the Network Advertising Initiative (NAI) Opt-Out Tool. It shows you which advertising
                  networks have placed cookies on your computer, and lets you opt-out of being tracked by them, should
                  you wish to do so. Opting out of a network does not mean you will no longer receive online
                  advertising. It only means that the network from which you opted out will no longer deliver ads
                  tailored to your web preferences and usage patterns.
                  <Spacer />
                  If you have any questions regarding the use of cookies on Wildscreen’s websites, please contact us.
                  <Spacer />
                  Last updated: November 2023
                  <Spacer />
                </p>
              </>
            ),
          },
          termsAndConditions: {
            heading: 'Terms & Conditions',
            content: () => (
              <>
                <h2>Terms & Conditions of use</h2>
                <p>
                  Thank you for visiting the Wildscreen ARK website. Please read these terms and conditions carefully
                  before using this site.
                  <Spacer />
                  This website is © Wildscreen. Material contained in this site is © Wildscreen or its contributors.
                  <Spacer />
                  Use of <a href="https://www.wildscreenark.org">www.wildscreenark.org</a> is provided by Wildscreen
                  subject to the following conditions.
                  <Spacer />
                  To use <a href="https://www.wildscreenark.org">www.wildscreenark.org</a> you must be 13 years of age,
                  or accompanied by someone that is over 18 years of age. For more information about the reasons for
                  this, please refer to our Privacy Policy.
                  <Spacer />
                  By using <a href="https://www.wildscreenark.org">www.wildscreenark.org</a> you agree to be legally
                  bound by these terms, which shall take effect immediately on your first use of the site. If you do not
                  agree to be legally bound by all the following terms please do not use our site.
                  <Spacer />
                  Wildscreen may change these terms at any time by posting changes online. Please review these terms
                  regularly to ensure you are aware of any changes made by Wildscreen.
                </p>
                <h2>Use of content from Wildscreen ARK</h2>
                <p>
                  Wildscreen ARK provides a free service to its users in the form of images, videos and text for
                  education and engagement purposes. Our contributors allow us to display this content for free for this
                  purpose. Many of these contributors make a livelihood from capturing unique images and footage, and
                  licensing its use to others and therefore own the rights to it (copyright). We believe strongly in
                  supporting our contributors in their work and therefore seek to uphold copyright in a variety of ways
                  on the website, for example by crediting all content used to the rights holder or distributor,
                  enabling you to easily copy that credit for display in educational use and in some cases by preventing
                  unauthorised image download.
                </p>
                <h2>Responsible use of Wildscreen ARK content for educational, non-commercial purposes</h2>
                <p>
                  We believe that enabling use of the content displayed on Wildscreen ARK, wherever possible, for
                  educational, non-commercial purposes, helps us fulfil our charitable objectives of helping people
                  connect with and take action for nature. We also believe that educating our users how to properly
                  respect the rights of copyright owners and distributors is equally important in protecting our
                  industry. We therefore ask that if you use images for educational, non-commercial purposes, you:
                  <ul>
                    <li>Only use copies of low resolution imagery that have not been altered</li>
                    <li>
                      Link to location of the content on Wildscreen ARK on your work or in its references (E.g.
                      Wildscreen ARK. (2024) Birds <a href="https://www.wildscreenark.org">www.wildscreenark.org</a>{' '}
                      [Accessed 1st January 2024]
                    </li>
                    <li>Credit the rights holder displayed with the content e.g. © Contributor name</li>
                  </ul>
                  If you wish to use the content from the Wildscreen ARK website for any use, please contact{' '}
                  <a href="mailto:hello@wildscreen.org.uk">hello@wildscreen.org.uk</a> to get permissions, or contact
                  the contributor directly.
                  <Spacer />
                  You may not copy, reproduce, republish, download, post, broadcast, transmit, make available to the
                  public, or otherwise use <a href="https://www.wildscreenark.org">www.wildscreenark.org</a> content in
                  any way except for your own personal, non-commercial use. You also agree not to adapt, alter or create
                  a derivative work from any <a href="https://www.wildscreenark.org">www.wildscreenark.org</a> content
                  except for your own personal, non-commercial use. Any other use of the site content requires the prior
                  written permission of Wildscreen.
                  <Spacer />
                  You agree to use <a href="https://www.wildscreenark.org">www.wildscreenark.org</a> only for lawful
                  purposes, and in a way that does not infringe the rights of, restrict or inhibit anyone else’s use and
                  enjoyment of the site.
                  <Spacer />
                  Prohibited behaviour includes harassing or causing distress or inconvenience to any person,
                  transmitting obscene or offensive content or disrupting the normal flow of dialogue within the site.
                  <Spacer />
                  <a href="https://www.wildscreenark.org">www.wildscreenark.org</a> content, including the information,
                  names, images, pictures, logos and icons regarding or relating to Wildscreen, its products and
                  services (or to third party products and services), is provided <strong>“AS IS”</strong> and on an{' '}
                  <strong>“IS AVAILABLE”</strong> basis without any representations or any kind of warranty made
                  (whether express or implied by law) to the extent permitted by law, including the implied warranties
                  of satisfactory quality, fitness for a particular purpose, non-infringement, compatibility, security
                  and accuracy.
                  <Spacer />
                  Under no circumstances will Wildscreen be liable for any of the following losses or damage (whether
                  such losses where foreseen, foreseeable, known or otherwise): (a) loss of data; (b) loss of revenue or
                  anticipated profits; (c) loss of business; (d) loss of opportunity; (e) loss of goodwill or injury to
                  reputation; (f) losses suffered by third parties; or (g) any indirect, consequential, special or
                  exemplary damages arising from the use of{' '}
                  <a href="https://www.wildscreenark.org">www.wildscreenark.org</a> regardless of the form of action.
                  <Spacer />
                  Wildscreen does not warrant that functions contained in{' '}
                  <a href="https://www.wildscreen.org">www.wildscreen.org</a> content will be uninterrupted or error
                  free, that defects will be corrected, or that{' '}
                  <a href="https://www.wildscreen.org">www.wildscreen.org</a> or the server that makes it available are
                  free of viruses or bugs.
                  <Spacer />
                  The names, images and logos identifying Wildscreen and its initiatives or third parties and their
                  products and services are subject to copyright, design rights and trademarks of Wildscreen, its
                  initiatives and/or third parties. Nothing contained in these terms shall be construed as conferring by
                  implication, estoppel or otherwise any licence or right to use any trademark, patent, design right or
                  copyright of the Wildscreen, its initiatives, or any other third party.
                  <Spacer />
                  These terms shall be governed by and interpreted in accordance with the laws of England and Wales.
                  <Spacer />
                  Wildscreen is a registered charity in England and Wales (number 299450) and a company limited by
                  guarantee registered in England No 2206559 at Unit 2.6, Temple Studios, Temple Gate, Bristol, BS1 6QA.
                </p>
                <h2>Licence Agreement for Content Contributors</h2>
                <p>
                  <strong>Glossary of terms</strong>
                  <Spacer />
                  <strong>“we”, “us”, and “our”</strong>: Within this policy these terms refer to Wildscreen as a
                  charitable company, and its trading subsidiaries that are different and separate entities.
                  <Spacer />
                  <strong>“Wildscreen ARK”, “ARK”</strong>: Within this policy these terms refer to our database of
                  image and video footage from time to time, as hosted at the website platform{' '}
                  <a href="https://www.wildscreenark.org">www.wildscreenark.org</a> and any successor platform owned by
                  Wildscreen.
                  <Spacer />
                  <strong>“content contributor/s”</strong>: Within this policy this term refers to individuals or
                  organisations who have contributed content for use on Wildscreen ARK in accordance with our Terms &
                  Conditions.
                  <Spacer />
                  <strong>“content”</strong>: Within this policy this term refers to photographs and footage and related
                  captions, credits, rights and other information, and metadata.
                  <Spacer />
                  <strong>“user/s”, “end user/s”</strong>: Within this policy these terms refer to individuals and
                  organisations that interact with and use Wildscreen ARK platform.
                </p>
                <p>
                  <u>
                    When you send images, footage and related content to Wildscreen ARK for inclusion in the project:
                  </u>
                  <ol>
                    <li>
                      You warrant that you hold the necessary rights to do so, that doing so will not infringe any third
                      party rights or result in your breaching any agreement you have entered with a third party, and
                      that Wildscreen ARK’s use of your content as contemplated by this licence agreement will not
                      infringe the rights of any third parties. If you are not the copyright owner you must be the
                      authorised representative of the copyright owner. You also warrant that the caption and rights
                      information related to the content is correct to the best of your knowledge.
                    </li>
                    <li>
                      You grant us a:
                      <ul>
                        <li>World-wide (meaning accessed from anywhere in the world)</li>
                        <li>Royalty-free (Meaning we don’t pay you money to use it)</li>
                        <li>Non-exclusive (meaning you can also license it to other platforms)</li>
                        <li>Perpetual (meaning forever unless and until you opt out)</li>
                        <li>
                          Limitedly sub-licensable (meaning we will only allow third parties to use the content for the
                          limited purposes set out below)
                        </li>
                      </ul>
                    </li>
                  </ol>
                  ... licence to:
                  <ul>
                    <li>
                      display a reduced resolution (maximum 720p longest edge) version of your content on the Wildscreen
                      ARK user interface, which can be accessed and viewed by users on a not for profit basis;
                    </li>
                    <li>
                      use this resolution version for educational purposes on Wildscreen ARK branded learning resources
                      alongside other content, materials and information (which may be made available to the public on a
                      not for profit basis on educational third party platforms);
                    </li>
                    <li>
                      make available for, and allow users to, screenshot or screen capture the low resolution version
                      and use it only for their own personal, non-commercial use, e.g. school homework, on a not for
                      profit basis.
                    </li>
                    <li>use the reduced resolution version for Wildscreen promotional materials.</li>
                    <li>
                      display the reduced resolution version alongside other content, including marketing, on the
                      Wildscreen ARK platform.
                    </li>
                  </ul>
                  <u>In return, we will use reasonable endeavours to:</u>
                  <ul>
                    <li>Display a credit that you specify to us (if any) with your content wherever they are found;</li>
                    <li>
                      Use copy-protection technology to maintain the credit on the content, by disabling the
                      “right-click save as” functionality on the Wildscreen ARK user interface;
                    </li>
                    <li>
                      Refer any commercial enquiries we receive regarding the use of your content to you using the
                      contact details you have supplied us with (if any);
                    </li>
                    <li>
                      Make clear to users in our Terms and Conditions that they may only use the content for personal,
                      non-commercial use;
                    </li>
                    <li>
                      Display a link to the Terms and Conditions of use of content on Wildscreen ARK on every page of
                      the website;
                    </li>
                  </ul>
                  You accept that we cannot control the actions of our users or take responsibility if, despite our
                  obligations above, any user or other person uses your content in a way that is not authorised under
                  this agreement and/or our Terms and Conditions.
                  <br />
                  <br />
                  <u>
                    If you believe that content you own the copyright to has been uploaded to Wildscreen ARK without
                    your permission,
                  </u>{' '}
                  please notify us immediately with your concerns at{' '}
                  <a href="mailto:hello@wildscreen.org.uk">hello@wildscreen.org.uk</a>. So that we can react effectively
                  to your notice, please specify the content in question and its location (including if possible URL) on
                  the platform. Please be aware that we may ask you to provide us with evidence that you own the
                  copyright to this content. We are committed to upholding copyright law and will investigate any
                  copyright concern thoroughly with the content contributors involved and take appropriate measures to
                  uphold copyright based on the outcomes of the investigation.
                  <Spacer />
                  <u>Metadata</u>
                  <Spacer />
                  Relevant metadata tags and image identification data may be attributed to content files. This includes
                  descriptive data about the media, rights data including credit and licensing, and administrative data.
                  Internal and external users will search for visual assets with the help of this metadata, so that
                  appropriate information and credit can be displayed on the Wildscreen ARK website. We reserve the
                  right to tag your content using our own tagging database to make your content as useful and accessible
                  as possible to our users; however we will ensure that the original source material metadata is
                  retained.
                  <Spacer />
                  <u>Use by educational third party providers</u>
                  <Spacer />
                  At Wildscreen we produce a wide range of free educational activities and materials to enable teachers,
                  schools, kids and parents worldwide to discover the natural world in creative and engaging ways. Our
                  60+ resources have had &gt;930k downloads, reaching over 30 million school children globally.{' '}
                  <Spacer />
                  We upload free-to-use materials featuring content from our contributors to STEM Learning. STEM
                  Learning is a UK-based not-for-profit organisation dedicated to improving young people’s lives through
                  the power of STEM and believe great STEM education builds knowledge and skills that are vital for
                  everyone.
                  <Spacer />
                  When we upload materials to <a href="https://www.stem.org.uk">www.stem.org.uk</a>, we grant them a
                  non-exclusive, royalty-free, and worldwide right and licence to use, copy, reproduce, publish, and
                  distribute those materials in whole or in part on their website, and for other users of their website
                  to use it them accordance with their terms of use.
                  <Spacer />
                  <u>Termination</u>
                  <Spacer />
                  At any time if you wish this licence to end, please notify us in writing (email is sufficient). We
                  will delete your content from Wildscreen ARK within 10 days following receipt of your notice, or for
                  contributors with high volumes of content, on a timescale agreed by both parties in writing or email.
                  By consenting to these terms and conditions you acknowledge the content may still be temporarily
                  available online due to caching or references to it within the database.
                  <Spacer />
                  Upon notice of termination, we will also remove your content from any promotional and educational
                  materials containing it, and from any historical online use (for example social media) to which we
                  still have reasonable access. For example, this would include removing it from educational resources
                  held on <a href="https://www.tes.com">www.tes.com</a>, but would not include deletion of materials
                  already downloaded and saved on users’ private networks for their personal use. Users retaining such
                  materials privately would still have the rights to personal use as stipulated in the original licence
                  agreement under which it was downloaded.
                </p>
                <p>
                  Thank you once again for considering a contribution to Wildscreen ARK! <Spacer />
                  Last updated: December 2023
                </p>
              </>
            ),
          },
        },
        components: {},
      },
    },
  },
} as const;

export type TLanguageKey = keyof typeof dictionary;

export const LanguageDictionaryKeys = {
  en: 'English',
};

export function useDictionary(key: TLanguageKey) {
  return dictionary[key];
}
