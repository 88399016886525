import { UploadSimple } from '@phosphor-icons/react';
import { Button } from '@rocketmakers/armstrong';
import { useDictionary } from '@wildscreen/core/src/core';
import * as React from 'react';

import { WildscreenImage } from '../wildscreenImage';

import styles from './fileUpload.module.scss';

export interface IInitialFile {
  fileName?: string;
  src?: string;
  isVideo?: boolean;
}
export interface IFileUploadProps {
  initialFile?: IInitialFile;
  onFileSelected?: (e: File) => void;
  entityType?: string;
  acceptAll?: boolean;
}

const imageFileExtensions = [
  'image/apng',
  'image/gif',
  'image/jpeg',
  'image/pjpeg',
  'image/png',
  'image/svg+xml',
  'image/webp',
];
const videoFileExtensions = ['video/mp4', 'video/quicktime', 'video/webm', 'video/ogg', 'video/mpeg'];

export const FileUpload: React.FC<IFileUploadProps> = ({
  onFileSelected,
  initialFile,
  entityType = 'Asset',
  acceptAll,
  ...rest
}) => {
  const [file, setFile] = React.useState<File | null>(null);
  const [preview, setPreview] = React.useState<string>('');

  const { admin } = useDictionary('en');

  const fileInputRef = React.createRef<HTMLInputElement>();

  const handleFileChange = (e?: React.ChangeEvent<HTMLInputElement>) => {
    e?.preventDefault();
    const selectedFile = e?.target.files?.[0];

    if (selectedFile) {
      setFile(selectedFile);
      onFileSelected?.(selectedFile);
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result as string);
      };

      if (selectedFile.type.includes('image') || selectedFile.type.includes('video')) {
        reader.readAsDataURL(selectedFile);
      } else {
        setPreview('');
      }
    } else {
      setFile(null);
      setPreview('');
    }
  };

  const render = React.useMemo(() => {
    if (initialFile?.src && preview === '') {
      if (acceptAll) {
        return <div className={styles.namePreview}>{initialFile?.fileName ?? initialFile?.src.split('/').pop()}</div>;
      }
      if (initialFile.isVideo) {
        return (
          <video controls width="400" src={initialFile.src}>
            <track kind="captions" />
          </video>
        );
      }
      return <WildscreenImage size="large" alt="Preview" url={initialFile.src} />;
    }
    if (file) {
      if (acceptAll) {
        return <div className={styles.namePreview}>{file.name}</div>;
      }
      if (file.type.includes('image')) {
        return <WildscreenImage size="large" url={URL.createObjectURL(file)} alt="Preview" />;
      }
      return (
        <video controls width="400" src={URL.createObjectURL(file)}>
          <track kind="captions" />
        </video>
      );
    }
    return <div />;
  }, [initialFile, preview, file, acceptAll]);

  const handleFileSelect = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e?.preventDefault();
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const existingAsset = !!initialFile?.src || preview !== '';

  return (
    <div className={styles.container} {...rest}>
      <label>{admin.authenticated.views.uploader(false, entityType)}</label>
      <div className={styles.innerContainer}>
        <div className={styles.fileContainer}>
          <div>{render}</div>
        </div>
        <input
          type="file"
          accept={acceptAll ? undefined : [...imageFileExtensions, ...videoFileExtensions].join(', ')}
          onChange={handleFileChange}
          style={{ display: 'none' }}
          ref={fileInputRef}
        />
        <Button
          className={(existingAsset && styles.reUpload) || ''}
          leftOverlay={<UploadSimple />}
          onClick={handleFileSelect}
          data-style="outline-black"
        >
          {admin.authenticated.views.uploader(existingAsset, entityType)}
        </Button>
      </div>
    </div>
  );
};
