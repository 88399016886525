import { Button, Input, useForm } from '@rocketmakers/armstrong';
import { useDictionary } from '@wildscreen/core/src/core';
import { testIds } from '@wildscreen/core/src/core/testIds';
import { useOnKeyPress } from '@wildscreen/core/src/hooks/useOnKeyPress';
import { AuthView } from '@wildscreen/ui-components/src/components/authView';
import * as React from 'react';
import { z } from 'zod';

import { TPasswordConfirmation, useAuth } from '../../contexts';

import styles from './auth.module.scss';

export const VerifyView: React.FC = () => {
  const { admin } = useDictionary('en');
  const [newPasswordError, setNewPasswordError] = React.useState<string | undefined>(undefined);
  const { setLoggedIn, completeNewPassword, isFetching, tempUser, setIsFetching } = useAuth();

  const {
    formProp: confirmFormProps,
    formState: confirmFormState,
    validate: confirmValidate,
  } = useForm<TPasswordConfirmation>(
    {
      password: undefined,
      passwordConfirmation: undefined,
    },
    {
      validationMode: 'both',
      validationSchema: {
        passwordConfirmation: z.string().min(1, admin.unauthenticated.views.resetPassword.confirmNewPassword),
        password: z.string().min(1, admin.unauthenticated.views.login.passwordValidation),
      },
    }
  );

  const onConfirmNewPassword = React.useCallback(
    async (e?: React.FormEvent<HTMLFormElement>) => {
      e?.preventDefault();
      const { password, passwordConfirmation } = confirmFormState as TPasswordConfirmation;
      if (confirmValidate() && passwordConfirmation && password && tempUser) {
        try {
          const user = await completeNewPassword(tempUser, password);
          if (user) {
            setLoggedIn(user.signInUserSession.accessToken.jwtToken);
          }
          // eslint-disable-next-line @typescript-eslint/no-explicit-any -- ignore
        } catch (error: any) {
          setIsFetching(false);
          switch (error.code) {
            case 'NotAuthorizedException':
              setTimeout(() => {
                setNewPasswordError(undefined);
              }, 5000);
              setNewPasswordError(error.message);
              break;
            default:
              break;
          }
        }
      }
    },
    [confirmFormState, confirmValidate, tempUser, completeNewPassword, setLoggedIn, setIsFetching]
  );

  useOnKeyPress('Enter', () => onConfirmNewPassword());

  return (
    <AuthView data-testid={testIds.unauthenticated.views.verify.view}>
      <div className={styles.authContainer}>
        <h1>{admin.unauthenticated.views.login.confirmation}</h1>
        <form onSubmit={onConfirmNewPassword}>
          <Input
            required
            bind={confirmFormProps('password').bind()}
            validationMode="message"
            type="password"
            data-testid={testIds.unauthenticated.views.resetPassword.password}
            label={admin.unauthenticated.views.resetPassword.newPassword}
          />
          <Input
            required
            bind={confirmFormProps('passwordConfirmation').bind()}
            validationMode="message"
            type="password"
            data-testid={testIds.unauthenticated.views.resetPassword.confirmPassword}
            label={admin.unauthenticated.views.resetPassword.confirmNewPassword}
          />
          <div className={styles.failed}>
            {newPasswordError && (
              <span data-testid={testIds.unauthenticated.views.login.loginFailed}>{newPasswordError}</span>
            )}
          </div>
          <Button
            pending={isFetching}
            data-style="yellow"
            type="submit"
            data-testid={testIds.unauthenticated.views.login.confirmButton}
          >
            {admin.unauthenticated.views.login.confirmation}
          </Button>
        </form>
      </div>
    </AuthView>
  );
};
