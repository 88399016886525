import { IValidationError } from '@rocketmakers/armstrong';

export interface IServerValidationError {
  attribute: string;
  messages: {
    developer: string;
    friendly: string;
  };
  path: string;
  value: string;
}

export interface IServerUnknownErrorData {
  fields: { [key: string]: { message: string } };
  status: number;
  name: string;
  message?: string;
}

export interface IServerValidationErrorResponse {
  appCode: 'ValidationError';
  errors: IServerValidationError[];
}

export interface IServerUnknownErrorResponse {
  appCode: 'UnknownServerError';
  data: IServerUnknownErrorData;
}

export interface IServerNotFoundErrorResponse {
  appCode: 'NotFoundError';
  data: IServerUnknownErrorData;
}

export interface IServerUnauthorisedErrorResponse {
  appCode: 'UnauthorisedError';
  message?: string;
}

export type ServerErrorResponseData =
  | IServerValidationErrorResponse
  | IServerUnknownErrorResponse
  | IServerUnauthorisedErrorResponse
  | IServerNotFoundErrorResponse;
export interface IUnknownError {
  status: number;
  messages: string[];
}

export function formatValidationErrorsForArmstrong(
  serverValidationErrors: IServerValidationError[]
): IValidationError[] {
  return serverValidationErrors.map(error => {
    const err = error.messages.friendly ?? '';

    const replacedError = err.replace(/.+ must be greater than 0/, 'Must be greater than 0');

    return {
      key: error.path.replace('0.', ''),
      message: replacedError,
    };
  });
}
