import * as React from 'react';

import styles from './viewHeader.module.scss';

export type TViewHeaderProps = React.PropsWithChildren<{
  backTo?: string;
  backToText?: string;
  header?: string;
}>;

export const ViewHeader: React.FC<TViewHeaderProps> = ({ children, header }) => {
  return (
    <div className={styles.header}>
      {header && <h2>{header}</h2>}
      {children && <div>{children}</div>}
    </div>
  );
};
