import { ArrowLeft } from '@phosphor-icons/react';
import { Button, Switch, useForm } from '@rocketmakers/armstrong';
import { routes, testIds, useDictionary } from '@wildscreen/core/src/core';
import * as React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { z } from 'zod';

import { apiHooks } from '../../api';
import { AssetDetails } from '../../components/assetDetails';
import { AssetTags } from '../../components/assetTags';
import { AuthenticatedView } from '../../components/authenticatedView';
import { generateAssetTagsFormValidationSchema } from '../../helpers/tags';
import { ICreateNewAssetForm } from '../../typings/asset';

import styles from './asset.module.scss';

export const CreateAssetView: React.FC = () => {
  const navigate = useNavigate();
  const { admin } = useDictionary('en');

  const [selectedFile, setSelectedFile] = React.useState<File | null>(null);
  const [createNewAsset, { isFetching: isCreating }] = apiHooks.asset.assetUploadPost.useMutation();

  const { formProp, formState, isValid } = useForm<ICreateNewAssetForm>(
    {
      details: {
        libraryCreditId: '',
        credit: '',
        description: '',
        isHomepageWorthy: false,
        assetLocation: '',
        originId: '',
        title: '',
      },
      tags: {
        adaptations: [],
        behaviours: [],
        generalContentDescriptors: [],
        habitats: [],
        lifeStages: [],
        regions: [],
        relatedClassifications: [],
      },
    },
    {
      validationMode: 'both',
      validationSchema: {
        details: {
          schema: {
            title: z.string().nonempty(),
            credit: z.string().nonempty(),
          },
        },
        tags: generateAssetTagsFormValidationSchema(5),
      },
    }
  );

  const onSubmit = React.useCallback(
    async (e?: React.FormEvent<HTMLFormElement>) => {
      e?.preventDefault();
      const uploadResponse = await createNewAsset({
        ...formState?.details,
        ...formState?.tags,
        file: selectedFile,
      });
      const newAssetId = uploadResponse.data?.assetId;
      if (newAssetId) {
        navigate(routes.authenticated.admin.assets.edit({ assetId: newAssetId }), { replace: true });
      }
    },
    [createNewAsset, selectedFile, formState, navigate]
  );

  const returnRoute = routes.authenticated.admin.assets.gallery();

  const onReset = React.useCallback(() => {
    navigate(returnRoute);
  }, [navigate, returnRoute]);

  return (
    <AuthenticatedView
      data-testid={testIds.authenticated.views.uploadAsset.view}
      headerContent={
        <div className={styles.header}>
          <Link to={returnRoute} className={styles.navContainer}>
            <ArrowLeft />
            <h3>{admin.authenticated.views.assets.back}</h3>
          </Link>
          <div className={styles.details}>
            <h2>{admin.authenticated.views.assets.heading(false)}</h2>
            <Switch label={admin.authenticated.views.assets.actionAdvert} onCheckedChange={function ro() {}} />
          </div>
        </div>
      }
    >
      <form onSubmit={onSubmit} onReset={onReset}>
        <AssetDetails bind={formProp('details').bind()} onFileSelected={setSelectedFile} />
        <AssetTags bind={formProp('tags').bind()} />
        <div className={styles.actionButtons}>
          <Button type="reset" data-style="silverback">
            {admin.authenticated.views.assets.discardChanges}
          </Button>
          <Button pending={isCreating} disabled={!isValid} type="submit" data-style="seaweed">
            {admin.authenticated.views.assets.publish}
          </Button>
        </div>
      </form>
    </AuthenticatedView>
  );
};
