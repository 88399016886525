import { IBindingProps, MultiSelect, useChildForm } from '@rocketmakers/armstrong';
import { testIds, useDictionary } from '@wildscreen/core/src/core';
import { ITagsForm } from '@wildscreen/core/src/types/forms';
import { ClassificationKeys } from '@wildscreen/ui-components/src/types/classificationTypes';
import * as React from 'react';

import { useGetClassificationTags } from '../../hooks/useGetClassificationTags';

import styles from './tagsForm.module.scss';

export interface ITagsFormProps {
  bind: IBindingProps<ITagsForm>;
}

export const TagsForm: React.FC<ITagsFormProps> = ({ bind }) => {
  const { formProp } = useChildForm<ITagsForm>(bind);
  const { admin } = useDictionary('en');

  const { data, setSearchTerms } = useGetClassificationTags();

  return (
    <div className={styles.container}>
      {ClassificationKeys.map(x => (
        <MultiSelect
          key={x}
          onInputChange={e => {
            setSearchTerms(p => ({ ...p, [x]: e }));
          }}
          options={data?.[x]}
          placeholder={admin.authenticated.views.editTaxonomy.placeholder}
          data-testid={testIds.authenticated.views.editTaxonomy.forms.tags.input(x)}
          label={admin.authenticated.views.editTaxonomy?.[x]}
          bind={formProp(x as any).bind()}
        />
      ))}
    </div>
  );
};
