import { FileImage } from '@phosphor-icons/react';
import { IBindingProps, Input, useChildForm } from '@rocketmakers/armstrong';
import { testIds, useDictionary } from '@wildscreen/core/src/core';
import { FileUpload, IInitialFile } from '@wildscreen/ui-components/src/components/fileUpload';
import { TextAreaInput } from '@wildscreen/ui-components/src/components/textarea/textarea';
import * as React from 'react';

import { IDetails } from '../../typings/taxonomy';
import { ExpandableSection } from '../expandableSection';

import styles from './taxonomyDetails.module.scss';

export interface ITaxonomyDetailsProps {
  bind: IBindingProps<IDetails>;
  onFileSelected: (e: File) => void;
  initialFile?: IInitialFile;
  isSpecies?: boolean;
}

export const TaxonomyDetails: React.FC<ITaxonomyDetailsProps> = ({ bind, isSpecies, onFileSelected, initialFile }) => {
  const [isOpen, setIsOpen] = React.useState(true);

  const { admin } = useDictionary('en');
  const { formProp, formState } = useChildForm(bind);

  const [hasFile, setHasFile] = React.useState<boolean>(!!formState?.coverImage);

  const onInterceptFileSelected = React.useCallback(
    (e: File) => {
      onFileSelected(e);
      setHasFile(true);
    },
    [onFileSelected]
  );

  return (
    <div className={styles.container}>
      <ExpandableSection
        colour="taxonomy-details"
        title={admin.authenticated.views.editTaxonomy.details.heading(isSpecies)}
        leftLogo={<FileImage />}
        isOpen={isOpen}
        onToggle={() => setIsOpen(s => !s)}
      >
        <Input
          label={admin.authenticated.views.editTaxonomy.scientificName}
          type="text"
          bind={formProp('scientificName').bind()}
          disabled
          data-testid={testIds.authenticated.views.editTaxonomy.forms.details.scientificName}
        />
        <Input
          label={admin.authenticated.views.editTaxonomy.details.redListStatus}
          type="text"
          bind={formProp('redListStatus').bind()}
          disabled
          data-testid={testIds.authenticated.views.editTaxonomy.forms.details.redListStatus}
        />
        <Input
          className={styles.friendlyName}
          label={admin.authenticated.views.editTaxonomy.friendlyName}
          type="text"
          bind={formProp('friendlyName').bind()}
          data-testid={testIds.authenticated.views.editTaxonomy.forms.details.friendlyName}
        />
        <div className={styles.label}>{admin.authenticated.views.editTaxonomy.details.friendlyBottomLabel}</div>
        <TextAreaInput
          label={admin.authenticated.views.editTaxonomy.description}
          bind={formProp('description').bind()}
          data-test={testIds.authenticated.views.editTaxonomy.forms.details.description}
        />
        <FileUpload
          data-test={testIds.authenticated.views.editTaxonomy.forms.details.imageUpload}
          initialFile={initialFile}
          onFileSelected={onInterceptFileSelected}
        />
        <Input
          disabled={!hasFile}
          placeholder={!hasFile ? 'Add a file before specifying the Credit' : undefined}
          label={admin.authenticated.views.editTaxonomy.imageCreditText}
          type="text"
          bind={formProp('imageCreditText').bind()}
          data-test={testIds.authenticated.views.editTaxonomy.forms.details.imageCreditText}
        />
      </ExpandableSection>
    </div>
  );
};
