import React from 'react';
import { Link } from 'react-router-dom';

import { publishedStates } from '../publishButton';

import styles from './searchResult.module.scss';

interface IProps {
  to: string;
  taxonomyClass?: string;
  scientificName: string;
  commonName?: string;
  isPublished?: boolean;
}

export const SearchResult: React.FC<IProps> = ({ scientificName, commonName, to, taxonomyClass, isPublished }) => {
  const publishedState = isPublished ? 'published' : 'unpublished';
  return (
    <Link to={to} className={styles.result}>
      <div className={styles.left}>
        {taxonomyClass && <div className={styles.class}>{taxonomyClass}</div>}
        <div className={styles.name}>
          {scientificName} {commonName && <span>{`(${commonName})`}</span>}
        </div>
      </div>
      <div className={styles.right}>
        <div className={styles.pub} data-style={publishedStates[publishedState].style}>
          {publishedStates[publishedState].icon}
          {publishedStates[publishedState].title}
        </div>
      </div>
    </Link>
  );
};
