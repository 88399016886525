import { getItem, removeItem, setItem, StorageKeys } from '@wildscreen/api/src/localStorage/safeLocalStorage';
import * as React from 'react';

import { AppSections, TLanguageKey, useDictionary } from '../core/dictionary';

export class LanguageStore {
  static save(language: TLanguageKey) {
    setItem(StorageKeys.languageKey, language);
  }

  static get() {
    return getItem(StorageKeys.languageKey);
  }

  static clear() {
    return removeItem(StorageKeys.languageKey);
  }
}

interface ILanguageContext {
  currentDictionary?: AppSections;
  setLanguageHandler?: (value: TLanguageKey) => void;
}

export const LanguageContext = React.createContext<ILanguageContext>({});

export const LanguageContextProvider = ({ children }: React.PropsWithChildren<{}>) => {
  const [language, setLanguage] = React.useState<TLanguageKey>('en');

  // Set language from local storage if it exists
  React.useEffect(() => {
    const storedLanguage = LanguageStore.get();
    if (storedLanguage) {
      setLanguage(storedLanguage as TLanguageKey);
    }
  }, []);

  const currentDictionary = useDictionary(language);

  const setLanguageHandler = (value: TLanguageKey) => {
    setLanguage(value);
    LanguageStore.save(value);
  };

  return (
    <LanguageContext.Provider value={{ currentDictionary, setLanguageHandler }}>{children}</LanguageContext.Provider>
  );
};

export function useLanguageContext() {
  const ctx = React.useContext(LanguageContext);
  if (!ctx) {
    throw new Error('No Language Context');
  }
  return ctx;
}
