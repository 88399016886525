import { ArrowLeft } from '@phosphor-icons/react';
import { Button, useForm, useToast } from '@rocketmakers/armstrong';
import { routes, testIds, useDictionary } from '@wildscreen/core/src/core';
import * as React from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { apiHooks } from '../../api';
import { AuthenticatedView } from '../../components/authenticatedView';
import { EducationResourceDetails } from '../../components/educationResourceDetails';
import { EducationResourceTags } from '../../components/educationResourceTags';
import { educationResourceValidationSchema } from '../../helpers/validation';
import { ICreateNewResourceForm } from '../../typings/asset';

import styles from './educationResourceForm.module.scss';

export const CreateEducationResourceView: React.FC = () => {
  const navigate = useNavigate();
  const { admin } = useDictionary('en');
  const dispatch = useToast();

  const [selectedFile, setSelectedFile] = React.useState<File | null>(null);
  const [createNewResource, { isFetching: isCreating }] = apiHooks.resource.resourcePost.useMutation();

  const { formProp, formState, isValid } = useForm<ICreateNewResourceForm>(
    {
      details: {
        description: '',
        title: '',
      },
      tags: {
        ageCategory: undefined,
        type: undefined,
        subjects: [],
        actions: [],
        biologicalThemes: [],
        habitats: [],
        landRegions: [],
        threats: [],
      },
    },
    { validationSchema: educationResourceValidationSchema }
  );

  const onSubmit = React.useCallback(
    async (e?: React.FormEvent<HTMLFormElement>) => {
      e?.preventDefault();
      const uploadResponse = await createNewResource({
        ...formState?.details,
        ...formState?.tags,
        file: selectedFile,
        resourceName: selectedFile?.name,
        isPublished: true,
      });
      const newResourceId = uploadResponse.data?.resourceId;
      if (newResourceId) {
        dispatch({
          description: admin.authenticated.views.editTaxonomy.toasts.educationResource.published('published'),
          testId: testIds.authenticated.components.publishedToasts('success'),
        });
        navigate(routes.authenticated.admin.educationResources.root());
      }
    },
    [
      createNewResource,
      formState?.details,
      formState?.tags,
      selectedFile,
      dispatch,
      admin.authenticated.views.editTaxonomy.toasts.educationResource,
      navigate,
    ]
  );

  const returnRoute = routes.authenticated.admin.educationResources.root();

  const onReset = React.useCallback(() => {
    navigate(returnRoute);
  }, [navigate, returnRoute]);

  return (
    <AuthenticatedView
      data-testid={testIds.authenticated.views.educationResources.create}
      headerContent={
        <div className={styles.header}>
          <Link to={returnRoute} className={styles.navContainer}>
            <ArrowLeft />
            <h3>{admin.authenticated.views.educationResources.form.back}</h3>
          </Link>
          <div className={styles.details}>
            <h2>{admin.authenticated.views.educationResources.form.heading(false)}</h2>
          </div>
        </div>
      }
    >
      <form onSubmit={onSubmit} onReset={onReset}>
        <EducationResourceDetails bind={formProp('details').bind()} onFileSelected={setSelectedFile} />
        <EducationResourceTags bind={formProp('tags').bind()} />
        <div className={styles.actionButtons}>
          <Button type="reset" data-style="silverback">
            {admin.authenticated.views.assets.discardChanges}
          </Button>
          <Button pending={isCreating} disabled={!isValid || !selectedFile} type="submit" data-style="seaweed">
            {admin.authenticated.views.assets.publish}
          </Button>
        </div>
      </form>
    </AuthenticatedView>
  );
};
