import { Button, Input, useForm } from '@rocketmakers/armstrong';
import { ClassificationParentView, TaxonRank } from '@wildscreen/api/src/apiClients';
import { capitalizeEachWord, useDictionary } from '@wildscreen/core/src/core';
import { ITagsForm } from '@wildscreen/core/src/types/forms';
import { TTaxonomyRanks } from '@wildscreen/ui-components/src/types';
import { ClassificationKeys } from '@wildscreen/ui-components/src/types/classificationTypes';
import * as React from 'react';
import { z } from 'zod';

import { apiHooks } from '../../api';
import { generateTagsFormValidationSchema } from '../../helpers/tags';
import { TagsForm } from '../tagsForm';

import styles from './taxonomyTagsForm.module.scss';

export interface ITaxonomyTagsForm {
  scientificName: string;
  friendlyName: string;
  tags: ITagsForm;
  wildscreenId: string;
  wildscreenId2: string;
}

export interface ITaxonomyTagsFormProps {
  classificationParents?: ClassificationParentView;
  taxonRank: TTaxonomyRanks;
  onClose?: () => void;
}

export const TaxonomyTagsForm: React.FC<ITaxonomyTagsFormProps> = ({ taxonRank, classificationParents, onClose }) => {
  const taxon = capitalizeEachWord(taxonRank);
  const wildscreenId = classificationParents?.[taxon]?.taxonomy.wildscreenId;

  const { formProp, formState, isValid } = useForm<Omit<ITaxonomyTagsForm, 'scientificName' | 'friendlyName'>>(
    {
      wildscreenId: wildscreenId || '',
      wildscreenId2: wildscreenId,
      tags: {
        actions: classificationParents?.[taxon]?.classification?.actions || [],
        adaptations: classificationParents?.[taxon]?.classification?.adaptations || [],
        biologicalThemes: classificationParents?.[taxon]?.classification?.biologicalThemes || [],
        habitat: classificationParents?.[taxon]?.classification?.habitat || [],
        landRegion: classificationParents?.[taxon]?.classification?.landRegion || [],
        threats: classificationParents?.[taxon]?.classification?.threats || [],
      },
    },
    {
      validationMode: 'both',
      validationSchema: {
        wildscreenId: z.string().nonempty({ message: 'Please enter a valid wildscreen ID' }),
        wildscreenId2: z.string().nonempty({ message: 'Please enter a valid wildscreen ID' }),
        tags: generateTagsFormValidationSchema(5),
      },
    }
  );
  const { admin } = useDictionary('en');

  const [updateTags, { isFetching }] = apiHooks.classificationPage.pageDocumentWildscreenIdPartialPut.useMutation();

  const onSaveTags = React.useCallback(
    async (e?: React.FormEvent<HTMLFormElement>) => {
      e?.preventDefault();
      const classificationTags = ClassificationKeys.reduce((acc, x) => {
        acc[x] = formState?.tags?.[x]?.length !== 0 ? formState?.tags?.[x] : ['K7hP$w2JzL@5!DqR'];
        return acc;
      }, {});

      await updateTags({
        ...classificationTags,
        wildscreenId,
        wildscreenId2: wildscreenId,
      });
      onClose?.();
    },
    [updateTags, formState, wildscreenId, onClose]
  );

  return (
    <div className={styles.container}>
      <form onSubmit={onSaveTags} onReset={() => onClose?.()}>
        <Input
          disabled
          type="text"
          label={admin.authenticated.views.editTaxonomy.scientificName}
          value={classificationParents?.[taxon as TaxonRank]?.taxonomy?.gbifScientificName || ''}
        />
        <Input
          className={styles.friendlyName}
          type="text"
          label={admin.authenticated.views.editTaxonomy.friendlyName}
          value={classificationParents?.[taxon as TaxonRank]?.taxonomy?.vernacularNames?.[0] || ''}
        />
        <div className={styles.label}>{admin.authenticated.views.editTaxonomy.details.friendlyBottomLabel}</div>
        <TagsForm bind={formProp('tags').bind()} />
        <div className={styles.actionButtons}>
          <Button type="reset" data-style="silverback">
            {admin.authenticated.views.editTaxonomy.discardChanges}
          </Button>
          <Button pending={isFetching} disabled={!isValid} type="submit" data-style="seaweed">
            {admin.authenticated.views.editTaxonomy.taxonomy.save}
          </Button>
        </div>
      </form>
    </div>
  );
};
