import * as React from 'react';

import styles from './taxonomyHeader.module.scss';

export interface ITaxonomyHeaderProps {
  scientificName: string;
  commonName?: string;
}

export const TaxonomyHeader: React.FC<ITaxonomyHeaderProps> = ({ scientificName, commonName }) => {
  return (
    <div className={styles.container}>
      <h2 aria-label={scientificName}>
        {scientificName} {commonName && <span>{`(${commonName})`}</span>}
      </h2>
    </div>
  );
};
