import { ArrowLeft } from '@phosphor-icons/react';
import { Button, useForm, useToast } from '@rocketmakers/armstrong';
import {
  ClassificationPageApiPageDocumentWildscreenIdPutRequest,
  ClassificationPageView,
  ClassificationParentViewParents,
} from '@wildscreen/api/src/apiClients';
import { capitalizeEachWord, testIds, TTaxonomy, useDictionary } from '@wildscreen/core/src/core';
import { coalesceTags, filterParentTags } from '@wildscreen/core/src/core/taxonomy';
import { PublishButton } from '@wildscreen/ui-components/src/components/publishButton';
import { ClassificationKeys } from '@wildscreen/ui-components/src/types/classificationTypes';
import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { z } from 'zod';

import { apiHooks } from '../../api';
import { AuthenticatedView } from '../../components/authenticatedView';
import { LastUpdated } from '../../components/lastUpdated';
import { Taxonomy } from '../../components/taxonomy';
import { TaxonomyCharacteristics } from '../../components/taxonomyCharacteristics';
import { TaxonomyDetails } from '../../components/taxonomyDetails';
import { TaxonomyHeader } from '../../components/taxonomyHeader';
import { generateTagsFormValidationSchema } from '../../helpers/tags';
import { IEditTaxonomyFormProps } from '../../typings/taxonomy';

import styles from './taxonomy.module.scss';

interface IProps {
  backText: string;
}

export const EditTaxonomyView: React.FC<IProps> = ({ backText }) => {
  const { admin } = useDictionary('en');
  const { wildscreenId } = useParams<TTaxonomy>();
  const navigate = useNavigate();
  const [selectedFile, setSelectedFile] = React.useState<File | null>(null);
  const [{ data }] = apiHooks.taxonomy.taxonomyDocumentWildscreenIdGet.useQuery({
    parameters: { wildscreenId },
    cacheKey: 'wildscreenId',
  });

  const scientificName = data?.data.results?.[0].suggestedName || '';
  const commonName = data?.data.results?.[0].vernacularNames?.[0] || '';
  const taxonRank = data?.data.results?.[0].taxonRank || '';

  const [{ data: parentsData }] = apiHooks.classificationPage.pageDocumentWildscreenIdParentsGet.useQuery({
    parameters: { wildscreenId },
    cacheKey: p => `${scientificName}-${p.wildscreenId}`,
    debugKey: `parents-${wildscreenId}`,
  });

  const [{ data: pageData, isFetching: isFetchingClassification }] =
    apiHooks.classificationPage.pageDocumentWildscreenIdGet.useQuery({
      parameters: { wildscreenId },
      cacheKey: 'wildscreenId',
    });

  const [updatePageDocument, { isFetching: isFetchingUpdate }] =
    apiHooks.classificationPage.pageDocumentWildscreenIdPut.useMutation({
      parameters: { wildscreenId },
    });

  const { formProp, formState, isValid } = useForm<IEditTaxonomyFormProps>(
    {
      details: {
        redListStatus: data?.data?.results?.[0].threatStatus || '',
        scientificName,
        description: pageData?.data?.classification?.description || '',
        imageCreditText: pageData?.data.asset?.credit || '',
        friendlyName: pageData?.data?.classification?.friendlyName || '',
        coverImage: pageData?.data?.asset?.assetLocation || '',
      },
      tags: {
        actions: coalesceTags('actions', pageData?.data?.classification, parentsData?.data),
        adaptations: coalesceTags('adaptations', pageData?.data?.classification, parentsData?.data),
        biologicalThemes: coalesceTags('biologicalThemes', pageData?.data?.classification, parentsData?.data),
        habitat: coalesceTags('habitat', pageData?.data?.classification, parentsData?.data),
        landRegion: coalesceTags('landRegion', pageData?.data?.classification, parentsData?.data),
        threats: coalesceTags('threats', pageData?.data?.classification, parentsData?.data),
        behaviours: coalesceTags('behaviours', pageData?.data?.classification, parentsData?.data),
      },
    },
    {
      validationMode: 'both',
      validationSchema: {
        details: {
          schema: {
            description: z.string(),
            friendlyName: z.string(),
            imageCreditText: z.string(),
            scientificName: z.string(),
            redListStatus: z.string(),
            coverImage: z.string(),
          },
        },
        tags: generateTagsFormValidationSchema(10),
      },
    }
  );

  const onSubmit = React.useCallback(
    async (e?: React.FormEvent<HTMLFormElement>) => {
      e?.preventDefault();

      const classificationTags = ClassificationKeys.reduce((acc, x) => {
        acc[x] =
          formState?.tags?.[x]?.length !== 0
            ? filterParentTags(x, formState?.tags?.[x] || [], parentsData?.data)
            : ['K7hP$w2JzL@5!DqR'];
        return acc;
      }, {});

      let pageDocument: Partial<ClassificationPageApiPageDocumentWildscreenIdPutRequest> = {
        friendlyName: formState?.details.friendlyName,
        description: formState?.details.description,
        coverImageCredit: formState?.details.imageCreditText,
        wildscreenId,
        wildscreenId2: wildscreenId,
        ...classificationTags,
      };

      if (selectedFile) {
        pageDocument = { ...pageDocument, file: selectedFile };
      }

      // file: selectedFile,
      await updatePageDocument(pageDocument);
    },
    [formState, parentsData?.data, updatePageDocument, wildscreenId, selectedFile]
  );

  const dispatch = useToast();

  const onPublishClick = React.useCallback(
    async (e?: React.FormEvent<HTMLFormElement>) => {
      e?.preventDefault();
      const isPublished = !pageData?.data.classification?.isPublished ? 'published' : 'unpublished';
      const title = capitalizeEachWord(isPublished);

      const classificationTags = ClassificationKeys.reduce((acc, x) => {
        acc[x] =
          formState?.tags?.[x]?.length !== 0
            ? filterParentTags(x, formState?.tags?.[x] || [], parentsData?.data)
            : ['K7hP$w2JzL@5!DqR'];
        return acc;
      }, {});

      try {
        let pageDocument: Partial<ClassificationPageApiPageDocumentWildscreenIdPutRequest> = {
          friendlyName: formState?.details.friendlyName,
          description: formState?.details.description,
          coverImageCredit: formState?.details.imageCreditText,
          wildscreenId,
          wildscreenId2: wildscreenId,
          ...classificationTags,
        };

        if (selectedFile) {
          pageDocument = { ...pageDocument, file: selectedFile };
        }
        await updatePageDocument({
          ...pageDocument,
          isPublished: !pageData?.data.classification?.isPublished,
        });

        dispatch({
          title,
          description: admin.authenticated.views.editTaxonomy.toasts.taxonomy.published(isPublished),
          testId: testIds.authenticated.components.publishedToasts('success'),
        });
        // eslint-disable-next-line @typescript-eslint/no-explicit-any -- ignore
      } catch (error: any) {
        dispatch({
          title,
          testId: testIds.authenticated.components.publishedToasts('failed'),
          description: admin.authenticated.views.editTaxonomy.toasts.taxonomy.failed,
        });
      }
    },
    [
      admin.authenticated.views.editTaxonomy.toasts.taxonomy,
      dispatch,
      formState?.details.description,
      formState?.details.friendlyName,
      formState?.details.imageCreditText,
      formState?.tags,
      pageData?.data.classification?.isPublished,
      parentsData?.data,
      selectedFile,
      updatePageDocument,
      wildscreenId,
    ]
  );

  const initialFile = React.useMemo(() => {
    return { src: pageData?.data?.asset?.assetLocation, isVideo: pageData?.data?.asset?.isVideo };
  }, [pageData?.data.asset?.assetLocation, pageData?.data.asset?.isVideo]);

  const onBackClick = React.useCallback(
    (e: React.MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault();
      navigate(-1);
    },
    [navigate]
  );

  function convertToClassificationDict(parents: ClassificationParentViewParents | null | undefined): {
    [key: string]: ClassificationPageView;
  } {
    if (!parents) {
      return {};
    }

    const oldFormat: { [key: string]: ClassificationPageView } = {};

    if (parents.Unknown) {
      oldFormat.Unknown = parents.Unknown;
    }
    if (parents.Kingdom) {
      oldFormat.Kingdom = parents.Kingdom;
    }
    if (parents.Phylum) {
      oldFormat.Phylum = parents.Phylum;
    }
    if (parents.Class) {
      oldFormat.Class = parents.Class;
    }
    if (parents.Order) {
      oldFormat.Order = parents.Order;
    }
    if (parents.Family) {
      oldFormat.Family = parents.Family;
    }
    if (parents.Genus) {
      oldFormat.Genus = parents.Genus;
    }
    if (parents.Species) {
      oldFormat.Species = parents.Species;
    }

    return oldFormat;
  }

  return (
    <AuthenticatedView
      data-testid={testIds.authenticated.views.editTaxonomy.view}
      headerContent={
        <div className={styles.header}>
          <a href="!#" onClick={onBackClick} className={styles.navContainer}>
            <ArrowLeft />
            <h3>{backText}</h3>
          </a>
          <div className={styles.details}>
            <TaxonomyHeader scientificName={scientificName} commonName={commonName} />
            {!isFetchingClassification &&
              pageData?.data.classification?.updatedUserId &&
              pageData?.data.classification?.updatedTimestamp && (
                <div className={styles.subDetails}>
                  <LastUpdated
                    name={pageData.data.classification.updatedUserId}
                    date={pageData.data.classification.updatedTimestamp}
                  />
                  <PublishButton
                    published={pageData?.data.classification?.isPublished}
                    onClick={onPublishClick}
                    isFetching={isFetchingUpdate || isFetchingClassification}
                  />
                </div>
              )}
          </div>
        </div>
      }
    >
      <TaxonomyDetails
        isSpecies={taxonRank === 'Species'}
        bind={formProp('details').bind()}
        onFileSelected={setSelectedFile}
        initialFile={initialFile}
      />
      <Taxonomy
        classificationParentData={convertToClassificationDict(parentsData?.data.parents)}
        taxonomyData={data?.data.results || []}
      />
      <TaxonomyCharacteristics isSpecies={taxonRank === 'Species'} bind={formProp('tags').bind()} />
      <div className={styles.actionButtons}>
        <Button data-style="silverback">{admin.authenticated.views.editTaxonomy.discardChanges}</Button>
        <div>
          <Button data-style="outline-black" type="submit" disabled={!isValid} onClick={async () => onSubmit()}>
            {admin.authenticated.views.editTaxonomy.saveAsDraft}
          </Button>
          <Button
            disabled={!isValid}
            onClick={async () => onPublishClick()}
            pending={isFetchingUpdate || isFetchingClassification}
            data-style="seaweed"
          >
            {pageData?.data.classification?.isPublished
              ? admin.authenticated.views.editTaxonomy.unpublish
              : admin.authenticated.views.editTaxonomy.publish}
          </Button>
        </div>
      </div>
    </AuthenticatedView>
  );
};
