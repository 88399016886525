import * as React from 'react';

export function useOnKeyPress(key: string, callback: () => Promise<void>) {
  React.useEffect(() => {
    const keyDownHandler = event => {
      if (event.key === key) {
        event.preventDefault();
        callback();
      }
    };

    document.addEventListener('keydown', keyDownHandler);

    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, [callback, key]);
}
