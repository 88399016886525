import {
  ClassificationEntry,
  ClassificationParentView,
  SearchAsset,
  SearchClassification,
  TaxonomyEntry,
} from '@wildscreen/api/src/apiClients';

import { ITagsForm } from '../types/forms';
import { capitalizeEachWord } from './helpers';

type TTaxonomyKey = keyof TaxonomyEntry;
export type TTaxonomyKeys = Array<TTaxonomyKey>;

export const taxonKeyArray = ['kingdom', 'phylum', 'class', 'order', 'family', 'genus', 'species'];
export const characteristicsKeyArray = [
  'adaptations',
  'biologicalThemes',
  'behaviours',
  'habitats',
  'habitat',
  'landRegion',
  'threats',
  'actions',
  'lifeStages',
];

/**
 * Check if the input object has any of the keys in the keysToCheck array
 * @param inputObject Taxon object to check
 * @param keysToCheck Array of keys to check
 * @returns Array of keys that exist in the input object
 */
export function checkTaxonomyObjectForKeys(
  inputObject: TaxonomyEntry,
  keysToCheck: TTaxonomyKeys = ['phylum', 'class', 'order', 'family', 'genus']
) {
  const resultArray: TTaxonomyKeys = [];
  for (const key of keysToCheck) {
    if (inputObject[key] !== null && inputObject[key] !== undefined) {
      resultArray.push(key as TTaxonomyKey);
    }
  }

  return resultArray;
}

export type TTag = {
  type: string;
  tag: string;
};

/**
 * Get the tags from the ark asset data
 * @param asset ark asset data
 * @returns array of key tag pairs showing the characteristics first then the taxonomy
 */
export function getTagsFromAsset(asset: SearchAsset | undefined) {
  const tagSet = new Set<string>();
  const resultArray: TTag[] = [];
  taxonKeyArray.forEach(t => {
    if (asset?.inheritance?.[t]) {
      characteristicsKeyArray.forEach(e => {
        // Get values from the classification keys
        if (asset?.inheritance?.[t]?.[e]) {
          asset?.inheritance?.[t]?.[e]?.forEach(tag => {
            const tagString = JSON.stringify({ type: e, tag });
            if (!tagSet.has(tagString)) {
              tagSet.add(tagString);
              resultArray.push({ type: e, tag });
            }
          });
        }
      });
    }
  });
  characteristicsKeyArray.forEach(e => {
    if (asset?.[e]) {
      asset[e].forEach(tag => {
        const tagString = JSON.stringify({ type: e, tag });
        if (!tagSet.has(tagString)) {
          tagSet.add(tagString);
          resultArray.push({ type: e, tag });
        }
      });
    }
  });

  taxonKeyArray.forEach(t => {
    if (asset?.inheritance?.[t]?.values) {
      asset.inheritance[t].values.forEach(tag => {
        const tagString = JSON.stringify({ type: t, tag });
        if (!tagSet.has(tagString)) {
          tagSet.add(tagString);
          resultArray.push({ type: t, tag });
        }
      });
    }
  });

  return resultArray;
}

export function getTagsFromClassification(asset: SearchClassification | undefined) {
  const resultArray: TTag[] = [];
  const tagSet = new Set<string>();
  taxonKeyArray.forEach(t => {
    if (asset?.inheritance?.[t]) {
      characteristicsKeyArray.forEach(e => {
        // Get values from the classification keys
        if (asset?.inheritance?.[t]?.[e]) {
          asset?.inheritance?.[t]?.[e]?.forEach(tag => {
            const tagString = JSON.stringify({ type: e, tag });
            if (!tagSet.has(tagString)) {
              tagSet.add(tagString);
              resultArray.push({ type: e, tag });
            }
          });
        }
      });
    }
  });

  characteristicsKeyArray.forEach(e => {
    if (asset?.[e]) {
      asset[e].forEach(tag => {
        const tagString = JSON.stringify({ type: e, tag });
        if (!tagSet.has(tagString)) {
          tagSet.add(tagString);
          resultArray.push({ type: e, tag });
        }
      });
    }
  });

  // Get values from the inheritance keys
  taxonKeyArray.forEach(t => {
    if (asset?.inheritance?.[t]) {
      asset?.inheritance?.[t].values?.forEach(tag => {
        const tagString = JSON.stringify({ type: t, tag });
        if (!tagSet.has(tagString)) {
          tagSet.add(tagString);
          resultArray.push({ type: t, tag });
        }
      });
    }
  });

  return resultArray;
}

/**
 * Get the tags from the ark asset data
 * @param asset ark asset data
 * @returns array of key tag pairs showing the characteristics first then the taxonomy
 */
export function getTagsFromClassificationInheritance(asset: SearchClassification | SearchAsset | undefined) {
  const resultArray: TTag[] = [];
  const tagSet = new Set<string>();
  taxonKeyArray.forEach(t => {
    if (asset?.inheritance?.[t]) {
      characteristicsKeyArray.forEach(e => {
        // Get values from the classification keys
        if (asset?.inheritance?.[t]?.[e]) {
          asset?.inheritance?.[t]?.[e]?.forEach(tag => {
            const tagString = JSON.stringify({ type: e, tag });
            if (!tagSet.has(tagString)) {
              tagSet.add(tagString);
              resultArray.push({ type: e, tag });
            }
          });
        }
      });
    }
  });

  characteristicsKeyArray.forEach(e => {
    if (asset?.[e]) {
      asset[e].forEach(tag => {
        const tagString = JSON.stringify({ type: e, tag });
        if (!tagSet.has(tagString)) {
          tagSet.add(tagString);
          resultArray.push({ type: e, tag });
        }
      });
    }
  });

  // Get values from the inheritance keys
  taxonKeyArray.forEach(t => {
    if (asset?.inheritance?.[t]) {
      asset?.inheritance?.[t].values?.forEach(tag => {
        const tagString = JSON.stringify({ type: t, tag });
        if (!tagSet.has(tagString)) {
          tagSet.add(tagString);
          resultArray.push({ type: t, tag });
        }
      });
    }
  });

  return resultArray;
}

/**
 * Get the values of the keys that exist in the input object
 * @param inputObject Taxon object to check
 * @param keysToCheck Array of keys to check
 * @returns Array of values
 */
export function getTaxonomyTags(
  inputObject?: TaxonomyEntry,
  keysToCheck: TTaxonomyKeys = ['phylum', 'class', 'order', 'family', 'genus']
) {
  const resultArray: Array<TTag> = [];
  if (!inputObject) {
    return resultArray;
  }
  for (const key of keysToCheck) {
    if (inputObject[key] !== null && inputObject[key] !== undefined) {
      resultArray.push({ type: key, tag: inputObject[key] as string });
    }
  }

  return resultArray;
}

export const taxonRanks = ['kingdom', 'phylum', 'class', 'order', 'family', 'genus'];

/**
 * Get tags from the parent taxonomy data
 * @param tagContainer key of the tag bucket
 * @param parentsData parent taxonomy data
 * @returns array of tags
 */
export function getTagsFromParentTaxonomy(
  tagContainer: keyof ITagsForm,
  parentsData: ClassificationParentView | undefined
) {
  return taxonRanks.flatMap(t => parentsData?.parents?.[capitalizeEachWord(t)]?.classification?.[tagContainer] || []);
}

/**
 * Coalesce tags from the page data and the parent taxonomy data
 * @param tagContainer key of the tag bucket
 * @param pageData taxonomy page data
 * @param parentsData parent taxonomy data
 * @returns array of tags
 */
export function coalesceTags(
  tagContainer: keyof ITagsForm,
  pageData: ClassificationEntry | undefined,
  parentsData: ClassificationParentView | undefined
) {
  const tags = pageData?.[tagContainer] || [];
  const tagsFromParentTaxonomy = getTagsFromParentTaxonomy(tagContainer, parentsData);
  return [...tagsFromParentTaxonomy, ...tags];
}

/**
 * Filter out tags that are already in the parent taxonomy data
 * @param tagContainer key of the tag bucket
 * @param newTags array of tags to filter
 * @param parentsData parent taxonomy data
 * @returns array of tags
 */
export function filterParentTags(
  tagContainer: keyof ITagsForm,
  newTags: string[],
  parentsData: ClassificationParentView | undefined
) {
  const tagsFromParentTaxonomy = getTagsFromParentTaxonomy(tagContainer, parentsData);
  const filteredTags = newTags.filter(x => !tagsFromParentTaxonomy.includes(x));
  return filteredTags.length > 0 ? filteredTags : ['K7hP$w2JzL@5!DqR'];
}
