import { ArrowLeft } from '@phosphor-icons/react';
import { Button, Switch, useForm, useToast } from '@rocketmakers/armstrong';
import { AssetApiAssetUploadAssetIdPutRequest } from '@wildscreen/api/src/apiClients';
import { routes, TAsset, testIds, useDictionary } from '@wildscreen/core/src/core';
import * as React from 'react';
import { Link, useParams } from 'react-router-dom';
import { z } from 'zod';

import { apiHooks } from '../../api';
import { AssetDetails } from '../../components/assetDetails';
import { AssetTags } from '../../components/assetTags';
import { AuthenticatedView } from '../../components/authenticatedView';
import { LastUpdated } from '../../components/lastUpdated';
import { generateAssetTagsFormValidationSchema } from '../../helpers/tags';
import { IUpdateNewAssetForm } from '../../typings/asset';

import styles from './asset.module.scss';

export const EditAssetView: React.FC = () => {
  const { assetId } = useParams<TAsset>();

  const { admin } = useDictionary('en');

  const [selectedFile, setSelectedFile] = React.useState<File | null>(null);

  const [{ data }] = apiHooks.asset.assetAssetIdGet.useQuery({
    parameters: {
      assetId,
    },
    cacheKey: 'assetId',
  });

  const [editAsset, { isFetching: isUpdating }] = apiHooks.asset.assetUploadAssetIdPut.useMutation();

  const dispatch = useToast();
  const { formProp, formState, isValid } = useForm<IUpdateNewAssetForm>(
    {
      details: {
        assetId: data?.data.assetId ?? '',
        credit: data?.data.credit ?? '',
        description: data?.data.description ?? '',
        assetLocation: data?.data.assetLocation ?? '',
        isHomepageWorthy: data?.data.isHomepageWorthy ?? false,
        originId: data?.data.originId ?? '',
        title: data?.data.title ?? '',
        libraryCreditId: data?.data?.libraryCreditId ?? '',
      },
      tags: {
        adaptations: data?.data.adaptations ?? [],
        behaviours: data?.data.behaviours ?? [],
        generalContentDescriptors: data?.data.generalContentDescriptors ?? [],
        habitats: data?.data.habitats ?? [],
        lifeStages: data?.data.lifeStages ?? [],
        regions: data?.data.regions ?? [],
        relatedClassifications: data?.data.relatedClassifications ?? [],
      },
    },
    {
      validationMode: 'both',
      validationSchema: {
        details: {
          schema: {
            title: z.string().nonempty(),
            credit: z.string().nonempty(),
          },
        },
        tags: generateAssetTagsFormValidationSchema(5),
      },
    }
  );

  const onSubmit = React.useCallback(
    async (e?: React.FormEvent<HTMLFormElement>) => {
      e?.preventDefault();
      let assetData: Partial<AssetApiAssetUploadAssetIdPutRequest> = {
        ...formState?.details,
        ...formState?.tags,
        isPublished: true,
      };

      if (selectedFile) {
        assetData = { ...assetData, file: selectedFile };
      }

      await editAsset(assetData);
      dispatch({
        description: admin.authenticated.views.editTaxonomy.toasts.asset.published('published'),
        testId: testIds.authenticated.components.publishedToasts('success'),
      });
    },
    [editAsset, selectedFile, dispatch, formState, admin]
  );

  const onRemoveAsset = React.useCallback(
    async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e?.preventDefault();
      const assetData: Partial<AssetApiAssetUploadAssetIdPutRequest> = {
        ...formState?.details,
        ...formState?.tags,
        isPublished: false,
      };
      await editAsset(assetData);

      dispatch({
        description: admin.authenticated.views.editTaxonomy.toasts.asset.published('unpublished'),
        testId: testIds.authenticated.components.publishedToasts('success'),
      });
    },
    [editAsset, dispatch, formState, admin]
  );

  const initialFile = React.useMemo(() => {
    return {
      src: data?.data?.assetLocation,
      isVideo: data?.data?.isVideo,
    };
  }, [data?.data?.assetLocation, data?.data?.isVideo]);

  return (
    <AuthenticatedView
      data-testid={testIds.authenticated.views.uploadAsset.view}
      headerContent={
        <div className={styles.header}>
          <div className={styles.linkAndAudit}>
            <Link to={routes.authenticated.admin.assets.gallery()} className={styles.navContainer}>
              <ArrowLeft />
              <h3>{admin.authenticated.views.assets.back}</h3>
            </Link>
            {data?.data?.updatedUserId && data?.data?.updatedTimestamp && (
              <LastUpdated removeMargin name={data?.data?.updatedUserId} date={data?.data?.updatedTimestamp} />
            )}
          </div>
          <div className={styles.details}>
            <h2>{admin.authenticated.views.assets.heading(true)}</h2>
            <Switch label={admin.authenticated.views.assets.actionAdvert} onCheckedChange={function ro() {}} />
          </div>
        </div>
      }
    >
      <form onSubmit={onSubmit}>
        <AssetDetails bind={formProp('details').bind()} onFileSelected={setSelectedFile} initialFile={initialFile} />
        <AssetTags bind={formProp('tags').bind()} />
        <div className={styles.actionButtons}>
          {data?.data?.isPublished && (
            <Button type="button" onClick={onRemoveAsset} data-style="danger">
              {admin.authenticated.views.assets.removeAsset}
            </Button>
          )}
          <Button pending={isUpdating} disabled={!isValid} type="submit" data-style="seaweed">
            {data?.data?.isPublished ? `Update` : 'Publish'}
          </Button>
        </div>
      </form>
    </AuthenticatedView>
  );
};
