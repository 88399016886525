export type TSearch = {
  wildscreenId?: string;
};

export type TTaxonomy = {
  wildscreenId?: string;
};

export type TAsset = {
  assetId?: string;
};
export type TLibraryCredit = {
  libraryCreditId?: string;
};

export type TEducationResources = {
  educationResourcesId?: string;
};

export type TClassification = {
  classificationId?: string;
};

export type TExploreAsset = {
  assetProperty?: string;
  assetSearchTerm?: string;
};

export type TExploreClassification = {
  classificationProperty?: string;
  classificationSearchTerm?: string;
};

export type TAssetProperty = {
  targetProperty?: string;
};

export type TClassificationProperty = {
  targetProperty?: string;
};

export type TExploreTaxonomy = {
  taxonomyRank?: string;
  taxonomySearchTerm?: string;
};

export const routes = {
  arkive: {
    root: () => `/`,
  },
  unauthenticated: {
    root: () => `/`,
    login: () => `/login`,
    verify: () => `/verify`,
    register: () => '/register',
    forgotPassword: () => '/forgot-password',
    resetPassword: () => '/reset-password',
  },
  authenticated: {
    admin: {
      root: () => `/`,
      account: () => `/account`,
      assets: {
        gallery: () => `/assets`,
        create: () => `/assets/create`,
        edit: (params: TAsset) => `/assets/edit/${params.assetId ?? ':assetId'}`,
      },
      homepageContent: {
        root: () => '/homepage-content',
      },
      libraryCredit: {
        root: () => '/library-credit',
        create: () => '/library-credit/create',
        edit: (params: TLibraryCredit) => `/library-credit/edit/${params.libraryCreditId ?? ':libraryCreditId'}`,
      },
      educationResources: {
        root: () => '/education-resources',
        create: () => '/education-resources/create',
        edit: (params: TEducationResources) =>
          `/education-resources/edit/${params.educationResourcesId ?? ':educationResourcesId'}`,
      },
      search: {
        root: () => '/search',
        edit: (params: TSearch) => `/search/edit/${params.wildscreenId ?? ':wildscreenId'}`,
      },
      taxonomy: {
        root: () => `/taxonomy`,
        edit: (params: TTaxonomy) => `/taxonomy/edit/${params.wildscreenId ?? ':wildscreenId'}`,
      },
    },
    arkive: {
      root: () => `/`,
      explore: () => `/explore`,
      assetProperty: (params: TAssetProperty) => `/asset-property/${params.targetProperty ?? ':targetProperty'}`,
      classificationProperty: (params: TClassificationProperty) =>
        `/classification-property/${params.targetProperty ?? ':targetProperty'}`,
      exploreTaxonomy: (params: TExploreTaxonomy) =>
        `/explore-taxonomy/${params.taxonomyRank ?? ':taxonomyRank'}/${
          params.taxonomySearchTerm ?? ':taxonomySearchTerm'
        }`,
      exploreAsset: (params: TExploreAsset) =>
        `/explore-asset/${params.assetProperty ?? ':assetProperty'}/${params.assetSearchTerm ?? ':assetSearchTerm'}`,
      exploreClassification: (params: TExploreClassification) =>
        `/explore/${params.classificationProperty ?? ':classificationProperty'}/${
          params.classificationSearchTerm ?? ':classificationSearchTerm'
        }`,
      aboutUs: () => `/about-us`,
      educationResources: () => `/education-resources`,
      wildscreen: () => `/wildscreen`,
      termsAndConditions: () => `/terms-and-conditions`,
      privacyPolicy: () => `/privacy-policy`,
      asset: (params: TAsset) => `/asset/${params.assetId ?? ':assetId'}`,
      taxonomy: (params: TTaxonomy) => `/taxonomy/${params.wildscreenId ?? ':wildscreenId'}}`,
      classification: (params: TClassification) => `/classification/${params.classificationId ?? ':classificationId'}`,
      error404: () => `/not-found`,
    },
  },
};
