import { ArmstrongConfigProvider } from '@rocketmakers/armstrong';
import { routes, useDictionary } from '@wildscreen/core/src/core';
import * as React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { AuthenticatedShell, UnauthenticatedShell } from './components/_layout/shells';
import { useAuth } from './contexts';
import { AccountView } from './views/account';
import { CreateAssetView, EditAssetView } from './views/asset';
import { AssetGalleryView } from './views/assetGalleryView';
import { ForgotPasswordView, LoginView, RegisterView, ResetPasswordView, VerifyView } from './views/auth';
import {
  CreateEducationResourceView,
  EditEducationResourceView,
  EducationResourcesView,
} from './views/educationResources';
import { HomepageContentView } from './views/homepageContent';
import { CreateLibraryCreditView, LibraryCreditView } from './views/libraryCredit';
import { EditLibraryCreditView } from './views/libraryCredit/editLibraryCredit';
import { SearchView } from './views/search';
import { EditTaxonomyView, TaxonomyView } from './views/taxonomy';

const UnauthenticatedRoutes = () => {
  return (
    <Routes>
      <Route path={routes.unauthenticated.root()} element={<UnauthenticatedShell />}>
        <Route path={routes.unauthenticated.login()} element={<LoginView />} />
        <Route path={routes.unauthenticated.register()} element={<RegisterView />} />
        <Route path={routes.unauthenticated.forgotPassword()} element={<ForgotPasswordView />} />
        <Route path={routes.unauthenticated.resetPassword()} element={<ResetPasswordView />} />
        <Route path={routes.unauthenticated.verify()} element={<VerifyView />} />
        <Route
          path={routes.unauthenticated.root()}
          element={<Navigate replace to={routes.unauthenticated.login()} />}
        />
        <Route path="*" element={<Navigate replace to={routes.unauthenticated.login()} />} />
      </Route>
    </Routes>
  );
};

const AuthenticatedRoutes = () => {
  const { admin } = useDictionary('en');
  return (
    <Routes>
      <Route path="/" element={<AuthenticatedShell />}>
        <Route index path={routes.authenticated.admin.search.root()} element={<SearchView />} />
        <Route path={routes.authenticated.admin.taxonomy.root()} element={<TaxonomyView />} />
        <Route path={routes.authenticated.admin.account()} element={<AccountView />} />
        <Route path={routes.authenticated.admin.assets.gallery()} element={<AssetGalleryView />} />
        <Route path={routes.authenticated.admin.assets.create()} element={<CreateAssetView />} />
        <Route path={routes.authenticated.admin.assets.edit({ assetId: ':assetId' })} element={<EditAssetView />} />
        <Route path={routes.authenticated.admin.libraryCredit.root()} element={<LibraryCreditView />} />
        <Route path={routes.authenticated.admin.libraryCredit.create()} element={<CreateLibraryCreditView />} />
        <Route
          path={routes.authenticated.admin.libraryCredit.edit({ libraryCreditId: ':libraryCreditId' })}
          element={<EditLibraryCreditView />}
        />
        <Route
          path={routes.authenticated.admin.search.edit({ wildscreenId: ':wildscreenId' })}
          element={<EditTaxonomyView backText={admin.authenticated.views.editTaxonomy.backToSearch} />}
        />
        <Route
          path={routes.authenticated.admin.taxonomy.edit({ wildscreenId: ':wildscreenId' })}
          element={<EditTaxonomyView backText={admin.authenticated.views.editTaxonomy.backToTaxonomy} />}
        />
        <Route path={routes.authenticated.admin.educationResources.root()} element={<EducationResourcesView />} />
        <Route
          path={routes.authenticated.admin.educationResources.create()}
          element={<CreateEducationResourceView />}
        />
        <Route
          path={routes.authenticated.admin.educationResources.edit({ educationResourcesId: ':educationResourcesId' })}
          element={<EditEducationResourceView />}
        />
        <Route path={routes.authenticated.admin.homepageContent.root()} element={<HomepageContentView />} />
        <Route path="*" element={<Navigate replace to={routes.authenticated.admin.search.root()} />} />
      </Route>
    </Routes>
  );
};

export const Routing: React.FC = () => {
  const { isLoggedIn } = useAuth();
  return (
    <ArmstrongConfigProvider>
      {isLoggedIn ? <AuthenticatedRoutes /> : <UnauthenticatedRoutes />}
    </ArmstrongConfigProvider>
  );
};
