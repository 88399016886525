// import { AssetListProperty } from '@wildscreen/api/src/apiClients';
import { AssetListProperty, ClassificationListProperty } from '@wildscreen/api/src/apiClients';
import { formatAgeCategory, formatSubject } from '@wildscreen/core/src/core';
import * as React from 'react';

import { apiHooks } from '../api';
import { mapResourceCategories, mapTags } from '../helpers/tags';

export function useGetEducationResourceTags() {
  const [searchTerms, setSearchTerms] = React.useState<{
    ageCategory: string;
    type: string;
    subjects: string;
    biologicalThemes: string;
    habitats: string;
    landRegions: string;
    threats: string;
    actions: string;
  }>({
    ageCategory: '',
    type: '',
    subjects: '',
    actions: '',
    biologicalThemes: '',
    habitats: '',
    landRegions: '',
    threats: '',
  });

  const [{ data: subjectData }] = apiHooks.data.dataPropertiesResourceSubjectGet.useQuery();

  const [{ data: ageCatData }] = apiHooks.data.dataPropertiesResourceAgeCategoryGet.useQuery();

  const [{ data: resourceTypeData }] = apiHooks.data.dataPropertiesResourceTypeGet.useQuery();

  const [{ data: biologicalThemesData }] = apiHooks.data.dataPropertiesClassificationTargetPropertyGet.useQuery({
    parameters: { targetProperty: ClassificationListProperty.BiologicalThemes, prefix: searchTerms.biologicalThemes },
    cacheKey: 'targetProperty',
  });

  const [{ data: habitatsData }] = apiHooks.data.dataPropertiesAssetTargetPropertyGet.useQuery({
    parameters: { targetProperty: AssetListProperty.Habitats, prefix: searchTerms.habitats },
    cacheKey: 'targetProperty',
  });

  const [{ data: landRegionsData }] = apiHooks.data.dataPropertiesAssetTargetPropertyGet.useQuery({
    parameters: { targetProperty: AssetListProperty.LandRegions, prefix: searchTerms.landRegions },
    cacheKey: 'targetProperty',
  });

  const [{ data: threatsData }] = apiHooks.data.dataPropertiesClassificationTargetPropertyGet.useQuery({
    parameters: { targetProperty: ClassificationListProperty.Threats, prefix: searchTerms.threats },
    cacheKey: 'targetProperty',
  });

  const [{ data: actionsData }] = apiHooks.data.dataPropertiesClassificationTargetPropertyGet.useQuery({
    parameters: { targetProperty: ClassificationListProperty.Actions, prefix: searchTerms.actions },
    cacheKey: 'targetProperty',
  });

  return {
    data: {
      subjects: mapResourceCategories(subjectData, formatSubject),
      ageCategory: mapResourceCategories(ageCatData, formatAgeCategory),
      type: mapResourceCategories(resourceTypeData),
      biologicalThemes: mapTags(biologicalThemesData),
      habitats: mapTags(habitatsData),
      landRegions: mapTags(landRegionsData),
      threats: mapTags(threatsData),
      actions: mapTags(actionsData),
    },
    setSearchTerms,
    searchTerms,
  };
}
