import * as React from 'react';

import { TLogoSize } from '../../../types';

import styles from './wildscreen.module.scss';

export interface IWildscreenLogoProps {
  size: TLogoSize;
  animate: boolean;
}

export const WildscreenLogo: React.FC<IWildscreenLogoProps> = ({ size, animate }) => {
  return (
    <div data-size={size} className={styles.wildscreenLogo} data-in-header={animate}>
      <svg
        data-in-header={animate}
        xmlns="http://www.w3.org/2000/svg"
        width="201"
        height="144"
        viewBox="0 0 201 144"
        fill="none"
      >
        <path
          d="M200.932 -16.4541L200.932 116.888C200.932 131.8 188.843 143.888 173.932 143.888L27.3428 143.888C12.4311 143.888 0.342776 131.8 0.342777 116.888L0.342789 -16.4541L200.932 -16.4541Z"
          className={styles.surround}
        />
        <path d="M47.6414 110.404V115.603H25.9648V93.9254H31.3311V110.404H47.6414Z" className={styles.dark} />
        <path d="M175.32 93.9254V115.603H153.641V110.236H169.958V93.9254H175.32Z" className={styles.dark} />
        <path d="M47.6414 23.189V28.4926H31.3287V44.8679H25.9648V23.189H47.6414Z" className={styles.dark} />
        <path d="M175.32 23.189V44.8679H169.956V28.7585H153.641V23.189H175.32Z" className={styles.dark} />
        <path
          d="M77.0841 50.0237H74.3306L72.7004 42.0347L71.1969 50.0237H68.6244L66.3965 37.7236H69.3309L70.454 45.3138L71.9397 37.7236H74.0592L75.7437 45.3305L76.7764 37.7236H79.131L77.0841 50.0237Z"
          className={styles.dark}
        />
        <path
          d="M79.9824 37.3789H82.4644V39.5348H79.9824V37.3789ZM79.9824 40.9841H82.4644V50.0235H79.9824V40.9841Z"
          className={styles.dark}
        />
        <path
          d="M86.2496 50.0237H84.8729C84.1479 50.0237 83.7676 49.6612 83.7676 48.9005V37.7236H86.2496V50.0237Z"
          className={styles.dark}
        />
        <path
          d="M87.4102 47.0524V43.9549C87.4102 41.6544 88.3702 40.7854 89.7648 40.7854C90.9058 40.7854 91.4856 41.4014 91.7242 41.999V37.7236H94.1877V50.0237H91.7213V48.8821C91.4856 49.5344 90.9058 50.2046 89.7827 50.2046C88.3702 50.2046 87.4102 49.2267 87.4102 47.0524ZM91.7034 46.8173V44.1722C91.7034 43.3936 91.3576 42.9948 90.7975 42.9948C90.2541 42.9948 89.8916 43.3752 89.8916 44.1722V46.8173C89.8916 47.5958 90.2541 47.9583 90.7975 47.9583C91.3588 47.9583 91.7034 47.5958 91.7034 46.8173Z"
          className={styles.dark}
        />
        <path
          d="M95.1621 47.5598V47.1962L96.9739 46.9968V47.4134C96.9739 48.2467 97.2621 48.6271 97.896 48.6271C98.4394 48.6271 98.7471 48.3193 98.7471 47.6854C98.7471 47.1962 98.5299 46.9426 98.1495 46.7432L96.7227 46.0379C95.7263 45.5307 95.2733 44.7701 95.2733 43.6469C95.2733 42.0524 96.1429 40.7845 98.2982 40.7845C100.092 40.7845 101.122 41.6541 101.122 43.1755V43.6285L99.3286 43.81V43.3928C99.3286 42.813 99.1292 42.4506 98.5679 42.4506C98.0245 42.4506 97.7888 42.7963 97.7888 43.3202C97.7888 43.7368 97.9519 43.9725 98.5316 44.2624L99.9446 44.9683C100.924 45.4576 101.321 46.2182 101.321 47.2872C101.321 48.7907 100.561 50.2038 98.0971 50.2038C96.1948 50.2043 95.1621 49.3169 95.1621 47.5598Z"
          className={styles.dark}
        />
        <path
          d="M108.349 46.545V46.7081C108.349 49.3169 106.918 50.2043 105.198 50.2043C103.008 50.2043 102.1 48.755 102.1 46.8349V44.1719C102.1 42.3244 102.951 40.7845 105.198 40.7845C106.846 40.7845 108.349 41.6178 108.349 44.1719V44.4802L106.411 44.6433V44.1719C106.411 43.3933 106.066 42.9946 105.505 42.9946C104.945 42.9946 104.599 43.3933 104.599 44.1719V46.817C104.599 47.5955 104.945 47.958 105.505 47.958C106.049 47.958 106.411 47.5955 106.411 46.817V46.3819L108.349 46.545Z"
          className={styles.dark}
        />
        <path
          d="M109.328 40.9839H111.81V42.4874C112.19 41.2196 113.005 40.7845 114.165 40.7845L114.147 43.4654C113.859 43.3023 113.368 43.2481 113.151 43.2481C112.136 43.2481 111.81 43.9183 111.81 45.1683V50.0234H109.328V40.9839Z"
          className={styles.dark}
        />
        <path
          d="M130.666 40.9844V42.183C130.883 41.5307 131.662 40.7878 132.767 40.7878C134.092 40.7878 134.886 41.567 134.886 43.3782V50.0238H132.405V44.136C132.405 43.4301 132.169 43.0313 131.608 43.0313C131.047 43.0313 130.666 43.4301 130.666 44.1544V50.0238H128.184V40.9844H130.666Z"
          className={styles.dark}
        />
        <path
          d="M117.722 40.7845C117.085 40.8063 116.476 41.0461 115.995 41.4637C115.514 41.8814 115.191 42.4515 115.081 43.0787C115.081 43.0814 115.081 43.0841 115.081 43.0868C115.065 43.1756 115.054 43.2653 115.048 43.3553C114.969 44.1834 114.928 46.5438 116.73 48.3343C117.728 49.3336 119.056 49.935 120.465 50.0257V47.9217C119.693 47.7304 119.223 47.1149 119.057 46.3398H120.465V40.7845H117.722Z"
          className={styles.color}
        />
        <path
          d="M124.054 40.7845C123.417 40.8063 122.808 41.0461 122.327 41.4637C121.846 41.8814 121.523 42.4515 121.413 43.0787V43.0868C121.397 43.1756 121.386 43.2653 121.38 43.3553C121.301 44.1834 121.26 46.5438 123.063 48.3343C124.061 49.3333 125.389 49.9341 126.799 50.024V47.9217C126.027 47.7304 125.557 47.1149 125.391 46.3398H126.801V40.7845H124.054Z"
          className={styles.color}
        />
        <path
          d="M77.0841 50.0237H74.3306L72.7004 42.0347L71.1969 50.0237H68.6244L66.3965 37.7236H69.3309L70.454 45.3138L71.9397 37.7236H74.0592L75.7437 45.3305L76.7764 37.7236H79.131L77.0841 50.0237Z"
          className={styles.dark}
        />
        <path
          d="M79.9824 37.3789H82.4644V39.5348H79.9824V37.3789ZM79.9824 40.9841H82.4644V50.0235H79.9824V40.9841Z"
          className={styles.dark}
        />
        <path
          d="M86.2496 50.0237H84.8729C84.1479 50.0237 83.7676 49.6612 83.7676 48.9005V37.7236H86.2496V50.0237Z"
          className={styles.dark}
        />
        <path
          d="M87.4102 47.0524V43.9549C87.4102 41.6544 88.3702 40.7854 89.7648 40.7854C90.9058 40.7854 91.4856 41.4014 91.7242 41.999V37.7236H94.1877V50.0237H91.7213V48.8821C91.4856 49.5344 90.9058 50.2046 89.7827 50.2046C88.3702 50.2046 87.4102 49.2267 87.4102 47.0524ZM91.7034 46.8173V44.1722C91.7034 43.3936 91.3576 42.9948 90.7975 42.9948C90.2541 42.9948 89.8916 43.3752 89.8916 44.1722V46.8173C89.8916 47.5958 90.2541 47.9583 90.7975 47.9583C91.3588 47.9583 91.7034 47.5958 91.7034 46.8173Z"
          className={styles.dark}
        />
        <path
          d="M95.1621 47.5598V47.1962L96.9739 46.9968V47.4134C96.9739 48.2467 97.2621 48.6271 97.896 48.6271C98.4394 48.6271 98.7471 48.3193 98.7471 47.6854C98.7471 47.1962 98.5299 46.9426 98.1495 46.7432L96.7227 46.0379C95.7263 45.5307 95.2733 44.7701 95.2733 43.6469C95.2733 42.0524 96.1429 40.7845 98.2982 40.7845C100.092 40.7845 101.122 41.6541 101.122 43.1755V43.6285L99.3286 43.81V43.3928C99.3286 42.813 99.1292 42.4506 98.5679 42.4506C98.0245 42.4506 97.7888 42.7963 97.7888 43.3202C97.7888 43.7368 97.9519 43.9725 98.5316 44.2624L99.9446 44.9683C100.924 45.4576 101.321 46.2182 101.321 47.2872C101.321 48.7907 100.561 50.2038 98.0971 50.2038C96.1948 50.2043 95.1621 49.3169 95.1621 47.5598Z"
          className={styles.dark}
        />
        <path
          d="M108.349 46.545V46.7081C108.349 49.3169 106.918 50.2043 105.198 50.2043C103.008 50.2043 102.1 48.755 102.1 46.8349V44.1719C102.1 42.3244 102.951 40.7845 105.198 40.7845C106.846 40.7845 108.349 41.6178 108.349 44.1719V44.4802L106.411 44.6433V44.1719C106.411 43.3933 106.066 42.9946 105.505 42.9946C104.945 42.9946 104.599 43.3933 104.599 44.1719V46.817C104.599 47.5955 104.945 47.958 105.505 47.958C106.049 47.958 106.411 47.5955 106.411 46.817V46.3819L108.349 46.545Z"
          className={styles.dark}
        />
        <path
          d="M109.328 40.9839H111.81V42.4874C112.19 41.2196 113.005 40.7845 114.165 40.7845L114.147 43.4654C113.859 43.3023 113.368 43.2481 113.151 43.2481C112.136 43.2481 111.81 43.9183 111.81 45.1683V50.0234H109.328V40.9839Z"
          className={styles.dark}
        />
        <path
          d="M130.666 40.9844V42.183C130.883 41.5307 131.662 40.7878 132.767 40.7878C134.092 40.7878 134.886 41.567 134.886 43.3782V50.0238H132.405V44.136C132.405 43.4301 132.169 43.0313 131.608 43.0313C131.047 43.0313 130.666 43.4301 130.666 44.1544V50.0238H128.184V40.9844H130.666Z"
          className={styles.dark}
        />
        <path
          d="M117.722 40.7845C117.085 40.8063 116.476 41.0461 115.995 41.4637C115.514 41.8814 115.191 42.4515 115.081 43.0787C115.081 43.0814 115.081 43.0841 115.081 43.0868C115.065 43.1756 115.054 43.2653 115.048 43.3553C114.969 44.1834 114.928 46.5438 116.73 48.3343C117.728 49.3336 119.056 49.935 120.465 50.0257V47.9217C119.693 47.7304 119.223 47.1149 119.057 46.3398H120.465V40.7845H117.722Z"
          className={styles.color}
        />
        <path
          d="M124.054 40.7845C123.417 40.8063 122.808 41.0461 122.327 41.4637C121.846 41.8814 121.523 42.4515 121.413 43.0787V43.0868C121.397 43.1756 121.386 43.2653 121.38 43.3553C121.301 44.1834 121.26 46.5438 123.063 48.3343C124.061 49.3333 125.389 49.9341 126.799 50.024V47.9217C126.027 47.7304 125.557 47.1149 125.391 46.3398H126.801V40.7845H124.054Z"
          className={styles.color}
        />
        <path
          d="M56.0957 101.413L65.8886 57.0835H77.836L86.4538 101.413H75.4857L74.1147 93.3174H65.8234L64.3218 101.413H56.0957ZM70.1323 68.3127L66.7374 85.8747H73.266L70.1323 68.3127Z"
          className={styles.color}
        />
        <path
          d="M100.662 76.2124C103.339 76.2124 104.645 74.9066 104.645 72.2299V69.4879C104.645 66.9417 103.339 65.7013 100.662 65.7013H97.5285V76.2124H100.662ZM114.634 100.303L114.764 101.413H107.387C106.407 101.413 105.689 101.086 105.298 100.434C104.906 99.7807 104.645 98.8667 104.579 97.7568L104.057 87.3763C103.861 84.569 102.556 83.1327 100.14 83.1327H97.6591V101.348H88.519V57.0182H102.947C106.407 57.0182 109.084 57.8669 110.977 59.6296C112.871 61.3924 113.85 64.0038 113.85 67.464V72.0993C113.85 75.8859 112.022 78.3668 108.301 79.542V79.7378C111.369 80.456 113.001 82.8063 113.197 86.7887L113.785 96.7122C113.915 98.6055 114.177 99.7807 114.634 100.303Z"
          className={styles.color}
        />
        <path
          d="M117.112 57.0835H126.187V77.6487L136.828 57.0835H145.185L136.241 75.0372L144.728 101.413H134.674L129.647 83.0021L126.187 89.2043V101.413H117.112V57.0835Z"
          className={styles.color}
        />
      </svg>
    </div>
  );
};
