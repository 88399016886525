import { Trash, Upload } from '@phosphor-icons/react';
import { Button, Input, useForm, useToast } from '@rocketmakers/armstrong';
import { LibraryCreditApiLibraryCreditLibraryCreditIdPutRequest } from '@wildscreen/api/src/apiClients';
import { routes, testIds, TLibraryCredit, useDictionary } from '@wildscreen/core/src/core';
import { FileUpload } from '@wildscreen/ui-components/src/components/fileUpload';
import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { z } from 'zod';

import { apiHooks } from '../../api';
import { AuthenticatedView } from '../../components/authenticatedView';

import styles from './libraryCredit.module.scss';

export const EditLibraryCreditView: React.FC = () => {
  const { admin } = useDictionary('en');

  const { libraryCreditId } = useParams<TLibraryCredit>();

  const dispatch = useToast();
  const [selectedFile, setSelectedFile] = React.useState<File | null>(null);

  const navigate = useNavigate();

  const [{ data }] = apiHooks.libraryCredit.libraryCreditLibraryCreditIdGet.useQuery({
    parameters: {
      libraryCreditId: libraryCreditId ?? '',
    },
  });

  const { formProp, formState } = useForm<LibraryCreditApiLibraryCreditLibraryCreditIdPutRequest>(
    {
      libraryCreditId: libraryCreditId ?? '',
      libraryName: data?.data.name ?? '',
      url: data?.data.url ?? '',
    },
    {
      validationMode: 'both',
      validationSchema: {
        libraryCreditId: z.string(),
        libraryName: z.string().min(1).max(255).optional(),
        url: z.string().url().optional(),
      },
    }
  );

  const [updateLibraryCredit, { isFetching }] = apiHooks.libraryCredit.libraryCreditLibraryCreditIdPut.useMutation();
  const [deleteLibraryCredit, { isFetching: isDeleting }] =
    apiHooks.libraryCredit.libraryCreditLibraryCreditIdDelete.useMutation();

  const initialFile = React.useMemo(() => {
    return {
      src: data?.data?.libraryCreditLocation,
      isVideo: false,
    };
  }, [data?.data?.libraryCreditLocation]);

  const onDelete = React.useCallback(
    async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e?.preventDefault();
      try {
        await deleteLibraryCredit({
          libraryCreditId: libraryCreditId ?? '',
        });
        dispatch({
          title: admin.authenticated.views.libraryCredit.success('deleted'),
        });
        navigate(routes.authenticated.admin.libraryCredit.root());
      } catch (error) {
        dispatch({
          title: admin.authenticated.views.libraryCredit.error('delete', JSON.stringify(error)),
        });
      }
    },
    [admin.authenticated.views.libraryCredit, deleteLibraryCredit, dispatch, libraryCreditId, navigate]
  );

  const onSubmit = React.useCallback(
    async (e?: React.FormEvent<HTMLFormElement>) => {
      e?.preventDefault();

      let updateLibraryCreditRequest: LibraryCreditApiLibraryCreditLibraryCreditIdPutRequest = {
        libraryCreditId: libraryCreditId ?? '',
        libraryCreditId2: libraryCreditId ?? '',
        libraryName: formState?.libraryName,
        url: formState?.url,
      };

      if (selectedFile) {
        updateLibraryCreditRequest = {
          ...updateLibraryCreditRequest,
          file: selectedFile,
        };
      }
      try {
        await updateLibraryCredit(updateLibraryCreditRequest);
        dispatch({
          title: admin.authenticated.views.libraryCredit.success('updated'),
        });
      } catch (error) {
        dispatch({
          title: admin.authenticated.views.libraryCredit.error('update', JSON.stringify(error)),
        });
      }
    },
    [
      libraryCreditId,
      formState?.libraryName,
      formState?.url,
      selectedFile,
      updateLibraryCredit,
      dispatch,
      admin.authenticated.views.libraryCredit,
    ]
  );

  return (
    <AuthenticatedView
      data-testid={testIds.authenticated.views.libraryCredit.view}
      heading={admin.authenticated.views.libraryCredit.action('Update')}
    >
      <form onSubmit={onSubmit}>
        <Input
          label={admin.authenticated.views.libraryCredit.libraryName}
          required
          type="text"
          bind={formProp('libraryName').bind()}
        />
        <Input label={admin.authenticated.views.libraryCredit.libraryUrl} type="text" bind={formProp('url').bind()} />
        <FileUpload
          initialFile={initialFile}
          onFileSelected={setSelectedFile}
          data-testid={testIds.authenticated.views.uploadAsset.forms.details.input('asset')}
          entityType="Library Credit"
        />
        <div className={styles.actions}>
          <Button type="submit" data-style="seaweed" leftOverlay={<Upload />} pending={isFetching}>
            {admin.authenticated.views.libraryCredit.action('Update')}
          </Button>
          <Button disabled={true} pending={isDeleting} onClick={onDelete} data-style="danger" leftOverlay={<Trash />}>
            {admin.authenticated.views.libraryCredit.action('Delete')}
          </Button>
        </div>
      </form>
    </AuthenticatedView>
  );
};
