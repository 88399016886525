import { CheckCircle, Ghost } from '@phosphor-icons/react';
import { Button } from '@rocketmakers/armstrong';
import * as React from 'react';

import styles from './publishButton.module.scss';

export type TPublishedState = 'published' | 'unpublished';

export const publishedStates = {
  published: { icon: <CheckCircle />, title: 'Published', style: 'seaweed' },
  unpublished: { icon: <Ghost />, title: 'Unpublished', style: 'silverback' },
};

export interface IPublishButtonProps {
  isFetching?: boolean;
  published?: boolean;
  onClick?: () => void;
}

export const PublishButton: React.FC<IPublishButtonProps> = ({ published, onClick, isFetching }) => {
  const publishedState = published ? 'published' : 'unpublished';
  return (
    <Button
      className={styles.publishButton}
      pending={isFetching}
      onClick={e => {
        e.stopPropagation();
        onClick?.();
      }}
      leftOverlay={publishedStates[publishedState].icon}
      data-style={publishedStates[publishedState].style}
      type="submit"
    >
      {publishedStates[publishedState].title}
    </Button>
  );
};
