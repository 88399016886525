/* tslint:disable */
/* eslint-disable */
/**
 * Wildscreen.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface ArkResource
 */
export interface ArkResource {
    /**
     * 
     * @type {string}
     * @memberof ArkResource
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof ArkResource
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof ArkResource
     */
    'ageCategory'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ArkResource
     */
    'subjects'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof ArkResource
     */
    'type'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ArkResource
     */
    'isPublished': boolean | null;
    /**
     * 
     * @type {string}
     * @memberof ArkResource
     */
    'resourceName'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ArkResource
     */
    'biologicalThemes'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ArkResource
     */
    'habitats'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ArkResource
     */
    'landRegions'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ArkResource
     */
    'threats'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ArkResource
     */
    'actions'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof ArkResource
     */
    'resourceId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ArkResource
     */
    'location'?: string | null;
}
/**
 * 
 * @export
 * @interface ArkResourceAllOf
 */
export interface ArkResourceAllOf {
    /**
     * 
     * @type {string}
     * @memberof ArkResourceAllOf
     */
    'resourceId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ArkResourceAllOf
     */
    'location'?: string | null;
}
/**
 * 
 * @export
 * @interface ArkResourceSearchResult
 */
export interface ArkResourceSearchResult {
    /**
     * 
     * @type {number}
     * @memberof ArkResourceSearchResult
     */
    'total': number;
    /**
     * 
     * @type {Array<ArkResource>}
     * @memberof ArkResourceSearchResult
     */
    'results'?: Array<ArkResource> | null;
    /**
     * 
     * @type {number}
     * @memberof ArkResourceSearchResult
     */
    'from': number | null;
    /**
     * 
     * @type {number}
     * @memberof ArkResourceSearchResult
     */
    'size': number | null;
}
/**
 * 
 * @export
 * @interface Asset
 */
export interface Asset {
    /**
     * 
     * @type {string}
     * @memberof Asset
     */
    'assetId'?: string;
    /**
     * 
     * @type {string}
     * @memberof Asset
     */
    'assetLocation'?: string;
    /**
     * 
     * @type {string}
     * @memberof Asset
     */
    'originId'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Asset
     */
    'isVideo': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Asset
     */
    'isHero': boolean;
    /**
     * 
     * @type {string}
     * @memberof Asset
     */
    'promotionLink'?: string;
    /**
     * 
     * @type {string}
     * @memberof Asset
     */
    'title'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Asset
     */
    'isPublished': boolean;
    /**
     * 
     * @type {string}
     * @memberof Asset
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof Asset
     */
    'credit'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Asset
     */
    'isHomepageWorthy': boolean | null;
    /**
     * List of regions where the asset is relevant or originated.
     * @type {Array<string>}
     * @memberof Asset
     */
    'regions'?: Array<string> | null;
    /**
     * List of behaviors associated with the asset.
     * @type {Array<string>}
     * @memberof Asset
     */
    'behaviours'?: Array<string> | null;
    /**
     * List of adaptations associated with the asset.
     * @type {Array<string>}
     * @memberof Asset
     */
    'adaptations'?: Array<string> | null;
    /**
     * List of habitats associated with the asset.
     * @type {Array<string>}
     * @memberof Asset
     */
    'habitats'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof Asset
     */
    'relatedClassifications'?: Array<string> | null;
    /**
     * List of Life stages associated with the asset.
     * @type {Array<string>}
     * @memberof Asset
     */
    'lifeStages'?: Array<string> | null;
    /**
     * List of General Content Descriptors associated with the asset.
     * @type {Array<string>}
     * @memberof Asset
     */
    'generalContentDescriptors'?: Array<string> | null;
    /**
     * Represents a User\'s Id.  <remarks>Mapping currently needs to be added to DatabaseContext manually when used</remarks>
     * @type {string}
     * @memberof Asset
     */
    'creationUserId': string;
    /**
     * 
     * @type {string}
     * @memberof Asset
     */
    'creationTimestamp': string;
    /**
     * Represents a User\'s Id.  <remarks>Mapping currently needs to be added to DatabaseContext manually when used</remarks>
     * @type {string}
     * @memberof Asset
     */
    'updatedUserId': string;
    /**
     * Timestamp of the last update.
     * @type {string}
     * @memberof Asset
     */
    'updatedTimestamp': string;
    /**
     * User ID of the publisher.
     * @type {string}
     * @memberof Asset
     */
    'publishingUserId': string | null;
    /**
     * Timestamp of when the asset was published.
     * @type {string}
     * @memberof Asset
     */
    'publishedTimestamp': string | null;
    /**
     * User ID of the one who unpublished the asset.
     * @type {string}
     * @memberof Asset
     */
    'unpublishingUserId': string | null;
    /**
     * Timestamp of when the asset was unpublished.
     * @type {string}
     * @memberof Asset
     */
    'unpublishedTimestamp': string | null;
    /**
     * 
     * @type {string}
     * @memberof Asset
     */
    'libraryCreditId'?: string;
    /**
     * 
     * @type {LibraryCredit}
     * @memberof Asset
     */
    'libraryCredit'?: LibraryCredit;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const AssetListProperty = {
    LandRegions: 'LandRegions',
    Adaptations: 'Adaptations',
    Behaviours: 'Behaviours',
    Habitats: 'Habitats',
    LifeStages: 'LifeStages',
    GeneralContentDescriptors: 'GeneralContentDescriptors'
} as const;

export type AssetListProperty = typeof AssetListProperty[keyof typeof AssetListProperty];


/**
 * 
 * @export
 * @interface AssetResults
 */
export interface AssetResults {
    /**
     * 
     * @type {number}
     * @memberof AssetResults
     */
    'total': number;
    /**
     * 
     * @type {boolean}
     * @memberof AssetResults
     */
    'found': boolean;
    /**
     * 
     * @type {Array<Asset>}
     * @memberof AssetResults
     */
    'results'?: Array<Asset> | null;
    /**
     * 
     * @type {number}
     * @memberof AssetResults
     */
    'from': number | null;
    /**
     * 
     * @type {number}
     * @memberof AssetResults
     */
    'size': number | null;
}
/**
 * 
 * @export
 * @interface AssetSearchQuery
 */
export interface AssetSearchQuery {
    /**
     * 
     * @type {number}
     * @memberof AssetSearchQuery
     */
    'size': number | null;
    /**
     * 
     * @type {number}
     * @memberof AssetSearchQuery
     */
    'from': number | null;
    /**
     * 
     * @type {string}
     * @memberof AssetSearchQuery
     */
    'wildscreenId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AssetSearchQuery
     */
    'search'?: string;
    /**
     * 
     * @type {string}
     * @memberof AssetSearchQuery
     */
    'kingdom'?: string;
    /**
     * 
     * @type {string}
     * @memberof AssetSearchQuery
     */
    'phylum'?: string;
    /**
     * 
     * @type {string}
     * @memberof AssetSearchQuery
     */
    'class'?: string;
    /**
     * 
     * @type {string}
     * @memberof AssetSearchQuery
     */
    'order'?: string;
    /**
     * 
     * @type {string}
     * @memberof AssetSearchQuery
     */
    'family'?: string;
    /**
     * 
     * @type {string}
     * @memberof AssetSearchQuery
     */
    'genus'?: string;
    /**
     * 
     * @type {TaxonRank}
     * @memberof AssetSearchQuery
     */
    'taxonRank'?: TaxonRank;
    /**
     * 
     * @type {string}
     * @memberof AssetSearchQuery
     */
    'landRegion'?: string;
    /**
     * 
     * @type {string}
     * @memberof AssetSearchQuery
     */
    'action'?: string;
    /**
     * 
     * @type {string}
     * @memberof AssetSearchQuery
     */
    'threat'?: string;
    /**
     * 
     * @type {string}
     * @memberof AssetSearchQuery
     */
    'habitat'?: string;
    /**
     * 
     * @type {string}
     * @memberof AssetSearchQuery
     */
    'behaviour'?: string;
    /**
     * 
     * @type {string}
     * @memberof AssetSearchQuery
     */
    'adaptation'?: string;
    /**
     * 
     * @type {string}
     * @memberof AssetSearchQuery
     */
    'biologicalTheme'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AssetSearchQuery
     */
    'jitter': boolean | null;
    /**
     * 
     * @type {string}
     * @memberof AssetSearchQuery
     */
    'assetId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AssetSearchQuery
     */
    'assetLocation'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof AssetSearchQuery
     */
    'isHomepageWorthy'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof AssetSearchQuery
     */
    'lifeStages'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AssetSearchQuery
     */
    'generalContentDescriptors'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof AssetSearchQuery
     */
    'hasPromotionLink'?: boolean | null;
}


/**
 * 
 * @export
 * @interface AssetSearchQueryAllOf
 */
export interface AssetSearchQueryAllOf {
    /**
     * 
     * @type {string}
     * @memberof AssetSearchQueryAllOf
     */
    'assetId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AssetSearchQueryAllOf
     */
    'assetLocation'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof AssetSearchQueryAllOf
     */
    'isHomepageWorthy'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof AssetSearchQueryAllOf
     */
    'lifeStages'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AssetSearchQueryAllOf
     */
    'generalContentDescriptors'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof AssetSearchQueryAllOf
     */
    'hasPromotionLink'?: boolean | null;
}
/**
 * 
 * @export
 * @interface Classification
 */
export interface Classification {
    /**
     * 
     * @type {string}
     * @memberof Classification
     */
    'wildscreenId'?: string;
    /**
     * 
     * @type {TaxonRank}
     * @memberof Classification
     */
    'taxonRank'?: TaxonRank;
    /**
     * 
     * @type {string}
     * @memberof Classification
     */
    'domain'?: string;
    /**
     * 
     * @type {string}
     * @memberof Classification
     */
    'kingdom'?: string;
    /**
     * 
     * @type {string}
     * @memberof Classification
     */
    'phylum'?: string;
    /**
     * 
     * @type {string}
     * @memberof Classification
     */
    'class'?: string;
    /**
     * 
     * @type {string}
     * @memberof Classification
     */
    'order'?: string;
    /**
     * 
     * @type {string}
     * @memberof Classification
     */
    'family'?: string;
    /**
     * 
     * @type {string}
     * @memberof Classification
     */
    'genus'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Classification
     */
    'landRegion'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof Classification
     */
    'actions'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof Classification
     */
    'threats'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof Classification
     */
    'habitat'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof Classification
     */
    'biologicalThemes'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof Classification
     */
    'adaptations'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof Classification
     */
    'behaviours'?: Array<string> | null;
    /**
     * 
     * @type {boolean}
     * @memberof Classification
     */
    'isPublished': boolean;
    /**
     * 
     * @type {string}
     * @memberof Classification
     */
    'friendlyName'?: string;
    /**
     * 
     * @type {string}
     * @memberof Classification
     */
    'description'?: string;
    /**
     * 
     * @type {CoverAssetView}
     * @memberof Classification
     */
    'cover'?: CoverAssetView;
}


/**
 * 
 * @export
 * @interface ClassificationEntry
 */
export interface ClassificationEntry {
    /**
     * 
     * @type {string}
     * @memberof ClassificationEntry
     */
    'wildscreenId'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ClassificationEntry
     */
    'isPublished': boolean;
    /**
     * 
     * @type {string}
     * @memberof ClassificationEntry
     */
    'friendlyName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ClassificationEntry
     */
    'description'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ClassificationEntry
     */
    'landRegion'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ClassificationEntry
     */
    'actions'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ClassificationEntry
     */
    'threats'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ClassificationEntry
     */
    'habitat'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ClassificationEntry
     */
    'biologicalThemes'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ClassificationEntry
     */
    'adaptations'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ClassificationEntry
     */
    'behaviours'?: Array<string> | null;
    /**
     * Represents a User\'s Id.  <remarks>Mapping currently needs to be added to DatabaseContext manually when used</remarks>
     * @type {string}
     * @memberof ClassificationEntry
     */
    'creationUserId': string;
    /**
     * 
     * @type {string}
     * @memberof ClassificationEntry
     */
    'creationTimestamp': string;
    /**
     * Represents a User\'s Id.  <remarks>Mapping currently needs to be added to DatabaseContext manually when used</remarks>
     * @type {string}
     * @memberof ClassificationEntry
     */
    'updatedUserId': string;
    /**
     * 
     * @type {string}
     * @memberof ClassificationEntry
     */
    'updatedTimestamp': string;
    /**
     * 
     * @type {string}
     * @memberof ClassificationEntry
     */
    'publishingUserId': string | null;
    /**
     * 
     * @type {string}
     * @memberof ClassificationEntry
     */
    'publishedTimestamp': string | null;
    /**
     * 
     * @type {string}
     * @memberof ClassificationEntry
     */
    'unpublishingUserId': string | null;
    /**
     * 
     * @type {string}
     * @memberof ClassificationEntry
     */
    'unpublishedTimestamp': string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ClassificationListProperty = {
    LandRegions: 'LandRegions',
    Adaptations: 'Adaptations',
    BiologicalThemes: 'BiologicalThemes',
    Behaviours: 'Behaviours',
    Habitats: 'Habitats',
    Actions: 'Actions',
    Threats: 'Threats'
} as const;

export type ClassificationListProperty = typeof ClassificationListProperty[keyof typeof ClassificationListProperty];


/**
 * 
 * @export
 * @interface ClassificationPageView
 */
export interface ClassificationPageView {
    /**
     * 
     * @type {ClassificationEntry}
     * @memberof ClassificationPageView
     */
    'classification'?: ClassificationEntry;
    /**
     * 
     * @type {TaxonomyEntry}
     * @memberof ClassificationPageView
     */
    'taxonomy'?: TaxonomyEntry;
    /**
     * 
     * @type {Asset}
     * @memberof ClassificationPageView
     */
    'asset'?: Asset;
}
/**
 * 
 * @export
 * @interface ClassificationParentView
 */
export interface ClassificationParentView {
    /**
     * 
     * @type {ClassificationParentViewParents}
     * @memberof ClassificationParentView
     */
    'parents'?: ClassificationParentViewParents | null;
}
/**
 * 
 * @export
 * @interface ClassificationParentViewParents
 */
export interface ClassificationParentViewParents {
    /**
     * 
     * @type {ClassificationPageView}
     * @memberof ClassificationParentViewParents
     */
    'Unknown'?: ClassificationPageView;
    /**
     * 
     * @type {ClassificationPageView}
     * @memberof ClassificationParentViewParents
     */
    'Kingdom'?: ClassificationPageView;
    /**
     * 
     * @type {ClassificationPageView}
     * @memberof ClassificationParentViewParents
     */
    'Phylum'?: ClassificationPageView;
    /**
     * 
     * @type {ClassificationPageView}
     * @memberof ClassificationParentViewParents
     */
    'Class'?: ClassificationPageView;
    /**
     * 
     * @type {ClassificationPageView}
     * @memberof ClassificationParentViewParents
     */
    'Order'?: ClassificationPageView;
    /**
     * 
     * @type {ClassificationPageView}
     * @memberof ClassificationParentViewParents
     */
    'Family'?: ClassificationPageView;
    /**
     * 
     * @type {ClassificationPageView}
     * @memberof ClassificationParentViewParents
     */
    'Genus'?: ClassificationPageView;
    /**
     * 
     * @type {ClassificationPageView}
     * @memberof ClassificationParentViewParents
     */
    'Species'?: ClassificationPageView;
}
/**
 * 
 * @export
 * @interface ClassificationSearchQuery
 */
export interface ClassificationSearchQuery {
    /**
     * 
     * @type {number}
     * @memberof ClassificationSearchQuery
     */
    'size': number | null;
    /**
     * 
     * @type {number}
     * @memberof ClassificationSearchQuery
     */
    'from': number | null;
    /**
     * 
     * @type {string}
     * @memberof ClassificationSearchQuery
     */
    'wildscreenId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ClassificationSearchQuery
     */
    'search'?: string;
    /**
     * 
     * @type {string}
     * @memberof ClassificationSearchQuery
     */
    'kingdom'?: string;
    /**
     * 
     * @type {string}
     * @memberof ClassificationSearchQuery
     */
    'phylum'?: string;
    /**
     * 
     * @type {string}
     * @memberof ClassificationSearchQuery
     */
    'class'?: string;
    /**
     * 
     * @type {string}
     * @memberof ClassificationSearchQuery
     */
    'order'?: string;
    /**
     * 
     * @type {string}
     * @memberof ClassificationSearchQuery
     */
    'family'?: string;
    /**
     * 
     * @type {string}
     * @memberof ClassificationSearchQuery
     */
    'genus'?: string;
    /**
     * 
     * @type {TaxonRank}
     * @memberof ClassificationSearchQuery
     */
    'taxonRank'?: TaxonRank;
    /**
     * 
     * @type {string}
     * @memberof ClassificationSearchQuery
     */
    'landRegion'?: string;
    /**
     * 
     * @type {string}
     * @memberof ClassificationSearchQuery
     */
    'action'?: string;
    /**
     * 
     * @type {string}
     * @memberof ClassificationSearchQuery
     */
    'threat'?: string;
    /**
     * 
     * @type {string}
     * @memberof ClassificationSearchQuery
     */
    'habitat'?: string;
    /**
     * 
     * @type {string}
     * @memberof ClassificationSearchQuery
     */
    'behaviour'?: string;
    /**
     * 
     * @type {string}
     * @memberof ClassificationSearchQuery
     */
    'adaptation'?: string;
    /**
     * 
     * @type {string}
     * @memberof ClassificationSearchQuery
     */
    'biologicalTheme'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ClassificationSearchQuery
     */
    'jitter': boolean | null;
}


/**
 * 
 * @export
 * @interface CoverAssetView
 */
export interface CoverAssetView {
    /**
     * 
     * @type {string}
     * @memberof CoverAssetView
     */
    'assetLocation'?: string;
    /**
     * 
     * @type {string}
     * @memberof CoverAssetView
     */
    'credit'?: string;
}
/**
 * 
 * @export
 * @interface HttpValidationProblemDetails
 */
export interface HttpValidationProblemDetails {
    /**
     * 
     * @type {string}
     * @memberof HttpValidationProblemDetails
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof HttpValidationProblemDetails
     */
    'title'?: string;
    /**
     * 
     * @type {number}
     * @memberof HttpValidationProblemDetails
     */
    'status': number | null;
    /**
     * 
     * @type {string}
     * @memberof HttpValidationProblemDetails
     */
    'detail'?: string;
    /**
     * 
     * @type {string}
     * @memberof HttpValidationProblemDetails
     */
    'instance'?: string;
    /**
     * 
     * @type {{ [key: string]: Array<string>; }}
     * @memberof HttpValidationProblemDetails
     */
    'errors'?: { [key: string]: Array<string>; } | null;
}
/**
 * 
 * @export
 * @interface HttpValidationProblemDetailsAllOf
 */
export interface HttpValidationProblemDetailsAllOf {
    /**
     * 
     * @type {{ [key: string]: Array<string>; }}
     * @memberof HttpValidationProblemDetailsAllOf
     */
    'errors'?: { [key: string]: Array<string>; } | null;
}
/**
 * 
 * @export
 * @interface Inheritance
 */
export interface Inheritance {
    /**
     * 
     * @type {Array<string>}
     * @memberof Inheritance
     */
    'vernacularNames'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof Inheritance
     */
    'scientificNames'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof Inheritance
     */
    'friendlyNames'?: Array<string> | null;
    /**
     * 
     * @type {InheritedClassifications}
     * @memberof Inheritance
     */
    'domain'?: InheritedClassifications;
    /**
     * 
     * @type {InheritedClassifications}
     * @memberof Inheritance
     */
    'kingdom'?: InheritedClassifications;
    /**
     * 
     * @type {InheritedClassifications}
     * @memberof Inheritance
     */
    'phylum'?: InheritedClassifications;
    /**
     * 
     * @type {InheritedClassifications}
     * @memberof Inheritance
     */
    'class'?: InheritedClassifications;
    /**
     * 
     * @type {InheritedClassifications}
     * @memberof Inheritance
     */
    'order'?: InheritedClassifications;
    /**
     * 
     * @type {InheritedClassifications}
     * @memberof Inheritance
     */
    'family'?: InheritedClassifications;
    /**
     * 
     * @type {InheritedClassifications}
     * @memberof Inheritance
     */
    'genus'?: InheritedClassifications;
    /**
     * 
     * @type {InheritedClassifications}
     * @memberof Inheritance
     */
    'species'?: InheritedClassifications;
}
/**
 * 
 * @export
 * @interface InheritedClassifications
 */
export interface InheritedClassifications {
    /**
     * 
     * @type {Array<string>}
     * @memberof InheritedClassifications
     */
    'landRegion'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof InheritedClassifications
     */
    'actions'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof InheritedClassifications
     */
    'threats'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof InheritedClassifications
     */
    'habitats'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof InheritedClassifications
     */
    'biologicalThemes'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof InheritedClassifications
     */
    'adaptations'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof InheritedClassifications
     */
    'values'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface LibraryCredit
 */
export interface LibraryCredit {
    /**
     * 
     * @type {string}
     * @memberof LibraryCredit
     */
    'libraryCreditId'?: string;
    /**
     * 
     * @type {string}
     * @memberof LibraryCredit
     */
    'libraryCreditLocation'?: string;
    /**
     * 
     * @type {string}
     * @memberof LibraryCredit
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof LibraryCredit
     */
    'url'?: string;
    /**
     * Represents a User\'s Id.  <remarks>Mapping currently needs to be added to DatabaseContext manually when used</remarks>
     * @type {string}
     * @memberof LibraryCredit
     */
    'creationUserId': string;
    /**
     * 
     * @type {string}
     * @memberof LibraryCredit
     */
    'creationTimestamp': string;
    /**
     * Represents a User\'s Id.  <remarks>Mapping currently needs to be added to DatabaseContext manually when used</remarks>
     * @type {string}
     * @memberof LibraryCredit
     */
    'updatedUserId': string;
    /**
     * 
     * @type {string}
     * @memberof LibraryCredit
     */
    'updatedTimestamp': string;
}
/**
 * 
 * @export
 * @interface LibraryCreditResults
 */
export interface LibraryCreditResults {
    /**
     * 
     * @type {number}
     * @memberof LibraryCreditResults
     */
    'total': number;
    /**
     * 
     * @type {boolean}
     * @memberof LibraryCreditResults
     */
    'found': boolean;
    /**
     * 
     * @type {Array<LibraryCredit>}
     * @memberof LibraryCreditResults
     */
    'results'?: Array<LibraryCredit> | null;
    /**
     * 
     * @type {number}
     * @memberof LibraryCreditResults
     */
    'from': number | null;
    /**
     * 
     * @type {number}
     * @memberof LibraryCreditResults
     */
    'size': number | null;
}
/**
 * 
 * @export
 * @interface MultiAssetSearchQuery
 */
export interface MultiAssetSearchQuery {
    /**
     * 
     * @type {{ [key: string]: AssetSearchQuery; }}
     * @memberof MultiAssetSearchQuery
     */
    'queriesByKey'?: { [key: string]: AssetSearchQuery; } | null;
    /**
     * 
     * @type {number}
     * @memberof MultiAssetSearchQuery
     */
    'size': number | null;
}
/**
 * 
 * @export
 * @interface MultiClassificationSearchQuery
 */
export interface MultiClassificationSearchQuery {
    /**
     * 
     * @type {{ [key: string]: ClassificationSearchQuery; }}
     * @memberof MultiClassificationSearchQuery
     */
    'queriesByKey'?: { [key: string]: ClassificationSearchQuery; } | null;
    /**
     * 
     * @type {number}
     * @memberof MultiClassificationSearchQuery
     */
    'size': number | null;
}
/**
 * 
 * @export
 * @interface ProblemDetails
 */
export interface ProblemDetails {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    'title'?: string;
    /**
     * 
     * @type {number}
     * @memberof ProblemDetails
     */
    'status': number | null;
    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    'detail'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    'instance'?: string;
}
/**
 * 
 * @export
 * @interface PropertyValuesSearchResult
 */
export interface PropertyValuesSearchResult {
    /**
     * 
     * @type {Array<string>}
     * @memberof PropertyValuesSearchResult
     */
    'values'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface PublishState
 */
export interface PublishState {
    /**
     * 
     * @type {boolean}
     * @memberof PublishState
     */
    'exists': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PublishState
     */
    'isPublished': boolean;
}
/**
 * 
 * @export
 * @interface Resource
 */
export interface Resource {
    /**
     * 
     * @type {string}
     * @memberof Resource
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof Resource
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof Resource
     */
    'ageCategory'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Resource
     */
    'subjects'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof Resource
     */
    'type'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Resource
     */
    'isPublished': boolean | null;
    /**
     * 
     * @type {string}
     * @memberof Resource
     */
    'resourceName'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Resource
     */
    'biologicalThemes'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof Resource
     */
    'habitats'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof Resource
     */
    'landRegions'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof Resource
     */
    'threats'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof Resource
     */
    'actions'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof Resource
     */
    'resourceId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Resource
     */
    'location'?: string | null;
    /**
     * Represents a User\'s Id.  <remarks>Mapping currently needs to be added to DatabaseContext manually when used</remarks>
     * @type {string}
     * @memberof Resource
     */
    'creationUserId'?: string;
    /**
     * 
     * @type {string}
     * @memberof Resource
     */
    'creationTimestamp'?: string;
    /**
     * Represents a User\'s Id.  <remarks>Mapping currently needs to be added to DatabaseContext manually when used</remarks>
     * @type {string}
     * @memberof Resource
     */
    'updatedUserId'?: string;
    /**
     * 
     * @type {string}
     * @memberof Resource
     */
    'updatedTimestamp'?: string;
}
/**
 * 
 * @export
 * @interface ResourceAllOf
 */
export interface ResourceAllOf {
    /**
     * 
     * @type {string}
     * @memberof ResourceAllOf
     */
    'resourceId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ResourceAllOf
     */
    'location'?: string | null;
    /**
     * Represents a User\'s Id.  <remarks>Mapping currently needs to be added to DatabaseContext manually when used</remarks>
     * @type {string}
     * @memberof ResourceAllOf
     */
    'creationUserId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResourceAllOf
     */
    'creationTimestamp'?: string;
    /**
     * Represents a User\'s Id.  <remarks>Mapping currently needs to be added to DatabaseContext manually when used</remarks>
     * @type {string}
     * @memberof ResourceAllOf
     */
    'updatedUserId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResourceAllOf
     */
    'updatedTimestamp'?: string;
}
/**
 * 
 * @export
 * @interface ResourceData
 */
export interface ResourceData {
    /**
     * 
     * @type {string}
     * @memberof ResourceData
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResourceData
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResourceData
     */
    'ageCategory'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ResourceData
     */
    'subjects'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof ResourceData
     */
    'type'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ResourceData
     */
    'isPublished': boolean | null;
    /**
     * 
     * @type {string}
     * @memberof ResourceData
     */
    'resourceName'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ResourceData
     */
    'biologicalThemes'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ResourceData
     */
    'habitats'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ResourceData
     */
    'landRegions'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ResourceData
     */
    'threats'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ResourceData
     */
    'actions'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface ResourceQuery
 */
export interface ResourceQuery {
    /**
     * 
     * @type {number}
     * @memberof ResourceQuery
     */
    'from': number | null;
    /**
     * 
     * @type {number}
     * @memberof ResourceQuery
     */
    'size': number | null;
}
/**
 * 
 * @export
 * @interface ResourceResults
 */
export interface ResourceResults {
    /**
     * 
     * @type {number}
     * @memberof ResourceResults
     */
    'total': number;
    /**
     * 
     * @type {boolean}
     * @memberof ResourceResults
     */
    'found': boolean;
    /**
     * 
     * @type {Array<Resource>}
     * @memberof ResourceResults
     */
    'results'?: Array<Resource> | null;
    /**
     * 
     * @type {number}
     * @memberof ResourceResults
     */
    'from': number | null;
    /**
     * 
     * @type {number}
     * @memberof ResourceResults
     */
    'size': number | null;
}
/**
 * 
 * @export
 * @interface ResourceSearchQuery
 */
export interface ResourceSearchQuery {
    /**
     * 
     * @type {number}
     * @memberof ResourceSearchQuery
     */
    'size': number | null;
    /**
     * 
     * @type {number}
     * @memberof ResourceSearchQuery
     */
    'from': number | null;
}
/**
 * 
 * @export
 * @interface SearchAsset
 */
export interface SearchAsset {
    /**
     * 
     * @type {Inheritance}
     * @memberof SearchAsset
     */
    'inheritance'?: Inheritance;
    /**
     * 
     * @type {string}
     * @memberof SearchAsset
     */
    'assetId'?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchAsset
     */
    'assetLocation'?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchAsset
     */
    'originId'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SearchAsset
     */
    'isVideo': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SearchAsset
     */
    'isHero': boolean;
    /**
     * 
     * @type {string}
     * @memberof SearchAsset
     */
    'promotionLink'?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchAsset
     */
    'title'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SearchAsset
     */
    'isPublished': boolean;
    /**
     * 
     * @type {string}
     * @memberof SearchAsset
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchAsset
     */
    'credit'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SearchAsset
     */
    'isHomepageWorthy': boolean | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof SearchAsset
     */
    'regions'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof SearchAsset
     */
    'behaviours'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof SearchAsset
     */
    'adaptations'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof SearchAsset
     */
    'habitats'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof SearchAsset
     */
    'relatedClassifications'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof SearchAsset
     */
    'lifeStages'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof SearchAsset
     */
    'generalContentDescriptors'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof SearchAsset
     */
    'libraryCreditId'?: string;
    /**
     * 
     * @type {LibraryCredit}
     * @memberof SearchAsset
     */
    'libraryCredit'?: LibraryCredit;
    /**
     * 
     * @type {Array<string>}
     * @memberof SearchAsset
     */
    'vernacularNames'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof SearchAsset
     */
    'scientificNames'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface SearchAssetMultiSearchResult
 */
export interface SearchAssetMultiSearchResult {
    /**
     * 
     * @type {{ [key: string]: SearchAssetSearchResult; }}
     * @memberof SearchAssetMultiSearchResult
     */
    'results'?: { [key: string]: SearchAssetSearchResult; } | null;
}
/**
 * 
 * @export
 * @interface SearchAssetSearchResult
 */
export interface SearchAssetSearchResult {
    /**
     * 
     * @type {number}
     * @memberof SearchAssetSearchResult
     */
    'total': number;
    /**
     * 
     * @type {Array<SearchAsset>}
     * @memberof SearchAssetSearchResult
     */
    'results'?: Array<SearchAsset> | null;
    /**
     * 
     * @type {number}
     * @memberof SearchAssetSearchResult
     */
    'from': number | null;
    /**
     * 
     * @type {number}
     * @memberof SearchAssetSearchResult
     */
    'size': number | null;
}
/**
 * 
 * @export
 * @interface SearchClassification
 */
export interface SearchClassification {
    /**
     * 
     * @type {string}
     * @memberof SearchClassification
     */
    'wildscreenId'?: string;
    /**
     * 
     * @type {TaxonRank}
     * @memberof SearchClassification
     */
    'taxonRank'?: TaxonRank;
    /**
     * 
     * @type {string}
     * @memberof SearchClassification
     */
    'domain'?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchClassification
     */
    'kingdom'?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchClassification
     */
    'phylum'?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchClassification
     */
    'class'?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchClassification
     */
    'order'?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchClassification
     */
    'family'?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchClassification
     */
    'genus'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof SearchClassification
     */
    'landRegion'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof SearchClassification
     */
    'actions'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof SearchClassification
     */
    'threats'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof SearchClassification
     */
    'habitat'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof SearchClassification
     */
    'biologicalThemes'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof SearchClassification
     */
    'adaptations'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof SearchClassification
     */
    'behaviours'?: Array<string> | null;
    /**
     * 
     * @type {boolean}
     * @memberof SearchClassification
     */
    'isPublished': boolean;
    /**
     * 
     * @type {string}
     * @memberof SearchClassification
     */
    'friendlyName'?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchClassification
     */
    'description'?: string;
    /**
     * 
     * @type {CoverAssetView}
     * @memberof SearchClassification
     */
    'cover'?: CoverAssetView;
    /**
     * 
     * @type {Inheritance}
     * @memberof SearchClassification
     */
    'inheritance'?: Inheritance;
    /**
     * 
     * @type {Array<string>}
     * @memberof SearchClassification
     */
    'vernacularNames'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof SearchClassification
     */
    'scientificNames'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof SearchClassification
     */
    'friendlyNames'?: Array<string> | null;
}


/**
 * 
 * @export
 * @interface SearchClassificationAllOf
 */
export interface SearchClassificationAllOf {
    /**
     * 
     * @type {Inheritance}
     * @memberof SearchClassificationAllOf
     */
    'inheritance'?: Inheritance;
    /**
     * 
     * @type {Array<string>}
     * @memberof SearchClassificationAllOf
     */
    'vernacularNames'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof SearchClassificationAllOf
     */
    'scientificNames'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof SearchClassificationAllOf
     */
    'friendlyNames'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface SearchClassificationMultiSearchResult
 */
export interface SearchClassificationMultiSearchResult {
    /**
     * 
     * @type {{ [key: string]: SearchClassificationSearchResult; }}
     * @memberof SearchClassificationMultiSearchResult
     */
    'results'?: { [key: string]: SearchClassificationSearchResult; } | null;
}
/**
 * 
 * @export
 * @interface SearchClassificationSearchResult
 */
export interface SearchClassificationSearchResult {
    /**
     * 
     * @type {number}
     * @memberof SearchClassificationSearchResult
     */
    'total': number;
    /**
     * 
     * @type {Array<SearchClassification>}
     * @memberof SearchClassificationSearchResult
     */
    'results'?: Array<SearchClassification> | null;
    /**
     * 
     * @type {number}
     * @memberof SearchClassificationSearchResult
     */
    'from': number | null;
    /**
     * 
     * @type {number}
     * @memberof SearchClassificationSearchResult
     */
    'size': number | null;
}
/**
 * 
 * @export
 * @interface SearchableResource
 */
export interface SearchableResource {
    /**
     * 
     * @type {string}
     * @memberof SearchableResource
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchableResource
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchableResource
     */
    'ageCategory'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof SearchableResource
     */
    'subjects'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof SearchableResource
     */
    'type'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SearchableResource
     */
    'isPublished': boolean | null;
    /**
     * 
     * @type {string}
     * @memberof SearchableResource
     */
    'resourceName'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof SearchableResource
     */
    'biologicalThemes'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof SearchableResource
     */
    'habitats'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof SearchableResource
     */
    'landRegions'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof SearchableResource
     */
    'threats'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof SearchableResource
     */
    'actions'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof SearchableResource
     */
    'resourceId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SearchableResource
     */
    'location'?: string | null;
    /**
     * Represents a User\'s Id.  <remarks>Mapping currently needs to be added to DatabaseContext manually when used</remarks>
     * @type {string}
     * @memberof SearchableResource
     */
    'creationUserId'?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchableResource
     */
    'creationTimestamp'?: string;
    /**
     * Represents a User\'s Id.  <remarks>Mapping currently needs to be added to DatabaseContext manually when used</remarks>
     * @type {string}
     * @memberof SearchableResource
     */
    'updatedUserId'?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchableResource
     */
    'updatedTimestamp'?: string;
    /**
     * 
     * @type {number}
     * @memberof SearchableResource
     */
    'ageCategoryRank'?: number;
}
/**
 * 
 * @export
 * @interface SearchableResourceAllOf
 */
export interface SearchableResourceAllOf {
    /**
     * 
     * @type {number}
     * @memberof SearchableResourceAllOf
     */
    'ageCategoryRank'?: number;
}
/**
 * 
 * @export
 * @interface Tag
 */
export interface Tag {
    /**
     * 
     * @type {string}
     * @memberof Tag
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof Tag
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof Tag
     */
    'coverImageLocation'?: string;
    /**
     * 
     * @type {TagType}
     * @memberof Tag
     */
    'type'?: TagType;
}


/**
 * 
 * @export
 * @interface TagSearchResult
 */
export interface TagSearchResult {
    /**
     * 
     * @type {Array<Tag>}
     * @memberof TagSearchResult
     */
    'tags'?: Array<Tag> | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const TagType = {
    Action: 'Action',
    Adaptation: 'Adaptation',
    Behaviour: 'Behaviour',
    BiologicalTheme: 'BiologicalTheme',
    Habitat: 'Habitat',
    Threat: 'Threat',
    Region: 'Region',
    LifeStage: 'LifeStage',
    Descriptor: 'Descriptor'
} as const;

export type TagType = typeof TagType[keyof typeof TagType];


/**
 * 
 * @export
 * @enum {string}
 */

export const TaxonRank = {
    Unknown: 'Unknown',
    Kingdom: 'Kingdom',
    Phylum: 'Phylum',
    Class: 'Class',
    Order: 'Order',
    Family: 'Family',
    Genus: 'Genus',
    Species: 'Species'
} as const;

export type TaxonRank = typeof TaxonRank[keyof typeof TaxonRank];


/**
 * 
 * @export
 * @interface TaxonomyEntry
 */
export interface TaxonomyEntry {
    /**
     * 
     * @type {string}
     * @memberof TaxonomyEntry
     */
    'wildscreenId'?: string;
    /**
     * 
     * @type {TaxonRank}
     * @memberof TaxonomyEntry
     */
    'taxonRank'?: TaxonRank;
    /**
     * 
     * @type {string}
     * @memberof TaxonomyEntry
     */
    'domain'?: string;
    /**
     * 
     * @type {string}
     * @memberof TaxonomyEntry
     */
    'kingdom'?: string;
    /**
     * 
     * @type {string}
     * @memberof TaxonomyEntry
     */
    'phylum'?: string;
    /**
     * 
     * @type {string}
     * @memberof TaxonomyEntry
     */
    'class'?: string;
    /**
     * 
     * @type {string}
     * @memberof TaxonomyEntry
     */
    'order'?: string;
    /**
     * 
     * @type {string}
     * @memberof TaxonomyEntry
     */
    'family'?: string;
    /**
     * 
     * @type {string}
     * @memberof TaxonomyEntry
     */
    'genus'?: string;
    /**
     * 
     * @type {string}
     * @memberof TaxonomyEntry
     */
    'gbifCanonicalName'?: string;
    /**
     * 
     * @type {string}
     * @memberof TaxonomyEntry
     */
    'gbifDatasetId'?: string;
    /**
     * 
     * @type {string}
     * @memberof TaxonomyEntry
     */
    'gbifGenericName'?: string;
    /**
     * 
     * @type {string}
     * @memberof TaxonomyEntry
     */
    'gbifInfraspecificEpithet'?: string;
    /**
     * 
     * @type {string}
     * @memberof TaxonomyEntry
     */
    'gbifScientificName'?: string;
    /**
     * 
     * @type {string}
     * @memberof TaxonomyEntry
     */
    'gbifScientificNameAuthorship'?: string;
    /**
     * 
     * @type {string}
     * @memberof TaxonomyEntry
     */
    'gbifSpecificEpithet'?: string;
    /**
     * 
     * @type {string}
     * @memberof TaxonomyEntry
     */
    'gbifTaxonId'?: string;
    /**
     * 
     * @type {string}
     * @memberof TaxonomyEntry
     */
    'gbifTaxonomicStatus'?: string;
    /**
     * 
     * @type {string}
     * @memberof TaxonomyEntry
     */
    'threatStatus'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof TaxonomyEntry
     */
    'vernacularNames'?: Array<string> | null;
}


/**
 * 
 * @export
 * @interface TaxonomyEntryView
 */
export interface TaxonomyEntryView {
    /**
     * 
     * @type {string}
     * @memberof TaxonomyEntryView
     */
    'wildscreenId'?: string;
    /**
     * 
     * @type {TaxonRank}
     * @memberof TaxonomyEntryView
     */
    'taxonRank'?: TaxonRank;
    /**
     * 
     * @type {string}
     * @memberof TaxonomyEntryView
     */
    'domain'?: string;
    /**
     * 
     * @type {string}
     * @memberof TaxonomyEntryView
     */
    'kingdom'?: string;
    /**
     * 
     * @type {string}
     * @memberof TaxonomyEntryView
     */
    'phylum'?: string;
    /**
     * 
     * @type {string}
     * @memberof TaxonomyEntryView
     */
    'class'?: string;
    /**
     * 
     * @type {string}
     * @memberof TaxonomyEntryView
     */
    'order'?: string;
    /**
     * 
     * @type {string}
     * @memberof TaxonomyEntryView
     */
    'family'?: string;
    /**
     * 
     * @type {string}
     * @memberof TaxonomyEntryView
     */
    'genus'?: string;
    /**
     * 
     * @type {string}
     * @memberof TaxonomyEntryView
     */
    'gbifCanonicalName'?: string;
    /**
     * 
     * @type {string}
     * @memberof TaxonomyEntryView
     */
    'gbifDatasetId'?: string;
    /**
     * 
     * @type {string}
     * @memberof TaxonomyEntryView
     */
    'gbifGenericName'?: string;
    /**
     * 
     * @type {string}
     * @memberof TaxonomyEntryView
     */
    'gbifInfraspecificEpithet'?: string;
    /**
     * 
     * @type {string}
     * @memberof TaxonomyEntryView
     */
    'gbifScientificName'?: string;
    /**
     * 
     * @type {string}
     * @memberof TaxonomyEntryView
     */
    'gbifScientificNameAuthorship'?: string;
    /**
     * 
     * @type {string}
     * @memberof TaxonomyEntryView
     */
    'gbifSpecificEpithet'?: string;
    /**
     * 
     * @type {string}
     * @memberof TaxonomyEntryView
     */
    'gbifTaxonId'?: string;
    /**
     * 
     * @type {string}
     * @memberof TaxonomyEntryView
     */
    'gbifTaxonomicStatus'?: string;
    /**
     * 
     * @type {string}
     * @memberof TaxonomyEntryView
     */
    'threatStatus'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof TaxonomyEntryView
     */
    'vernacularNames'?: Array<string> | null;
    /**
     * 
     * @type {PublishState}
     * @memberof TaxonomyEntryView
     */
    'publishState'?: PublishState;
    /**
     * 
     * @type {string}
     * @memberof TaxonomyEntryView
     */
    'suggestedName'?: string | null;
}


/**
 * 
 * @export
 * @interface TaxonomyEntryViewAllOf
 */
export interface TaxonomyEntryViewAllOf {
    /**
     * 
     * @type {PublishState}
     * @memberof TaxonomyEntryViewAllOf
     */
    'publishState'?: PublishState;
    /**
     * 
     * @type {string}
     * @memberof TaxonomyEntryViewAllOf
     */
    'suggestedName'?: string | null;
}
/**
 * 
 * @export
 * @interface TaxonomyResults
 */
export interface TaxonomyResults {
    /**
     * 
     * @type {number}
     * @memberof TaxonomyResults
     */
    'total': number;
    /**
     * 
     * @type {boolean}
     * @memberof TaxonomyResults
     */
    'found': boolean;
    /**
     * 
     * @type {Array<TaxonomyEntryView>}
     * @memberof TaxonomyResults
     */
    'results'?: Array<TaxonomyEntryView> | null;
    /**
     * 
     * @type {number}
     * @memberof TaxonomyResults
     */
    'from': number | null;
    /**
     * 
     * @type {number}
     * @memberof TaxonomyResults
     */
    'size': number | null;
}
/**
 * 
 * @export
 * @interface UpdateResourceData
 */
export interface UpdateResourceData {
    /**
     * 
     * @type {string}
     * @memberof UpdateResourceData
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateResourceData
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateResourceData
     */
    'ageCategory'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateResourceData
     */
    'subjects'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateResourceData
     */
    'type'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateResourceData
     */
    'isPublished': boolean | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateResourceData
     */
    'resourceName'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateResourceData
     */
    'biologicalThemes'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateResourceData
     */
    'habitats'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateResourceData
     */
    'landRegions'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateResourceData
     */
    'threats'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateResourceData
     */
    'actions'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateResourceData
     */
    'resourceId'?: string | null;
}
/**
 * 
 * @export
 * @interface UpdateResourceDataAllOf
 */
export interface UpdateResourceDataAllOf {
    /**
     * 
     * @type {string}
     * @memberof UpdateResourceDataAllOf
     */
    'resourceId'?: string | null;
}

/**
 * ArkApi - axios parameter creator
 * @export
 */
export const ArkApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get a single asset by its id.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        arkAssetDocumentIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('arkAssetDocumentIdGet', 'id', id)
            const localVarPath = `/ark/asset/document/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Perform multiple non-paged searches in a single request.
         * @param {MultiAssetSearchQuery} [multiAssetSearchQuery] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        arkAssetMultisearchPost: async (multiAssetSearchQuery?: MultiAssetSearchQuery, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/ark/asset/multisearch`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(multiAssetSearchQuery, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Search assets.
         * @param {AssetSearchQuery} [assetSearchQuery] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        arkAssetSearchPost: async (assetSearchQuery?: AssetSearchQuery, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/ark/asset/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(assetSearchQuery, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a single classification entry by its id.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        arkClassificationDocumentIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('arkClassificationDocumentIdGet', 'id', id)
            const localVarPath = `/ark/classification/document/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Perform multiple non-paged searches in a single request.
         * @param {MultiClassificationSearchQuery} [multiClassificationSearchQuery] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        arkClassificationMultisearchPost: async (multiClassificationSearchQuery?: MultiClassificationSearchQuery, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/ark/classification/multisearch`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(multiClassificationSearchQuery, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Search classifications.
         * @param {ClassificationSearchQuery} [classificationSearchQuery] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        arkClassificationSearchPost: async (classificationSearchQuery?: ClassificationSearchQuery, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/ark/classification/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(classificationSearchQuery, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Search resources.
         * @param {ResourceSearchQuery} [resourceSearchQuery] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        arkResourceSearchPost: async (resourceSearchQuery?: ResourceSearchQuery, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/ark/resource/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(resourceSearchQuery, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ArkApi - functional programming interface
 * @export
 */
export const ArkApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ArkApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get a single asset by its id.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async arkAssetDocumentIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SearchAsset>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.arkAssetDocumentIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Perform multiple non-paged searches in a single request.
         * @param {MultiAssetSearchQuery} [multiAssetSearchQuery] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async arkAssetMultisearchPost(multiAssetSearchQuery?: MultiAssetSearchQuery, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SearchAssetMultiSearchResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.arkAssetMultisearchPost(multiAssetSearchQuery, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Search assets.
         * @param {AssetSearchQuery} [assetSearchQuery] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async arkAssetSearchPost(assetSearchQuery?: AssetSearchQuery, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SearchAssetSearchResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.arkAssetSearchPost(assetSearchQuery, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a single classification entry by its id.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async arkClassificationDocumentIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SearchClassificationSearchResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.arkClassificationDocumentIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Perform multiple non-paged searches in a single request.
         * @param {MultiClassificationSearchQuery} [multiClassificationSearchQuery] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async arkClassificationMultisearchPost(multiClassificationSearchQuery?: MultiClassificationSearchQuery, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SearchClassificationMultiSearchResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.arkClassificationMultisearchPost(multiClassificationSearchQuery, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Search classifications.
         * @param {ClassificationSearchQuery} [classificationSearchQuery] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async arkClassificationSearchPost(classificationSearchQuery?: ClassificationSearchQuery, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SearchClassificationSearchResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.arkClassificationSearchPost(classificationSearchQuery, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Search resources.
         * @param {ResourceSearchQuery} [resourceSearchQuery] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async arkResourceSearchPost(resourceSearchQuery?: ResourceSearchQuery, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ArkResourceSearchResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.arkResourceSearchPost(resourceSearchQuery, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ArkApi - factory interface
 * @export
 */
export const ArkApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ArkApiFp(configuration)
    return {
        /**
         * 
         * @summary Get a single asset by its id.
         * @param {ArkApiArkAssetDocumentIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        arkAssetDocumentIdGet(requestParameters: ArkApiArkAssetDocumentIdGetRequest, options?: AxiosRequestConfig): AxiosPromise<SearchAsset> {
            return localVarFp.arkAssetDocumentIdGet(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Perform multiple non-paged searches in a single request.
         * @param {ArkApiArkAssetMultisearchPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        arkAssetMultisearchPost(requestParameters: ArkApiArkAssetMultisearchPostRequest = {}, options?: AxiosRequestConfig): AxiosPromise<SearchAssetMultiSearchResult> {
            return localVarFp.arkAssetMultisearchPost(requestParameters.multiAssetSearchQuery, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Search assets.
         * @param {ArkApiArkAssetSearchPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        arkAssetSearchPost(requestParameters: ArkApiArkAssetSearchPostRequest = {}, options?: AxiosRequestConfig): AxiosPromise<SearchAssetSearchResult> {
            return localVarFp.arkAssetSearchPost(requestParameters.assetSearchQuery, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a single classification entry by its id.
         * @param {ArkApiArkClassificationDocumentIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        arkClassificationDocumentIdGet(requestParameters: ArkApiArkClassificationDocumentIdGetRequest, options?: AxiosRequestConfig): AxiosPromise<SearchClassificationSearchResult> {
            return localVarFp.arkClassificationDocumentIdGet(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Perform multiple non-paged searches in a single request.
         * @param {ArkApiArkClassificationMultisearchPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        arkClassificationMultisearchPost(requestParameters: ArkApiArkClassificationMultisearchPostRequest = {}, options?: AxiosRequestConfig): AxiosPromise<SearchClassificationMultiSearchResult> {
            return localVarFp.arkClassificationMultisearchPost(requestParameters.multiClassificationSearchQuery, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Search classifications.
         * @param {ArkApiArkClassificationSearchPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        arkClassificationSearchPost(requestParameters: ArkApiArkClassificationSearchPostRequest = {}, options?: AxiosRequestConfig): AxiosPromise<SearchClassificationSearchResult> {
            return localVarFp.arkClassificationSearchPost(requestParameters.classificationSearchQuery, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Search resources.
         * @param {ArkApiArkResourceSearchPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        arkResourceSearchPost(requestParameters: ArkApiArkResourceSearchPostRequest = {}, options?: AxiosRequestConfig): AxiosPromise<ArkResourceSearchResult> {
            return localVarFp.arkResourceSearchPost(requestParameters.resourceSearchQuery, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for arkAssetDocumentIdGet operation in ArkApi.
 * @export
 * @interface ArkApiArkAssetDocumentIdGetRequest
 */
export interface ArkApiArkAssetDocumentIdGetRequest {
    /**
     * 
     * @type {string}
     * @memberof ArkApiArkAssetDocumentIdGet
     */
    readonly id: string
}

/**
 * Request parameters for arkAssetMultisearchPost operation in ArkApi.
 * @export
 * @interface ArkApiArkAssetMultisearchPostRequest
 */
export interface ArkApiArkAssetMultisearchPostRequest {
    /**
     * 
     * @type {MultiAssetSearchQuery}
     * @memberof ArkApiArkAssetMultisearchPost
     */
    readonly multiAssetSearchQuery?: MultiAssetSearchQuery
}

/**
 * Request parameters for arkAssetSearchPost operation in ArkApi.
 * @export
 * @interface ArkApiArkAssetSearchPostRequest
 */
export interface ArkApiArkAssetSearchPostRequest {
    /**
     * 
     * @type {AssetSearchQuery}
     * @memberof ArkApiArkAssetSearchPost
     */
    readonly assetSearchQuery?: AssetSearchQuery
}

/**
 * Request parameters for arkClassificationDocumentIdGet operation in ArkApi.
 * @export
 * @interface ArkApiArkClassificationDocumentIdGetRequest
 */
export interface ArkApiArkClassificationDocumentIdGetRequest {
    /**
     * 
     * @type {string}
     * @memberof ArkApiArkClassificationDocumentIdGet
     */
    readonly id: string
}

/**
 * Request parameters for arkClassificationMultisearchPost operation in ArkApi.
 * @export
 * @interface ArkApiArkClassificationMultisearchPostRequest
 */
export interface ArkApiArkClassificationMultisearchPostRequest {
    /**
     * 
     * @type {MultiClassificationSearchQuery}
     * @memberof ArkApiArkClassificationMultisearchPost
     */
    readonly multiClassificationSearchQuery?: MultiClassificationSearchQuery
}

/**
 * Request parameters for arkClassificationSearchPost operation in ArkApi.
 * @export
 * @interface ArkApiArkClassificationSearchPostRequest
 */
export interface ArkApiArkClassificationSearchPostRequest {
    /**
     * 
     * @type {ClassificationSearchQuery}
     * @memberof ArkApiArkClassificationSearchPost
     */
    readonly classificationSearchQuery?: ClassificationSearchQuery
}

/**
 * Request parameters for arkResourceSearchPost operation in ArkApi.
 * @export
 * @interface ArkApiArkResourceSearchPostRequest
 */
export interface ArkApiArkResourceSearchPostRequest {
    /**
     * 
     * @type {ResourceSearchQuery}
     * @memberof ArkApiArkResourceSearchPost
     */
    readonly resourceSearchQuery?: ResourceSearchQuery
}

/**
 * ArkApi - object-oriented interface
 * @export
 * @class ArkApi
 * @extends {BaseAPI}
 */
export class ArkApi extends BaseAPI {
    /**
     * 
     * @summary Get a single asset by its id.
     * @param {ArkApiArkAssetDocumentIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArkApi
     */
    public arkAssetDocumentIdGet(requestParameters: ArkApiArkAssetDocumentIdGetRequest, options?: AxiosRequestConfig) {
        return ArkApiFp(this.configuration).arkAssetDocumentIdGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Perform multiple non-paged searches in a single request.
     * @param {ArkApiArkAssetMultisearchPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArkApi
     */
    public arkAssetMultisearchPost(requestParameters: ArkApiArkAssetMultisearchPostRequest = {}, options?: AxiosRequestConfig) {
        return ArkApiFp(this.configuration).arkAssetMultisearchPost(requestParameters.multiAssetSearchQuery, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Search assets.
     * @param {ArkApiArkAssetSearchPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArkApi
     */
    public arkAssetSearchPost(requestParameters: ArkApiArkAssetSearchPostRequest = {}, options?: AxiosRequestConfig) {
        return ArkApiFp(this.configuration).arkAssetSearchPost(requestParameters.assetSearchQuery, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a single classification entry by its id.
     * @param {ArkApiArkClassificationDocumentIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArkApi
     */
    public arkClassificationDocumentIdGet(requestParameters: ArkApiArkClassificationDocumentIdGetRequest, options?: AxiosRequestConfig) {
        return ArkApiFp(this.configuration).arkClassificationDocumentIdGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Perform multiple non-paged searches in a single request.
     * @param {ArkApiArkClassificationMultisearchPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArkApi
     */
    public arkClassificationMultisearchPost(requestParameters: ArkApiArkClassificationMultisearchPostRequest = {}, options?: AxiosRequestConfig) {
        return ArkApiFp(this.configuration).arkClassificationMultisearchPost(requestParameters.multiClassificationSearchQuery, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Search classifications.
     * @param {ArkApiArkClassificationSearchPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArkApi
     */
    public arkClassificationSearchPost(requestParameters: ArkApiArkClassificationSearchPostRequest = {}, options?: AxiosRequestConfig) {
        return ArkApiFp(this.configuration).arkClassificationSearchPost(requestParameters.classificationSearchQuery, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Search resources.
     * @param {ArkApiArkResourceSearchPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArkApi
     */
    public arkResourceSearchPost(requestParameters: ArkApiArkResourceSearchPostRequest = {}, options?: AxiosRequestConfig) {
        return ArkApiFp(this.configuration).arkResourceSearchPost(requestParameters.resourceSearchQuery, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AssetApi - axios parameter creator
 * @export
 */
export const AssetApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} assetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assetAssetIdGet: async (assetId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'assetId' is not null or undefined
            assertParamExists('assetAssetIdGet', 'assetId', assetId)
            const localVarPath = `/asset/{assetId}`
                .replace(`{${"assetId"}}`, encodeURIComponent(String(assetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [wildscreenId] 
         * @param {string} [assetId] 
         * @param {Array<string>} [regions] 
         * @param {Array<string>} [behaviours] 
         * @param {Array<string>} [adaptations] 
         * @param {Array<string>} [habitats] 
         * @param {number} [from] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assetGet: async (wildscreenId?: string, assetId?: string, regions?: Array<string>, behaviours?: Array<string>, adaptations?: Array<string>, habitats?: Array<string>, from?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/asset`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (wildscreenId !== undefined) {
                localVarQueryParameter['WildscreenId'] = wildscreenId;
            }

            if (assetId !== undefined) {
                localVarQueryParameter['AssetId'] = assetId;
            }

            if (regions) {
                localVarQueryParameter['Regions'] = regions;
            }

            if (behaviours) {
                localVarQueryParameter['Behaviours'] = behaviours;
            }

            if (adaptations) {
                localVarQueryParameter['Adaptations'] = adaptations;
            }

            if (habitats) {
                localVarQueryParameter['Habitats'] = habitats;
            }

            if (from !== undefined) {
                localVarQueryParameter['From'] = from;
            }

            if (size !== undefined) {
                localVarQueryParameter['Size'] = size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} assetId 
         * @param {any} [file] any to upload
         * @param {string} [assetId2] 
         * @param {string} [originId] 
         * @param {boolean} [isVideo] 
         * @param {boolean} [isHero] 
         * @param {string} [promotionLink] 
         * @param {string} [title] 
         * @param {boolean} [isPublished] 
         * @param {string} [description] 
         * @param {string} [credit] 
         * @param {boolean} [isHomepageWorthy] 
         * @param {Array<string>} [regions] 
         * @param {Array<string>} [behaviours] 
         * @param {Array<string>} [adaptations] 
         * @param {Array<string>} [habitats] 
         * @param {Array<string>} [relatedClassifications] 
         * @param {Array<string>} [lifeStages] 
         * @param {Array<string>} [generalContentDescriptors] 
         * @param {string} [libraryCreditId] 
         * @param {any} [file2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assetUploadAssetIdPut: async (assetId: string, file?: any, assetId2?: string, originId?: string, isVideo?: boolean, isHero?: boolean, promotionLink?: string, title?: string, isPublished?: boolean, description?: string, credit?: string, isHomepageWorthy?: boolean, regions?: Array<string>, behaviours?: Array<string>, adaptations?: Array<string>, habitats?: Array<string>, relatedClassifications?: Array<string>, lifeStages?: Array<string>, generalContentDescriptors?: Array<string>, libraryCreditId?: string, file2?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'assetId' is not null or undefined
            assertParamExists('assetUploadAssetIdPut', 'assetId', assetId)
            const localVarPath = `/asset/upload/{assetId}`
                .replace(`{${"assetId"}}`, encodeURIComponent(String(assetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            if (file != null) {
                localVarFormParams.append('file', file, file.name);
            }


            if (assetId2 !== undefined) { 
                localVarFormParams.append('AssetId', assetId2 as any);
            }
    
            if (originId !== undefined) { 
                localVarFormParams.append('OriginId', originId as any);
            }
    
            if (isVideo !== undefined) { 
                localVarFormParams.append('IsVideo', isVideo as any);
            }
    
            if (isHero !== undefined) { 
                localVarFormParams.append('IsHero', isHero as any);
            }
    
            if (promotionLink !== undefined) { 
                localVarFormParams.append('PromotionLink', promotionLink as any);
            }
    
            if (title !== undefined) { 
                localVarFormParams.append('Title', title as any);
            }
    
            if (isPublished !== undefined) { 
                localVarFormParams.append('IsPublished', isPublished as any);
            }
    
            if (description !== undefined) { 
                localVarFormParams.append('Description', description as any);
            }
    
            if (credit !== undefined) { 
                localVarFormParams.append('Credit', credit as any);
            }
    
            if (isHomepageWorthy !== undefined) { 
                localVarFormParams.append('IsHomepageWorthy', isHomepageWorthy as any);
            }
                if (regions) {
                regions.forEach((element) => {
                    localVarFormParams.append('Regions', element as any);
                })
            }

                if (behaviours) {
                behaviours.forEach((element) => {
                    localVarFormParams.append('Behaviours', element as any);
                })
            }

                if (adaptations) {
                adaptations.forEach((element) => {
                    localVarFormParams.append('Adaptations', element as any);
                })
            }

                if (habitats) {
                habitats.forEach((element) => {
                    localVarFormParams.append('Habitats', element as any);
                })
            }

                if (relatedClassifications) {
                relatedClassifications.forEach((element) => {
                    localVarFormParams.append('RelatedClassifications', element as any);
                })
            }

                if (lifeStages) {
                lifeStages.forEach((element) => {
                    localVarFormParams.append('LifeStages', element as any);
                })
            }

                if (generalContentDescriptors) {
                generalContentDescriptors.forEach((element) => {
                    localVarFormParams.append('GeneralContentDescriptors', element as any);
                })
            }

    
            if (libraryCreditId !== undefined) { 
                localVarFormParams.append('LibraryCreditId', libraryCreditId as any);
            }
    
            if (file2 !== undefined) { 
                localVarFormParams.append('file', file2 as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {any} [file] any to upload
         * @param {string} [assetId] 
         * @param {string} [originId] 
         * @param {boolean} [isVideo] 
         * @param {boolean} [isHero] 
         * @param {string} [promotionLink] 
         * @param {string} [title] 
         * @param {boolean} [isPublished] 
         * @param {string} [description] 
         * @param {string} [credit] 
         * @param {boolean} [isHomepageWorthy] 
         * @param {Array<string>} [regions] 
         * @param {Array<string>} [behaviours] 
         * @param {Array<string>} [adaptations] 
         * @param {Array<string>} [habitats] 
         * @param {Array<string>} [relatedClassifications] 
         * @param {Array<string>} [lifeStages] 
         * @param {Array<string>} [generalContentDescriptors] 
         * @param {string} [libraryCreditId] 
         * @param {any} [file2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assetUploadPost: async (file?: any, assetId?: string, originId?: string, isVideo?: boolean, isHero?: boolean, promotionLink?: string, title?: string, isPublished?: boolean, description?: string, credit?: string, isHomepageWorthy?: boolean, regions?: Array<string>, behaviours?: Array<string>, adaptations?: Array<string>, habitats?: Array<string>, relatedClassifications?: Array<string>, lifeStages?: Array<string>, generalContentDescriptors?: Array<string>, libraryCreditId?: string, file2?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/asset/upload`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            if (file != null) {
                localVarFormParams.append('file', file, file.name);
            }


            if (assetId !== undefined) { 
                localVarFormParams.append('AssetId', assetId as any);
            }
    
            if (originId !== undefined) { 
                localVarFormParams.append('OriginId', originId as any);
            }
    
            if (isVideo !== undefined) { 
                localVarFormParams.append('IsVideo', isVideo as any);
            }
    
            if (isHero !== undefined) { 
                localVarFormParams.append('IsHero', isHero as any);
            }
    
            if (promotionLink !== undefined) { 
                localVarFormParams.append('PromotionLink', promotionLink as any);
            }
    
            if (title !== undefined) { 
                localVarFormParams.append('Title', title as any);
            }
    
            if (isPublished !== undefined) { 
                localVarFormParams.append('IsPublished', isPublished as any);
            }
    
            if (description !== undefined) { 
                localVarFormParams.append('Description', description as any);
            }
    
            if (credit !== undefined) { 
                localVarFormParams.append('Credit', credit as any);
            }
    
            if (isHomepageWorthy !== undefined) { 
                localVarFormParams.append('IsHomepageWorthy', isHomepageWorthy as any);
            }
                if (regions) {
                regions.forEach((element) => {
                    localVarFormParams.append('Regions', element as any);
                })
            }

                if (behaviours) {
                behaviours.forEach((element) => {
                    localVarFormParams.append('Behaviours', element as any);
                })
            }

                if (adaptations) {
                adaptations.forEach((element) => {
                    localVarFormParams.append('Adaptations', element as any);
                })
            }

                if (habitats) {
                habitats.forEach((element) => {
                    localVarFormParams.append('Habitats', element as any);
                })
            }

                if (relatedClassifications) {
                relatedClassifications.forEach((element) => {
                    localVarFormParams.append('RelatedClassifications', element as any);
                })
            }

                if (lifeStages) {
                lifeStages.forEach((element) => {
                    localVarFormParams.append('LifeStages', element as any);
                })
            }

                if (generalContentDescriptors) {
                generalContentDescriptors.forEach((element) => {
                    localVarFormParams.append('GeneralContentDescriptors', element as any);
                })
            }

    
            if (libraryCreditId !== undefined) { 
                localVarFormParams.append('LibraryCreditId', libraryCreditId as any);
            }
    
            if (file2 !== undefined) { 
                localVarFormParams.append('file', file2 as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AssetApi - functional programming interface
 * @export
 */
export const AssetApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AssetApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} assetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assetAssetIdGet(assetId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Asset>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assetAssetIdGet(assetId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [wildscreenId] 
         * @param {string} [assetId] 
         * @param {Array<string>} [regions] 
         * @param {Array<string>} [behaviours] 
         * @param {Array<string>} [adaptations] 
         * @param {Array<string>} [habitats] 
         * @param {number} [from] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assetGet(wildscreenId?: string, assetId?: string, regions?: Array<string>, behaviours?: Array<string>, adaptations?: Array<string>, habitats?: Array<string>, from?: number, size?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AssetResults>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assetGet(wildscreenId, assetId, regions, behaviours, adaptations, habitats, from, size, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} assetId 
         * @param {any} [file] any to upload
         * @param {string} [assetId2] 
         * @param {string} [originId] 
         * @param {boolean} [isVideo] 
         * @param {boolean} [isHero] 
         * @param {string} [promotionLink] 
         * @param {string} [title] 
         * @param {boolean} [isPublished] 
         * @param {string} [description] 
         * @param {string} [credit] 
         * @param {boolean} [isHomepageWorthy] 
         * @param {Array<string>} [regions] 
         * @param {Array<string>} [behaviours] 
         * @param {Array<string>} [adaptations] 
         * @param {Array<string>} [habitats] 
         * @param {Array<string>} [relatedClassifications] 
         * @param {Array<string>} [lifeStages] 
         * @param {Array<string>} [generalContentDescriptors] 
         * @param {string} [libraryCreditId] 
         * @param {any} [file2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assetUploadAssetIdPut(assetId: string, file?: any, assetId2?: string, originId?: string, isVideo?: boolean, isHero?: boolean, promotionLink?: string, title?: string, isPublished?: boolean, description?: string, credit?: string, isHomepageWorthy?: boolean, regions?: Array<string>, behaviours?: Array<string>, adaptations?: Array<string>, habitats?: Array<string>, relatedClassifications?: Array<string>, lifeStages?: Array<string>, generalContentDescriptors?: Array<string>, libraryCreditId?: string, file2?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Asset>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assetUploadAssetIdPut(assetId, file, assetId2, originId, isVideo, isHero, promotionLink, title, isPublished, description, credit, isHomepageWorthy, regions, behaviours, adaptations, habitats, relatedClassifications, lifeStages, generalContentDescriptors, libraryCreditId, file2, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {any} [file] any to upload
         * @param {string} [assetId] 
         * @param {string} [originId] 
         * @param {boolean} [isVideo] 
         * @param {boolean} [isHero] 
         * @param {string} [promotionLink] 
         * @param {string} [title] 
         * @param {boolean} [isPublished] 
         * @param {string} [description] 
         * @param {string} [credit] 
         * @param {boolean} [isHomepageWorthy] 
         * @param {Array<string>} [regions] 
         * @param {Array<string>} [behaviours] 
         * @param {Array<string>} [adaptations] 
         * @param {Array<string>} [habitats] 
         * @param {Array<string>} [relatedClassifications] 
         * @param {Array<string>} [lifeStages] 
         * @param {Array<string>} [generalContentDescriptors] 
         * @param {string} [libraryCreditId] 
         * @param {any} [file2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assetUploadPost(file?: any, assetId?: string, originId?: string, isVideo?: boolean, isHero?: boolean, promotionLink?: string, title?: string, isPublished?: boolean, description?: string, credit?: string, isHomepageWorthy?: boolean, regions?: Array<string>, behaviours?: Array<string>, adaptations?: Array<string>, habitats?: Array<string>, relatedClassifications?: Array<string>, lifeStages?: Array<string>, generalContentDescriptors?: Array<string>, libraryCreditId?: string, file2?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Asset>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assetUploadPost(file, assetId, originId, isVideo, isHero, promotionLink, title, isPublished, description, credit, isHomepageWorthy, regions, behaviours, adaptations, habitats, relatedClassifications, lifeStages, generalContentDescriptors, libraryCreditId, file2, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AssetApi - factory interface
 * @export
 */
export const AssetApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AssetApiFp(configuration)
    return {
        /**
         * 
         * @param {AssetApiAssetAssetIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assetAssetIdGet(requestParameters: AssetApiAssetAssetIdGetRequest, options?: AxiosRequestConfig): AxiosPromise<Asset> {
            return localVarFp.assetAssetIdGet(requestParameters.assetId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AssetApiAssetGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assetGet(requestParameters: AssetApiAssetGetRequest = {}, options?: AxiosRequestConfig): AxiosPromise<AssetResults> {
            return localVarFp.assetGet(requestParameters.wildscreenId, requestParameters.assetId, requestParameters.regions, requestParameters.behaviours, requestParameters.adaptations, requestParameters.habitats, requestParameters.from, requestParameters.size, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AssetApiAssetUploadAssetIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assetUploadAssetIdPut(requestParameters: AssetApiAssetUploadAssetIdPutRequest, options?: AxiosRequestConfig): AxiosPromise<Asset> {
            return localVarFp.assetUploadAssetIdPut(requestParameters.assetId, requestParameters.file, requestParameters.assetId2, requestParameters.originId, requestParameters.isVideo, requestParameters.isHero, requestParameters.promotionLink, requestParameters.title, requestParameters.isPublished, requestParameters.description, requestParameters.credit, requestParameters.isHomepageWorthy, requestParameters.regions, requestParameters.behaviours, requestParameters.adaptations, requestParameters.habitats, requestParameters.relatedClassifications, requestParameters.lifeStages, requestParameters.generalContentDescriptors, requestParameters.libraryCreditId, requestParameters.file2, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AssetApiAssetUploadPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assetUploadPost(requestParameters: AssetApiAssetUploadPostRequest = {}, options?: AxiosRequestConfig): AxiosPromise<Asset> {
            return localVarFp.assetUploadPost(requestParameters.file, requestParameters.assetId, requestParameters.originId, requestParameters.isVideo, requestParameters.isHero, requestParameters.promotionLink, requestParameters.title, requestParameters.isPublished, requestParameters.description, requestParameters.credit, requestParameters.isHomepageWorthy, requestParameters.regions, requestParameters.behaviours, requestParameters.adaptations, requestParameters.habitats, requestParameters.relatedClassifications, requestParameters.lifeStages, requestParameters.generalContentDescriptors, requestParameters.libraryCreditId, requestParameters.file2, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for assetAssetIdGet operation in AssetApi.
 * @export
 * @interface AssetApiAssetAssetIdGetRequest
 */
export interface AssetApiAssetAssetIdGetRequest {
    /**
     * 
     * @type {string}
     * @memberof AssetApiAssetAssetIdGet
     */
    readonly assetId: string
}

/**
 * Request parameters for assetGet operation in AssetApi.
 * @export
 * @interface AssetApiAssetGetRequest
 */
export interface AssetApiAssetGetRequest {
    /**
     * 
     * @type {string}
     * @memberof AssetApiAssetGet
     */
    readonly wildscreenId?: string

    /**
     * 
     * @type {string}
     * @memberof AssetApiAssetGet
     */
    readonly assetId?: string

    /**
     * 
     * @type {Array<string>}
     * @memberof AssetApiAssetGet
     */
    readonly regions?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof AssetApiAssetGet
     */
    readonly behaviours?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof AssetApiAssetGet
     */
    readonly adaptations?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof AssetApiAssetGet
     */
    readonly habitats?: Array<string>

    /**
     * 
     * @type {number}
     * @memberof AssetApiAssetGet
     */
    readonly from?: number

    /**
     * 
     * @type {number}
     * @memberof AssetApiAssetGet
     */
    readonly size?: number
}

/**
 * Request parameters for assetUploadAssetIdPut operation in AssetApi.
 * @export
 * @interface AssetApiAssetUploadAssetIdPutRequest
 */
export interface AssetApiAssetUploadAssetIdPutRequest {
    /**
     * 
     * @type {string}
     * @memberof AssetApiAssetUploadAssetIdPut
     */
    readonly assetId: string

    /**
     * any to upload
     * @type {any}
     * @memberof AssetApiAssetUploadAssetIdPut
     */
    readonly file?: any

    /**
     * 
     * @type {string}
     * @memberof AssetApiAssetUploadAssetIdPut
     */
    readonly assetId2?: string

    /**
     * 
     * @type {string}
     * @memberof AssetApiAssetUploadAssetIdPut
     */
    readonly originId?: string

    /**
     * 
     * @type {boolean}
     * @memberof AssetApiAssetUploadAssetIdPut
     */
    readonly isVideo?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof AssetApiAssetUploadAssetIdPut
     */
    readonly isHero?: boolean

    /**
     * 
     * @type {string}
     * @memberof AssetApiAssetUploadAssetIdPut
     */
    readonly promotionLink?: string

    /**
     * 
     * @type {string}
     * @memberof AssetApiAssetUploadAssetIdPut
     */
    readonly title?: string

    /**
     * 
     * @type {boolean}
     * @memberof AssetApiAssetUploadAssetIdPut
     */
    readonly isPublished?: boolean

    /**
     * 
     * @type {string}
     * @memberof AssetApiAssetUploadAssetIdPut
     */
    readonly description?: string

    /**
     * 
     * @type {string}
     * @memberof AssetApiAssetUploadAssetIdPut
     */
    readonly credit?: string

    /**
     * 
     * @type {boolean}
     * @memberof AssetApiAssetUploadAssetIdPut
     */
    readonly isHomepageWorthy?: boolean

    /**
     * 
     * @type {Array<string>}
     * @memberof AssetApiAssetUploadAssetIdPut
     */
    readonly regions?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof AssetApiAssetUploadAssetIdPut
     */
    readonly behaviours?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof AssetApiAssetUploadAssetIdPut
     */
    readonly adaptations?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof AssetApiAssetUploadAssetIdPut
     */
    readonly habitats?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof AssetApiAssetUploadAssetIdPut
     */
    readonly relatedClassifications?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof AssetApiAssetUploadAssetIdPut
     */
    readonly lifeStages?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof AssetApiAssetUploadAssetIdPut
     */
    readonly generalContentDescriptors?: Array<string>

    /**
     * 
     * @type {string}
     * @memberof AssetApiAssetUploadAssetIdPut
     */
    readonly libraryCreditId?: string

    /**
     * 
     * @type {any}
     * @memberof AssetApiAssetUploadAssetIdPut
     */
    readonly file2?: any
}

/**
 * Request parameters for assetUploadPost operation in AssetApi.
 * @export
 * @interface AssetApiAssetUploadPostRequest
 */
export interface AssetApiAssetUploadPostRequest {
    /**
     * any to upload
     * @type {any}
     * @memberof AssetApiAssetUploadPost
     */
    readonly file?: any

    /**
     * 
     * @type {string}
     * @memberof AssetApiAssetUploadPost
     */
    readonly assetId?: string

    /**
     * 
     * @type {string}
     * @memberof AssetApiAssetUploadPost
     */
    readonly originId?: string

    /**
     * 
     * @type {boolean}
     * @memberof AssetApiAssetUploadPost
     */
    readonly isVideo?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof AssetApiAssetUploadPost
     */
    readonly isHero?: boolean

    /**
     * 
     * @type {string}
     * @memberof AssetApiAssetUploadPost
     */
    readonly promotionLink?: string

    /**
     * 
     * @type {string}
     * @memberof AssetApiAssetUploadPost
     */
    readonly title?: string

    /**
     * 
     * @type {boolean}
     * @memberof AssetApiAssetUploadPost
     */
    readonly isPublished?: boolean

    /**
     * 
     * @type {string}
     * @memberof AssetApiAssetUploadPost
     */
    readonly description?: string

    /**
     * 
     * @type {string}
     * @memberof AssetApiAssetUploadPost
     */
    readonly credit?: string

    /**
     * 
     * @type {boolean}
     * @memberof AssetApiAssetUploadPost
     */
    readonly isHomepageWorthy?: boolean

    /**
     * 
     * @type {Array<string>}
     * @memberof AssetApiAssetUploadPost
     */
    readonly regions?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof AssetApiAssetUploadPost
     */
    readonly behaviours?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof AssetApiAssetUploadPost
     */
    readonly adaptations?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof AssetApiAssetUploadPost
     */
    readonly habitats?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof AssetApiAssetUploadPost
     */
    readonly relatedClassifications?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof AssetApiAssetUploadPost
     */
    readonly lifeStages?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof AssetApiAssetUploadPost
     */
    readonly generalContentDescriptors?: Array<string>

    /**
     * 
     * @type {string}
     * @memberof AssetApiAssetUploadPost
     */
    readonly libraryCreditId?: string

    /**
     * 
     * @type {any}
     * @memberof AssetApiAssetUploadPost
     */
    readonly file2?: any
}

/**
 * AssetApi - object-oriented interface
 * @export
 * @class AssetApi
 * @extends {BaseAPI}
 */
export class AssetApi extends BaseAPI {
    /**
     * 
     * @param {AssetApiAssetAssetIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetApi
     */
    public assetAssetIdGet(requestParameters: AssetApiAssetAssetIdGetRequest, options?: AxiosRequestConfig) {
        return AssetApiFp(this.configuration).assetAssetIdGet(requestParameters.assetId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AssetApiAssetGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetApi
     */
    public assetGet(requestParameters: AssetApiAssetGetRequest = {}, options?: AxiosRequestConfig) {
        return AssetApiFp(this.configuration).assetGet(requestParameters.wildscreenId, requestParameters.assetId, requestParameters.regions, requestParameters.behaviours, requestParameters.adaptations, requestParameters.habitats, requestParameters.from, requestParameters.size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AssetApiAssetUploadAssetIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetApi
     */
    public assetUploadAssetIdPut(requestParameters: AssetApiAssetUploadAssetIdPutRequest, options?: AxiosRequestConfig) {
        return AssetApiFp(this.configuration).assetUploadAssetIdPut(requestParameters.assetId, requestParameters.file, requestParameters.assetId2, requestParameters.originId, requestParameters.isVideo, requestParameters.isHero, requestParameters.promotionLink, requestParameters.title, requestParameters.isPublished, requestParameters.description, requestParameters.credit, requestParameters.isHomepageWorthy, requestParameters.regions, requestParameters.behaviours, requestParameters.adaptations, requestParameters.habitats, requestParameters.relatedClassifications, requestParameters.lifeStages, requestParameters.generalContentDescriptors, requestParameters.libraryCreditId, requestParameters.file2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AssetApiAssetUploadPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetApi
     */
    public assetUploadPost(requestParameters: AssetApiAssetUploadPostRequest = {}, options?: AxiosRequestConfig) {
        return AssetApiFp(this.configuration).assetUploadPost(requestParameters.file, requestParameters.assetId, requestParameters.originId, requestParameters.isVideo, requestParameters.isHero, requestParameters.promotionLink, requestParameters.title, requestParameters.isPublished, requestParameters.description, requestParameters.credit, requestParameters.isHomepageWorthy, requestParameters.regions, requestParameters.behaviours, requestParameters.adaptations, requestParameters.habitats, requestParameters.relatedClassifications, requestParameters.lifeStages, requestParameters.generalContentDescriptors, requestParameters.libraryCreditId, requestParameters.file2, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ClassificationPageApi - axios parameter creator
 * @export
 */
export const ClassificationPageApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} wildscreenId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageDocumentWildscreenIdGet: async (wildscreenId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'wildscreenId' is not null or undefined
            assertParamExists('pageDocumentWildscreenIdGet', 'wildscreenId', wildscreenId)
            const localVarPath = `/page/document/{wildscreenId}`
                .replace(`{${"wildscreenId"}}`, encodeURIComponent(String(wildscreenId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} wildscreenId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageDocumentWildscreenIdParentsGet: async (wildscreenId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'wildscreenId' is not null or undefined
            assertParamExists('pageDocumentWildscreenIdParentsGet', 'wildscreenId', wildscreenId)
            const localVarPath = `/page/document/{wildscreenId}/parents`
                .replace(`{${"wildscreenId"}}`, encodeURIComponent(String(wildscreenId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} wildscreenId 
         * @param {string} [wildscreenId2] 
         * @param {Array<string>} [landRegion] 
         * @param {Array<string>} [actions] 
         * @param {Array<string>} [threats] 
         * @param {Array<string>} [habitat] 
         * @param {Array<string>} [biologicalThemes] 
         * @param {Array<string>} [adaptations] 
         * @param {Array<string>} [behaviours] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageDocumentWildscreenIdPartialPut: async (wildscreenId: string, wildscreenId2?: string, landRegion?: Array<string>, actions?: Array<string>, threats?: Array<string>, habitat?: Array<string>, biologicalThemes?: Array<string>, adaptations?: Array<string>, behaviours?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'wildscreenId' is not null or undefined
            assertParamExists('pageDocumentWildscreenIdPartialPut', 'wildscreenId', wildscreenId)
            const localVarPath = `/page/document/{wildscreenId}/partial`
                .replace(`{${"wildscreenId"}}`, encodeURIComponent(String(wildscreenId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();


            if (wildscreenId2 !== undefined) { 
                localVarFormParams.append('WildscreenId', wildscreenId2 as any);
            }
                if (landRegion) {
                landRegion.forEach((element) => {
                    localVarFormParams.append('LandRegion', element as any);
                })
            }

                if (actions) {
                actions.forEach((element) => {
                    localVarFormParams.append('Actions', element as any);
                })
            }

                if (threats) {
                threats.forEach((element) => {
                    localVarFormParams.append('Threats', element as any);
                })
            }

                if (habitat) {
                habitat.forEach((element) => {
                    localVarFormParams.append('Habitat', element as any);
                })
            }

                if (biologicalThemes) {
                biologicalThemes.forEach((element) => {
                    localVarFormParams.append('BiologicalThemes', element as any);
                })
            }

                if (adaptations) {
                adaptations.forEach((element) => {
                    localVarFormParams.append('Adaptations', element as any);
                })
            }

                if (behaviours) {
                behaviours.forEach((element) => {
                    localVarFormParams.append('Behaviours', element as any);
                })
            }

    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} wildscreenId 
         * @param {any} [file] any to upload
         * @param {string} [wildscreenId2] 
         * @param {boolean} [isPublished] 
         * @param {string} [friendlyName] A more user-friendly name for the organism.
         * @param {string} [description] Description or additional information about the organism.
         * @param {Array<string>} [landRegion] List of land regions where the organism is found.
         * @param {Array<string>} [actions] List of actions that can be taken for conservation or study.
         * @param {Array<string>} [threats] List of threats faced by the organism.
         * @param {Array<string>} [habitat] List of habitats where the organism is commonly found.
         * @param {Array<string>} [biologicalThemes] 
         * @param {Array<string>} [adaptations] List of adaptations that the organism has developed.
         * @param {Array<string>} [behaviours] 
         * @param {any} [file2] 
         * @param {string} [coverImageCredit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageDocumentWildscreenIdPut: async (wildscreenId: string, file?: any, wildscreenId2?: string, isPublished?: boolean, friendlyName?: string, description?: string, landRegion?: Array<string>, actions?: Array<string>, threats?: Array<string>, habitat?: Array<string>, biologicalThemes?: Array<string>, adaptations?: Array<string>, behaviours?: Array<string>, file2?: any, coverImageCredit?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'wildscreenId' is not null or undefined
            assertParamExists('pageDocumentWildscreenIdPut', 'wildscreenId', wildscreenId)
            const localVarPath = `/page/document/{wildscreenId}`
                .replace(`{${"wildscreenId"}}`, encodeURIComponent(String(wildscreenId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            if (file != null) {
                localVarFormParams.append('file', file, file.name);
            }


            if (wildscreenId2 !== undefined) { 
                localVarFormParams.append('WildscreenId', wildscreenId2 as any);
            }
    
            if (isPublished !== undefined) { 
                localVarFormParams.append('IsPublished', isPublished as any);
            }
    
            if (friendlyName !== undefined) { 
                localVarFormParams.append('FriendlyName', friendlyName as any);
            }
    
            if (description !== undefined) { 
                localVarFormParams.append('Description', description as any);
            }
                if (landRegion) {
                landRegion.forEach((element) => {
                    localVarFormParams.append('LandRegion', element as any);
                })
            }

                if (actions) {
                actions.forEach((element) => {
                    localVarFormParams.append('Actions', element as any);
                })
            }

                if (threats) {
                threats.forEach((element) => {
                    localVarFormParams.append('Threats', element as any);
                })
            }

                if (habitat) {
                habitat.forEach((element) => {
                    localVarFormParams.append('Habitat', element as any);
                })
            }

                if (biologicalThemes) {
                biologicalThemes.forEach((element) => {
                    localVarFormParams.append('BiologicalThemes', element as any);
                })
            }

                if (adaptations) {
                adaptations.forEach((element) => {
                    localVarFormParams.append('Adaptations', element as any);
                })
            }

                if (behaviours) {
                behaviours.forEach((element) => {
                    localVarFormParams.append('Behaviours', element as any);
                })
            }

    
            if (file2 !== undefined) { 
                localVarFormParams.append('file', file2 as any);
            }
    
            if (coverImageCredit !== undefined) { 
                localVarFormParams.append('coverImageCredit', coverImageCredit as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ClassificationPageApi - functional programming interface
 * @export
 */
export const ClassificationPageApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ClassificationPageApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} wildscreenId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pageDocumentWildscreenIdGet(wildscreenId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClassificationPageView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pageDocumentWildscreenIdGet(wildscreenId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} wildscreenId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pageDocumentWildscreenIdParentsGet(wildscreenId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClassificationParentView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pageDocumentWildscreenIdParentsGet(wildscreenId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} wildscreenId 
         * @param {string} [wildscreenId2] 
         * @param {Array<string>} [landRegion] 
         * @param {Array<string>} [actions] 
         * @param {Array<string>} [threats] 
         * @param {Array<string>} [habitat] 
         * @param {Array<string>} [biologicalThemes] 
         * @param {Array<string>} [adaptations] 
         * @param {Array<string>} [behaviours] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pageDocumentWildscreenIdPartialPut(wildscreenId: string, wildscreenId2?: string, landRegion?: Array<string>, actions?: Array<string>, threats?: Array<string>, habitat?: Array<string>, biologicalThemes?: Array<string>, adaptations?: Array<string>, behaviours?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClassificationPageView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pageDocumentWildscreenIdPartialPut(wildscreenId, wildscreenId2, landRegion, actions, threats, habitat, biologicalThemes, adaptations, behaviours, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} wildscreenId 
         * @param {any} [file] any to upload
         * @param {string} [wildscreenId2] 
         * @param {boolean} [isPublished] 
         * @param {string} [friendlyName] A more user-friendly name for the organism.
         * @param {string} [description] Description or additional information about the organism.
         * @param {Array<string>} [landRegion] List of land regions where the organism is found.
         * @param {Array<string>} [actions] List of actions that can be taken for conservation or study.
         * @param {Array<string>} [threats] List of threats faced by the organism.
         * @param {Array<string>} [habitat] List of habitats where the organism is commonly found.
         * @param {Array<string>} [biologicalThemes] 
         * @param {Array<string>} [adaptations] List of adaptations that the organism has developed.
         * @param {Array<string>} [behaviours] 
         * @param {any} [file2] 
         * @param {string} [coverImageCredit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pageDocumentWildscreenIdPut(wildscreenId: string, file?: any, wildscreenId2?: string, isPublished?: boolean, friendlyName?: string, description?: string, landRegion?: Array<string>, actions?: Array<string>, threats?: Array<string>, habitat?: Array<string>, biologicalThemes?: Array<string>, adaptations?: Array<string>, behaviours?: Array<string>, file2?: any, coverImageCredit?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClassificationPageView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pageDocumentWildscreenIdPut(wildscreenId, file, wildscreenId2, isPublished, friendlyName, description, landRegion, actions, threats, habitat, biologicalThemes, adaptations, behaviours, file2, coverImageCredit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ClassificationPageApi - factory interface
 * @export
 */
export const ClassificationPageApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ClassificationPageApiFp(configuration)
    return {
        /**
         * 
         * @param {ClassificationPageApiPageDocumentWildscreenIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageDocumentWildscreenIdGet(requestParameters: ClassificationPageApiPageDocumentWildscreenIdGetRequest, options?: AxiosRequestConfig): AxiosPromise<ClassificationPageView> {
            return localVarFp.pageDocumentWildscreenIdGet(requestParameters.wildscreenId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ClassificationPageApiPageDocumentWildscreenIdParentsGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageDocumentWildscreenIdParentsGet(requestParameters: ClassificationPageApiPageDocumentWildscreenIdParentsGetRequest, options?: AxiosRequestConfig): AxiosPromise<ClassificationParentView> {
            return localVarFp.pageDocumentWildscreenIdParentsGet(requestParameters.wildscreenId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ClassificationPageApiPageDocumentWildscreenIdPartialPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageDocumentWildscreenIdPartialPut(requestParameters: ClassificationPageApiPageDocumentWildscreenIdPartialPutRequest, options?: AxiosRequestConfig): AxiosPromise<ClassificationPageView> {
            return localVarFp.pageDocumentWildscreenIdPartialPut(requestParameters.wildscreenId, requestParameters.wildscreenId2, requestParameters.landRegion, requestParameters.actions, requestParameters.threats, requestParameters.habitat, requestParameters.biologicalThemes, requestParameters.adaptations, requestParameters.behaviours, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ClassificationPageApiPageDocumentWildscreenIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageDocumentWildscreenIdPut(requestParameters: ClassificationPageApiPageDocumentWildscreenIdPutRequest, options?: AxiosRequestConfig): AxiosPromise<ClassificationPageView> {
            return localVarFp.pageDocumentWildscreenIdPut(requestParameters.wildscreenId, requestParameters.file, requestParameters.wildscreenId2, requestParameters.isPublished, requestParameters.friendlyName, requestParameters.description, requestParameters.landRegion, requestParameters.actions, requestParameters.threats, requestParameters.habitat, requestParameters.biologicalThemes, requestParameters.adaptations, requestParameters.behaviours, requestParameters.file2, requestParameters.coverImageCredit, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for pageDocumentWildscreenIdGet operation in ClassificationPageApi.
 * @export
 * @interface ClassificationPageApiPageDocumentWildscreenIdGetRequest
 */
export interface ClassificationPageApiPageDocumentWildscreenIdGetRequest {
    /**
     * 
     * @type {string}
     * @memberof ClassificationPageApiPageDocumentWildscreenIdGet
     */
    readonly wildscreenId: string
}

/**
 * Request parameters for pageDocumentWildscreenIdParentsGet operation in ClassificationPageApi.
 * @export
 * @interface ClassificationPageApiPageDocumentWildscreenIdParentsGetRequest
 */
export interface ClassificationPageApiPageDocumentWildscreenIdParentsGetRequest {
    /**
     * 
     * @type {string}
     * @memberof ClassificationPageApiPageDocumentWildscreenIdParentsGet
     */
    readonly wildscreenId: string
}

/**
 * Request parameters for pageDocumentWildscreenIdPartialPut operation in ClassificationPageApi.
 * @export
 * @interface ClassificationPageApiPageDocumentWildscreenIdPartialPutRequest
 */
export interface ClassificationPageApiPageDocumentWildscreenIdPartialPutRequest {
    /**
     * 
     * @type {string}
     * @memberof ClassificationPageApiPageDocumentWildscreenIdPartialPut
     */
    readonly wildscreenId: string

    /**
     * 
     * @type {string}
     * @memberof ClassificationPageApiPageDocumentWildscreenIdPartialPut
     */
    readonly wildscreenId2?: string

    /**
     * 
     * @type {Array<string>}
     * @memberof ClassificationPageApiPageDocumentWildscreenIdPartialPut
     */
    readonly landRegion?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof ClassificationPageApiPageDocumentWildscreenIdPartialPut
     */
    readonly actions?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof ClassificationPageApiPageDocumentWildscreenIdPartialPut
     */
    readonly threats?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof ClassificationPageApiPageDocumentWildscreenIdPartialPut
     */
    readonly habitat?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof ClassificationPageApiPageDocumentWildscreenIdPartialPut
     */
    readonly biologicalThemes?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof ClassificationPageApiPageDocumentWildscreenIdPartialPut
     */
    readonly adaptations?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof ClassificationPageApiPageDocumentWildscreenIdPartialPut
     */
    readonly behaviours?: Array<string>
}

/**
 * Request parameters for pageDocumentWildscreenIdPut operation in ClassificationPageApi.
 * @export
 * @interface ClassificationPageApiPageDocumentWildscreenIdPutRequest
 */
export interface ClassificationPageApiPageDocumentWildscreenIdPutRequest {
    /**
     * 
     * @type {string}
     * @memberof ClassificationPageApiPageDocumentWildscreenIdPut
     */
    readonly wildscreenId: string

    /**
     * any to upload
     * @type {any}
     * @memberof ClassificationPageApiPageDocumentWildscreenIdPut
     */
    readonly file?: any

    /**
     * 
     * @type {string}
     * @memberof ClassificationPageApiPageDocumentWildscreenIdPut
     */
    readonly wildscreenId2?: string

    /**
     * 
     * @type {boolean}
     * @memberof ClassificationPageApiPageDocumentWildscreenIdPut
     */
    readonly isPublished?: boolean

    /**
     * A more user-friendly name for the organism.
     * @type {string}
     * @memberof ClassificationPageApiPageDocumentWildscreenIdPut
     */
    readonly friendlyName?: string

    /**
     * Description or additional information about the organism.
     * @type {string}
     * @memberof ClassificationPageApiPageDocumentWildscreenIdPut
     */
    readonly description?: string

    /**
     * List of land regions where the organism is found.
     * @type {Array<string>}
     * @memberof ClassificationPageApiPageDocumentWildscreenIdPut
     */
    readonly landRegion?: Array<string>

    /**
     * List of actions that can be taken for conservation or study.
     * @type {Array<string>}
     * @memberof ClassificationPageApiPageDocumentWildscreenIdPut
     */
    readonly actions?: Array<string>

    /**
     * List of threats faced by the organism.
     * @type {Array<string>}
     * @memberof ClassificationPageApiPageDocumentWildscreenIdPut
     */
    readonly threats?: Array<string>

    /**
     * List of habitats where the organism is commonly found.
     * @type {Array<string>}
     * @memberof ClassificationPageApiPageDocumentWildscreenIdPut
     */
    readonly habitat?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof ClassificationPageApiPageDocumentWildscreenIdPut
     */
    readonly biologicalThemes?: Array<string>

    /**
     * List of adaptations that the organism has developed.
     * @type {Array<string>}
     * @memberof ClassificationPageApiPageDocumentWildscreenIdPut
     */
    readonly adaptations?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof ClassificationPageApiPageDocumentWildscreenIdPut
     */
    readonly behaviours?: Array<string>

    /**
     * 
     * @type {any}
     * @memberof ClassificationPageApiPageDocumentWildscreenIdPut
     */
    readonly file2?: any

    /**
     * 
     * @type {string}
     * @memberof ClassificationPageApiPageDocumentWildscreenIdPut
     */
    readonly coverImageCredit?: string
}

/**
 * ClassificationPageApi - object-oriented interface
 * @export
 * @class ClassificationPageApi
 * @extends {BaseAPI}
 */
export class ClassificationPageApi extends BaseAPI {
    /**
     * 
     * @param {ClassificationPageApiPageDocumentWildscreenIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClassificationPageApi
     */
    public pageDocumentWildscreenIdGet(requestParameters: ClassificationPageApiPageDocumentWildscreenIdGetRequest, options?: AxiosRequestConfig) {
        return ClassificationPageApiFp(this.configuration).pageDocumentWildscreenIdGet(requestParameters.wildscreenId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ClassificationPageApiPageDocumentWildscreenIdParentsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClassificationPageApi
     */
    public pageDocumentWildscreenIdParentsGet(requestParameters: ClassificationPageApiPageDocumentWildscreenIdParentsGetRequest, options?: AxiosRequestConfig) {
        return ClassificationPageApiFp(this.configuration).pageDocumentWildscreenIdParentsGet(requestParameters.wildscreenId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ClassificationPageApiPageDocumentWildscreenIdPartialPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClassificationPageApi
     */
    public pageDocumentWildscreenIdPartialPut(requestParameters: ClassificationPageApiPageDocumentWildscreenIdPartialPutRequest, options?: AxiosRequestConfig) {
        return ClassificationPageApiFp(this.configuration).pageDocumentWildscreenIdPartialPut(requestParameters.wildscreenId, requestParameters.wildscreenId2, requestParameters.landRegion, requestParameters.actions, requestParameters.threats, requestParameters.habitat, requestParameters.biologicalThemes, requestParameters.adaptations, requestParameters.behaviours, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ClassificationPageApiPageDocumentWildscreenIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClassificationPageApi
     */
    public pageDocumentWildscreenIdPut(requestParameters: ClassificationPageApiPageDocumentWildscreenIdPutRequest, options?: AxiosRequestConfig) {
        return ClassificationPageApiFp(this.configuration).pageDocumentWildscreenIdPut(requestParameters.wildscreenId, requestParameters.file, requestParameters.wildscreenId2, requestParameters.isPublished, requestParameters.friendlyName, requestParameters.description, requestParameters.landRegion, requestParameters.actions, requestParameters.threats, requestParameters.habitat, requestParameters.biologicalThemes, requestParameters.adaptations, requestParameters.behaviours, requestParameters.file2, requestParameters.coverImageCredit, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CoverApi - axios parameter creator
 * @export
 */
export const CoverApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {any} [file] any to upload
         * @param {string} [credit] 
         * @param {string} [wildscreenId] 
         * @param {any} [file2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coverUploadPost: async (file?: any, credit?: string, wildscreenId?: string, file2?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/cover/upload`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            if (file != null) {
                localVarFormParams.append('file', file, file.name);
            }


            if (credit !== undefined) { 
                localVarFormParams.append('Credit', credit as any);
            }
    
            if (wildscreenId !== undefined) { 
                localVarFormParams.append('WildscreenId', wildscreenId as any);
            }
    
            if (file2 !== undefined) { 
                localVarFormParams.append('file', file2 as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CoverApi - functional programming interface
 * @export
 */
export const CoverApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CoverApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {any} [file] any to upload
         * @param {string} [credit] 
         * @param {string} [wildscreenId] 
         * @param {any} [file2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async coverUploadPost(file?: any, credit?: string, wildscreenId?: string, file2?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.coverUploadPost(file, credit, wildscreenId, file2, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CoverApi - factory interface
 * @export
 */
export const CoverApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CoverApiFp(configuration)
    return {
        /**
         * 
         * @param {CoverApiCoverUploadPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coverUploadPost(requestParameters: CoverApiCoverUploadPostRequest = {}, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.coverUploadPost(requestParameters.file, requestParameters.credit, requestParameters.wildscreenId, requestParameters.file2, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for coverUploadPost operation in CoverApi.
 * @export
 * @interface CoverApiCoverUploadPostRequest
 */
export interface CoverApiCoverUploadPostRequest {
    /**
     * any to upload
     * @type {any}
     * @memberof CoverApiCoverUploadPost
     */
    readonly file?: any

    /**
     * 
     * @type {string}
     * @memberof CoverApiCoverUploadPost
     */
    readonly credit?: string

    /**
     * 
     * @type {string}
     * @memberof CoverApiCoverUploadPost
     */
    readonly wildscreenId?: string

    /**
     * 
     * @type {any}
     * @memberof CoverApiCoverUploadPost
     */
    readonly file2?: any
}

/**
 * CoverApi - object-oriented interface
 * @export
 * @class CoverApi
 * @extends {BaseAPI}
 */
export class CoverApi extends BaseAPI {
    /**
     * 
     * @param {CoverApiCoverUploadPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoverApi
     */
    public coverUploadPost(requestParameters: CoverApiCoverUploadPostRequest = {}, options?: AxiosRequestConfig) {
        return CoverApiFp(this.configuration).coverUploadPost(requestParameters.file, requestParameters.credit, requestParameters.wildscreenId, requestParameters.file2, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DataApi - axios parameter creator
 * @export
 */
export const DataApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dataPropertiesAssetGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/data/properties/asset`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AssetListProperty} targetProperty 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dataPropertiesAssetPropertyTargetPropertyGet: async (targetProperty: AssetListProperty, name?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'targetProperty' is not null or undefined
            assertParamExists('dataPropertiesAssetPropertyTargetPropertyGet', 'targetProperty', targetProperty)
            const localVarPath = `/data/properties/asset/property/{targetProperty}`
                .replace(`{${"targetProperty"}}`, encodeURIComponent(String(targetProperty)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AssetListProperty} targetProperty 
         * @param {string} [prefix] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dataPropertiesAssetTargetPropertyGet: async (targetProperty: AssetListProperty, prefix?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'targetProperty' is not null or undefined
            assertParamExists('dataPropertiesAssetTargetPropertyGet', 'targetProperty', targetProperty)
            const localVarPath = `/data/properties/asset/{targetProperty}`
                .replace(`{${"targetProperty"}}`, encodeURIComponent(String(targetProperty)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (prefix !== undefined) {
                localVarQueryParameter['prefix'] = prefix;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dataPropertiesClassificationGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/data/properties/classification`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ClassificationListProperty} targetProperty 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dataPropertiesClassificationPropertyTargetPropertyGet: async (targetProperty: ClassificationListProperty, name?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'targetProperty' is not null or undefined
            assertParamExists('dataPropertiesClassificationPropertyTargetPropertyGet', 'targetProperty', targetProperty)
            const localVarPath = `/data/properties/classification/property/{targetProperty}`
                .replace(`{${"targetProperty"}}`, encodeURIComponent(String(targetProperty)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ClassificationListProperty} targetProperty 
         * @param {string} [prefix] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dataPropertiesClassificationTargetPropertyGet: async (targetProperty: ClassificationListProperty, prefix?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'targetProperty' is not null or undefined
            assertParamExists('dataPropertiesClassificationTargetPropertyGet', 'targetProperty', targetProperty)
            const localVarPath = `/data/properties/classification/{targetProperty}`
                .replace(`{${"targetProperty"}}`, encodeURIComponent(String(targetProperty)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (prefix !== undefined) {
                localVarQueryParameter['prefix'] = prefix;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dataPropertiesResourceAgeCategoryGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/data/properties/resource/ageCategory`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dataPropertiesResourceSubjectGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/data/properties/resource/subject`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dataPropertiesResourceTypeGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/data/properties/resource/type`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DataApi - functional programming interface
 * @export
 */
export const DataApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DataApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dataPropertiesAssetGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PropertyValuesSearchResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dataPropertiesAssetGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AssetListProperty} targetProperty 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dataPropertiesAssetPropertyTargetPropertyGet(targetProperty: AssetListProperty, name?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Tag>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dataPropertiesAssetPropertyTargetPropertyGet(targetProperty, name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AssetListProperty} targetProperty 
         * @param {string} [prefix] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dataPropertiesAssetTargetPropertyGet(targetProperty: AssetListProperty, prefix?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TagSearchResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dataPropertiesAssetTargetPropertyGet(targetProperty, prefix, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dataPropertiesClassificationGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PropertyValuesSearchResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dataPropertiesClassificationGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ClassificationListProperty} targetProperty 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dataPropertiesClassificationPropertyTargetPropertyGet(targetProperty: ClassificationListProperty, name?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Tag>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dataPropertiesClassificationPropertyTargetPropertyGet(targetProperty, name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ClassificationListProperty} targetProperty 
         * @param {string} [prefix] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dataPropertiesClassificationTargetPropertyGet(targetProperty: ClassificationListProperty, prefix?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TagSearchResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dataPropertiesClassificationTargetPropertyGet(targetProperty, prefix, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dataPropertiesResourceAgeCategoryGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PropertyValuesSearchResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dataPropertiesResourceAgeCategoryGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dataPropertiesResourceSubjectGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PropertyValuesSearchResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dataPropertiesResourceSubjectGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dataPropertiesResourceTypeGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PropertyValuesSearchResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dataPropertiesResourceTypeGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DataApi - factory interface
 * @export
 */
export const DataApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DataApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dataPropertiesAssetGet(options?: AxiosRequestConfig): AxiosPromise<PropertyValuesSearchResult> {
            return localVarFp.dataPropertiesAssetGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DataApiDataPropertiesAssetPropertyTargetPropertyGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dataPropertiesAssetPropertyTargetPropertyGet(requestParameters: DataApiDataPropertiesAssetPropertyTargetPropertyGetRequest, options?: AxiosRequestConfig): AxiosPromise<Tag> {
            return localVarFp.dataPropertiesAssetPropertyTargetPropertyGet(requestParameters.targetProperty, requestParameters.name, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DataApiDataPropertiesAssetTargetPropertyGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dataPropertiesAssetTargetPropertyGet(requestParameters: DataApiDataPropertiesAssetTargetPropertyGetRequest, options?: AxiosRequestConfig): AxiosPromise<TagSearchResult> {
            return localVarFp.dataPropertiesAssetTargetPropertyGet(requestParameters.targetProperty, requestParameters.prefix, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dataPropertiesClassificationGet(options?: AxiosRequestConfig): AxiosPromise<PropertyValuesSearchResult> {
            return localVarFp.dataPropertiesClassificationGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DataApiDataPropertiesClassificationPropertyTargetPropertyGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dataPropertiesClassificationPropertyTargetPropertyGet(requestParameters: DataApiDataPropertiesClassificationPropertyTargetPropertyGetRequest, options?: AxiosRequestConfig): AxiosPromise<Tag> {
            return localVarFp.dataPropertiesClassificationPropertyTargetPropertyGet(requestParameters.targetProperty, requestParameters.name, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DataApiDataPropertiesClassificationTargetPropertyGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dataPropertiesClassificationTargetPropertyGet(requestParameters: DataApiDataPropertiesClassificationTargetPropertyGetRequest, options?: AxiosRequestConfig): AxiosPromise<TagSearchResult> {
            return localVarFp.dataPropertiesClassificationTargetPropertyGet(requestParameters.targetProperty, requestParameters.prefix, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dataPropertiesResourceAgeCategoryGet(options?: AxiosRequestConfig): AxiosPromise<PropertyValuesSearchResult> {
            return localVarFp.dataPropertiesResourceAgeCategoryGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dataPropertiesResourceSubjectGet(options?: AxiosRequestConfig): AxiosPromise<PropertyValuesSearchResult> {
            return localVarFp.dataPropertiesResourceSubjectGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dataPropertiesResourceTypeGet(options?: AxiosRequestConfig): AxiosPromise<PropertyValuesSearchResult> {
            return localVarFp.dataPropertiesResourceTypeGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for dataPropertiesAssetPropertyTargetPropertyGet operation in DataApi.
 * @export
 * @interface DataApiDataPropertiesAssetPropertyTargetPropertyGetRequest
 */
export interface DataApiDataPropertiesAssetPropertyTargetPropertyGetRequest {
    /**
     * 
     * @type {AssetListProperty}
     * @memberof DataApiDataPropertiesAssetPropertyTargetPropertyGet
     */
    readonly targetProperty: AssetListProperty

    /**
     * 
     * @type {string}
     * @memberof DataApiDataPropertiesAssetPropertyTargetPropertyGet
     */
    readonly name?: string
}

/**
 * Request parameters for dataPropertiesAssetTargetPropertyGet operation in DataApi.
 * @export
 * @interface DataApiDataPropertiesAssetTargetPropertyGetRequest
 */
export interface DataApiDataPropertiesAssetTargetPropertyGetRequest {
    /**
     * 
     * @type {AssetListProperty}
     * @memberof DataApiDataPropertiesAssetTargetPropertyGet
     */
    readonly targetProperty: AssetListProperty

    /**
     * 
     * @type {string}
     * @memberof DataApiDataPropertiesAssetTargetPropertyGet
     */
    readonly prefix?: string
}

/**
 * Request parameters for dataPropertiesClassificationPropertyTargetPropertyGet operation in DataApi.
 * @export
 * @interface DataApiDataPropertiesClassificationPropertyTargetPropertyGetRequest
 */
export interface DataApiDataPropertiesClassificationPropertyTargetPropertyGetRequest {
    /**
     * 
     * @type {ClassificationListProperty}
     * @memberof DataApiDataPropertiesClassificationPropertyTargetPropertyGet
     */
    readonly targetProperty: ClassificationListProperty

    /**
     * 
     * @type {string}
     * @memberof DataApiDataPropertiesClassificationPropertyTargetPropertyGet
     */
    readonly name?: string
}

/**
 * Request parameters for dataPropertiesClassificationTargetPropertyGet operation in DataApi.
 * @export
 * @interface DataApiDataPropertiesClassificationTargetPropertyGetRequest
 */
export interface DataApiDataPropertiesClassificationTargetPropertyGetRequest {
    /**
     * 
     * @type {ClassificationListProperty}
     * @memberof DataApiDataPropertiesClassificationTargetPropertyGet
     */
    readonly targetProperty: ClassificationListProperty

    /**
     * 
     * @type {string}
     * @memberof DataApiDataPropertiesClassificationTargetPropertyGet
     */
    readonly prefix?: string
}

/**
 * DataApi - object-oriented interface
 * @export
 * @class DataApi
 * @extends {BaseAPI}
 */
export class DataApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataApi
     */
    public dataPropertiesAssetGet(options?: AxiosRequestConfig) {
        return DataApiFp(this.configuration).dataPropertiesAssetGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DataApiDataPropertiesAssetPropertyTargetPropertyGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataApi
     */
    public dataPropertiesAssetPropertyTargetPropertyGet(requestParameters: DataApiDataPropertiesAssetPropertyTargetPropertyGetRequest, options?: AxiosRequestConfig) {
        return DataApiFp(this.configuration).dataPropertiesAssetPropertyTargetPropertyGet(requestParameters.targetProperty, requestParameters.name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DataApiDataPropertiesAssetTargetPropertyGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataApi
     */
    public dataPropertiesAssetTargetPropertyGet(requestParameters: DataApiDataPropertiesAssetTargetPropertyGetRequest, options?: AxiosRequestConfig) {
        return DataApiFp(this.configuration).dataPropertiesAssetTargetPropertyGet(requestParameters.targetProperty, requestParameters.prefix, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataApi
     */
    public dataPropertiesClassificationGet(options?: AxiosRequestConfig) {
        return DataApiFp(this.configuration).dataPropertiesClassificationGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DataApiDataPropertiesClassificationPropertyTargetPropertyGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataApi
     */
    public dataPropertiesClassificationPropertyTargetPropertyGet(requestParameters: DataApiDataPropertiesClassificationPropertyTargetPropertyGetRequest, options?: AxiosRequestConfig) {
        return DataApiFp(this.configuration).dataPropertiesClassificationPropertyTargetPropertyGet(requestParameters.targetProperty, requestParameters.name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DataApiDataPropertiesClassificationTargetPropertyGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataApi
     */
    public dataPropertiesClassificationTargetPropertyGet(requestParameters: DataApiDataPropertiesClassificationTargetPropertyGetRequest, options?: AxiosRequestConfig) {
        return DataApiFp(this.configuration).dataPropertiesClassificationTargetPropertyGet(requestParameters.targetProperty, requestParameters.prefix, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataApi
     */
    public dataPropertiesResourceAgeCategoryGet(options?: AxiosRequestConfig) {
        return DataApiFp(this.configuration).dataPropertiesResourceAgeCategoryGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataApi
     */
    public dataPropertiesResourceSubjectGet(options?: AxiosRequestConfig) {
        return DataApiFp(this.configuration).dataPropertiesResourceSubjectGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataApi
     */
    public dataPropertiesResourceTypeGet(options?: AxiosRequestConfig) {
        return DataApiFp(this.configuration).dataPropertiesResourceTypeGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * HealthApi - axios parameter creator
 * @export
 */
export const HealthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Tests that the API is still up and functional. Used for monitoring purposes.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/health`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * HealthApi - functional programming interface
 * @export
 */
export const HealthApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = HealthApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Tests that the API is still up and functional. Used for monitoring purposes.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async healthGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.healthGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * HealthApi - factory interface
 * @export
 */
export const HealthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = HealthApiFp(configuration)
    return {
        /**
         * 
         * @summary Tests that the API is still up and functional. Used for monitoring purposes.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthGet(options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.healthGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * HealthApi - object-oriented interface
 * @export
 * @class HealthApi
 * @extends {BaseAPI}
 */
export class HealthApi extends BaseAPI {
    /**
     * 
     * @summary Tests that the API is still up and functional. Used for monitoring purposes.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HealthApi
     */
    public healthGet(options?: AxiosRequestConfig) {
        return HealthApiFp(this.configuration).healthGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * LibraryCreditApi - axios parameter creator
 * @export
 */
export const LibraryCreditApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} libraryCreditId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        libraryCreditLibraryCreditIdDelete: async (libraryCreditId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'libraryCreditId' is not null or undefined
            assertParamExists('libraryCreditLibraryCreditIdDelete', 'libraryCreditId', libraryCreditId)
            const localVarPath = `/library-credit/{libraryCreditId}`
                .replace(`{${"libraryCreditId"}}`, encodeURIComponent(String(libraryCreditId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} libraryCreditId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        libraryCreditLibraryCreditIdGet: async (libraryCreditId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'libraryCreditId' is not null or undefined
            assertParamExists('libraryCreditLibraryCreditIdGet', 'libraryCreditId', libraryCreditId)
            const localVarPath = `/library-credit/{libraryCreditId}`
                .replace(`{${"libraryCreditId"}}`, encodeURIComponent(String(libraryCreditId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} libraryCreditId 
         * @param {any} [file] any to upload
         * @param {string} [libraryCreditId2] 
         * @param {string} [libraryName] 
         * @param {string} [url] 
         * @param {any} [file2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        libraryCreditLibraryCreditIdPut: async (libraryCreditId: string, file?: any, libraryCreditId2?: string, libraryName?: string, url?: string, file2?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'libraryCreditId' is not null or undefined
            assertParamExists('libraryCreditLibraryCreditIdPut', 'libraryCreditId', libraryCreditId)
            const localVarPath = `/library-credit/{libraryCreditId}`
                .replace(`{${"libraryCreditId"}}`, encodeURIComponent(String(libraryCreditId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            if (file != null) {
                localVarFormParams.append('file', file, file.name);
            }


            if (libraryCreditId2 !== undefined) { 
                localVarFormParams.append('LibraryCreditId', libraryCreditId2 as any);
            }
    
            if (libraryName !== undefined) { 
                localVarFormParams.append('LibraryName', libraryName as any);
            }
    
            if (url !== undefined) { 
                localVarFormParams.append('Url', url as any);
            }
    
            if (file2 !== undefined) { 
                localVarFormParams.append('file', file2 as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {any} [file] any to upload
         * @param {string} [newLibraryName] 
         * @param {string} [url] 
         * @param {any} [file2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        libraryCreditPost: async (file?: any, newLibraryName?: string, url?: string, file2?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/library-credit`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            if (file != null) {
                localVarFormParams.append('file', file, file.name);
            }


            if (newLibraryName !== undefined) { 
                localVarFormParams.append('NewLibraryName', newLibraryName as any);
            }
    
            if (url !== undefined) { 
                localVarFormParams.append('Url', url as any);
            }
    
            if (file2 !== undefined) { 
                localVarFormParams.append('file', file2 as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [from] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        libraryCreditSearchGet: async (from?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/library-credit/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (from !== undefined) {
                localVarQueryParameter['From'] = from;
            }

            if (size !== undefined) {
                localVarQueryParameter['Size'] = size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LibraryCreditApi - functional programming interface
 * @export
 */
export const LibraryCreditApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LibraryCreditApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} libraryCreditId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async libraryCreditLibraryCreditIdDelete(libraryCreditId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.libraryCreditLibraryCreditIdDelete(libraryCreditId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} libraryCreditId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async libraryCreditLibraryCreditIdGet(libraryCreditId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LibraryCredit>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.libraryCreditLibraryCreditIdGet(libraryCreditId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} libraryCreditId 
         * @param {any} [file] any to upload
         * @param {string} [libraryCreditId2] 
         * @param {string} [libraryName] 
         * @param {string} [url] 
         * @param {any} [file2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async libraryCreditLibraryCreditIdPut(libraryCreditId: string, file?: any, libraryCreditId2?: string, libraryName?: string, url?: string, file2?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LibraryCredit>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.libraryCreditLibraryCreditIdPut(libraryCreditId, file, libraryCreditId2, libraryName, url, file2, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {any} [file] any to upload
         * @param {string} [newLibraryName] 
         * @param {string} [url] 
         * @param {any} [file2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async libraryCreditPost(file?: any, newLibraryName?: string, url?: string, file2?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LibraryCredit>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.libraryCreditPost(file, newLibraryName, url, file2, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [from] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async libraryCreditSearchGet(from?: number, size?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LibraryCreditResults>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.libraryCreditSearchGet(from, size, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LibraryCreditApi - factory interface
 * @export
 */
export const LibraryCreditApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LibraryCreditApiFp(configuration)
    return {
        /**
         * 
         * @param {LibraryCreditApiLibraryCreditLibraryCreditIdDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        libraryCreditLibraryCreditIdDelete(requestParameters: LibraryCreditApiLibraryCreditLibraryCreditIdDeleteRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.libraryCreditLibraryCreditIdDelete(requestParameters.libraryCreditId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LibraryCreditApiLibraryCreditLibraryCreditIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        libraryCreditLibraryCreditIdGet(requestParameters: LibraryCreditApiLibraryCreditLibraryCreditIdGetRequest, options?: AxiosRequestConfig): AxiosPromise<LibraryCredit> {
            return localVarFp.libraryCreditLibraryCreditIdGet(requestParameters.libraryCreditId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LibraryCreditApiLibraryCreditLibraryCreditIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        libraryCreditLibraryCreditIdPut(requestParameters: LibraryCreditApiLibraryCreditLibraryCreditIdPutRequest, options?: AxiosRequestConfig): AxiosPromise<LibraryCredit> {
            return localVarFp.libraryCreditLibraryCreditIdPut(requestParameters.libraryCreditId, requestParameters.file, requestParameters.libraryCreditId2, requestParameters.libraryName, requestParameters.url, requestParameters.file2, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LibraryCreditApiLibraryCreditPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        libraryCreditPost(requestParameters: LibraryCreditApiLibraryCreditPostRequest = {}, options?: AxiosRequestConfig): AxiosPromise<LibraryCredit> {
            return localVarFp.libraryCreditPost(requestParameters.file, requestParameters.newLibraryName, requestParameters.url, requestParameters.file2, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LibraryCreditApiLibraryCreditSearchGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        libraryCreditSearchGet(requestParameters: LibraryCreditApiLibraryCreditSearchGetRequest = {}, options?: AxiosRequestConfig): AxiosPromise<LibraryCreditResults> {
            return localVarFp.libraryCreditSearchGet(requestParameters.from, requestParameters.size, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for libraryCreditLibraryCreditIdDelete operation in LibraryCreditApi.
 * @export
 * @interface LibraryCreditApiLibraryCreditLibraryCreditIdDeleteRequest
 */
export interface LibraryCreditApiLibraryCreditLibraryCreditIdDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof LibraryCreditApiLibraryCreditLibraryCreditIdDelete
     */
    readonly libraryCreditId: string
}

/**
 * Request parameters for libraryCreditLibraryCreditIdGet operation in LibraryCreditApi.
 * @export
 * @interface LibraryCreditApiLibraryCreditLibraryCreditIdGetRequest
 */
export interface LibraryCreditApiLibraryCreditLibraryCreditIdGetRequest {
    /**
     * 
     * @type {string}
     * @memberof LibraryCreditApiLibraryCreditLibraryCreditIdGet
     */
    readonly libraryCreditId: string
}

/**
 * Request parameters for libraryCreditLibraryCreditIdPut operation in LibraryCreditApi.
 * @export
 * @interface LibraryCreditApiLibraryCreditLibraryCreditIdPutRequest
 */
export interface LibraryCreditApiLibraryCreditLibraryCreditIdPutRequest {
    /**
     * 
     * @type {string}
     * @memberof LibraryCreditApiLibraryCreditLibraryCreditIdPut
     */
    readonly libraryCreditId: string

    /**
     * any to upload
     * @type {any}
     * @memberof LibraryCreditApiLibraryCreditLibraryCreditIdPut
     */
    readonly file?: any

    /**
     * 
     * @type {string}
     * @memberof LibraryCreditApiLibraryCreditLibraryCreditIdPut
     */
    readonly libraryCreditId2?: string

    /**
     * 
     * @type {string}
     * @memberof LibraryCreditApiLibraryCreditLibraryCreditIdPut
     */
    readonly libraryName?: string

    /**
     * 
     * @type {string}
     * @memberof LibraryCreditApiLibraryCreditLibraryCreditIdPut
     */
    readonly url?: string

    /**
     * 
     * @type {any}
     * @memberof LibraryCreditApiLibraryCreditLibraryCreditIdPut
     */
    readonly file2?: any
}

/**
 * Request parameters for libraryCreditPost operation in LibraryCreditApi.
 * @export
 * @interface LibraryCreditApiLibraryCreditPostRequest
 */
export interface LibraryCreditApiLibraryCreditPostRequest {
    /**
     * any to upload
     * @type {any}
     * @memberof LibraryCreditApiLibraryCreditPost
     */
    readonly file?: any

    /**
     * 
     * @type {string}
     * @memberof LibraryCreditApiLibraryCreditPost
     */
    readonly newLibraryName?: string

    /**
     * 
     * @type {string}
     * @memberof LibraryCreditApiLibraryCreditPost
     */
    readonly url?: string

    /**
     * 
     * @type {any}
     * @memberof LibraryCreditApiLibraryCreditPost
     */
    readonly file2?: any
}

/**
 * Request parameters for libraryCreditSearchGet operation in LibraryCreditApi.
 * @export
 * @interface LibraryCreditApiLibraryCreditSearchGetRequest
 */
export interface LibraryCreditApiLibraryCreditSearchGetRequest {
    /**
     * 
     * @type {number}
     * @memberof LibraryCreditApiLibraryCreditSearchGet
     */
    readonly from?: number

    /**
     * 
     * @type {number}
     * @memberof LibraryCreditApiLibraryCreditSearchGet
     */
    readonly size?: number
}

/**
 * LibraryCreditApi - object-oriented interface
 * @export
 * @class LibraryCreditApi
 * @extends {BaseAPI}
 */
export class LibraryCreditApi extends BaseAPI {
    /**
     * 
     * @param {LibraryCreditApiLibraryCreditLibraryCreditIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LibraryCreditApi
     */
    public libraryCreditLibraryCreditIdDelete(requestParameters: LibraryCreditApiLibraryCreditLibraryCreditIdDeleteRequest, options?: AxiosRequestConfig) {
        return LibraryCreditApiFp(this.configuration).libraryCreditLibraryCreditIdDelete(requestParameters.libraryCreditId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LibraryCreditApiLibraryCreditLibraryCreditIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LibraryCreditApi
     */
    public libraryCreditLibraryCreditIdGet(requestParameters: LibraryCreditApiLibraryCreditLibraryCreditIdGetRequest, options?: AxiosRequestConfig) {
        return LibraryCreditApiFp(this.configuration).libraryCreditLibraryCreditIdGet(requestParameters.libraryCreditId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LibraryCreditApiLibraryCreditLibraryCreditIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LibraryCreditApi
     */
    public libraryCreditLibraryCreditIdPut(requestParameters: LibraryCreditApiLibraryCreditLibraryCreditIdPutRequest, options?: AxiosRequestConfig) {
        return LibraryCreditApiFp(this.configuration).libraryCreditLibraryCreditIdPut(requestParameters.libraryCreditId, requestParameters.file, requestParameters.libraryCreditId2, requestParameters.libraryName, requestParameters.url, requestParameters.file2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LibraryCreditApiLibraryCreditPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LibraryCreditApi
     */
    public libraryCreditPost(requestParameters: LibraryCreditApiLibraryCreditPostRequest = {}, options?: AxiosRequestConfig) {
        return LibraryCreditApiFp(this.configuration).libraryCreditPost(requestParameters.file, requestParameters.newLibraryName, requestParameters.url, requestParameters.file2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LibraryCreditApiLibraryCreditSearchGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LibraryCreditApi
     */
    public libraryCreditSearchGet(requestParameters: LibraryCreditApiLibraryCreditSearchGetRequest = {}, options?: AxiosRequestConfig) {
        return LibraryCreditApiFp(this.configuration).libraryCreditSearchGet(requestParameters.from, requestParameters.size, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ResourceApi - axios parameter creator
 * @export
 */
export const ResourceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resourceIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('resourceIdGet', 'id', id)
            const localVarPath = `/resource/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {any} [file] any to upload
         * @param {string} [title] 
         * @param {string} [description] 
         * @param {string} [ageCategory] 
         * @param {Array<string>} [subjects] 
         * @param {string} [type] 
         * @param {boolean} [isPublished] 
         * @param {string} [resourceName] 
         * @param {Array<string>} [biologicalThemes] 
         * @param {Array<string>} [habitats] 
         * @param {Array<string>} [landRegions] 
         * @param {Array<string>} [threats] 
         * @param {Array<string>} [actions] 
         * @param {any} [file2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resourceIdPut: async (id: string, file?: any, title?: string, description?: string, ageCategory?: string, subjects?: Array<string>, type?: string, isPublished?: boolean, resourceName?: string, biologicalThemes?: Array<string>, habitats?: Array<string>, landRegions?: Array<string>, threats?: Array<string>, actions?: Array<string>, file2?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('resourceIdPut', 'id', id)
            const localVarPath = `/resource/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            if (file != null) {
                localVarFormParams.append('file', file, file.name);
            }


            if (title !== undefined) { 
                localVarFormParams.append('Title', title as any);
            }
    
            if (description !== undefined) { 
                localVarFormParams.append('Description', description as any);
            }
    
            if (ageCategory !== undefined) { 
                localVarFormParams.append('AgeCategory', ageCategory as any);
            }
                if (subjects) {
                subjects.forEach((element) => {
                    localVarFormParams.append('Subjects', element as any);
                })
            }

    
            if (type !== undefined) { 
                localVarFormParams.append('Type', type as any);
            }
    
            if (isPublished !== undefined) { 
                localVarFormParams.append('IsPublished', isPublished as any);
            }
    
            if (resourceName !== undefined) { 
                localVarFormParams.append('ResourceName', resourceName as any);
            }
                if (biologicalThemes) {
                biologicalThemes.forEach((element) => {
                    localVarFormParams.append('BiologicalThemes', element as any);
                })
            }

                if (habitats) {
                habitats.forEach((element) => {
                    localVarFormParams.append('Habitats', element as any);
                })
            }

                if (landRegions) {
                landRegions.forEach((element) => {
                    localVarFormParams.append('LandRegions', element as any);
                })
            }

                if (threats) {
                threats.forEach((element) => {
                    localVarFormParams.append('Threats', element as any);
                })
            }

                if (actions) {
                actions.forEach((element) => {
                    localVarFormParams.append('Actions', element as any);
                })
            }

    
            if (file2 !== undefined) { 
                localVarFormParams.append('file', file2 as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {any} [file] any to upload
         * @param {string} [title] 
         * @param {string} [description] 
         * @param {string} [ageCategory] 
         * @param {Array<string>} [subjects] 
         * @param {string} [type] 
         * @param {boolean} [isPublished] 
         * @param {string} [resourceName] 
         * @param {Array<string>} [biologicalThemes] 
         * @param {Array<string>} [habitats] 
         * @param {Array<string>} [landRegions] 
         * @param {Array<string>} [threats] 
         * @param {Array<string>} [actions] 
         * @param {any} [file2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resourcePost: async (file?: any, title?: string, description?: string, ageCategory?: string, subjects?: Array<string>, type?: string, isPublished?: boolean, resourceName?: string, biologicalThemes?: Array<string>, habitats?: Array<string>, landRegions?: Array<string>, threats?: Array<string>, actions?: Array<string>, file2?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/resource`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            if (file != null) {
                localVarFormParams.append('file', file, file.name);
            }


            if (title !== undefined) { 
                localVarFormParams.append('Title', title as any);
            }
    
            if (description !== undefined) { 
                localVarFormParams.append('Description', description as any);
            }
    
            if (ageCategory !== undefined) { 
                localVarFormParams.append('AgeCategory', ageCategory as any);
            }
                if (subjects) {
                subjects.forEach((element) => {
                    localVarFormParams.append('Subjects', element as any);
                })
            }

    
            if (type !== undefined) { 
                localVarFormParams.append('Type', type as any);
            }
    
            if (isPublished !== undefined) { 
                localVarFormParams.append('IsPublished', isPublished as any);
            }
    
            if (resourceName !== undefined) { 
                localVarFormParams.append('ResourceName', resourceName as any);
            }
                if (biologicalThemes) {
                biologicalThemes.forEach((element) => {
                    localVarFormParams.append('BiologicalThemes', element as any);
                })
            }

                if (habitats) {
                habitats.forEach((element) => {
                    localVarFormParams.append('Habitats', element as any);
                })
            }

                if (landRegions) {
                landRegions.forEach((element) => {
                    localVarFormParams.append('LandRegions', element as any);
                })
            }

                if (threats) {
                threats.forEach((element) => {
                    localVarFormParams.append('Threats', element as any);
                })
            }

                if (actions) {
                actions.forEach((element) => {
                    localVarFormParams.append('Actions', element as any);
                })
            }

    
            if (file2 !== undefined) { 
                localVarFormParams.append('file', file2 as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ResourceQuery} [resourceQuery] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resourceSearchPost: async (resourceQuery?: ResourceQuery, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/resource/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(resourceQuery, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ResourceApi - functional programming interface
 * @export
 */
export const ResourceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ResourceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resourceIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Resource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resourceIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {any} [file] any to upload
         * @param {string} [title] 
         * @param {string} [description] 
         * @param {string} [ageCategory] 
         * @param {Array<string>} [subjects] 
         * @param {string} [type] 
         * @param {boolean} [isPublished] 
         * @param {string} [resourceName] 
         * @param {Array<string>} [biologicalThemes] 
         * @param {Array<string>} [habitats] 
         * @param {Array<string>} [landRegions] 
         * @param {Array<string>} [threats] 
         * @param {Array<string>} [actions] 
         * @param {any} [file2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resourceIdPut(id: string, file?: any, title?: string, description?: string, ageCategory?: string, subjects?: Array<string>, type?: string, isPublished?: boolean, resourceName?: string, biologicalThemes?: Array<string>, habitats?: Array<string>, landRegions?: Array<string>, threats?: Array<string>, actions?: Array<string>, file2?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Resource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resourceIdPut(id, file, title, description, ageCategory, subjects, type, isPublished, resourceName, biologicalThemes, habitats, landRegions, threats, actions, file2, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {any} [file] any to upload
         * @param {string} [title] 
         * @param {string} [description] 
         * @param {string} [ageCategory] 
         * @param {Array<string>} [subjects] 
         * @param {string} [type] 
         * @param {boolean} [isPublished] 
         * @param {string} [resourceName] 
         * @param {Array<string>} [biologicalThemes] 
         * @param {Array<string>} [habitats] 
         * @param {Array<string>} [landRegions] 
         * @param {Array<string>} [threats] 
         * @param {Array<string>} [actions] 
         * @param {any} [file2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resourcePost(file?: any, title?: string, description?: string, ageCategory?: string, subjects?: Array<string>, type?: string, isPublished?: boolean, resourceName?: string, biologicalThemes?: Array<string>, habitats?: Array<string>, landRegions?: Array<string>, threats?: Array<string>, actions?: Array<string>, file2?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Resource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resourcePost(file, title, description, ageCategory, subjects, type, isPublished, resourceName, biologicalThemes, habitats, landRegions, threats, actions, file2, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ResourceQuery} [resourceQuery] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resourceSearchPost(resourceQuery?: ResourceQuery, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResourceResults>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resourceSearchPost(resourceQuery, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ResourceApi - factory interface
 * @export
 */
export const ResourceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ResourceApiFp(configuration)
    return {
        /**
         * 
         * @param {ResourceApiResourceIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resourceIdGet(requestParameters: ResourceApiResourceIdGetRequest, options?: AxiosRequestConfig): AxiosPromise<Resource> {
            return localVarFp.resourceIdGet(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ResourceApiResourceIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resourceIdPut(requestParameters: ResourceApiResourceIdPutRequest, options?: AxiosRequestConfig): AxiosPromise<Resource> {
            return localVarFp.resourceIdPut(requestParameters.id, requestParameters.file, requestParameters.title, requestParameters.description, requestParameters.ageCategory, requestParameters.subjects, requestParameters.type, requestParameters.isPublished, requestParameters.resourceName, requestParameters.biologicalThemes, requestParameters.habitats, requestParameters.landRegions, requestParameters.threats, requestParameters.actions, requestParameters.file2, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ResourceApiResourcePostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resourcePost(requestParameters: ResourceApiResourcePostRequest = {}, options?: AxiosRequestConfig): AxiosPromise<Resource> {
            return localVarFp.resourcePost(requestParameters.file, requestParameters.title, requestParameters.description, requestParameters.ageCategory, requestParameters.subjects, requestParameters.type, requestParameters.isPublished, requestParameters.resourceName, requestParameters.biologicalThemes, requestParameters.habitats, requestParameters.landRegions, requestParameters.threats, requestParameters.actions, requestParameters.file2, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ResourceApiResourceSearchPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resourceSearchPost(requestParameters: ResourceApiResourceSearchPostRequest = {}, options?: AxiosRequestConfig): AxiosPromise<ResourceResults> {
            return localVarFp.resourceSearchPost(requestParameters.resourceQuery, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for resourceIdGet operation in ResourceApi.
 * @export
 * @interface ResourceApiResourceIdGetRequest
 */
export interface ResourceApiResourceIdGetRequest {
    /**
     * 
     * @type {string}
     * @memberof ResourceApiResourceIdGet
     */
    readonly id: string
}

/**
 * Request parameters for resourceIdPut operation in ResourceApi.
 * @export
 * @interface ResourceApiResourceIdPutRequest
 */
export interface ResourceApiResourceIdPutRequest {
    /**
     * 
     * @type {string}
     * @memberof ResourceApiResourceIdPut
     */
    readonly id: string

    /**
     * any to upload
     * @type {any}
     * @memberof ResourceApiResourceIdPut
     */
    readonly file?: any

    /**
     * 
     * @type {string}
     * @memberof ResourceApiResourceIdPut
     */
    readonly title?: string

    /**
     * 
     * @type {string}
     * @memberof ResourceApiResourceIdPut
     */
    readonly description?: string

    /**
     * 
     * @type {string}
     * @memberof ResourceApiResourceIdPut
     */
    readonly ageCategory?: string

    /**
     * 
     * @type {Array<string>}
     * @memberof ResourceApiResourceIdPut
     */
    readonly subjects?: Array<string>

    /**
     * 
     * @type {string}
     * @memberof ResourceApiResourceIdPut
     */
    readonly type?: string

    /**
     * 
     * @type {boolean}
     * @memberof ResourceApiResourceIdPut
     */
    readonly isPublished?: boolean

    /**
     * 
     * @type {string}
     * @memberof ResourceApiResourceIdPut
     */
    readonly resourceName?: string

    /**
     * 
     * @type {Array<string>}
     * @memberof ResourceApiResourceIdPut
     */
    readonly biologicalThemes?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof ResourceApiResourceIdPut
     */
    readonly habitats?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof ResourceApiResourceIdPut
     */
    readonly landRegions?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof ResourceApiResourceIdPut
     */
    readonly threats?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof ResourceApiResourceIdPut
     */
    readonly actions?: Array<string>

    /**
     * 
     * @type {any}
     * @memberof ResourceApiResourceIdPut
     */
    readonly file2?: any
}

/**
 * Request parameters for resourcePost operation in ResourceApi.
 * @export
 * @interface ResourceApiResourcePostRequest
 */
export interface ResourceApiResourcePostRequest {
    /**
     * any to upload
     * @type {any}
     * @memberof ResourceApiResourcePost
     */
    readonly file?: any

    /**
     * 
     * @type {string}
     * @memberof ResourceApiResourcePost
     */
    readonly title?: string

    /**
     * 
     * @type {string}
     * @memberof ResourceApiResourcePost
     */
    readonly description?: string

    /**
     * 
     * @type {string}
     * @memberof ResourceApiResourcePost
     */
    readonly ageCategory?: string

    /**
     * 
     * @type {Array<string>}
     * @memberof ResourceApiResourcePost
     */
    readonly subjects?: Array<string>

    /**
     * 
     * @type {string}
     * @memberof ResourceApiResourcePost
     */
    readonly type?: string

    /**
     * 
     * @type {boolean}
     * @memberof ResourceApiResourcePost
     */
    readonly isPublished?: boolean

    /**
     * 
     * @type {string}
     * @memberof ResourceApiResourcePost
     */
    readonly resourceName?: string

    /**
     * 
     * @type {Array<string>}
     * @memberof ResourceApiResourcePost
     */
    readonly biologicalThemes?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof ResourceApiResourcePost
     */
    readonly habitats?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof ResourceApiResourcePost
     */
    readonly landRegions?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof ResourceApiResourcePost
     */
    readonly threats?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof ResourceApiResourcePost
     */
    readonly actions?: Array<string>

    /**
     * 
     * @type {any}
     * @memberof ResourceApiResourcePost
     */
    readonly file2?: any
}

/**
 * Request parameters for resourceSearchPost operation in ResourceApi.
 * @export
 * @interface ResourceApiResourceSearchPostRequest
 */
export interface ResourceApiResourceSearchPostRequest {
    /**
     * 
     * @type {ResourceQuery}
     * @memberof ResourceApiResourceSearchPost
     */
    readonly resourceQuery?: ResourceQuery
}

/**
 * ResourceApi - object-oriented interface
 * @export
 * @class ResourceApi
 * @extends {BaseAPI}
 */
export class ResourceApi extends BaseAPI {
    /**
     * 
     * @param {ResourceApiResourceIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResourceApi
     */
    public resourceIdGet(requestParameters: ResourceApiResourceIdGetRequest, options?: AxiosRequestConfig) {
        return ResourceApiFp(this.configuration).resourceIdGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ResourceApiResourceIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResourceApi
     */
    public resourceIdPut(requestParameters: ResourceApiResourceIdPutRequest, options?: AxiosRequestConfig) {
        return ResourceApiFp(this.configuration).resourceIdPut(requestParameters.id, requestParameters.file, requestParameters.title, requestParameters.description, requestParameters.ageCategory, requestParameters.subjects, requestParameters.type, requestParameters.isPublished, requestParameters.resourceName, requestParameters.biologicalThemes, requestParameters.habitats, requestParameters.landRegions, requestParameters.threats, requestParameters.actions, requestParameters.file2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ResourceApiResourcePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResourceApi
     */
    public resourcePost(requestParameters: ResourceApiResourcePostRequest = {}, options?: AxiosRequestConfig) {
        return ResourceApiFp(this.configuration).resourcePost(requestParameters.file, requestParameters.title, requestParameters.description, requestParameters.ageCategory, requestParameters.subjects, requestParameters.type, requestParameters.isPublished, requestParameters.resourceName, requestParameters.biologicalThemes, requestParameters.habitats, requestParameters.landRegions, requestParameters.threats, requestParameters.actions, requestParameters.file2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ResourceApiResourceSearchPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResourceApi
     */
    public resourceSearchPost(requestParameters: ResourceApiResourceSearchPostRequest = {}, options?: AxiosRequestConfig) {
        return ResourceApiFp(this.configuration).resourceSearchPost(requestParameters.resourceQuery, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TaxonomyApi - axios parameter creator
 * @export
 */
export const TaxonomyApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get the immediate children of a taxon entry.
         * @param {string} wildscreenId The wildscreen id of the taxonomy entry to get the children of.
         * @param {number} [from] The index to start the results from. Defaults to 0.
         * @param {number} [size] The number of results to return. Defaults to 10.
         * @param {boolean} [getPublicationState] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        taxonomyDocumentWildscreenIdChildrenGet: async (wildscreenId: string, from?: number, size?: number, getPublicationState?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'wildscreenId' is not null or undefined
            assertParamExists('taxonomyDocumentWildscreenIdChildrenGet', 'wildscreenId', wildscreenId)
            const localVarPath = `/taxonomy/document/{wildscreenId}/children`
                .replace(`{${"wildscreenId"}}`, encodeURIComponent(String(wildscreenId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (getPublicationState !== undefined) {
                localVarQueryParameter['getPublicationState'] = getPublicationState;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a single taxonomy entry by its wildscreen id.
         * @param {string} wildscreenId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        taxonomyDocumentWildscreenIdGet: async (wildscreenId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'wildscreenId' is not null or undefined
            assertParamExists('taxonomyDocumentWildscreenIdGet', 'wildscreenId', wildscreenId)
            const localVarPath = `/taxonomy/document/{wildscreenId}`
                .replace(`{${"wildscreenId"}}`, encodeURIComponent(String(wildscreenId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get potentially filtered search results from the taxonomy index.
         * @param {TaxonRank} [taxonRank] 
         * @param {string} [kingdom] 
         * @param {string} [phylum] 
         * @param {string} [_class] 
         * @param {string} [order] 
         * @param {string} [family] 
         * @param {string} [genus] 
         * @param {string} [vernacularName] 
         * @param {string} [canonicalName] 
         * @param {boolean} [ignoreSynonyms] 
         * @param {string} [search] 
         * @param {boolean} [getPublicationState] 
         * @param {number} [size] 
         * @param {number} [from] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        taxonomySearchGet: async (taxonRank?: TaxonRank, kingdom?: string, phylum?: string, _class?: string, order?: string, family?: string, genus?: string, vernacularName?: string, canonicalName?: string, ignoreSynonyms?: boolean, search?: string, getPublicationState?: boolean, size?: number, from?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/taxonomy/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (taxonRank !== undefined) {
                localVarQueryParameter['TaxonRank'] = taxonRank;
            }

            if (kingdom !== undefined) {
                localVarQueryParameter['Kingdom'] = kingdom;
            }

            if (phylum !== undefined) {
                localVarQueryParameter['Phylum'] = phylum;
            }

            if (_class !== undefined) {
                localVarQueryParameter['Class'] = _class;
            }

            if (order !== undefined) {
                localVarQueryParameter['Order'] = order;
            }

            if (family !== undefined) {
                localVarQueryParameter['Family'] = family;
            }

            if (genus !== undefined) {
                localVarQueryParameter['Genus'] = genus;
            }

            if (vernacularName !== undefined) {
                localVarQueryParameter['VernacularName'] = vernacularName;
            }

            if (canonicalName !== undefined) {
                localVarQueryParameter['CanonicalName'] = canonicalName;
            }

            if (ignoreSynonyms !== undefined) {
                localVarQueryParameter['IgnoreSynonyms'] = ignoreSynonyms;
            }

            if (search !== undefined) {
                localVarQueryParameter['Search'] = search;
            }

            if (getPublicationState !== undefined) {
                localVarQueryParameter['GetPublicationState'] = getPublicationState;
            }

            if (size !== undefined) {
                localVarQueryParameter['Size'] = size;
            }

            if (from !== undefined) {
                localVarQueryParameter['From'] = from;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TaxonomyApi - functional programming interface
 * @export
 */
export const TaxonomyApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TaxonomyApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get the immediate children of a taxon entry.
         * @param {string} wildscreenId The wildscreen id of the taxonomy entry to get the children of.
         * @param {number} [from] The index to start the results from. Defaults to 0.
         * @param {number} [size] The number of results to return. Defaults to 10.
         * @param {boolean} [getPublicationState] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async taxonomyDocumentWildscreenIdChildrenGet(wildscreenId: string, from?: number, size?: number, getPublicationState?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TaxonomyResults>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.taxonomyDocumentWildscreenIdChildrenGet(wildscreenId, from, size, getPublicationState, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a single taxonomy entry by its wildscreen id.
         * @param {string} wildscreenId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async taxonomyDocumentWildscreenIdGet(wildscreenId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TaxonomyResults>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.taxonomyDocumentWildscreenIdGet(wildscreenId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get potentially filtered search results from the taxonomy index.
         * @param {TaxonRank} [taxonRank] 
         * @param {string} [kingdom] 
         * @param {string} [phylum] 
         * @param {string} [_class] 
         * @param {string} [order] 
         * @param {string} [family] 
         * @param {string} [genus] 
         * @param {string} [vernacularName] 
         * @param {string} [canonicalName] 
         * @param {boolean} [ignoreSynonyms] 
         * @param {string} [search] 
         * @param {boolean} [getPublicationState] 
         * @param {number} [size] 
         * @param {number} [from] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async taxonomySearchGet(taxonRank?: TaxonRank, kingdom?: string, phylum?: string, _class?: string, order?: string, family?: string, genus?: string, vernacularName?: string, canonicalName?: string, ignoreSynonyms?: boolean, search?: string, getPublicationState?: boolean, size?: number, from?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TaxonomyResults>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.taxonomySearchGet(taxonRank, kingdom, phylum, _class, order, family, genus, vernacularName, canonicalName, ignoreSynonyms, search, getPublicationState, size, from, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TaxonomyApi - factory interface
 * @export
 */
export const TaxonomyApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TaxonomyApiFp(configuration)
    return {
        /**
         * 
         * @summary Get the immediate children of a taxon entry.
         * @param {TaxonomyApiTaxonomyDocumentWildscreenIdChildrenGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        taxonomyDocumentWildscreenIdChildrenGet(requestParameters: TaxonomyApiTaxonomyDocumentWildscreenIdChildrenGetRequest, options?: AxiosRequestConfig): AxiosPromise<TaxonomyResults> {
            return localVarFp.taxonomyDocumentWildscreenIdChildrenGet(requestParameters.wildscreenId, requestParameters.from, requestParameters.size, requestParameters.getPublicationState, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a single taxonomy entry by its wildscreen id.
         * @param {TaxonomyApiTaxonomyDocumentWildscreenIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        taxonomyDocumentWildscreenIdGet(requestParameters: TaxonomyApiTaxonomyDocumentWildscreenIdGetRequest, options?: AxiosRequestConfig): AxiosPromise<TaxonomyResults> {
            return localVarFp.taxonomyDocumentWildscreenIdGet(requestParameters.wildscreenId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get potentially filtered search results from the taxonomy index.
         * @param {TaxonomyApiTaxonomySearchGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        taxonomySearchGet(requestParameters: TaxonomyApiTaxonomySearchGetRequest = {}, options?: AxiosRequestConfig): AxiosPromise<TaxonomyResults> {
            return localVarFp.taxonomySearchGet(requestParameters.taxonRank, requestParameters.kingdom, requestParameters.phylum, requestParameters._class, requestParameters.order, requestParameters.family, requestParameters.genus, requestParameters.vernacularName, requestParameters.canonicalName, requestParameters.ignoreSynonyms, requestParameters.search, requestParameters.getPublicationState, requestParameters.size, requestParameters.from, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for taxonomyDocumentWildscreenIdChildrenGet operation in TaxonomyApi.
 * @export
 * @interface TaxonomyApiTaxonomyDocumentWildscreenIdChildrenGetRequest
 */
export interface TaxonomyApiTaxonomyDocumentWildscreenIdChildrenGetRequest {
    /**
     * The wildscreen id of the taxonomy entry to get the children of.
     * @type {string}
     * @memberof TaxonomyApiTaxonomyDocumentWildscreenIdChildrenGet
     */
    readonly wildscreenId: string

    /**
     * The index to start the results from. Defaults to 0.
     * @type {number}
     * @memberof TaxonomyApiTaxonomyDocumentWildscreenIdChildrenGet
     */
    readonly from?: number

    /**
     * The number of results to return. Defaults to 10.
     * @type {number}
     * @memberof TaxonomyApiTaxonomyDocumentWildscreenIdChildrenGet
     */
    readonly size?: number

    /**
     * 
     * @type {boolean}
     * @memberof TaxonomyApiTaxonomyDocumentWildscreenIdChildrenGet
     */
    readonly getPublicationState?: boolean
}

/**
 * Request parameters for taxonomyDocumentWildscreenIdGet operation in TaxonomyApi.
 * @export
 * @interface TaxonomyApiTaxonomyDocumentWildscreenIdGetRequest
 */
export interface TaxonomyApiTaxonomyDocumentWildscreenIdGetRequest {
    /**
     * 
     * @type {string}
     * @memberof TaxonomyApiTaxonomyDocumentWildscreenIdGet
     */
    readonly wildscreenId: string
}

/**
 * Request parameters for taxonomySearchGet operation in TaxonomyApi.
 * @export
 * @interface TaxonomyApiTaxonomySearchGetRequest
 */
export interface TaxonomyApiTaxonomySearchGetRequest {
    /**
     * 
     * @type {TaxonRank}
     * @memberof TaxonomyApiTaxonomySearchGet
     */
    readonly taxonRank?: TaxonRank

    /**
     * 
     * @type {string}
     * @memberof TaxonomyApiTaxonomySearchGet
     */
    readonly kingdom?: string

    /**
     * 
     * @type {string}
     * @memberof TaxonomyApiTaxonomySearchGet
     */
    readonly phylum?: string

    /**
     * 
     * @type {string}
     * @memberof TaxonomyApiTaxonomySearchGet
     */
    readonly _class?: string

    /**
     * 
     * @type {string}
     * @memberof TaxonomyApiTaxonomySearchGet
     */
    readonly order?: string

    /**
     * 
     * @type {string}
     * @memberof TaxonomyApiTaxonomySearchGet
     */
    readonly family?: string

    /**
     * 
     * @type {string}
     * @memberof TaxonomyApiTaxonomySearchGet
     */
    readonly genus?: string

    /**
     * 
     * @type {string}
     * @memberof TaxonomyApiTaxonomySearchGet
     */
    readonly vernacularName?: string

    /**
     * 
     * @type {string}
     * @memberof TaxonomyApiTaxonomySearchGet
     */
    readonly canonicalName?: string

    /**
     * 
     * @type {boolean}
     * @memberof TaxonomyApiTaxonomySearchGet
     */
    readonly ignoreSynonyms?: boolean

    /**
     * 
     * @type {string}
     * @memberof TaxonomyApiTaxonomySearchGet
     */
    readonly search?: string

    /**
     * 
     * @type {boolean}
     * @memberof TaxonomyApiTaxonomySearchGet
     */
    readonly getPublicationState?: boolean

    /**
     * 
     * @type {number}
     * @memberof TaxonomyApiTaxonomySearchGet
     */
    readonly size?: number

    /**
     * 
     * @type {number}
     * @memberof TaxonomyApiTaxonomySearchGet
     */
    readonly from?: number
}

/**
 * TaxonomyApi - object-oriented interface
 * @export
 * @class TaxonomyApi
 * @extends {BaseAPI}
 */
export class TaxonomyApi extends BaseAPI {
    /**
     * 
     * @summary Get the immediate children of a taxon entry.
     * @param {TaxonomyApiTaxonomyDocumentWildscreenIdChildrenGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaxonomyApi
     */
    public taxonomyDocumentWildscreenIdChildrenGet(requestParameters: TaxonomyApiTaxonomyDocumentWildscreenIdChildrenGetRequest, options?: AxiosRequestConfig) {
        return TaxonomyApiFp(this.configuration).taxonomyDocumentWildscreenIdChildrenGet(requestParameters.wildscreenId, requestParameters.from, requestParameters.size, requestParameters.getPublicationState, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a single taxonomy entry by its wildscreen id.
     * @param {TaxonomyApiTaxonomyDocumentWildscreenIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaxonomyApi
     */
    public taxonomyDocumentWildscreenIdGet(requestParameters: TaxonomyApiTaxonomyDocumentWildscreenIdGetRequest, options?: AxiosRequestConfig) {
        return TaxonomyApiFp(this.configuration).taxonomyDocumentWildscreenIdGet(requestParameters.wildscreenId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get potentially filtered search results from the taxonomy index.
     * @param {TaxonomyApiTaxonomySearchGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaxonomyApi
     */
    public taxonomySearchGet(requestParameters: TaxonomyApiTaxonomySearchGetRequest = {}, options?: AxiosRequestConfig) {
        return TaxonomyApiFp(this.configuration).taxonomySearchGet(requestParameters.taxonRank, requestParameters.kingdom, requestParameters.phylum, requestParameters._class, requestParameters.order, requestParameters.family, requestParameters.genus, requestParameters.vernacularName, requestParameters.canonicalName, requestParameters.ignoreSynonyms, requestParameters.search, requestParameters.getPublicationState, requestParameters.size, requestParameters.from, options).then((request) => request(this.axios, this.basePath));
    }
}


