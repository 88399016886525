import { ClassificationParentView } from '@wildscreen/api/src/apiClients';
import { testIds } from '@wildscreen/core/src/core';
import { Tag } from '@wildscreen/ui-components/src/components/tag';
import { TTaxonomyRanks } from '@wildscreen/ui-components/src/types';
import { ClassificationKeys } from '@wildscreen/ui-components/src/types/classificationTypes';
import * as React from 'react';

import styles from './tagsReadOnly.module.scss';

export interface ITagsReadOnlyProps {
  classificationParents?: ClassificationParentView;
  taxonRank: TTaxonomyRanks;
}

export const TagsReadOnly: React.FC<ITagsReadOnlyProps> = ({ taxonRank, classificationParents }) => {
  return (
    <div
      data-testid={testIds.authenticated.views.editTaxonomy.forms.tags.readOnly(taxonRank)}
      className={styles.container}
    >
      {ClassificationKeys.flatMap((x, i) =>
        classificationParents?.[taxonRank]?.classification?.[x]?.map((tag: string, idx: string) => (
          <Tag key={tag + idx + i} classificationKey={x} content={tag} />
        ))
      ).filter(x => x)}
    </div>
  );
};
