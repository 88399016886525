import { TaxonomyEntryView } from '@wildscreen/api/src/apiClients';
import { capitalizeEachWord, routes } from '@wildscreen/core/src/core';
import { useLoadingNextTreeBranch } from '@wildscreen/core/src/hooks/useLoading';
import { LoadMoreButton } from '@wildscreen/ui-components/src/components/loadMoreButton/loadMoreButton';
import { TaxonomyTreeLeaf } from '@wildscreen/ui-components/src/components/taxonomyTreeLeaf';
import { TTaxonomyRanks } from '@wildscreen/ui-components/src/types/common';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';

import { apiHooks } from '../../api';
import { useTree } from '../../hooks/useTree';

import styles from './taxonomyTree.module.scss';

export type TTaxonomyTreeProps = React.PropsWithChildren<{
  index: number;
  isKingdom?: boolean;
  wildscreenId?: string;
  onLoadingChange?: (e: boolean) => void;
}>;

export const TaxonomyTree: React.FC<TTaxonomyTreeProps> = ({ wildscreenId, index, onLoadingChange, isKingdom }) => {
  const marginLeft = `${index * 2.625}rem`;
  const navigate = useNavigate();

  const [currentPageSize, setCurrentPageSize] = React.useState<number>(APP_CONFIG.pageSize);
  const [currentTotalTaxonomyDocuments, setCurrentTotalTaxonomyDocuments] = React.useState<number>(0);
  const [currentPage, setCurrentPage] = React.useState<number>(0);
  const [branchData, setBranchData] = React.useState<TaxonomyEntryView[]>([]);
  const [isFetching, setIsFetching] = React.useState<boolean>(false);

  const { toggleItem, openItem, setNextTreeIndexAndWildscreenId, nextWildscreenId } = useTree();

  const fetchKindomData = apiHooks.taxonomy.taxonomySearchGet.useRequest({
    parameters: { taxonRank: 'Kingdom' },
  });

  const fetchData = apiHooks.taxonomy.taxonomyDocumentWildscreenIdChildrenGet.useRequest({
    parameters: { wildscreenId, from: currentPage, size: currentPageSize, getPublicationState: true },
  });

  const loadMore = React.useCallback(
    async (pageIndex: number) => {
      setIsFetching(true);
      if (isKingdom) {
        const responseData = await fetchKindomData();
        if (responseData?.data?.results) {
          setBranchData(s => [...s, ...(responseData.data.results || [])]);
        }
      } else {
        const from = pageIndex * APP_CONFIG.pageSize;
        const responseData = await fetchData({
          from,
          size: currentPageSize,
        });
        if (currentTotalTaxonomyDocuments === 0) {
          setCurrentTotalTaxonomyDocuments(responseData.data.total);
        }

        // This is to stop retrieving extra data from the API
        const remainder = responseData.data.total - (pageIndex === 0 ? pageIndex + 1 : pageIndex) * APP_CONFIG.pageSize;
        if (remainder > 0) {
          setCurrentPageSize(remainder);
        }

        if (responseData?.data?.results) {
          setBranchData(s => [...s, ...(responseData.data.results || [])]);
        }
      }
      setIsFetching(false);
    },
    [isKingdom, fetchKindomData, fetchData, currentPageSize, currentTotalTaxonomyDocuments]
  );

  React.useEffect(() => {
    loadMore(currentPage);
    // eslint-disable-next-line react-hooks/exhaustive-deps -- go away
  }, [currentPage]);

  const { isLoadingNextTreeBranch, updateNextTreeBranchLoadingState } = useLoadingNextTreeBranch();

  React.useEffect(() => {
    if (isFetching) {
      onLoadingChange?.(true);
    } else {
      onLoadingChange?.(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- triggers only
  }, [isFetching]);

  return (
    <div className={styles.taxonomyTreeContainer}>
      {branchData?.map((item, idx) => {
        const commonName = item.vernacularNames?.[0] && capitalizeEachWord(item.vernacularNames?.[0]);
        const scientificName = item.suggestedName || '';
        const isItemOpen = openItem === item.suggestedName;
        return (
          <div key={`${item.wildscreenId}-${idx}`}>
            <TaxonomyTreeLeaf
              indentIndex={index}
              taxonRank={item.taxonRank as TTaxonomyRanks}
              commonName={commonName}
              scientificName={scientificName}
              open={isItemOpen}
              published={item.publishState?.isPublished || false}
              onClick={() => {
                setNextTreeIndexAndWildscreenId(index + 1, item.wildscreenId || '');
                toggleItem(scientificName);
              }}
              onEditClick={() => {
                navigate(routes.authenticated.admin.taxonomy.edit({ wildscreenId: item.wildscreenId || '' }));
              }}
              loading={isLoadingNextTreeBranch && isItemOpen}
            />
            {isItemOpen && (
              <>
                <TaxonomyTree
                  wildscreenId={nextWildscreenId}
                  index={index + 1}
                  onLoadingChange={updateNextTreeBranchLoadingState}
                />
              </>
            )}
          </div>
        );
      })}
      {!isFetching && currentTotalTaxonomyDocuments === 0 && branchData.length === 0 && (
        <div style={{ marginLeft }}>No results</div>
      )}
      {index !== 1 && currentTotalTaxonomyDocuments > branchData?.length && (
        <div style={{ marginLeft }}>
          <LoadMoreButton onClick={() => setCurrentPage(s => s + 1)} />
        </div>
      )}
    </div>
  );
};
