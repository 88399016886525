import { IBindingProps, Select } from '@rocketmakers/armstrong';
import { testIds, useDictionary } from '@wildscreen/core/src/core';
import * as React from 'react';

import { apiHooks } from '../../api';

interface ILibraryCreditSelectionProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- ignore armstrong types
  bind: IBindingProps<any>;
}

export const LibraryCreditSelection: React.FC<ILibraryCreditSelectionProps> = ({ bind }) => {
  const { admin } = useDictionary('en');
  const [{ data, isFetching }] = apiHooks.libraryCredit.libraryCreditSearchGet.useQuery({
    parameters: { size: 1000, from: 0 },
  });

  return (
    <Select
      data-testid={testIds.authenticated.components.libraryCreditSelection('component')}
      pending={isFetching}
      bind={bind}
      label={admin.authenticated.views.libraryCredit.label}
      options={data?.data?.results?.map(x => ({ id: x.libraryCreditId, content: x.name })) || []}
    />
  );
};
