import axios from 'axios';

import {
  ArkApi,
  AssetApi,
  ClassificationPageApi,
  Configuration as ConfigType,
  CoverApi,
  DataApi,
  LibraryCreditApi,
  ResourceApi,
  TaxonomyApi,
} from './apiClients';
import { configuration } from './baseOptions';

export function fixGeneratedClient<T>(original: T): T {
  const keys = Object.getOwnPropertyNames(Object.getPrototypeOf(original));
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- This is an unfortunate hack to resolve a scoping issue with OpenApi
  return keys.reduce((p: any, c) => {
    if (c !== 'constructor') {
      // eslint-disable-next-line no-param-reassign, @typescript-eslint/no-explicit-any -- This is an unfortunate hack to resolve a scoping issue with OpenApi
      p[c] = (...args: any) => (original as any)[c](...args);
    }
    return p;
  }, {} as T) as T;
}

export class ApiClient {
  asset: AssetApi;

  ark: ArkApi;

  classificationPage: ClassificationPageApi;

  cover: CoverApi;

  data: DataApi;

  libraryCredit: LibraryCreditApi;

  taxonomy: TaxonomyApi;

  resource: ResourceApi;

  constructor(basePath: string, config: ConfigType = configuration) {
    const customAxios = axios.create({
      maxContentLength: Infinity,
      maxBodyLength: Infinity,
    });

    this.asset = fixGeneratedClient(new AssetApi(config, basePath, customAxios));
    this.ark = fixGeneratedClient(new ArkApi(config, basePath, customAxios));
    this.classificationPage = fixGeneratedClient(new ClassificationPageApi(config, basePath, customAxios));
    this.cover = fixGeneratedClient(new CoverApi(config, basePath, customAxios));
    this.data = fixGeneratedClient(new DataApi(config, basePath, customAxios));
    this.libraryCredit = fixGeneratedClient(new LibraryCreditApi(config, basePath, customAxios));
    this.taxonomy = fixGeneratedClient(new TaxonomyApi(config, basePath, customAxios));
    this.resource = fixGeneratedClient(new ResourceApi(config, basePath, customAxios));
  }
}
