import { IValidationSchema } from '@rocketmakers/armstrong';
import { z } from 'zod';

import { ICreateNewResourceForm, IUpdateNewResourceForm } from '../typings/asset';

export const educationResourceValidationSchema: IValidationSchema<ICreateNewResourceForm | IUpdateNewResourceForm> = {
  details: {
    schema: { description: z.string().nonempty(), title: z.string().nonempty() },
  },
  tags: {
    schema: {
      ageCategory: z.string().nonempty(),
      type: z.string().nonempty(),
      subjects: {
        opts: arr => arr.min(1),
        itemSchema: z.string().nonempty(),
      },
    },
  },
};
