import * as React from 'react';

export function useLoadingNextTreeBranch(initialState = false) {
  const [isLoadingNextTreeBranch, setIsLoadingNextTreeBranch] = React.useState(initialState);

  const updateNextTreeBranchLoadingState = (loading: boolean) => {
    setIsLoadingNextTreeBranch(loading);
  };

  return { isLoadingNextTreeBranch, updateNextTreeBranchLoadingState };
}

export function useDelayedLoading(isFetching: boolean, onLoadingChange?: (loading: boolean) => void) {
  React.useEffect(() => {
    let timer: NodeJS.Timeout | undefined;
    if (isFetching) {
      onLoadingChange?.(true);
    } else {
      timer = setTimeout(() => {
        onLoadingChange?.(false);
      }, 2500);
    }
    return () => clearTimeout(timer);
  }, [isFetching, onLoadingChange]);
}
