import { Image } from '@phosphor-icons/react';
import { Checkbox, IBindingProps, Input, useChildForm } from '@rocketmakers/armstrong';
import { testIds, useDictionary } from '@wildscreen/core/src/core';
import { FileUpload, IInitialFile } from '@wildscreen/ui-components/src/components/fileUpload';
import { TextAreaInput } from '@wildscreen/ui-components/src/components/textarea/textarea';
import * as React from 'react';

import { TAssetDetails } from '../../typings/asset';
import { ExpandableSection } from '../expandableSection';
import { LibraryCreditSelection } from '../libraryCreditSelection';

import styles from './assetDetails.module.scss';

export interface IAssetDetailsProps {
  bind: IBindingProps<TAssetDetails>;
  onFileSelected: (e: File) => void;
  initialFile?: IInitialFile;
}

export const AssetDetails: React.FC<IAssetDetailsProps> = ({ bind, onFileSelected, initialFile }) => {
  const [isOpen, setIsOpen] = React.useState(true);
  const { formProp } = useChildForm(bind);
  const { admin } = useDictionary('en');

  return (
    <div className={styles.container}>
      <ExpandableSection
        colour="asset"
        title={admin.authenticated.views.assets.details.heading}
        leftLogo={<Image />}
        isOpen={isOpen}
        onToggle={() => setIsOpen(s => !s)}
      >
        <Input
          label={admin.authenticated.views.assets.details.assetId}
          type="text"
          bind={formProp('assetId').bind()}
          disabled
          data-testid={testIds.authenticated.views.uploadAsset.forms.details.input('assetId')}
        />
        <Input
          label={admin.authenticated.views.assets.details.originId}
          type="text"
          bind={formProp('originId').bind()}
          data-testid={testIds.authenticated.views.uploadAsset.forms.details.input('originId')}
        />
        <FileUpload
          initialFile={initialFile}
          onFileSelected={onFileSelected}
          data-testid={testIds.authenticated.views.uploadAsset.forms.details.input('asset')}
        />
        <Input
          required
          label={admin.authenticated.views.assets.details.title}
          type="text"
          bind={formProp('title').bind()}
          data-testid={testIds.authenticated.views.uploadAsset.forms.details.input('title')}
        />
        <TextAreaInput
          label={admin.authenticated.views.assets.details.description}
          bind={formProp('description').bind()}
          data-testid={testIds.authenticated.views.uploadAsset.forms.details.input('description')}
        />
        <Input
          required
          label={admin.authenticated.views.assets.details.credit}
          type="text"
          bind={formProp('credit').bind()}
          data-testid={testIds.authenticated.views.uploadAsset.forms.details.input('credit')}
        />
        <LibraryCreditSelection bind={formProp('libraryCreditId').bind({})} />
        <Checkbox
          label={admin.authenticated.views.assets.details.homepageGalleryWorthy}
          data-testid={testIds.authenticated.views.uploadAsset.forms.details.input('ishomepageworthy')}
          bind={formProp('isHomepageWorthy').bind()}
        />
      </ExpandableSection>
    </div>
  );
};
