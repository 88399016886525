import * as React from 'react';

export const LessonIcon: React.FC<React.HTMLAttributes<SVGElement>> = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width="40" height="42" viewBox="0 0 40 42" fill="none" {...props}>
    <path
      d="M38.6137 3.93966H21.1441V2.14275C21.1441 1.49516 20.6191 0.970215 19.9716 0.970215C19.3241 0.970215 18.7991 1.49516 18.7991 2.14275V3.93966H1.38647C0.622027 3.93966 0 4.56161 0 5.32613V8.94995C0 9.71447 0.621949 10.3365 1.38647 10.3365H2.0976V28.7882C2.0976 30.7276 3.67542 32.3054 5.61474 32.3054H18.799V34.6234L15.215 39.083C14.8095 39.5878 14.8898 40.3258 15.3945 40.7315C15.6111 40.9055 15.8705 40.9901 16.1282 40.9901C16.4713 40.9901 16.8113 40.8402 17.043 40.552L19.9715 36.908L22.9002 40.552C23.1319 40.8403 23.4718 40.9901 23.8149 40.9901C24.0726 40.9901 24.3321 40.9055 24.5486 40.7315C25.0533 40.3258 25.1336 39.5878 24.728 39.083L21.144 34.6234V32.3053H34.3283C36.2677 32.3053 37.8454 30.7275 37.8454 28.7882V10.3364H38.6135C39.378 10.3364 40 9.71447 40 8.94988V5.32605C40.0002 4.56161 39.3782 3.93966 38.6137 3.93966ZM35.5005 28.7882C35.5005 29.4344 34.9747 29.9603 34.3284 29.9603H5.61482C4.96848 29.9603 4.44267 29.4345 4.44267 28.7882V10.3364H35.5005V28.7882ZM37.6552 7.99143H2.34507V6.28465H37.6552V7.99143Z"
      fill="black"
    />
    <path
      d="M15.0346 22.5802L12.7497 15.1261C12.6843 14.9226 12.5358 14.7657 12.3037 14.6557C12.0715 14.5457 11.8045 14.4908 11.5033 14.4908C11.2019 14.4908 10.9351 14.5457 10.703 14.6557C10.4708 14.7657 10.3221 14.9226 10.257 15.1261L7.98396 22.5802C7.96755 22.6454 7.9595 22.6901 7.9595 22.7146C7.95942 22.9266 8.09175 23.1098 8.35657 23.2645C8.62123 23.4193 8.89605 23.4966 9.18135 23.4966C9.53152 23.4966 9.73928 23.3786 9.80455 23.1422L10.22 21.6148H12.7983L13.2137 23.1422C13.2789 23.3786 13.4865 23.4966 13.8369 23.4966C14.1219 23.4966 14.3969 23.4193 14.6617 23.2645C14.9264 23.1098 15.0589 22.9266 15.0589 22.7146C15.0591 22.6901 15.0509 22.6455 15.0346 22.5802ZM10.6111 20.1486L11.503 16.8737L12.3951 20.1486H10.6111Z"
      fill="black"
    />
    <path
      d="M23.1121 23.232C22.9002 22.9429 22.5867 22.7208 22.1712 22.566C22.8635 22.2648 23.2099 21.6251 23.2099 20.6476C23.2099 19.1976 22.3952 18.4724 20.7659 18.4724H18.2609C18.0328 18.4724 17.8372 18.5234 17.6744 18.6251C17.5113 18.7271 17.43 18.8717 17.43 19.059V26.8307C17.43 26.9855 17.5052 27.1199 17.6561 27.2339C17.8067 27.3481 18.0084 27.405 18.2609 27.405H20.9737C21.7231 27.405 22.3198 27.1953 22.7639 26.7757C23.2078 26.3562 23.4298 25.6902 23.4298 24.7777V24.5211C23.4297 23.9511 23.3238 23.5212 23.1121 23.232ZM19.324 20.1343H20.607C20.8269 20.1343 20.9981 20.2198 21.1202 20.3909C21.2423 20.562 21.3035 20.7617 21.3035 20.9897C21.3035 21.2178 21.2424 21.4154 21.1202 21.5823C20.998 21.7494 20.8269 21.8328 20.607 21.8328H19.324V20.1343ZM21.5235 24.6189C21.5235 25.3684 21.1975 25.7431 20.5459 25.7431H19.324V23.2993H20.546C21.1975 23.2993 21.5236 23.6741 21.5236 24.4235V24.6189H21.5235Z"
      fill="black"
    />
    <path
      d="M30.9822 18.7379C30.8355 18.6117 30.587 18.5485 30.2368 18.5485C29.6421 18.5485 29.3285 18.74 29.2959 19.1227C29.2795 19.2938 29.2612 19.4282 29.241 19.526C29.2205 19.6237 29.1778 19.748 29.1126 19.8987C29.0474 20.0495 28.9395 20.1614 28.7888 20.2347C28.638 20.308 28.4486 20.3447 28.2206 20.3447C27.4793 20.3447 27.1085 19.9374 27.1085 19.1227V15.8844C27.1085 15.0699 27.475 14.6624 28.2083 14.6624C28.9088 14.6624 29.2715 15.029 29.2959 15.7622C29.3203 16.1452 29.6381 16.3365 30.2491 16.3365C30.5912 16.3365 30.8355 16.2735 30.9822 16.1471C31.1288 16.0209 31.2021 15.7826 31.2021 15.4322C31.2021 14.6747 30.9149 14.0801 30.3407 13.6481C29.7663 13.2164 29.031 13.0005 28.135 13.0005C27.2633 13.0005 26.5566 13.2327 26.0149 13.697C25.473 14.1613 25.2023 14.8906 25.2023 15.8843V19.1225C25.2026 20.1167 25.4733 20.8457 26.0151 21.31C26.5568 21.7743 27.2634 22.0065 28.1352 22.0065C29.0313 22.0065 29.7665 21.7785 30.3408 21.3223C30.9152 20.8662 31.2023 20.2429 31.2023 19.4527C31.2021 19.1026 31.1288 18.8643 30.9822 18.7379Z"
      fill="black"
    />
  </svg>
);
