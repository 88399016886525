import { CaretUp } from '@phosphor-icons/react';
import { testIds } from '@wildscreen/core/src/core';
import * as React from 'react';

import styles from './expandableSection.module.scss';

type TExpandableSectionColour = 'taxonomy-details' | 'taxonomy' | 'taxonomy-characteristics' | 'asset';

export type TExpandableSectionProps = React.PropsWithChildren<{
  title: string;
  isOpen: boolean;
  leftLogo: React.ReactNode;
  onToggle: () => void;
  colour?: TExpandableSectionColour;
  rightContent?: React.ReactNode;
}>;

export const ExpandableSection: React.FC<TExpandableSectionProps> = ({
  isOpen,
  onToggle,
  leftLogo,
  children,
  title,
  colour = 'species-details',
  rightContent,
}) => {
  return (
    <div data-testid={testIds.authenticated.components.expandableSection(title, isOpen)} className={styles.container}>
      <div
        data-colour={colour}
        role="button"
        tabIndex={0}
        onKeyDown={e => e.code === 'o' && onToggle()}
        onClick={onToggle}
        className={styles.sectionHeaderBar}
      >
        <div className={styles.sectionHeaderBarLeftIcon}>{leftLogo}</div>
        <div className={styles.sectionHeaderBarTitle}>
          {title} {rightContent && rightContent}
        </div>

        <div data-open={isOpen} className={styles.sectionHeaderBarIcon}>
          <CaretUp aria-hidden="true" />
        </div>
      </div>
      <div data-open={isOpen} className={styles.sectionContent}>
        {children}
      </div>
    </div>
  );
};
