import { Upload } from '@phosphor-icons/react';
import { Button, Spinner } from '@rocketmakers/armstrong';
import { routes, testIds, useDictionary } from '@wildscreen/core/src/core';
import { useQueryString } from '@wildscreen/core/src/hooks/useQueryString';
import { Pagination } from '@wildscreen/ui-components/src/components/pagination/pagination';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';

import { apiHooks } from '../../api';
import { AuthenticatedView } from '../../components/authenticatedView';
import { LibraryContainer } from '../../components/libraryContainer';

import styles from './libraryCredit.module.scss';

export const LibraryCreditView: React.FC = () => {
  const { admin } = useDictionary('en');

  const { search, setQueryString } = useQueryString();
  const navigate = useNavigate();

  React.useEffect(() => {
    setQueryString({ from: '0', p: '1' });
    // eslint-disable-next-line react-hooks/exhaustive-deps -- setting the initial query string
  }, []);

  const handlePageChange = React.useCallback(
    (pageNumber: number) => {
      let from = '0';
      if (pageNumber !== 1) {
        from = ((pageNumber - 1) * APP_CONFIG.assetsPerPage).toString();
      }
      setQueryString({ from, p: pageNumber.toString() });
    },
    [setQueryString]
  );

  const [{ data, isFetching: isFetchingLibraries }] = apiHooks.libraryCredit.libraryCreditSearchGet.useQuery({
    parameters: {
      size: APP_CONFIG.assetsPerPage,
      from: Number(search?.from) || 0,
    },
  });
  return (
    <AuthenticatedView
      data-testid={testIds.authenticated.views.libraryCredit.view}
      heading={admin.authenticated.views.libraryCredit.heading}
      headerContent={
        <div className={styles.header}>
          <div className={styles.details}>
            <div className={styles.upload}>
              <Button
                onClick={() => navigate(routes.authenticated.admin.libraryCredit.create())}
                data-style="black"
                leftOverlay={<Upload />}
              >
                {admin.authenticated.views.libraryCredit.action('Add')}
              </Button>
            </div>
          </div>
        </div>
      }
    >
      <div>
        {isFetchingLibraries ? (
          <div className={styles.loading}>
            <Spinner fillContainer />
            <h3>{admin.authenticated.views.libraryCredit.loading}</h3>
          </div>
        ) : (
          data?.data?.results?.map(library => <LibraryContainer key={library.libraryCreditId} data={library} />)
        )}
        <Pagination
          currentPage={search.p ? Number(search.p) : 1}
          itemsPerPage={APP_CONFIG.assetsPerPage}
          totalItems={data?.data?.total || 0}
          onPageChange={handlePageChange}
        />
      </div>
    </AuthenticatedView>
  );
};
