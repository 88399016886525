import * as React from 'react';

import { ViewHeader } from '../viewHeader';

import styles from './authenticatedView.module.scss';

export type TAuthenticatedViewProps = React.PropsWithChildren<{
  heading?: string;
  debug?: boolean;
  headerContent?: React.ReactNode;
}>;

export const AuthenticatedView: React.FC<TAuthenticatedViewProps> = ({
  heading,
  debug,
  children,
  headerContent,
  ...rest
}) => {
  return (
    <div className={styles.authenticatedContainer} {...rest}>
      <ViewHeader header={heading}>{(headerContent && headerContent) || null}</ViewHeader>
      <div className={styles.contentContainer} data-testid="content">
        {debug ? <div>{`Hello I'm the ${heading} page`}</div> : null}
        {children}
      </div>
    </div>
  );
};
