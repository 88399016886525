import * as React from 'react';

export const WorksheetIcon: React.FC<React.HTMLAttributes<SVGElement>> = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width="41" height="41" viewBox="0 0 41 41" fill="none" {...props}>
    <path
      d="M39.4694 12.6451C38.0986 11.2742 35.868 11.2743 34.4968 12.6454C34.075 13.0675 31.3823 15.7625 30.9709 16.1742V6.88679C30.9709 5.94766 30.6052 5.06479 29.9411 4.40076L26.6264 1.08591C25.9623 0.421803 25.0794 0.0561523 24.1403 0.0561523H4.01587C2.07722 0.0561523 0.5 1.63337 0.5 3.57203V36.5431C0.5 38.4818 2.07722 40.059 4.01587 40.059H27.455C29.3937 40.059 30.9709 38.4818 30.9709 36.5431V26.1229L39.4693 17.6173C40.8434 16.2433 40.8437 14.0192 39.4694 12.6451ZM23.9392 2.40007C24.1607 2.40007 24.5873 2.36171 24.969 2.7433L28.2838 6.05814C28.6557 6.43012 28.6271 6.83772 28.6271 7.0879H23.9392V2.40007ZM28.627 36.5431C28.627 37.1893 28.1013 37.7151 27.455 37.7151H4.01587C3.36966 37.7151 2.84392 37.1893 2.84392 36.5431V3.57203C2.84392 2.92581 3.36966 2.40007 4.01587 2.40007H21.5952V8.25986C21.5952 8.90709 22.12 9.43181 22.7672 9.43181H28.627V18.5202C28.627 18.5202 25.1694 21.9807 25.1692 21.9808L23.5126 23.6375C23.384 23.7661 23.287 23.923 23.2295 24.0956L21.572 29.0678C21.4316 29.4889 21.5413 29.9533 21.8552 30.2671C22.1695 30.5814 22.634 30.6905 23.0545 30.5503L28.0267 28.8929C28.1993 28.8354 28.3562 28.7384 28.4848 28.6097L28.627 28.4675V36.5431ZM25.9987 24.4662L27.6561 26.1236L27.023 26.7567L24.5369 27.5854L25.3656 25.0992L25.9987 24.4662ZM29.3131 24.4658L27.6557 22.8085C28.5383 21.9252 32.458 18.0021 33.2893 17.1701L34.9467 18.8275L29.3131 24.4658ZM37.8116 15.9602L36.6035 17.1694L34.9461 15.512L36.1546 14.3025C36.6118 13.8455 37.3552 13.8456 37.8121 14.3025C38.2689 14.7595 38.2713 15.5004 37.8116 15.9602Z"
      fill="black"
    />
    <path
      d="M22.7672 11.7757H6.35979C5.71256 11.7757 5.18783 12.3005 5.18783 12.9477C5.18783 13.5949 5.71256 14.1196 6.35979 14.1196H22.7672C23.4144 14.1196 23.9392 13.5949 23.9392 12.9477C23.9392 12.3005 23.4144 11.7757 22.7672 11.7757Z"
      fill="black"
    />
    <path
      d="M18.0794 16.4636H6.35979C5.71256 16.4636 5.18783 16.9883 5.18783 17.6355C5.18783 18.2828 5.71256 18.8075 6.35979 18.8075H18.0794C18.7266 18.8075 19.2513 18.2828 19.2513 17.6355C19.2513 16.9883 18.7266 16.4636 18.0794 16.4636Z"
      fill="black"
    />
    <path
      d="M18.0794 21.1514H6.35979C5.71256 21.1514 5.18783 21.6761 5.18783 22.3233C5.18783 22.9706 5.71256 23.4953 6.35979 23.4953H18.0794C18.7266 23.4953 19.2513 22.9706 19.2513 22.3233C19.2513 21.6761 18.7266 21.1514 18.0794 21.1514Z"
      fill="black"
    />
    <path
      d="M18.0794 25.8392H6.35979C5.71256 25.8392 5.18783 26.3639 5.18783 27.0112C5.18783 27.6584 5.71256 28.1831 6.35979 28.1831H18.0794C18.7266 28.1831 19.2513 27.6584 19.2513 27.0112C19.2513 26.3639 18.7266 25.8392 18.0794 25.8392Z"
      fill="black"
    />
    <path
      d="M22.7672 33.0272H15.7354C15.0882 33.0272 14.5635 33.552 14.5635 34.1992C14.5635 34.8464 15.0882 35.3711 15.7354 35.3711H22.7672C23.4144 35.3711 23.9392 34.8464 23.9392 34.1992C23.9392 33.552 23.4144 33.0272 22.7672 33.0272Z"
      fill="black"
    />
  </svg>
);
