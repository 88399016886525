import { testIds, useDictionary } from '@wildscreen/core/src/core';
import { TaxonomyTreeLeaf } from '@wildscreen/ui-components/src/components/taxonomyTreeLeaf';
import * as React from 'react';

import { AuthenticatedView } from '../../components/authenticatedView';
import { TaxonomyTree } from '../../components/taxonomyTree';
import { useTree } from '../../hooks/useTree';

export const TaxonomyView: React.FC = () => {
  const { admin } = useDictionary('en');

  const { openItem, toggleItem } = useTree();

  const [isFetching, setIsFetching] = React.useState<boolean>(false);

  return (
    <AuthenticatedView
      data-testid={testIds.authenticated.views.taxonomy.view}
      heading={admin.authenticated.views.taxonomy.heading}
    >
      <TaxonomyTreeLeaf
        loading={isFetching}
        indentIndex={0}
        taxonRank="Domain"
        scientificName="Eukaryota"
        open={openItem === 'Eukaryota'}
        onClick={() => toggleItem('Eukaryota')}
      >
        <TaxonomyTree isKingdom={true} index={1} onLoadingChange={setIsFetching} />
      </TaxonomyTreeLeaf>
      <TaxonomyTreeLeaf
        indentIndex={0}
        taxonRank="Domain"
        scientificName="Bacteria"
        open={openItem === 'Bacteria'}
        onClick={() => toggleItem('Bacteria')}
      />
      <TaxonomyTreeLeaf
        indentIndex={0}
        taxonRank="Domain"
        scientificName="Archaea"
        open={openItem === 'Archaea'}
        onClick={() => toggleItem('Archaea')}
      />
    </AuthenticatedView>
  );
};
