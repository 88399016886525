import { Button, Input, useForm } from '@rocketmakers/armstrong';
import { routes, useDictionary } from '@wildscreen/core/src/core';
import { testIds } from '@wildscreen/core/src/core/testIds';
import { useOnKeyPress } from '@wildscreen/core/src/hooks/useOnKeyPress';
import { AuthView } from '@wildscreen/ui-components/src/components/authView';
import * as React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { z } from 'zod';

import { TResetPasswordParameters, useAuth } from '../../contexts';

import styles from './auth.module.scss';

type TExtendResetPasswordParameters = TResetPasswordParameters & { passwordConfirmation?: string };

export const ResetPasswordView: React.FC = () => {
  const { resetPassword, setIsFetching } = useAuth();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  const [success, setSuccess] = React.useState<boolean>(false);

  const parsedCode = searchParams.get('code') || '';
  const parsedUsername = searchParams.get('username') || '';
  const [resetPasswordError, setResetPasswordError] = React.useState<string | undefined>(undefined);
  const { admin } = useDictionary('en');
  const { formProp, formState, validate } = useForm<TExtendResetPasswordParameters>(
    {
      username: parsedUsername,
      code: parsedCode,
      password: undefined,
      passwordConfirmation: undefined,
    },
    {
      validationMode: 'both',
      validationSchema: {
        username: z.string().email(admin.unauthenticated.views.resetPassword.emailValidation),
        password: z.string().min(1, admin.unauthenticated.views.resetPassword.passwordValidation),
        code: z.string().min(6, admin.unauthenticated.views.resetPassword.codeValidation),
      },
    }
  );

  const onSubmit = React.useCallback(
    async (e?: React.FormEvent<HTMLFormElement>) => {
      e?.preventDefault();
      const { username, password, passwordConfirmation, code } = formState as TExtendResetPasswordParameters;
      if (password !== passwordConfirmation) {
        formProp('passwordConfirmation').addValidationError("Passwords don't match");
        return;
      }

      if (validate() && username && password && code) {
        try {
          await resetPassword({ username, password, code });
          setSuccess(true);
          setTimeout(() => {
            navigate(routes.unauthenticated.login());
          }, 2500);
          // eslint-disable-next-line @typescript-eslint/no-explicit-any -- ignore
        } catch (error: any) {
          setIsFetching(false);
          switch (error.code) {
            case 'NotAuthorizedException':
              setTimeout(() => {
                setResetPasswordError(undefined);
              }, 5000);
              setResetPasswordError(error.message);
              break;
            default:
              break;
          }
        }
      }
    },
    [formState, validate, formProp, resetPassword, navigate, setIsFetching]
  );

  useOnKeyPress('Enter', () => onSubmit());

  return (
    <AuthView data-testid={testIds.unauthenticated.views.resetPassword.view}>
      <div className={styles.authContainer}>
        <h1>
          {success
            ? admin.unauthenticated.views.resetPassword.successMessage
            : admin.unauthenticated.views.resetPassword.title}
        </h1>
        {success ? null : (
          <form onSubmit={onSubmit}>
            <Input
              required
              disabled
              bind={formProp('username').bind()}
              validationMode="message"
              data-testid={testIds.unauthenticated.views.resetPassword.email}
              label={admin.unauthenticated.views.resetPassword.email}
            />
            <Input
              required
              bind={formProp('password').bind()}
              validationMode="message"
              type="password"
              data-testid={testIds.unauthenticated.views.resetPassword.password}
              label={admin.unauthenticated.views.resetPassword.newPassword}
            />
            <Input
              required
              bind={formProp('passwordConfirmation').bind()}
              validationMode="message"
              type="password"
              data-testid={testIds.unauthenticated.views.resetPassword.confirmPassword}
              label={admin.unauthenticated.views.resetPassword.confirmNewPassword}
            />
            <div className={styles.failed}>
              {resetPasswordError && (
                <span data-testid={testIds.unauthenticated.views.resetPassword.resetPasswordFailed}>
                  {resetPasswordError}
                </span>
              )}
            </div>
            <Button
              data-style="yellow"
              type="submit"
              data-testid={testIds.unauthenticated.views.resetPassword.resetPasswordButton}
            >
              {admin.unauthenticated.views.resetPassword.resetPasswordButton}
            </Button>
          </form>
        )}
      </div>
    </AuthView>
  );
};
