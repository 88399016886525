import { Button, Spinner } from '@rocketmakers/armstrong';
import * as React from 'react';

import styles from './loadMoreButton.module.scss';

export interface ILoadMoreButtonProps {
  isFetching?: boolean;
  onClick?: () => void;
}

export const LoadMoreButton: React.FC<ILoadMoreButtonProps> = ({ onClick, isFetching }) => {
  return (
    <Button
      rightOverlay={isFetching ? <Spinner /> : undefined}
      className={styles.loadMoreButton}
      onClick={e => {
        e.stopPropagation();
        onClick?.();
      }}
      type="submit"
    >
      Load More
    </Button>
  );
};
