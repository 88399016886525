import { Upload } from '@phosphor-icons/react';
import { Button, Input, useForm, useToast } from '@rocketmakers/armstrong';
import { LibraryCreditApiLibraryCreditPostRequest } from '@wildscreen/api/src/apiClients';
import { routes, testIds, useDictionary } from '@wildscreen/core/src/core';
import { FileUpload } from '@wildscreen/ui-components/src/components/fileUpload';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { z } from 'zod';

import { apiHooks } from '../../api';
import { AuthenticatedView } from '../../components/authenticatedView';

export const CreateLibraryCreditView: React.FC = () => {
  const { admin } = useDictionary('en');

  const dispatch = useToast();
  const [selectedFile, setSelectedFile] = React.useState<File | null>(null);

  const navigate = useNavigate();

  const { formProp, formState } = useForm<LibraryCreditApiLibraryCreditPostRequest>(
    {
      newLibraryName: '',
      url: '',
    },
    {
      validationMode: 'both',
      validationSchema: {
        newLibraryName: z.string().min(1).max(255),
        url: z.string().url().optional(),
      },
    }
  );

  const [createLibraryCredit, { isFetching }] = apiHooks.libraryCredit.libraryCreditPost.useMutation();

  const onSubmit = React.useCallback(
    async (e?: React.FormEvent<HTMLFormElement>) => {
      e?.preventDefault();

      let createLibraryCreditRequest: LibraryCreditApiLibraryCreditPostRequest = {
        newLibraryName: formState?.newLibraryName,
        url: formState?.url,
      };

      if (selectedFile) {
        createLibraryCreditRequest = {
          ...createLibraryCreditRequest,
          file: selectedFile,
        };
      }
      try {
        const createdLibrary = await createLibraryCredit(createLibraryCreditRequest);
        dispatch({
          title: admin.authenticated.views.libraryCredit.success('added'),
        });

        setTimeout(() => {
          navigate(
            routes.authenticated.admin.libraryCredit.edit({ libraryCreditId: createdLibrary.data.libraryCreditId })
          );
        }, 2500);
      } catch (error) {
        dispatch({
          title: admin.authenticated.views.libraryCredit.error('create', JSON.stringify(error)),
        });
      }
    },
    [
      formState?.newLibraryName,
      formState?.url,
      selectedFile,
      createLibraryCredit,
      dispatch,
      admin.authenticated.views.libraryCredit,
      navigate,
    ]
  );

  return (
    <AuthenticatedView
      data-testid={testIds.authenticated.views.libraryCredit.view}
      heading={admin.authenticated.views.libraryCredit.action('Add')}
    >
      <form onSubmit={onSubmit}>
        <Input
          label={admin.authenticated.views.libraryCredit.libraryName}
          required
          type="text"
          bind={formProp('newLibraryName').bind()}
        />
        <Input label={admin.authenticated.views.libraryCredit.libraryUrl} type="text" bind={formProp('url').bind()} />
        <FileUpload
          onFileSelected={setSelectedFile}
          data-testid={testIds.authenticated.views.uploadAsset.forms.details.input('asset')}
          entityType="Library Credit"
        />
        <Button
          type="submit"
          onClick={() => navigate(routes.authenticated.admin.libraryCredit.create())}
          data-style="seaweed"
          leftOverlay={<Upload />}
          pending={isFetching}
        >
          {admin.authenticated.views.libraryCredit.action('Add')}
        </Button>
      </form>
    </AuthenticatedView>
  );
};
