import { PencilSimpleLine } from '@phosphor-icons/react';
import { LibraryCredit } from '@wildscreen/api/src/apiClients';
import { routes } from '@wildscreen/core/src/core';
import * as React from 'react';
import { Link } from 'react-router-dom';

import styles from './libraryContainer.module.scss';

export interface ILibraryContainerProps {
  data?: Omit<LibraryCredit, 'creationTimestamp' | 'creationUserId' | 'updatedUserId' | 'updatedTimestamp'>;
}

export const LibraryContainer: React.FC<ILibraryContainerProps> = ({ data }) => {
  const [hasImage, setHasImage] = React.useState(!!data?.libraryCreditLocation);
  return (
    <div className={styles.container}>
      {hasImage ? (
        <img onError={() => setHasImage(false)} src={data?.libraryCreditLocation} alt={data?.name} /> // maxwidth?
      ) : (
        <div className={styles.noImage} />
      )}
      <div className={styles.rowInformation}>
        <div className={styles.rowType}>{data?.name}</div>
        <div className={styles.content} aria-label={data?.url}>
          <div>{data?.url}</div>
        </div>
      </div>
      <div className={styles.actions}>
        <Link
          to={routes.authenticated.admin.libraryCredit.edit({ libraryCreditId: data?.libraryCreditId ?? '' })}
          className={styles.edit}
        >
          <PencilSimpleLine size={25} />
        </Link>
      </div>
    </div>
  );
};
