import { Auth } from '@aws-amplify/auth';
import { ApiHooksStore } from '@rocketmakers/api-hooks';
import { ToastProvider } from '@rocketmakers/armstrong';
import * as Sentry from '@sentry/react';
import { LanguageContextProvider } from '@wildscreen/core/src/context/dictionary';
import * as React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import { AuthContextProvider } from './contexts';
import { AppContextProvider } from './contexts/appContext';
import { Routing } from './routing';

import '@rocketmakers/armstrong/css';
import '@wildscreen/ui-components/src/theme/theme.scss';
import './theme/theme.scss';

Auth.configure({
  Auth: {
    // REQUIRED - Amazon Cognito Region
    region: APP_CONFIG.region,

    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: APP_CONFIG.cognitoUserPoolId,

    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: APP_CONFIG.cognitoClientId,
  },
});

Sentry.init({
  environment: APP_CONFIG.environment,
  dsn: APP_CONFIG.adminSentryDsn,
  integrations: [
    new Sentry.BrowserTracing({
      tracePropagationTargets: [],
    }),
    new Sentry.Replay(),
  ],
  tracesSampleRate: 0.1,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 0.1,
});

async function render() {
  const rootElementId = 'root';
  const container = document.getElementById(rootElementId);
  if (!container) {
    throw new Error(`Root element ${rootElementId} not found in page`);
  }
  const root = createRoot(container);
  root.render(
    <BrowserRouter>
      <ToastProvider duration={5000} position="top-right">
        <AuthContextProvider>
          <ApiHooksStore.Provider>
            <AppContextProvider>
              <LanguageContextProvider>
                <Routing />
              </LanguageContextProvider>
            </AppContextProvider>
          </ApiHooksStore.Provider>
        </AuthContextProvider>
      </ToastProvider>
    </BrowserRouter>
  );
}

render();
