import { Configuration } from './apiClients';
import { AuthTokenStore } from './localStorage/tokenStore';

class BaseOptions {
  // eslint-disable-next-line class-methods-use-this -- ignore
  public get headers(): { [key: string]: string } {
    const accessToken = AuthTokenStore.get();
    if (accessToken) {
      return { Authorization: `Bearer ${accessToken}` };
    }
    return {};
  }
}

const baseOptions = new BaseOptions();

export const configuration = new Configuration({ baseOptions });
