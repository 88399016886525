import * as React from 'react';

import styles from './wildscreenImage.module.scss';

export interface IWildscreenImageProps {
  url: string;
  alt: string;
  size?: 'small' | 'medium' | 'large' | 'xlarge' | 'xxlarge' | 'uncapped';
  onError?: () => void;
}

export const WildscreenImage: React.FC<IWildscreenImageProps> = ({ url, alt, onError, size = 'large' }) => {
  const [isLoading, setIsLoading] = React.useState<boolean>(true);

  React.useEffect(() => {
    let timeout: NodeJS.Timeout;
    const image = new Image();

    image.onload = () => {
      timeout = setTimeout(() => setIsLoading(false), 750);
    };

    image.src = url;
    return () => {
      setIsLoading(false);
      clearTimeout(timeout);
    };
  }, [onError, url]);

  // maxWidth?
  return (
    <div data-size={size} className={styles.image} data-is-loading={isLoading}>
      <img onError={() => onError?.()} loading="lazy" src={url} alt={alt} />
    </div>
  );
};
