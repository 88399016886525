import * as React from 'react';

import { ActivityIcon } from './activity';
import { LessonIcon } from './lesson';
import { QuizIcon } from './quiz';
import { WorksheetIcon } from './worksheet';

interface IProps extends React.HTMLAttributes<SVGElement> {
  resourceType?: string;
}

export const EducationResourceIcon: React.FC<IProps> = ({ resourceType, ...props }) => {
  switch (resourceType) {
    case 'Worksheet':
      return <WorksheetIcon {...props} />;
    case 'Activity':
      return <ActivityIcon {...props} />;
    case 'Quiz':
      return <QuizIcon {...props} />;
    case 'Lesson':
      return <LessonIcon {...props} />;
    default:
      return null;
  }
};
