import { CaretUp, PencilSimpleLine } from '@phosphor-icons/react';
import { Spinner } from '@rocketmakers/armstrong';
import { TaxonRank } from '@wildscreen/api/src/apiClients';
import { testIds } from '@wildscreen/core/src/core/testIds';
import * as React from 'react';

import { TTaxonomyRanks } from '../../types';
import { PublishButton } from '../publishButton';

import styles from './taxonomyTreeLeaf.module.scss';

type TRenderPosition = 'inline' | 'below';

export type TTaxonomyTreeLeafProps = React.PropsWithChildren<{
  taxonRank: TTaxonomyRanks;
  renderChildrenPosition?: TRenderPosition;
  open: boolean;
  commonName?: string;
  hideOpenIcon?: boolean;
  published?: boolean;
  indentIndex: number;
  scientificName: string;
  loading?: boolean;
  onClick?: () => void;
  onEditClick?: () => void;
  onPublishClick?: () => void;
}>;

export const TaxonomyTreeLeaf: React.FC<TTaxonomyTreeLeafProps> = ({
  taxonRank,
  open,
  commonName,
  indentIndex,
  hideOpenIcon,
  onClick,
  onEditClick,
  published,
  onPublishClick,
  scientificName,
  children,
  loading,
  renderChildrenPosition = 'below',
}) => {
  const openState = taxonRank === 'Species' ? false : open;

  const openTreeItem = React.useCallback(() => {
    if (taxonRank !== 'Species') {
      onClick?.();
    }
  }, [onClick, taxonRank]);

  return (
    <>
      <div
        data-testid={testIds.authenticated.components.taxonomicRow(taxonRank, scientificName, openState)}
        onKeyDown={e => e.code === 'Enter' && taxonRank !== 'Species' && openTreeItem()}
        onClick={openTreeItem}
        data-on-click={!!onClick}
        data-open={openState}
        className={styles.container}
        role="button"
        tabIndex={0}
        data-multiplier={indentIndex}
        aria-label={`Toggle ${taxonRank} ${openState ? 'collapse' : 'expand'}`}
      >
        {loading ? (
          <div className={styles.loadingIcon} data-hide={taxonRank === 'Species' || hideOpenIcon}>
            <Spinner />
          </div>
        ) : (
          <div data-hide={taxonRank === 'Species' || hideOpenIcon} data-open={openState} className={styles.icon}>
            <CaretUp aria-hidden="true" />
          </div>
        )}
        <div className={styles.rowInformation}>
          <div aria-label={taxonRank} className={styles.rowType}>
            {taxonRank}
          </div>
          <div className={styles.content} aria-label={scientificName}>
            <div>
              {scientificName} {commonName && <span>{`(${commonName})`}</span>}
            </div>
            {renderChildrenPosition === 'inline' && <div className={styles.inlineChildren}>{children}</div>}
          </div>
        </div>
        <div className={styles.actions}>
          {taxonRank === TaxonRank.Species && published !== undefined && (
            <PublishButton published={published} onClick={onPublishClick} />
          )}
          <div
            onClick={e => {
              e.stopPropagation();
              onEditClick?.();
            }}
            role="button"
            tabIndex={0}
            onKeyDown={e => e.code === 'e' && onEditClick?.()}
            className={styles.edit}
            aria-label={`Edit ${taxonRank}`}
          >
            <PencilSimpleLine size={25} />
          </div>
        </div>
      </div>
      {renderChildrenPosition === 'below' && openState ? children : null}
    </>
  );
};
