import { Image } from '@phosphor-icons/react';
import { IBindingProps, Input, useChildForm } from '@rocketmakers/armstrong';
import { testIds, useDictionary } from '@wildscreen/core/src/core';
import { FileUpload, IInitialFile } from '@wildscreen/ui-components/src/components/fileUpload';
import { TextAreaInput } from '@wildscreen/ui-components/src/components/textarea/textarea';
import * as React from 'react';

import { TResourceDetails } from '../../typings/asset';
import { ExpandableSection } from '../expandableSection';

import styles from './educationResourceDetails.module.scss';

export interface IEducationResourceDetailsProps {
  bind: IBindingProps<TResourceDetails>;
  onFileSelected: (e: File) => void;
  initialFile?: IInitialFile;
}

export const EducationResourceDetails: React.FC<IEducationResourceDetailsProps> = ({
  bind,
  onFileSelected,
  initialFile,
}) => {
  const [isOpen, setIsOpen] = React.useState(true);
  const { formProp } = useChildForm(bind);
  const { admin } = useDictionary('en');

  return (
    <div className={styles.container}>
      <ExpandableSection
        colour="asset"
        title={admin.authenticated.views.educationResources.form.details.heading}
        leftLogo={<Image />}
        isOpen={isOpen}
        onToggle={() => setIsOpen(s => !s)}
      >
        <FileUpload
          entityType="Resource"
          initialFile={initialFile}
          onFileSelected={onFileSelected}
          data-testid={testIds.authenticated.views.educationResources.upload.forms.details.input('resource')}
          acceptAll
        />
        <Input
          required
          label={admin.authenticated.views.educationResources.form.details.title}
          type="text"
          bind={formProp('title').bind()}
          data-testid={testIds.authenticated.views.educationResources.upload.forms.details.input('title')}
        />
        <TextAreaInput
          label={admin.authenticated.views.educationResources.form.details.description}
          bind={formProp('description').bind()}
          data-testid={testIds.authenticated.views.educationResources.upload.forms.details.input('description')}
        />
      </ExpandableSection>
    </div>
  );
};
