import { WarningCircle } from '@phosphor-icons/react';
import { Asset } from '@wildscreen/api/src/apiClients';
import { routes } from '@wildscreen/core/src/core';
import * as React from 'react';
import { Link } from 'react-router-dom';

import { WildscreenImage } from '../wildscreenImage';

import styles from './assetThumbnail.module.scss';

export interface IAssetThumbnailProps {
  asset: Asset;
}

const Warning: React.FC = () => {
  return (
    <div className={styles.assetWarning}>
      <WarningCircle />
      <div>Asset link broken</div>
    </div>
  );
};

export const AssetThumbnail: React.FC<IAssetThumbnailProps> = ({ asset }) => {
  const [assetBroken, setAssetBroken] = React.useState<boolean>(false);

  const assetSrc = React.useCallback(() => {
    return asset.isVideo ? (
      <video onError={() => setAssetBroken(true)} controls src={asset.assetLocation}>
        <track kind="captions" />
      </video>
    ) : (
      <WildscreenImage
        size="medium"
        onError={() => setAssetBroken(true)}
        url={asset.assetLocation ?? ''}
        alt={`${asset.title} Preview`}
      />
    );
  }, [asset]);

  return (
    <Link className={styles.assetContainer} to={routes.authenticated.admin.assets.edit({ assetId: asset.assetId })}>
      <div data-broken={assetBroken} className={styles.asset}>
        {(assetBroken && <Warning />) || assetSrc()}
      </div>
      <div className={styles.assetTitle}>{asset.title}</div>
    </Link>
  );
};
