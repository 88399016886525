import { Upload } from '@phosphor-icons/react';
import { Button, Spinner } from '@rocketmakers/armstrong';
import { routes, testIds, useDictionary } from '@wildscreen/core/src/core';
import { useQueryString } from '@wildscreen/core/src/hooks/useQueryString';
import { AssetThumbnail } from '@wildscreen/ui-components/src/components/assetThumbnail/assetThumbnail';
import { Pagination } from '@wildscreen/ui-components/src/components/pagination/pagination';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';

import { apiHooks } from '../../api';
import { AuthenticatedView } from '../../components/authenticatedView';

import styles from './assetGallery.module.scss';

export const AssetGalleryView: React.FC = () => {
  const { admin } = useDictionary('en');
  const navigate = useNavigate();

  const { search, setQueryString } = useQueryString();

  const [{ data, isFetching }] = apiHooks.asset.assetGet.useQuery({
    parameters: {
      size: APP_CONFIG.assetsPerPage,
      from: Number(search?.from) || 0,
    },
  });

  React.useEffect(() => {
    setQueryString({ from: '0', p: '1' });
    // eslint-disable-next-line react-hooks/exhaustive-deps -- setting the initial query string
  }, []);

  const handlePageChange = React.useCallback(
    (pageNumber: number) => {
      let from = '0';
      if (pageNumber !== 1) {
        from = ((pageNumber - 1) * APP_CONFIG.assetsPerPage).toString();
      }
      setQueryString({ from, p: pageNumber.toString() });
    },
    [setQueryString]
  );

  return (
    <AuthenticatedView
      data-testid={testIds.authenticated.views.assetGallery.view}
      heading={admin.authenticated.views.assetGallery.heading}
      headerContent={
        <div className={styles.header}>
          <div className={styles.details}>
            {/* This was never hooked up it seems? Removing for now... */}
            {/* <div className={styles.search}>
              <Input type="text" rightOverlay={<MagnifyingGlass />} />
            </div> */}
            <div className={styles.upload}>
              <Button
                onClick={() => navigate(routes.authenticated.admin.assets.create())}
                data-style="black"
                leftOverlay={<Upload />}
              >
                {admin.authenticated.views.assetGallery.upload}
              </Button>
            </div>
          </div>
        </div>
      }
    >
      <>
        {!isFetching ? (
          <div className={styles.assetGrid}>
            {data?.data.results?.map(asset => {
              return <AssetThumbnail key={asset.assetId} asset={asset} />;
            })}
          </div>
        ) : (
          <div className={styles.loading}>
            <Spinner fillContainer />
            <h3>{admin.authenticated.views.assetGallery.loading}</h3>
          </div>
        )}
        <Pagination
          totalItems={data?.data.total || 0}
          itemsPerPage={APP_CONFIG.assetsPerPage}
          currentPage={search.p ? Number(search.p) : 1}
          onPageChange={handlePageChange}
        />
      </>
    </AuthenticatedView>
  );
};
