import { ClassificationListProperty } from '@wildscreen/api/src/apiClients';
import * as React from 'react';

import { apiHooks } from '../api';
import { mapTags } from '../helpers/tags';

export function useGetClassificationTags() {
  const [searchTerms, setSearchTerms] = React.useState({
    adaptations: '',
    biologicalThemes: '',
    habitat: '',
    landRegion: '',
    threats: '',
    actions: '',
  });

  const [{ data: adaptationData }] = apiHooks.data.dataPropertiesClassificationTargetPropertyGet.useQuery({
    parameters: { targetProperty: ClassificationListProperty.Adaptations, prefix: searchTerms.adaptations },
    cacheKey: 'targetProperty',
  });

  const [{ data: biologicalThemeData }] = apiHooks.data.dataPropertiesClassificationTargetPropertyGet.useQuery({
    parameters: { targetProperty: ClassificationListProperty.BiologicalThemes, prefix: searchTerms.biologicalThemes },
    cacheKey: 'targetProperty',
  });

  const [{ data: habitatData }] = apiHooks.data.dataPropertiesClassificationTargetPropertyGet.useQuery({
    parameters: { targetProperty: ClassificationListProperty.Habitats, prefix: searchTerms.habitat },
    cacheKey: 'targetProperty',
  });

  const [{ data: landRegionData }] = apiHooks.data.dataPropertiesClassificationTargetPropertyGet.useQuery({
    parameters: { targetProperty: ClassificationListProperty.LandRegions, prefix: searchTerms.landRegion },
    cacheKey: 'targetProperty',
  });

  const [{ data: threatData }] = apiHooks.data.dataPropertiesClassificationTargetPropertyGet.useQuery({
    parameters: { targetProperty: ClassificationListProperty.Threats, prefix: searchTerms.threats },
    cacheKey: 'targetProperty',
  });

  const [{ data: actionData }] = apiHooks.data.dataPropertiesClassificationTargetPropertyGet.useQuery({
    parameters: { targetProperty: ClassificationListProperty.Actions, prefix: searchTerms.actions },
    cacheKey: 'targetProperty',
  });

  return {
    data: {
      adaptations: mapTags(adaptationData),
      biologicalThemes: mapTags(biologicalThemeData),
      habitat: mapTags(habitatData),
      landRegion: mapTags(landRegionData),
      threats: mapTags(threatData),
      actions: mapTags(actionData),
    },
    setSearchTerms,
    searchTerms,
  };
}
