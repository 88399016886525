import { ClassificationPageApiPageDocumentWildscreenIdPartialPutRequest } from '@wildscreen/api/src/apiClients';

export type TClassificationKey = keyof Omit<
  ClassificationPageApiPageDocumentWildscreenIdPartialPutRequest,
  'wildscreenId' | 'wildscreenId2'
>;

export const ClassificationKeys: Array<TClassificationKey> = [
  'adaptations',
  'biologicalThemes',
  'habitat',
  'landRegion',
  'threats',
  'actions',
];
