import { MagnifyingGlass, WarningCircle } from '@phosphor-icons/react';
import { Button, Input, useForm } from '@rocketmakers/armstrong';
import { capitalizeEachWord, routes, testIds, useDictionary } from '@wildscreen/core/src/core';
import { SearchResult } from '@wildscreen/ui-components/src/components/searchResult';
import * as React from 'react';
import { useSearchParams } from 'react-router-dom';

import { apiHooks } from '../../api';
import { AuthenticatedView } from '../../components/authenticatedView';

import styles from './search.module.scss';

const maxResults = 20;

export const SearchView: React.FC = () => {
  const dict = useDictionary('en');
  const [searchParams, setSearchParams] = useSearchParams();

  const search = searchParams.get('q') ?? '';
  const { formProp, formState } = useForm({ search });

  const [{ data, isFetching }] = apiHooks.taxonomy.taxonomySearchGet.useQuery({
    parameters: {
      getPublicationState: true,
      ignoreSynonyms: true,
      search,
      size: maxResults,
      from: 0,
    },
  });

  const onSearch = React.useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      const query = formState?.search ?? '';
      setSearchParams(query ? { q: query } : undefined, { preventScrollReset: true });
    },
    [formState?.search, setSearchParams]
  );

  React.useEffect(() => {
    if (!formState?.search) setSearchParams(undefined, { preventScrollReset: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps -- triggers only
  }, [formState?.search]);

  return (
    <AuthenticatedView
      data-testid={testIds.authenticated.views.search.view}
      heading={dict.admin.authenticated.views.search.heading}
    >
      <form className={styles.search} onSubmit={onSearch}>
        <Input
          className={styles.input}
          type="search"
          leftOverlay={<MagnifyingGlass />}
          bind={formProp('search').bind()}
          placeholder={dict.admin.authenticated.views.search.placeholder}
          disabled={isFetching}
        />
        <Button className={styles.btn} type="submit" pending={isFetching}>
          {dict.admin.authenticated.views.search.buttonText}
        </Button>
      </form>
      {(data?.data.total ?? 0) > maxResults && (
        <div className={styles.warning}>
          <WarningCircle />
          {dict.admin.authenticated.views.search.tooManyResults(data?.data.total ?? 0, maxResults)}
        </div>
      )}
      {data?.data.total === 0 && (
        <div className={styles.warning}>
          <WarningCircle />
          {dict.admin.authenticated.views.search.noResults(search)}
        </div>
      )}
      <nav className={styles.results}>
        {data?.data.results?.map((item, i) => {
          const commonName = item.vernacularNames?.[0] && capitalizeEachWord(item.vernacularNames?.[0]);
          const scientificName = item.suggestedName || '';
          return (
            <SearchResult
              scientificName={scientificName}
              commonName={commonName}
              key={i}
              to={routes.authenticated.admin.search.edit({ wildscreenId: item.wildscreenId || '' })}
              isPublished={item.publishState?.isPublished}
              taxonomyClass={item.class}
            />
          );
        })}
      </nav>
    </AuthenticatedView>
  );
};
