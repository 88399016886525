import { Button, Input, useForm } from '@rocketmakers/armstrong';
import { routes, useDictionary } from '@wildscreen/core/src/core';
import { testIds } from '@wildscreen/core/src/core/testIds';
import { useOnKeyPress } from '@wildscreen/core/src/hooks/useOnKeyPress';
import { AuthView } from '@wildscreen/ui-components/src/components/authView';
import * as React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { z } from 'zod';

import { TLoginParameters, useAuth } from '../../contexts';

import styles from './auth.module.scss';

export const LoginView: React.FC = () => {
  const { setLoggedIn, login, isFetching, setTempUser, setIsFetching } = useAuth();
  const navigate = useNavigate();
  const [loginError, setLoginError] = React.useState<string | undefined>(undefined);
  const { admin } = useDictionary('en');

  const { formProp, formState, validate } = useForm<TLoginParameters>(
    {
      username: undefined,
      password: undefined,
    },
    {
      validationMode: 'both',
      validationSchema: {
        username: z.string().email(admin.unauthenticated.views.login.emailValidation),
        password: z.string().min(1, admin.unauthenticated.views.login.passwordValidation),
      },
    }
  );

  const onLogin = React.useCallback(
    async (e?: React.FormEvent<HTMLFormElement>) => {
      e?.preventDefault();
      const { username, password } = formState as TLoginParameters;
      if (validate() && username && password) {
        try {
          const user = await login({ username, password });

          if (user?.challengeName === 'NEW_PASSWORD_REQUIRED') {
            setTempUser(user);
            navigate(routes.unauthenticated.verify());
            return;
          }

          const isSignedIn = !!user.signInUserSession.accessToken.jwtToken;
          if (isSignedIn) {
            setLoggedIn(user.signInUserSession.accessToken.jwtToken);
          }
          // eslint-disable-next-line @typescript-eslint/no-explicit-any -- ignore
        } catch (error: any) {
          setIsFetching(false);
          switch (error.code) {
            case 'NotAuthorizedException':
              setTimeout(() => {
                setLoginError(undefined);
              }, 5000);
              setLoginError(error.message);
              break;
            default:
              break;
          }
        }
      }
    },
    [formState, validate, login, setTempUser, navigate, setLoggedIn, setIsFetching]
  );

  useOnKeyPress('Enter', () => onLogin());

  return (
    <AuthView data-testid={testIds.unauthenticated.views.login.view}>
      <div className={styles.authContainer}>
        <h1>{admin.unauthenticated.views.login.login}</h1>
        <form onSubmit={onLogin}>
          <Input
            required
            bind={formProp('username').bind()}
            validationMode="message"
            data-testid={testIds.unauthenticated.views.login.email}
            label={admin.unauthenticated.views.login.email}
          />
          <Input
            required
            bind={formProp('password').bind()}
            validationMode="message"
            type="password"
            data-testid={testIds.unauthenticated.views.login.password}
            label={admin.unauthenticated.views.login.password}
          />
          <div className={styles.failed}>
            {loginError && <span data-testid={testIds.unauthenticated.views.login.loginFailed}>{loginError}</span>}
          </div>
          <Link className={styles.forgotPassword} to={routes.unauthenticated.forgotPassword()}>
            {admin.unauthenticated.views.login.forgotPassword}
          </Link>
          <Button
            pending={isFetching}
            data-style="yellow"
            type="submit"
            data-testid={testIds.unauthenticated.views.login.loginButton}
          >
            {admin.unauthenticated.views.login.loginButton}
          </Button>
        </form>
      </div>
    </AuthView>
  );
};
