export type MakeRequired<T> = {
  [K in keyof T]-?: T[K];
};

export function capitalizeEachWord(inputString: string): string {
  return inputString
    .toLowerCase()
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
}

export function capitalizeFirstLetter(inputString: string): string {
  return inputString.toLowerCase().charAt(0).toUpperCase() + inputString.slice(1).toLowerCase();
}

export function shuffleArray<T>(array: Array<T>) {
  const shuffledArray = [...array]; // Create a copy of the original array

  // eslint-disable-next-line no-plusplus -- ignore
  for (let i = shuffledArray.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
  }

  return shuffledArray; // Return the shuffled copy
}

export function removePluralFromWords(inputString: string): string {
  const wordsToRemoveSFrom = ['adaptations', 'behaviours', 'habitats', 'landRegions', 'threats', 'actions'];
  if (wordsToRemoveSFrom.includes(inputString)) {
    const regexPattern = /s(?!.*s)/g;
    return inputString.replace(regexPattern, '');
  }
  return inputString;
}

export const formatAgeCategory = (ageCategory: string, asHeading = false) => {
  switch (ageCategory) {
    case 'Range3To5':
      return asHeading ? 'For ages 3-5' : '3-5 years';
    case 'Range5To7':
      return asHeading ? 'For ages 5-7' : '5-7 years';
    case 'Range7To11':
      return asHeading ? 'For ages 7-11' : '7-11 years';
    case 'Range11To14':
      return asHeading ? 'For ages 11-14' : '11-14 years';
    case 'Range14To16':
      return asHeading ? 'For ages 14-16' : '14-16 years';
    case 'Range16Plus':
      return asHeading ? 'For ages 16+' : '16+ years';
    default:
      return 'Unknown';
  }
};

export const formatSubject = (subject: string) => {
  switch (subject) {
    case 'PhysicalEducation':
      return 'Physical education';
    case 'DesignAndTechnology':
      return 'Design and technology';
    case 'ItAndComputing':
      return 'IT and computing';
    case 'ModernLanguages':
      return 'Modern languages';
    case 'ReligiousStudies':
      return 'Religious studies';
    case 'HealthAndSocialCare':
      return 'Health and social care';
    default:
      return subject;
  }
};
