import { Auth, CognitoUser } from '@aws-amplify/auth';
import { AuthTokenStore } from '@wildscreen/api/src/localStorage/tokenStore';
import { MakeRequired, routes } from '@wildscreen/core/src/core';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';

export type TLoginParameters = {
  username?: string;
  password?: string;
};

export type TPasswordConfirmation = {
  password?: string;
  passwordConfirmation?: string;
};

export type TForgotPasswordParameters = Pick<TLoginParameters, 'username'>;

export type TResetPasswordParameters = TLoginParameters & { code?: string };

export interface IAuthContext {
  isLoggedIn: boolean;
  isFetching: boolean;
  setIsFetching: React.Dispatch<React.SetStateAction<boolean>>;
  login: (data: MakeRequired<TLoginParameters>) => Promise<CognitoUser | any>;
  logout: () => Promise<void>;
  tempUser: CognitoUser | undefined;
  setTempUser: React.Dispatch<React.SetStateAction<CognitoUser | undefined>>;
  currentAuthenticatedUser: any | undefined;
  forgotPassword: (data: MakeRequired<TForgotPasswordParameters>) => Promise<any>;
  resetPassword: (data: MakeRequired<TResetPasswordParameters>) => Promise<any>;
  completeNewPassword: (user: CognitoUser, newPassword: string) => Promise<any>;
  setIsLoggedIn: (value: boolean) => void;
  setLoggedIn: (token: string) => void;
}

export const AuthContext = React.createContext<IAuthContext>({
  isLoggedIn: false,
  isFetching: false,
  tempUser: undefined,
  setIsFetching: () => {},
  setTempUser: () => {},
  setLoggedIn: () => {},
  setIsLoggedIn: () => {},
  currentAuthenticatedUser: undefined,
  forgotPassword: async () => {},
  resetPassword: async () => {},
  completeNewPassword: async () => {},
  login: async () => {},
  logout: async () => {},
});

export const AuthContextProvider: React.FC<React.PropsWithChildren<Partial<IAuthContext>>> = ({
  children,
  ...mockState
}) => {
  const navigate = useNavigate();
  const [isFetching, setIsFetching] = React.useState<boolean>(false);
  const [isLoggedIn, setIsLoggedIn] = React.useState<boolean>(!!AuthTokenStore.get());
  const [currentAuthenticatedUser, setCurrentAuthenticatedUser] = React.useState<CognitoUser | undefined>(undefined);
  const [tempUser, setTempUser] = React.useState<CognitoUser | undefined>(undefined);

  const loadingWrapper = async (fn: () => Promise<any>) => {
    setIsFetching(true);
    const res = await fn();
    setIsFetching(false);
    return res;
  };

  React.useEffect(() => {
    const checkCurrentUserAuthenticated = async () => {
      try {
        const user = await Auth.currentAuthenticatedUser();
        const session = await Auth.currentSession();

        AuthTokenStore.save(session.getAccessToken().getJwtToken());
        setCurrentAuthenticatedUser(user);
        setIsLoggedIn(true);
      } catch (error) {
        AuthTokenStore.clear();
        setIsLoggedIn(false);
      }
    };

    checkCurrentUserAuthenticated();
  }, []);

  /**
   * Set the user as logged in and redirect to the admin root
   */
  const setLoggedIn = (token: string) => {
    setIsLoggedIn(true);
    AuthTokenStore.save(token);
    navigate(routes.authenticated.admin.search.root());
  };

  const completeNewPassword = async (user: CognitoUser, newPassword: string) => {
    return loadingWrapper(async () => Auth.completeNewPassword(user, newPassword));
  };

  /**
   * Send a forgot password email
   * @param email email address
   * @returns Promise
   */
  const forgotPassword = async (data: MakeRequired<TForgotPasswordParameters>) => {
    return loadingWrapper(async () => Auth.forgotPassword(data.username));
  };

  /**
   * Reset password
   * @param email email address
   * @returns Promise
   */
  const resetPassword = async (data: MakeRequired<TResetPasswordParameters>) => {
    return loadingWrapper(async () => Auth.forgotPasswordSubmit(data.username, data.code, data.password));
  };

  /**
   * Login the user
   * @param data login credentials
   * @returns CognitoUser
   */
  const login = async (data: MakeRequired<TLoginParameters>) => {
    return loadingWrapper(async () => Auth.signIn(data.username, data.password));
  };

  /**
   * Logout the user
   */
  const logout = async () => {
    await Auth.signOut();
    AuthTokenStore.clear();
    setIsLoggedIn(false);
    navigate(routes.unauthenticated.login());
  };

  return (
    <AuthContext.Provider
      value={{
        tempUser,
        setIsFetching,
        setTempUser,
        isFetching,
        isLoggedIn,
        setLoggedIn,
        setIsLoggedIn,
        completeNewPassword,
        currentAuthenticatedUser,
        forgotPassword,
        resetPassword,
        login,
        logout,
        ...mockState,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

/**
 * Hook to get the AuthContext
 * @returns AuthContext
 */
export function useAuth() {
  const ctx = React.useContext(AuthContext);
  if (!ctx) {
    throw new Error('No Auth Context');
  }
  return ctx;
}
