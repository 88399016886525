import { format } from 'date-fns';
import * as React from 'react';

import styles from './lastUpdated.module.scss';

export interface ILastUpdatedProps {
  name: string;
  date: string;
  removeMargin?: boolean;
}

export const LastUpdated: React.FC<ILastUpdatedProps> = ({ date, name, removeMargin }) => {
  return (
    <div data-remove-margin={removeMargin} className={styles.container}>
      <div>Last updated</div>
      <div>
        <span>{name}</span>
        <span>{format(new Date(date), 'dd/MM/yyyy')}</span>
      </div>
    </div>
  );
};
