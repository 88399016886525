export const testIds = {
  unauthenticated: {
    views: {
      login: {
        email: 'email-input',
        password: 'password-input',
        view: 'login-view',
        loginButton: 'login-btn',
        loginFailed: 'login-failed',
        confirmButton: 'confirm-btn',
      },
      forgotPassword: {
        view: 'forgot-password-view',
        email: 'email-input',
        forgotPasswordButton: 'forgot-password-btn',
        successMessage: 'success-message',
        forgotPasswordFailed: 'forgot-password-failed',
      },
      verify: {
        view: 'verify-view',
      },
      resetPassword: {
        view: 'reset-password-view',
        email: 'email-input',
        password: 'password-input',
        confirmPassword: 'confirm-password-input',
        resetPasswordButton: 'reset-password-btn',
        resetPasswordFailed: 'reset-password-failed',
      },
      register: {
        view: 'register-view',
      },
    },
    components: {
      pagination: {
        button: (buttonText: string, active?: boolean) =>
          `pagination-${buttonText.toLowerCase()}${active ? '-active' : ''}-btn`,
      },
      cookieBanner: {
        cta: `cookie-banner-cta`,
        privacyPolicy: `cookie-banner-privacy-policy`,
        preference: (target: string) => `cookie-banner-preference-${target}`,
      },
    },
  },
  authenticated: {
    views: {
      welcome: {
        view: 'welcome-view',
        testHookValue: 'test-hook-value',
        contextValue: 'context-value',
        submitButton: 'submit-btn',
      },
      search: {
        view: 'search-view',
      },
      account: {
        view: 'account-view',
      },
      assetGallery: {
        view: 'asset-gallery-view',
      },
      uploadAsset: {
        view: 'upload-asset-view',
        forms: {
          details: {
            input: (name: string) => `${name.toLowerCase()}-input`,
          },
        },
      },
      editAsset: {
        view: 'edit-asset-view',
      },
      homepageContent: {
        view: 'homepage-content-view',
      },
      libraryCredit: {
        view: 'library-credit-view',
        create: 'create-library-credit-view',
        edit: 'edit-library-credit-view',
      },
      educationResources: {
        view: 'education-resources-view',
        create: 'create-education-resources-view',
        edit: 'edit-education-resources-view',
        upload: {
          view: 'upload-education-resource-view',
          forms: {
            details: {
              input: (name: string) => `${name.toLowerCase()}-input`,
            },
          },
        },
      },
      taxonomy: {
        view: 'taxonomy-view',
      },
      editTaxonomy: {
        view: 'edit-taxonomy-view',
        forms: {
          details: {
            scientificName: 'scientific-name-input',
            redListStatus: 'red-list-status-input',
            friendlyName: 'friendly-name-input',
            description: 'description-input',
            imageCreditText: 'image-credit-text-input',
            imageUpload: 'image-upload',
          },
          tags: {
            readOnly: (taxonRank: string) => `${taxonRank.toLowerCase()}-only-tag`,
            input: (name: string) => `${name.toLowerCase()}-tag-input`,
          },
        },
      },
    },
    components: {
      libraryCredit: (component: string) => `library-credit-${component}`,
      libraryCreditSelection: (name: string) => `${name.toLowerCase()}-library-credit-selection`,
      searchItem: (name: string, component: string) => `${name.toLowerCase()}-${component.toLowerCase()}-search-item`,
      seeAllTile: (name: string) => `${name}-see-all-tile`,
      collectionTile: (name: string, component: string, size: string) =>
        `${name.toLowerCase()}-${component.toLowerCase()}-${size}-collection-tile`,
      pod: (podType: string, name: string, component: string) =>
        `${podType}-${name.toLowerCase().replaceAll(' ', '-')}-pod-${component}`,
      educationResource: (component: string) => `education-resource-${component}`,
      tag: (name: string, type: string) => `${type}-${name.toLowerCase()}-tag`,
      galleryAsset: (component: string) => `gallery-${component}`,
      banner: (component: string) => `banner-${component}`,
      socials: (social: string) => `${social}-social-link`,
      footer: (component: string) => `footer-${component}`,
      publishedToasts: (e: 'success' | 'failed') => `published-${e}-toast`,
      expandableSection: (title: string, open: boolean) =>
        `${title.toLowerCase()}-expandable-section-${open ? 'open' : 'closed'}`,
      taxonomicTree: (taxonRank: string, title: string) =>
        `${taxonRank.toLowerCase()}-${title.toLowerCase()}-taxonomic-tree`,
      taxonomicRow: (taxonRank: string, title: string, open: boolean) =>
        `${taxonRank.toLowerCase()}-${title.toLowerCase()}-taxonomic-row-${open ? 'open' : 'closed'}`,
      logoutButton: 'logout-btn',
      header: {
        admin: {
          view: 'header-view',
          searchLink: 'search-link',
          taxonomyLink: 'taxonomy-link',
          assetGalleryLink: 'asset-gallery-link',
          homepageContentLink: 'homepage-content-link',
          libraryCreditLink: 'library-credit-link',
          educationResourcesLink: 'education-resources-link',
          accountLink: 'account-link',
        },
        arkive: (component: string) => `header-${component}`,
      },
    },
  },
} as const;

export function generateLocatorTestId(id: string): string {
  return `data-testid="${id}"`;
}
