import { PawPrint } from '@phosphor-icons/react';
import { IBindingProps } from '@rocketmakers/armstrong';
import { useDictionary } from '@wildscreen/core/src/core';
import { ITagsForm } from '@wildscreen/core/src/types/forms';
import * as React from 'react';

import { ExpandableSection } from '../expandableSection';
import { TagsForm } from '../tagsForm';

import styles from './taxonomyCharacteristics.module.scss';

export interface ITaxonomyCharacteristicsProps {
  bind: IBindingProps<ITagsForm>;
  isSpecies?: boolean;
}

export const TaxonomyCharacteristics: React.FC<ITaxonomyCharacteristicsProps> = ({ bind, isSpecies }) => {
  const [isOpen, setIsOpen] = React.useState(true);
  const { admin } = useDictionary('en');

  return (
    <div className={styles.container}>
      <ExpandableSection
        colour="taxonomy-characteristics"
        title={admin.authenticated.views.editTaxonomy.characteristics.heading(isSpecies)}
        leftLogo={<PawPrint />}
        isOpen={isOpen}
        onToggle={() => setIsOpen(s => !s)}
      >
        <TagsForm bind={bind} />
      </ExpandableSection>
    </div>
  );
};
