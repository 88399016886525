import { AssetListProperty } from '@wildscreen/api/src/apiClients';
import * as React from 'react';

import { apiHooks } from '../api';
import { mapTags } from '../helpers/tags';

export function useGetAssetTags() {
  const [searchTerms, setSearchTerms] = React.useState<{
    behaviours: string;
    lifeStages: string;
    adaptations: string;
    habitats: string;
    regions: string;
    generalContentDescriptors: string;
  }>({
    behaviours: '',
    lifeStages: '',
    adaptations: '',
    habitats: '',
    regions: '',
    generalContentDescriptors: '',
  });

  const [{ data: behaviourData }] = apiHooks.data.dataPropertiesAssetTargetPropertyGet.useQuery({
    parameters: { targetProperty: AssetListProperty.Behaviours, prefix: searchTerms.behaviours },
    cacheKey: 'targetProperty',
  });

  const [{ data: lifeStagesData }] = apiHooks.data.dataPropertiesAssetTargetPropertyGet.useQuery({
    parameters: { targetProperty: AssetListProperty.LifeStages, prefix: searchTerms.lifeStages },
    cacheKey: 'targetProperty',
  });

  const [{ data: adaptationsData }] = apiHooks.data.dataPropertiesAssetTargetPropertyGet.useQuery({
    parameters: { targetProperty: AssetListProperty.Adaptations, prefix: searchTerms.adaptations },
    cacheKey: 'targetProperty',
  });

  const [{ data: habitatsData }] = apiHooks.data.dataPropertiesAssetTargetPropertyGet.useQuery({
    parameters: { targetProperty: AssetListProperty.Habitats, prefix: searchTerms.habitats },
    cacheKey: 'targetProperty',
  });

  const [{ data: regionsData }] = apiHooks.data.dataPropertiesAssetTargetPropertyGet.useQuery({
    parameters: { targetProperty: AssetListProperty.LandRegions, prefix: searchTerms.regions },
    cacheKey: 'targetProperty',
  });

  const [{ data: generalContentDescriptorsData }] = apiHooks.data.dataPropertiesAssetTargetPropertyGet.useQuery({
    parameters: {
      targetProperty: AssetListProperty.GeneralContentDescriptors,
      prefix: searchTerms.generalContentDescriptors,
    },
    cacheKey: 'targetProperty',
  });

  return {
    data: {
      adaptations: mapTags(adaptationsData),
      behaviours: mapTags(behaviourData),
      habitats: mapTags(habitatsData),
      lifeStages: mapTags(lifeStagesData),
      regions: mapTags(regionsData),
      generalContentDescriptors: mapTags(generalContentDescriptorsData),
    },
    setSearchTerms,
    searchTerms,
  };
}
