import { Asset, AssetApiAssetUploadPostRequest, ResourceApiResourcePostRequest } from '@wildscreen/api/src/apiClients';

type TTagKeys =
  | 'adaptations'
  | 'behaviours'
  | 'habitats'
  | 'regions'
  | 'relatedClassifications'
  | 'generalContentDescriptors'
  | 'lifeStages';

type TDetails = 'assetId' | 'credit' | 'description' | 'originId' | 'title' | 'isHomepageWorthy' | 'libraryCreditId';

export type TAssetTags = Pick<AssetApiAssetUploadPostRequest, TTagKeys>;
export type TAssetDetails = Pick<AssetApiAssetUploadPostRequest, TDetails> & Pick<Asset, 'assetLocation'>;

type TResourceTagsKeys = 'subjects' | 'biologicalThemes' | 'habitats' | 'landRegions' | 'threats' | 'actions';
type TResourcePropertiesKeys = 'ageCategory' | 'type';

type TResourceTagDetails = 'description' | 'title';

export type TResourceTags = Pick<ResourceApiResourcePostRequest, TResourceTagsKeys>;
export type TResourceDetails = Pick<ResourceApiResourcePostRequest, TResourceTagDetails>;
export type TResourceProperties = Pick<ResourceApiResourcePostRequest, TResourcePropertiesKeys>;

export const assetDetailKeys: Array<keyof TAssetDetails> = [
  'assetId',
  'credit',
  'description',
  'isHomepageWorthy',
  'originId',
  'title',
];

export const assetTagsKeys: Array<keyof TAssetTags> = [
  'behaviours',
  'lifeStages',
  'adaptations',
  'habitats',
  'regions',
  'generalContentDescriptors',
];

export const educationResourceDetailsKeys: Array<keyof TResourceDetails> = ['description', 'title'];

export const educationResourceTagsKeys: Array<keyof TResourceTags> = [
  'subjects',
  'biologicalThemes',
  'habitats',
  'landRegions',
  'threats',
  'actions',
];

export const educationResourcePropertiesKeys: Array<keyof TResourceProperties> = ['ageCategory', 'type'];

export interface ICreateNewAssetForm {
  details: TAssetDetails;
  tags: TAssetTags;
}
export interface IUpdateNewAssetForm {
  details: TAssetDetails;
  tags: TAssetTags;
}

export interface ICreateNewResourceForm {
  details: TResourceDetails;
  tags: TResourceTags & TResourceProperties;
}
export interface IUpdateNewResourceForm {
  details: TResourceDetails;
  tags: TResourceTags & TResourceProperties;
}
