import { testIds } from '@wildscreen/core/src/core';
import * as React from 'react';

import styles from './pagination.module.scss';

export interface IPaginationProps {
  itemsPerPage: number;
  totalItems: number;
  currentPage: number;
  onPageChange: (pageNumber: number) => void;
}

export const Pagination: React.FC<IPaginationProps> = ({ currentPage, itemsPerPage, onPageChange, totalItems }) => {
  const handlePageClick = React.useCallback(
    (page: number) => {
      onPageChange(page);
    },
    [onPageChange]
  );

  const prevPage = () => {
    if (currentPage > 1) {
      onPageChange(currentPage - 1);
    }
  };

  const nextPage = () => {
    const totalPages = Math.ceil(totalItems / itemsPerPage);
    if (currentPage < totalPages) {
      onPageChange(currentPage + 1);
    }
  };

  const updatePaginationButtons = React.useCallback(() => {
    const totalPages = Math.ceil(totalItems / itemsPerPage);
    let startPage = Math.max(currentPage - 4, 0);
    let endPage = Math.min(currentPage + 5, totalPages);

    if (endPage - startPage < 9) {
      if (startPage === 1) {
        endPage = Math.min(endPage + (9 - (endPage - startPage)), totalPages);
      } else {
        startPage = Math.max(startPage - (9 - (endPage - startPage)), 1);
      }
    }

    const pageButtons: Array<JSX.Element> = [];
    // eslint-disable-next-line no-plusplus -- ignore
    for (let i = startPage; i <= endPage; i++) {
      pageButtons.push(
        <button
          data-testid={testIds.unauthenticated.components.pagination.button(i.toString(), i === currentPage)}
          key={i}
          className={i === currentPage ? styles.active : styles.inactive}
          onClick={() => handlePageClick(i)}
        >
          {i}
        </button>
      );
    }

    return pageButtons;
  }, [currentPage, handlePageClick, itemsPerPage, totalItems]);

  React.useEffect(() => {
    updatePaginationButtons();
  }, [currentPage, totalItems, updatePaginationButtons]);

  if (totalItems <= itemsPerPage) {
    return null;
  }

  return (
    <div className={styles.paginationContainer}>
      <button
        data-testid={testIds.unauthenticated.components.pagination.button('prev')}
        onClick={prevPage}
        disabled={currentPage === 1}
      >
        {'<'}
      </button>
      <div>{updatePaginationButtons()}</div>
      <button
        data-testid={testIds.unauthenticated.components.pagination.button('next')}
        onClick={nextPage}
        disabled={currentPage === Math.ceil(totalItems / itemsPerPage)}
      >
        {'>'}
      </button>
    </div>
  );
};
