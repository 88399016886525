import { testIds } from '@wildscreen/core/src/core/testIds';
import * as React from 'react';

import styles from './auth.module.scss';

export const RegisterView: React.FC = () => {
  return (
    <div className={styles.container} data-testid={testIds.unauthenticated.views.register.view}>
      <h2>Register</h2>
    </div>
  );
};
