import * as React from 'react';

import { CmsLogo } from '../logos/cms';
import { WildscreenLogo } from '../logos/wildscreen/wildscreen';

import styles from './authView.module.scss';

export type TAuthViewProps = React.PropsWithChildren;

export const AuthView: React.FC<TAuthViewProps> = ({ children, ...rest }) => {
  return (
    <div className={styles.container} {...rest}>
      <header>
        <WildscreenLogo size="large" animate={true} />
      </header>
      <div className={styles.center}>{children}</div>
      <footer>
        <CmsLogo size="large" />
      </footer>
    </div>
  );
};
