import { ClassificationParentView } from '@wildscreen/api/src/apiClients';
import { capitalizeEachWord } from '@wildscreen/core/src/core';
import { TTaxonomyRanks } from '@wildscreen/ui-components/src/types';
import * as React from 'react';

import { TagsReadOnly } from '../tagsReadOnly';
import { TaxonomyTagsForm } from '../taxonomyTagsForm';

export interface ITagsContainerProps {
  classificationParentsData?: ClassificationParentView;
  isOpen: boolean;
  taxonRank: TTaxonomyRanks;
  onClose?: () => void;
}

export const TagsContainer: React.FC<ITagsContainerProps> = ({
  classificationParentsData,
  isOpen,
  taxonRank,
  onClose,
}) => {
  return isOpen ? (
    <TaxonomyTagsForm
      classificationParents={classificationParentsData}
      taxonRank={capitalizeEachWord(taxonRank) as TTaxonomyRanks}
      onClose={() => onClose?.()}
    />
  ) : (
    <TagsReadOnly
      classificationParents={classificationParentsData}
      taxonRank={capitalizeEachWord(taxonRank) as TTaxonomyRanks}
    />
  );
};
