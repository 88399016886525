import * as React from 'react';

export const QuizIcon: React.FC<React.HTMLAttributes<SVGElement>> = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width="40" height="41" viewBox="0 0 40 41" fill="none" {...props}>
    <path
      d="M20 7.03125C18.0615 7.03125 16.4844 8.60836 16.4844 10.5469C16.4844 11.1941 17.0091 11.7188 17.6562 11.7188C18.3034 11.7188 18.8281 11.1941 18.8281 10.5469C18.8281 9.9007 19.3538 9.375 20 9.375C20.6462 9.375 21.1719 9.9007 21.1719 10.5469C21.1719 10.9615 20.9491 11.3495 20.5906 11.5595C19.487 12.2059 18.8281 13.3006 18.8281 14.4877V15.2344C18.8281 15.8816 19.3528 16.4062 20 16.4062C20.6472 16.4062 21.1719 15.8816 21.1719 15.2344V14.4877C21.1719 13.9891 21.5938 13.6881 21.7753 13.5818C22.8487 12.953 23.5156 11.7901 23.5156 10.5469C23.5156 8.60836 21.9385 7.03125 20 7.03125Z"
      fill="black"
    />
    <path
      d="M20 21.0938C20.6472 21.0938 21.1719 20.5691 21.1719 19.9219C21.1719 19.2747 20.6472 18.75 20 18.75C19.3528 18.75 18.8281 19.2747 18.8281 19.9219C18.8281 20.5691 19.3528 21.0938 20 21.0938Z"
      fill="black"
    />
    <path
      d="M20 0.78125C12.8921 0.78125 7.10938 6.56398 7.10938 13.6719C7.10938 16.5912 8.11625 19.4512 9.94453 21.7251C11.6428 23.8372 12.5781 26.5263 12.5781 29.2969V32.5781C12.5781 34.1059 13.5579 35.4084 14.9219 35.8922V37.2656C14.9219 39.2041 16.499 40.7812 18.4375 40.7812H21.5625C23.501 40.7812 25.0781 39.2041 25.0781 37.2656V35.8922C26.4421 35.4084 27.4219 34.1059 27.4219 32.5781V29.2969C27.4219 26.5263 28.3572 23.8372 30.0555 21.7251C31.8837 19.4512 32.8906 16.5912 32.8906 13.6719C32.8906 6.56398 27.1079 0.78125 20 0.78125ZM22.7344 37.2656C22.7344 37.9118 22.2087 38.4375 21.5625 38.4375H18.4375C17.7913 38.4375 17.2656 37.9118 17.2656 37.2656V36.0938H22.7344V37.2656ZM25.0781 32.5781C25.0781 33.2243 24.5524 33.75 23.9062 33.75H16.0938C15.4476 33.75 14.9219 33.2243 14.9219 32.5781V30.4297H25.0781V32.5781ZM28.229 20.2564C26.4304 22.4933 25.3477 25.266 25.1227 28.1641H14.8773C14.6523 25.266 13.5697 22.4933 11.7711 20.2564C10.2547 18.3705 9.45312 16.0935 9.45312 13.6719C9.45312 7.85633 14.1845 3.125 20 3.125C25.8155 3.125 30.5469 7.85633 30.5469 13.6719C30.5469 16.0935 29.7453 18.3705 28.229 20.2564Z"
      fill="black"
    />
    <path
      d="M38.8281 12.5H36.4844C35.8372 12.5 35.3125 13.0247 35.3125 13.6719C35.3125 14.3191 35.8372 14.8438 36.4844 14.8438H38.8281C39.4753 14.8438 40 14.3191 40 13.6719C40 13.0247 39.4753 12.5 38.8281 12.5Z"
      fill="black"
    />
    <path
      d="M3.51562 14.8438C4.16281 14.8438 4.6875 14.3191 4.6875 13.6719C4.6875 13.0247 4.16281 12.5 3.51562 12.5H1.17188C0.524687 12.5 0 13.0247 0 13.6719C0 14.3191 0.524687 14.8438 1.17188 14.8438H3.51562Z"
      fill="black"
    />
    <path
      d="M35.8958 9.1618L38.0981 8.36016C38.7063 8.13883 39.0198 7.46633 38.7985 6.85812C38.5772 6.24992 37.9047 5.93641 37.2965 6.15773L35.0941 6.95938C34.4859 7.1807 34.1724 7.8532 34.3937 8.46141C34.6151 9.06961 35.2877 9.38313 35.8958 9.1618Z"
      fill="black"
    />
    <path
      d="M4.10422 18.182L1.90188 18.9836C1.29367 19.2049 0.980156 19.8774 1.20148 20.4856C1.42281 21.0938 2.09531 21.4073 2.70352 21.186L4.90586 20.3844C5.51406 20.163 5.82758 19.4905 5.60625 18.8823C5.38492 18.2741 4.71234 17.9606 4.10422 18.182Z"
      fill="black"
    />
    <path
      d="M38.0982 18.9836L35.8959 18.182C35.2877 17.9606 34.6152 18.2741 34.3938 18.8823C34.1724 19.4905 34.486 20.163 35.0942 20.3844L37.2966 21.186C37.9048 21.4073 38.5772 21.0938 38.7986 20.4856C39.02 19.8774 38.7063 19.2049 38.0982 18.9836Z"
      fill="black"
    />
    <path
      d="M4.90578 6.95945L2.70344 6.15781C2.09523 5.93648 1.42281 6.25 1.20141 6.8582C0.98 7.46641 1.29359 8.13883 1.9018 8.36023L4.10414 9.16187C4.71234 9.3832 5.38477 9.06969 5.60617 8.46148C5.82758 7.85328 5.51398 7.18078 4.90578 6.95945Z"
      fill="black"
    />
  </svg>
);
