import { List } from '@phosphor-icons/react';
import { ClassificationParentView, TaxonomyEntry } from '@wildscreen/api/src/apiClients';
import { capitalizeEachWord, useDictionary } from '@wildscreen/core/src/core';
import { TaxonomyTreeLeaf } from '@wildscreen/ui-components/src/components/taxonomyTreeLeaf';
import { TTaxonomyRanks } from '@wildscreen/ui-components/src/types';
import { ClassificationKeys } from '@wildscreen/ui-components/src/types/classificationTypes';
import * as React from 'react';

import { ExpandableSection } from '../expandableSection';
import { TagsContainer } from '../tagsContainer';

import styles from './taxonomy.module.scss';

export interface ITaxonomyProps {
  classificationParentData?: ClassificationParentView;
  taxonomyData?: TaxonomyEntry[];
}

export const Taxonomy: React.FC<ITaxonomyProps> = ({ classificationParentData, taxonomyData }) => {
  const [isOpen, setIsOpen] = React.useState(true);

  const { admin } = useDictionary('en');

  const [openItem, setOpenItem] = React.useState<string | null>(null);

  const toggleItem = (itemName: string) => {
    if (openItem === itemName) {
      setOpenItem(null);
    } else {
      setOpenItem(itemName);
    }
  };

  const taxonRanks = React.useMemo(() => ['kingdom', 'phylum', 'class', 'order', 'family', 'genus'], []);

  const currentTaxonRank = React.useMemo(() => taxonomyData?.[0]?.taxonRank, [taxonomyData]);

  const countInheritedTraits = React.useMemo(
    () =>
      taxonRanks
        .map(t => {
          return ClassificationKeys.reduce((acc, cur) => {
            // eslint-disable-next-line no-param-reassign -- ignore
            acc += classificationParentData?.[capitalizeEachWord(t)]?.classification?.[cur]?.length || 0;
            return acc;
          }, 0);
        })
        .reduce((acc, cur) => acc + cur, 0),
    [classificationParentData, taxonRanks]
  );

  return (
    <div className={styles.container}>
      <ExpandableSection
        colour="taxonomy"
        title={admin.authenticated.views.editTaxonomy.taxonomy.heading}
        leftLogo={<List />}
        isOpen={isOpen}
        onToggle={() => setIsOpen(s => !s)}
        rightContent={<div className={styles.inheritedTraits}>{countInheritedTraits} inherited traits</div>}
      >
        <>
          <TaxonomyTreeLeaf
            renderChildrenPosition="below"
            taxonRank={'Domain'}
            open={openItem === 'Eukaryota'}
            indentIndex={0}
            scientificName="Eukaryota"
            onEditClick={() => toggleItem('Eukaryota')}
          />
          {Object.keys(taxonomyData?.[0] || [])
            .filter(x => taxonRanks.includes(x))
            .filter(x => x !== currentTaxonRank?.toLowerCase())
            .map((key, index) => {
              if (taxonomyData?.[0][key] !== null) {
                return (
                  <TaxonomyTreeLeaf
                    key={`${key}-${index}`}
                    renderChildrenPosition={openItem ? 'below' : 'inline'}
                    taxonRank={capitalizeEachWord(key) as TTaxonomyRanks}
                    open={openItem === key}
                    indentIndex={0}
                    scientificName={taxonomyData?.[0][key]}
                    onEditClick={() => toggleItem(key)}
                  >
                    <TagsContainer
                      classificationParentsData={classificationParentData}
                      isOpen={!!openItem}
                      taxonRank={key as TTaxonomyRanks}
                      onClose={() => toggleItem(key)}
                    />
                  </TaxonomyTreeLeaf>
                );
              }
              return null;
            })}
        </>
      </ExpandableSection>
    </div>
  );
};
