import { ApiHooks, ApiHooksResponders, EndpointIDs } from '@rocketmakers/api-hooks';
import { ApiClient, IProcessingResponse } from '@wildscreen/api/src';

import { useApiProcessing } from './processing';

export const apiClient = new ApiClient(APP_CONFIG.baseApiUrl);

export const endpointIDs = EndpointIDs.create(apiClient);

/**
 * APIHooks - endpoint settings
 * ----------------------------------------------------------
 * Define endpoint level settings for queries and mutations.
 * These will override the application level settings in `create.ts`, but can be overridden at hook execution level
 */
export const hookConfigFactory: ApiHooks.HookConfigLibraryFactory<typeof apiClient> = empty => {
  const endpointSettings = { ...empty };

  endpointSettings.classificationPage.pageDocumentWildscreenIdPut.mutation = {
    refetchQueries: [
      endpointIDs.classificationPage.pageDocumentWildscreenIdGet({
        cacheKeyFromMutationParam: p => p.wildscreenId,
      }),
    ],
  };

  endpointSettings.asset.assetUploadAssetIdPut.mutation = {
    refetchQueries: [
      endpointIDs.asset.assetAssetIdGet({
        cacheKeyFromMutationParam: p => p.assetId,
      }),
    ],
  };

  endpointSettings.resource.resourceIdPut.mutation = {
    refetchQueries: [
      endpointIDs.resource.resourceIdGet({
        cacheKeyFromMutationParam: p => p.id,
      }),
    ],
  };

  endpointSettings.classificationPage.pageDocumentWildscreenIdPartialPut.mutation = {
    refetchQueries: [endpointIDs.classificationPage.pageDocumentWildscreenIdParentsGet()],
  };

  endpointSettings.libraryCredit.libraryCreditPost.mutation = {
    refetchQueries: [endpointIDs.libraryCredit.libraryCreditLibraryCreditIdGet()],
  };

  endpointSettings.libraryCredit.libraryCreditLibraryCreditIdPut.mutation = {
    refetchQueries: [
      endpointIDs.libraryCredit.libraryCreditLibraryCreditIdGet({ cacheKeyFromMutationParam: p => p.libraryCreditId }),
    ],
  };

  endpointSettings.libraryCredit.libraryCreditLibraryCreditIdDelete.mutation = {
    refetchQueries: [endpointIDs.libraryCredit.libraryCreditSearchGet()],
  };

  endpointSettings.taxonomy.taxonomySearchGet.query = {
    cacheKey: p => p?.search ?? '',
  };

  return endpointSettings;
};

/**
 * Responders - root factory creation point
 * ------------------------------------------
 * Factory for creating responders to handle advanced cache management.
 */
export const responderFactory = ApiHooksResponders.createFactory(apiClient);

export const MockEndpoints: ApiHooks.MockEndpointLibraryFactory<typeof apiClient> = emptyLibrary => {
  const mockEndpoints = { ...emptyLibrary };
  return mockEndpoints;
};

export const apiHooks = ApiHooks.create<typeof apiClient, IProcessingResponse>(apiClient, {
  generalConfig: {
    debugMode: false,
  },
  mutationConfig: {
    throwErrors: true,
  },
  hookConfigFactory,
  processingHook: useApiProcessing,
  queryConfig: {
    maxCachingDepth: 100,
    caching: {
      staleIfOlderThan: 1,
    },
  },
});
