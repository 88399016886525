import { routes, testIds, useDictionary } from '@wildscreen/core/src/core';
import { CmsLogo } from '@wildscreen/ui-components/src/components/logos/cms';
import * as React from 'react';
import { Link, useLocation } from 'react-router-dom';

import userCircle from '@wildscreen/ui-components/src/assets/svgs/UserCircle.svg';

import styles from './header.module.scss';

export const Header: React.FC = () => {
  const { pathname } = useLocation();
  const { admin } = useDictionary('en');
  return (
    <header data-testid={testIds.authenticated.components.header.admin.view} className={styles.header}>
      <div>
        <CmsLogo size="small" />
      </div>
      <div className={styles.navigation}>
        <Link
          data-testid={testIds.authenticated.components.header.admin.searchLink}
          data-on-page={pathname === routes.authenticated.admin.search.root()}
          to={routes.authenticated.admin.search.root()}
        >
          {admin.authenticated.views.search.heading}
        </Link>
        <Link
          data-testid={testIds.authenticated.components.header.admin.taxonomyLink}
          data-on-page={pathname === routes.authenticated.admin.taxonomy.root()}
          to={routes.authenticated.admin.taxonomy.root()}
        >
          {admin.authenticated.views.taxonomy.heading}
        </Link>
        <Link
          data-testid={testIds.authenticated.components.header.admin.assetGalleryLink}
          data-on-page={pathname === routes.authenticated.admin.assets.gallery()}
          to={routes.authenticated.admin.assets.gallery()}
        >
          {admin.authenticated.views.assetGallery.heading}
        </Link>
        <Link
          data-testid={testIds.authenticated.components.header.admin.libraryCreditLink}
          data-on-page={pathname === routes.authenticated.admin.libraryCredit.root()}
          to={routes.authenticated.admin.libraryCredit.root()}
        >
          {admin.authenticated.views.libraryCredit.heading}
        </Link>
        <Link
          data-testid={testIds.authenticated.components.header.admin.educationResourcesLink}
          data-on-page={pathname === routes.authenticated.admin.educationResources.root()}
          to={routes.authenticated.admin.educationResources.root()}
        >
          {admin.authenticated.views.educationResources.heading}
        </Link>
        <Link
          data-on-page={pathname === routes.authenticated.admin.account()}
          data-testid={testIds.authenticated.components.header.admin.accountLink}
          to={routes.authenticated.admin.account()}
        >
          <img src={userCircle} alt={admin.authenticated.views.account.heading} />
        </Link>
      </div>
    </header>
  );
};
