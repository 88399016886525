import { Button, Input } from '@rocketmakers/armstrong';
import { testIds, useDictionary } from '@wildscreen/core/src/core';
import * as React from 'react';

import { AuthenticatedView } from '../../components/authenticatedView';
import { useAuth } from '../../contexts';

import styles from './account.module.scss';

export const AccountView: React.FC = () => {
  const { admin } = useDictionary('en');
  const { currentAuthenticatedUser, logout } = useAuth();
  return (
    <AuthenticatedView
      data-testid={testIds.authenticated.views.account.view}
      heading={admin.authenticated.views.account.heading}
    >
      <div className={styles.container}>
        <Input
          label={admin.authenticated.views.account.email}
          disabled
          value={currentAuthenticatedUser?.attributes?.email}
        />
        <Button data-style="outline-black" onClick={logout} data-testid={testIds.authenticated.components.logoutButton}>
          {admin.authenticated.views.account.logoutButton}
        </Button>
      </div>
    </AuthenticatedView>
  );
};
