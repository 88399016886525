import { IBindingProps, useBindingState, ValidationErrors } from '@rocketmakers/armstrong';
import * as React from 'react';

import styles from './textarea.module.scss';

interface ITextAreaInputProps {
  bind: IBindingProps<string>;
  label: string;
}

export const TextAreaInput: React.FC<ITextAreaInputProps> = ({ bind, label, ...rest }) => {
  const [boundValue, boundOnChange, { validationErrorMessages }] = useBindingState(bind);

  return (
    <div className={styles.container}>
      <label htmlFor={label}>{label}</label>
      <textarea
        {...rest}
        placeholder="Enter text here..."
        id={label}
        value={boundValue}
        onChange={e => boundOnChange(e.target.value)}
      />
      <ValidationErrors validationErrors={validationErrorMessages} />
    </div>
  );
};
