import { Button, Input, useForm } from '@rocketmakers/armstrong';
import { MakeRequired, useDictionary } from '@wildscreen/core/src/core';
import { testIds } from '@wildscreen/core/src/core/testIds';
import { useOnKeyPress } from '@wildscreen/core/src/hooks/useOnKeyPress';
import { AuthView } from '@wildscreen/ui-components/src/components/authView';
import * as React from 'react';
import { z } from 'zod';

import { TForgotPasswordParameters, useAuth } from '../../contexts';

import styles from './auth.module.scss';

export const ForgotPasswordView: React.FC = () => {
  const { admin } = useDictionary('en');
  const { forgotPassword } = useAuth();

  const [forgotPasswordError, setForgotPasswordError] = React.useState<string | undefined>(undefined);
  const [success, setSuccess] = React.useState<boolean>(false);

  const { formProp, formState, validate } = useForm<TForgotPasswordParameters>(
    {
      username: undefined,
    },
    {
      validationMode: 'both',
      validationSchema: {
        username: z.string().email(admin.unauthenticated.views.login.emailValidation),
      },
    }
  );

  const onSubmit = React.useCallback(
    async (e?: React.FormEvent<HTMLFormElement>) => {
      e?.preventDefault();
      if (validate() && formState?.username) {
        try {
          await forgotPassword(formState as MakeRequired<TForgotPasswordParameters>);
          setSuccess(true);
          // eslint-disable-next-line @typescript-eslint/no-explicit-any -- ignore
        } catch (error: any) {
          setSuccess(false);
          setForgotPasswordError(error.message);

          setTimeout(() => {
            setSuccess(false);
            setForgotPasswordError(undefined);
          }, 5000);
        }
      }
    },
    [forgotPassword, formState, validate]
  );

  useOnKeyPress('Enter', () => onSubmit());

  return (
    <AuthView data-testid={testIds.unauthenticated.views.forgotPassword.view}>
      <div className={styles.authContainer}>
        <h1>{admin.unauthenticated.views.forgotPassword.title}</h1>
        <form onSubmit={onSubmit}>
          <Input
            required
            bind={formProp('username').bind()}
            validationMode="message"
            data-testid={testIds.unauthenticated.views.forgotPassword.email}
            label={admin.unauthenticated.views.forgotPassword.email}
          />
          {success ? <div>{admin.unauthenticated.views.forgotPassword.successMessage}</div> : null}
          <div className={styles.failed}>
            {forgotPasswordError && (
              <span data-testid={testIds.unauthenticated.views.forgotPassword.forgotPasswordFailed}>
                {forgotPasswordError}
              </span>
            )}
          </div>
          <Button type="submit" data-testid={testIds.unauthenticated.views.forgotPassword.forgotPasswordButton}>
            {admin.unauthenticated.views.forgotPassword.forgotPasswordButton}
          </Button>
        </form>
      </div>
    </AuthView>
  );
};
