import { Tag } from '@phosphor-icons/react';
import { IBindingProps, MultiSelect, useChildForm } from '@rocketmakers/armstrong';
import { testIds, useDictionary } from '@wildscreen/core/src/core';
import * as React from 'react';

import { useGetAssetTags } from '../../hooks/useGetAssetTags';
import { assetTagsKeys, TAssetTags } from '../../typings/asset';
import { ExpandableSection } from '../expandableSection';
import { SpeciesSearchInput } from '../speciesSearchInput';

import styles from './assetTags.module.scss';

export interface IAssetTagsProps {
  bind: IBindingProps<TAssetTags>;
}

export const AssetTags: React.FC<IAssetTagsProps> = ({ bind }) => {
  const [isOpen, setIsOpen] = React.useState(true);
  const { formProp } = useChildForm(bind);
  const { admin } = useDictionary('en');

  const { data, setSearchTerms } = useGetAssetTags();

  return (
    <div className={styles.container}>
      <ExpandableSection
        colour="asset"
        title={admin.authenticated.views.assets.contentTagging.heading}
        leftLogo={<Tag />}
        isOpen={isOpen}
        onToggle={() => setIsOpen(s => !s)}
      >
        <SpeciesSearchInput
          label={admin.authenticated.views.assets.contentTagging.species}
          bind={formProp('relatedClassifications').bind()}
        />
        {assetTagsKeys.map(x => (
          <MultiSelect
            key={x}
            onInputChange={e => {
              setSearchTerms(p => ({ ...p, [x]: e }));
            }}
            placeholder={admin.authenticated.views.editTaxonomy.placeholder}
            data-testid={testIds.authenticated.views.editTaxonomy.forms.tags.input(x)}
            options={data?.[x]}
            label={admin.authenticated.views.assets.contentTagging?.[x]}
            bind={formProp(x).bind()}
          />
        ))}
      </ExpandableSection>
    </div>
  );
};
