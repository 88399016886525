import { routes } from '@wildscreen/core/src/core';
import * as React from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import { Header } from '../header';

export const UnauthenticatedShell: React.FC<{}> = () => {
  return (
    <div data-testid="unauthenticated-shell">
      <main>
        <Outlet />
      </main>
    </div>
  );
};

export const AuthenticatedShell: React.FC<{}> = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  React.useEffect(() => {
    if (pathname === '/') {
      navigate(routes.authenticated.admin.search.root());
    }
  }, [navigate, pathname]);
  return (
    <div data-testid="authenticated-shell">
      <Header />
      <main>
        <Outlet />
      </main>
    </div>
  );
};
