import { WarningCircle } from '@phosphor-icons/react';
import { TTaxonomyKeys } from '@wildscreen/core/src/core/taxonomy';
import * as React from 'react';

import { TClassificationKey } from '../../types/classificationTypes';

import styles from './tag.module.scss';

export interface ITagProps {
  content?: string | React.ReactNode;
  classificationKey?: TClassificationKey;
  taxonomyKey?: TTaxonomyKeys;
  isPublished?: boolean;
  showPublished?: boolean;
  speciesTag?: boolean;
}

export const Tag: React.FC<React.PropsWithChildren<ITagProps>> = ({
  content,
  classificationKey,
  taxonomyKey,
  isPublished,
  showPublished,
  speciesTag,
}) => {
  return (
    <div
      data-species={speciesTag}
      data-taxonomy={taxonomyKey}
      data-classification={classificationKey}
      className={styles.container}
    >
      {showPublished && <div className={styles.published}>{!isPublished && <WarningCircle />}</div>}
      {content && <div className={styles.content}>{content}</div>}
    </div>
  );
};
