import { Upload } from '@phosphor-icons/react';
import { Button, Spinner } from '@rocketmakers/armstrong';
import { Resource } from '@wildscreen/api/src/apiClients';
import { formatAgeCategory, formatSubject, routes, testIds, useDictionary } from '@wildscreen/core/src/core';
import { useQueryString } from '@wildscreen/core/src/hooks/useQueryString';
import { EducationResourceIcon } from '@wildscreen/ui-components/src/components/educationResourceIcons';
import { Pagination } from '@wildscreen/ui-components/src/components/pagination/pagination';
import * as React from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { apiHooks } from '../../api';
import { AuthenticatedView } from '../../components/authenticatedView';

import styles from './educationResources.module.scss';

export const EducationResourcesView: React.FC = () => {
  const { admin } = useDictionary('en');
  const navigate = useNavigate();

  const { search, setQueryString } = useQueryString();

  const [{ data, isFetching }] = apiHooks.resource.resourceSearchPost.useQuery({
    parameters: {
      resourceQuery: {
        size: APP_CONFIG.resourcesPerPage,
        from: Number(search?.from) || 0,
      },
    },
  });

  React.useEffect(() => {
    setQueryString({ from: '0', p: '1' });
    // eslint-disable-next-line react-hooks/exhaustive-deps -- setting the initial query string
  }, []);

  const handlePageChange = React.useCallback(
    (pageNumber: number) => {
      let from = '0';
      if (pageNumber !== 1) {
        from = ((pageNumber - 1) * APP_CONFIG.resourcesPerPage).toString();
      }
      setQueryString({ from, p: pageNumber.toString() });
    },
    [setQueryString]
  );

  const groupedByAgeCat = React.useMemo(() => {
    if (!data) {
      return {};
    }
    return (
      data?.data?.results?.reduce((acc, resource) => {
        const cat = resource.ageCategory ?? 'Unknown';
        if (!acc[cat]) {
          acc[cat] = [];
        }
        acc[cat].push(resource);
        return acc;
      }, {} as Record<string, Resource[]>) ?? {}
    );
  }, [data]);

  return (
    <AuthenticatedView
      data-testid={testIds.authenticated.views.educationResources.view}
      heading={admin.authenticated.views.educationResources.heading}
      headerContent={
        <div className={styles.header}>
          <div className={styles.details}>
            <div className={styles.upload}>
              <Button
                onClick={() => navigate(routes.authenticated.admin.educationResources.create())}
                data-style="black"
                leftOverlay={<Upload />}
              >
                {admin.authenticated.views.educationResources.upload}
              </Button>
            </div>
          </div>
        </div>
      }
    >
      <>
        {!isFetching ? (
          <>
            {Object.entries(groupedByAgeCat).map(([key, resources]) => {
              return (
                <React.Fragment key={key}>
                  <h3 className={styles.sectionHeader}>{formatAgeCategory(key, true)}</h3>
                  <div className={styles.resourceGrid}>
                    {resources.map(resource => (
                      <Link
                        className={styles.resourceCard}
                        key={resource.resourceId}
                        to={routes.authenticated.admin.educationResources.edit({
                          educationResourcesId: resource.resourceId ?? undefined,
                        })}
                      >
                        <div className={styles.left}>
                          <h4>
                            {resource.title}
                            {!resource.isPublished && (
                              <>
                                &nbsp;<span className={styles.unpublished}>(unpublished)</span>
                              </>
                            )}
                          </h4>
                          {!!resource.subjects?.length && (
                            <div>
                              {admin.authenticated.views.educationResources.form.contentTagging.subjects}:{' '}
                              {resource.subjects?.map(formatSubject).join(', ')}
                            </div>
                          )}
                          {!!resource.ageCategory && (
                            <div>
                              {admin.authenticated.views.educationResources.form.contentTagging.ageCategory}:{' '}
                              {formatAgeCategory(resource.ageCategory)}
                            </div>
                          )}
                          {!!resource.type && (
                            <div>
                              {admin.authenticated.views.educationResources.form.contentTagging.type}: {resource.type}
                            </div>
                          )}
                        </div>
                        <div className={styles.right}>
                          <EducationResourceIcon resourceType={resource.type} />
                        </div>
                      </Link>
                    ))}
                  </div>
                </React.Fragment>
              );
            })}
          </>
        ) : (
          <div className={styles.loading}>
            <Spinner fillContainer />
            <h3>{admin.authenticated.views.educationResources.loading}</h3>
          </div>
        )}
        <Pagination
          totalItems={data?.data.total ?? 0}
          itemsPerPage={APP_CONFIG.resourcesPerPage}
          currentPage={search.p ? Number(search.p) : 1}
          onPageChange={handlePageChange}
        />
      </>
    </AuthenticatedView>
  );
};
