import { Auth } from '@aws-amplify/auth';
import { ApiHooks } from '@rocketmakers/api-hooks';
import { IValidationError, useDidUpdateEffect, useToast } from '@rocketmakers/armstrong';
import { formatValidationErrorsForArmstrong, ServerErrorResponseData } from '@wildscreen/api/src';
import { AuthTokenStore } from '@wildscreen/api/src/localStorage/tokenStore';
import { routes } from '@wildscreen/core/src/core';
import { AxiosError } from 'axios';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';

export interface IProcessingResponse {
  validationErrors: IValidationError[];
}

export const useApiProcessing: ApiHooks.ProcessingHook<
  IProcessingResponse,
  unknown,
  AxiosError<ServerErrorResponseData>
> = ({ hookType, error }) => {
  const dispatch = useToast();
  const navigate = useNavigate();

  const errorData = error?.response?.data;

  // Toast non-validation errors
  useDidUpdateEffect(() => {
    if (errorData && errorData.appCode === 'UnauthorisedError') {
      Auth.signOut();
      AuthTokenStore.clear();
      navigate(routes.unauthenticated.login());
    }

    if (errorData && errorData?.appCode !== 'ValidationError' && errorData.appCode !== 'NotFoundError') {
      if (dispatch) {
        dispatch({
          title: 'An error has occurred.',
        });
      }
    }
  }, [errorData, dispatch]);

  // Return validation errors for mutations
  const validationErrors = React.useMemo(() => {
    if (hookType === 'mutation' && errorData?.appCode === 'ValidationError') {
      return formatValidationErrorsForArmstrong(errorData?.errors);
    }
    return [];
  }, [errorData, hookType]);

  return { validationErrors };
};
