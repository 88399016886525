import * as React from 'react';

import { TLogoSize } from '../../../types';

import styles from './cms.module.scss';

export interface ICmsLogoProps {
  size: TLogoSize;
}

export const CmsLogo: React.FC<ICmsLogoProps> = ({ size }) => {
  return <div data-size={size} className={styles.cmsLogo} />;
};
