import { Tag } from '@phosphor-icons/react';
import { IBindingProps, MultiSelect, Select, useChildForm } from '@rocketmakers/armstrong';
import { testIds, useDictionary } from '@wildscreen/core/src/core';
import * as React from 'react';

import { useGetEducationResourceTags } from '../../hooks/useGetEducationResourceTags';
import {
  educationResourcePropertiesKeys,
  educationResourceTagsKeys,
  TResourceProperties,
  TResourceTags,
} from '../../typings/asset';
import { ExpandableSection } from '../expandableSection';

import styles from './educationResourceTags.module.scss';

export interface IEducationResourceTagsProps {
  bind: IBindingProps<TResourceTags & TResourceProperties>;
}

export const EducationResourceTags: React.FC<IEducationResourceTagsProps> = ({ bind }) => {
  const [isOpen, setIsOpen] = React.useState(true);
  const { formProp } = useChildForm(bind);
  const { admin } = useDictionary('en');

  const { data, setSearchTerms } = useGetEducationResourceTags();

  return (
    <div className={styles.container}>
      <ExpandableSection
        colour="asset"
        title={admin.authenticated.views.educationResources.form.contentTagging.heading}
        leftLogo={<Tag />}
        isOpen={isOpen}
        onToggle={() => setIsOpen(s => !s)}
      >
        {educationResourcePropertiesKeys.map(x => (
          <Select
            key={x}
            placeholder={admin.authenticated.views.editTaxonomy.placeholder}
            data-testid={testIds.authenticated.views.editTaxonomy.forms.tags.input(x)}
            options={data?.[x]}
            label={admin.authenticated.views.educationResources.form.contentTagging?.[x]}
            bind={formProp(x).bind() as IBindingProps<string>}
          />
        ))}
        {educationResourceTagsKeys.map(x => (
          <MultiSelect
            key={x}
            onInputChange={e => {
              setSearchTerms(p => ({ ...p, [x]: e }));
            }}
            placeholder={admin.authenticated.views.editTaxonomy.placeholder}
            data-testid={testIds.authenticated.views.editTaxonomy.forms.tags.input(x)}
            options={data?.[x]}
            label={admin.authenticated.views.educationResources.form.contentTagging?.[x]}
            bind={formProp(x).bind() as IBindingProps<(string | undefined)[]>}
          />
        ))}
      </ExpandableSection>
    </div>
  );
};
