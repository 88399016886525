import { ArrowLeft } from '@phosphor-icons/react';
import { Button, useForm, useToast } from '@rocketmakers/armstrong';
import { ResourceApiResourceIdPutRequest } from '@wildscreen/api/src/apiClients';
import { routes, TEducationResources, testIds, useDictionary } from '@wildscreen/core/src/core';
import * as React from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';

import { apiHooks } from '../../api';
import { AuthenticatedView } from '../../components/authenticatedView';
import { EducationResourceDetails } from '../../components/educationResourceDetails';
import { EducationResourceTags } from '../../components/educationResourceTags';
import { LastUpdated } from '../../components/lastUpdated';
import { educationResourceValidationSchema } from '../../helpers/validation';
import { IUpdateNewResourceForm } from '../../typings/asset';

import styles from './educationResourceForm.module.scss';

export const EditEducationResourceView: React.FC = () => {
  const { educationResourcesId } = useParams<TEducationResources>();
  const navigate = useNavigate();

  const { admin } = useDictionary('en');

  const [selectedFile, setSelectedFile] = React.useState<File | null>(null);

  const [{ data }] = apiHooks.resource.resourceIdGet.useQuery({
    parameters: {
      id: educationResourcesId,
    },
    cacheKey: 'id',
  });

  const [editResource, { isFetching: isUpdating }] = apiHooks.resource.resourceIdPut.useMutation();

  const dispatch = useToast();
  const { formProp, formState, isValid } = useForm<IUpdateNewResourceForm>(
    {
      details: {
        description: data?.data.description ?? '',
        title: data?.data.title ?? '',
      },
      tags: {
        ageCategory: data?.data.ageCategory,
        type: data?.data.type,
        subjects: data?.data.subjects ?? [],
        actions: data?.data.actions ?? [],
        biologicalThemes: data?.data.biologicalThemes ?? [],
        habitats: data?.data.habitats ?? [],
        landRegions: data?.data.landRegions ?? [],
        threats: data?.data.threats ?? [],
      },
    },
    { validationSchema: educationResourceValidationSchema }
  );

  const onSubmit = React.useCallback(
    async (e?: React.FormEvent<HTMLFormElement>) => {
      e?.preventDefault();
      let assetData: Partial<ResourceApiResourceIdPutRequest> = {
        id: educationResourcesId,
        resourceName: data?.data?.resourceName,
        ...formState?.details,
        ...formState?.tags,
        isPublished: true,
      };

      if (selectedFile) {
        assetData = { ...assetData, file: selectedFile, resourceName: selectedFile.name };
      }

      await editResource(assetData);
      dispatch({
        description: admin.authenticated.views.editTaxonomy.toasts.educationResource.published('published'),
        testId: testIds.authenticated.components.publishedToasts('success'),
      });
      navigate(routes.authenticated.admin.educationResources.root());
    },
    [
      educationResourcesId,
      data?.data?.resourceName,
      formState?.details,
      formState?.tags,
      selectedFile,
      editResource,
      dispatch,
      admin.authenticated.views.editTaxonomy.toasts.educationResource,
      navigate,
    ]
  );

  // Bring this back if un-publishing becomes a thing for education resources
  const onRemoveResource = React.useCallback(
    async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e?.preventDefault();
      const assetData: Partial<ResourceApiResourceIdPutRequest> = {
        id: educationResourcesId,
        resourceName: data?.data?.resourceName,
        ...formState?.details,
        ...formState?.tags,
        isPublished: false,
      };
      await editResource(assetData);

      dispatch({
        description: admin.authenticated.views.editTaxonomy.toasts.educationResource.published('unpublished'),
        testId: testIds.authenticated.components.publishedToasts('success'),
      });
    },
    [
      educationResourcesId,
      data?.data?.resourceName,
      formState?.details,
      formState?.tags,
      editResource,
      dispatch,
      admin.authenticated.views.editTaxonomy.toasts.educationResource,
    ]
  );

  const initialFile = React.useMemo(() => {
    return {
      src: data?.data?.location || undefined,
      fileName: data?.data?.resourceName,
    };
  }, [data?.data]);

  return (
    <AuthenticatedView
      data-testid={testIds.authenticated.views.educationResources.edit}
      headerContent={
        <div className={styles.header}>
          <div className={styles.linkAndAudit}>
            <Link to={routes.authenticated.admin.educationResources.root()} className={styles.navContainer}>
              <ArrowLeft />
              <h3>{admin.authenticated.views.educationResources.form.back}</h3>
            </Link>
            {data?.data?.updatedUserId && data?.data?.updatedTimestamp && (
              <LastUpdated removeMargin name={data?.data?.updatedUserId} date={data?.data?.updatedTimestamp} />
            )}
          </div>
          <div className={styles.details}>
            <h2>{admin.authenticated.views.educationResources.form.heading(true)}</h2>
          </div>
        </div>
      }
    >
      <form onSubmit={onSubmit}>
        <EducationResourceDetails
          bind={formProp('details').bind()}
          onFileSelected={setSelectedFile}
          initialFile={initialFile}
        />
        <EducationResourceTags bind={formProp('tags').bind()} />
        <div className={styles.actionButtons}>
          {data?.data?.isPublished && (
            <Button type="button" onClick={onRemoveResource} data-style="danger">
              {admin.authenticated.views.educationResources.form.removeResource}
            </Button>
          )}
          <Button pending={isUpdating} disabled={!isValid} type="submit" data-style="seaweed">
            {data?.data?.isPublished ? `Update` : 'Publish'}
          </Button>
        </div>
      </form>
    </AuthenticatedView>
  );
};
