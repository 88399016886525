import { IArrayOfZod } from '@rocketmakers/armstrong';
import { PropertyValuesSearchResult, TagSearchResult } from '@wildscreen/api/src/apiClients';
import { AxiosResponse } from 'axios';
import { z } from 'zod';

export function removeDuplicates(arr: string[]) {
  // Use a Set to check for duplicate values
  const valueSet = new Set();
  for (const item of arr) {
    if (valueSet.has(item)) {
      return false; // Duplicate found, validation fails
    }
    valueSet.add(item);
  }
  return true; // No duplicates found, validation passes
}

export const duplicatesErrorMessage = {
  message: 'Array should not contain duplicate values',
};

export const generateTagsFormValidationSchema = (maxNumberOfTags = 5) => {
  return {
    schema: {
      actions: z
        .array(z.string())
        .max(maxNumberOfTags, {
          message: `Please select no more than ${maxNumberOfTags} actions`,
        })
        .refine(removeDuplicates, duplicatesErrorMessage) as unknown as IArrayOfZod<string>,
      adaptations: z
        .array(z.string())
        .max(maxNumberOfTags, {
          message: `Please select no more than ${maxNumberOfTags} adaptations`,
        })
        .refine(removeDuplicates, duplicatesErrorMessage) as unknown as IArrayOfZod<string>,
      biologicalThemes: z
        .array(z.string())
        .max(maxNumberOfTags, {
          message: `Please select no more than ${maxNumberOfTags} biological themes`,
        })
        .refine(removeDuplicates, duplicatesErrorMessage) as unknown as IArrayOfZod<string>,
      habitat: z
        .array(z.string())
        .max(maxNumberOfTags, {
          message: `Please select no more than ${maxNumberOfTags} habitat`,
        })
        .refine(removeDuplicates, duplicatesErrorMessage) as unknown as IArrayOfZod<string>,
      landRegion: z
        .array(z.string())
        .max(maxNumberOfTags, {
          message: `Please select no more than ${maxNumberOfTags} land region`,
        })
        .refine(removeDuplicates, duplicatesErrorMessage) as unknown as IArrayOfZod<string>,
      threats: z
        .array(z.string())
        .max(maxNumberOfTags, {
          message: `Please select no more than ${maxNumberOfTags} threats`,
        })
        .refine(removeDuplicates, duplicatesErrorMessage) as unknown as IArrayOfZod<string>,
    },
  };
};

export const generateAssetTagsFormValidationSchema = (maxNumberOfTags = 5) => {
  return {
    schema: {
      relatedClassifications: z
        .array(z.string())
        .min(1, { message: 'Please select at least one species' })
        .max(maxNumberOfTags, {
          message: `Please select no more than ${maxNumberOfTags} adaptations`,
        })
        .refine(removeDuplicates, duplicatesErrorMessage) as unknown as IArrayOfZod<string>,
      adaptations: z
        .array(z.string())
        .max(maxNumberOfTags, {
          message: `Please select no more than ${maxNumberOfTags} adaptations`,
        })
        .refine(removeDuplicates, duplicatesErrorMessage) as unknown as IArrayOfZod<string>,
      behaviours: z
        .array(z.string())
        .max(maxNumberOfTags, {
          message: `Please select no more than ${maxNumberOfTags} behaviours`,
        })
        .refine(removeDuplicates, duplicatesErrorMessage) as unknown as IArrayOfZod<string>,
      habitats: z
        .array(z.string())
        .max(maxNumberOfTags, {
          message: `Please select no more than ${maxNumberOfTags} habitat`,
        })
        .refine(removeDuplicates, duplicatesErrorMessage) as unknown as IArrayOfZod<string>,
      lifeStages: z
        .array(z.string())
        .max(maxNumberOfTags, {
          message: `Please select no more than ${maxNumberOfTags} life stages`,
        })
        .refine(removeDuplicates, duplicatesErrorMessage) as unknown as IArrayOfZod<string>,
      regions: z
        .array(z.string())
        .max(maxNumberOfTags, {
          message: `Please select no more than ${maxNumberOfTags} land region`,
        })
        .refine(removeDuplicates, duplicatesErrorMessage) as unknown as IArrayOfZod<string>,
      generalContentDescriptors: z
        .array(z.string())
        .max(maxNumberOfTags, {
          message: `Please select no more than ${maxNumberOfTags} general content descriptors`,
        })
        .refine(removeDuplicates, duplicatesErrorMessage) as unknown as IArrayOfZod<string>,
    },
  };
};

export function mapTags(data: AxiosResponse<TagSearchResult, any> | undefined) {
  return data?.data?.tags?.map(p => ({ id: p.name, content: p.name })) ?? [];
}

export function mapResourceCategories(
  data: AxiosResponse<PropertyValuesSearchResult, any> | undefined,
  format?: (value: string) => string
) {
  return data?.data?.values?.map(p => ({ id: p, content: format ? format(p) : p })) ?? [];
}
