import { testIds, useDictionary } from '@wildscreen/core/src/core';
import * as React from 'react';

import { AuthenticatedView } from '../../components/authenticatedView';

export const HomepageContentView: React.FC = () => {
  const { admin } = useDictionary('en');
  return (
    <AuthenticatedView
      debug
      data-testid={testIds.authenticated.views.homepageContent.view}
      heading={admin.authenticated.views.homepageContent.heading}
    />
  );
};
