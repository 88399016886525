import * as React from 'react';

type TUseTreeResponse = {
  nextWildscreenId: string;
  nextTreeIndex: number;
  setNextTreeIndexAndWildscreenId: (index: number, wildscreenId: string) => void;
  openItem: string | null;
  setOpenItem: React.Dispatch<React.SetStateAction<string | null>>;
  toggleItem: (itemName: string) => void;
};

/**
 * @name useTree
 * @description
 * This hook is used to manage the state of the tree component.
 * @returns {object} - An object containing the nextWildscreenId, setNextWildscreenId, openItem, setOpenItem, toggleItem.
 */
export function useTree(): TUseTreeResponse {
  const [nextWildscreenId, setNextWildscreenId] = React.useState('');
  const [nextTreeIndex, setNextTreeIndex] = React.useState(0);
  const [openItem, setOpenItem] = React.useState<string | null>(null);

  const toggleItem = (itemName: string) => {
    if (openItem === itemName) {
      setOpenItem(null);
    } else {
      setOpenItem(itemName);
    }
  };

  const setNextTreeIndexAndWildscreenId = (index: number, wildscreenId: string) => {
    // Update the component state
    setNextTreeIndex(index);
    setNextWildscreenId(wildscreenId);
  };

  return {
    nextWildscreenId,
    nextTreeIndex,
    setNextTreeIndexAndWildscreenId,
    openItem,
    setOpenItem,
    toggleItem,
  };
}
