import { IArmstrongOption, IBindingProps, Label, useBindingState, useDebounce } from '@rocketmakers/armstrong';
import { TaxonRank } from '@wildscreen/api/src/apiClients';
import { Tag } from '@wildscreen/ui-components/src/components/tag';
import * as React from 'react';
import { components, OnChangeValue, Theme } from 'react-select';
import Select from 'react-select/async';

import { apiHooks } from '../../api';

import styles from './speciesSearchInput.module.scss';

interface IProps {
  bind: IBindingProps<string[]>;
  label?: string;
}

function getOptionLabel(option?: IArmstrongOption<string, unknown>) {
  return option?.content?.toString() ?? '';
}

function getOptionValue(option?: IArmstrongOption<string, unknown>): string {
  return option?.id ?? '';
}

function getTheme(theme: Theme) {
  return {
    ...theme,
    colors: {
      ...theme.colors,
      primary: '#181818',
    },
  };
}

function useGetSpeciesNames(currentWildscreenIds: string[]) {
  const [names, setNames] = React.useState<{ id: string; content: string; isPublished: boolean }[]>([]);
  const getNames = apiHooks.taxonomy.taxonomyDocumentWildscreenIdGet.useRequest();

  React.useEffect(() => {
    const getAllNames = async () => {
      const promises = await Promise.all(
        currentWildscreenIds.map(async id => {
          const res = await getNames({ wildscreenId: id });

          return {
            id,
            content: res?.data?.results?.[0].vernacularNames?.[0] || res?.data?.results?.[0].gbifScientificName || '',
            isPublished: !!res.data?.results?.[0].publishState,
          };
        })
      );

      setNames(promises);
    };
    getAllNames();
  }, [currentWildscreenIds, getNames, setNames]);

  return names;
}

export const SpeciesSearchInput: React.FC<IProps> = ({ bind, label, ...nativeProps }) => {
  const [value, setValue] = useBindingState(bind);

  const [actualValue, setActualValue, throttledValue] = useDebounce<string>(100);
  const searchData = apiHooks.taxonomy.taxonomySearchGet.useRequest();

  const handleChange = React.useCallback(
    (newValue: OnChangeValue<unknown, true>) => {
      const castValue = newValue as IArmstrongOption<string>[];
      setValue?.([...castValue.map(v => v.id)]);
      setActualValue('');
    },
    [setActualValue, setValue]
  );
  const speciesNames = useGetSpeciesNames(value || []);

  const onInputChange = (query, { action }) => {
    if (action === 'input-change') {
      setActualValue(query);
      return query;
    }
    return query;
  };

  const loadOptions = (inputValue: string) =>
    new Promise<IArmstrongOption<string>[]>(resolve => {
      setTimeout(async () => {
        if (inputValue.length > 1) {
          const foundResults = await searchData({
            search: inputValue,
            from: 0,
            size: 5,
            taxonRank: TaxonRank.Species,
            // Ignore everything that isn't accepted or a proparte synonym
            ignoreSynonyms: true,
          });
          const speciesOptions =
            foundResults?.data?.results?.map(species => {
              let displayName = `${species.suggestedName} (${species.gbifTaxonomicStatus})`;

              // If there are any vernacularNames for the species, add up to 3 of them to the displayName
              if (species.vernacularNames && species.vernacularNames.length > 0) {
                const extraNames = species.vernacularNames.slice(0, 3);

                // Join the extra names in to a comma-separated string
                displayName += ` | ${extraNames.join(', ')}`;
              }

              return {
                id: species.wildscreenId || '',
                content: displayName,
              };
            }) || [];
          resolve(speciesOptions);
        }
        return resolve([]);
      }, 100);
    });

  const selectedValue = React.useMemo(() => {
    return speciesNames?.filter(o => value?.some(v => v === o.id));
  }, [speciesNames, value]);

  return (
    <>
      <Label className="arm-label arm-select-label" required>
        {label}
      </Label>
      <Select
        {...nativeProps}
        isMulti
        cacheOptions
        components={{
          ClearIndicator: undefined,
          CrossIcon: undefined,
          DropdownIndicator: undefined,
          MultiValueRemove: ({ ...props }) => <components.MultiValueRemove {...props}>x</components.MultiValueRemove>,
          MultiValueContainer: ({ children }) => {
            const foundResult = speciesNames.find(x => x.content === children);
            return <Tag content={children} isPublished={foundResult?.isPublished} showPublished speciesTag />;
          },
        }}
        onChange={handleChange}
        loadOptions={loadOptions}
        getOptionLabel={getOptionLabel}
        getOptionValue={getOptionValue}
        inputValue={actualValue}
        placeholder="Search for a species..."
        value={selectedValue || []}
        onInputChange={onInputChange}
        theme={getTheme}
        classNames={{
          container: () => styles.container,
          multiValueRemove: () => styles.multiValueRemove,
          control: () => 'arm-select__control',
          valueContainer: () => styles.valueContainer,
          menuList: () => styles.generic,
        }}
      />
    </>
  );
};
